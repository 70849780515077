/**
 * 数据中心配置信息
 */
export class DataCenter {
    /**
     * 连接协议，默认ws://
     */
    protocol = 'ws://';
    ip: string;
    port = 5001;
}
