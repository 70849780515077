
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='createModule($event)'>
                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>创建模块
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='importPage($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage($event)'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
        </ul>
    