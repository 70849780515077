import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boolPipe'
})

export class BoolPipe implements PipeTransform {
    transform(item: any): boolean {
        if (typeof item === 'boolean') {
            return item;
        }
        if (typeof item === 'number') {
            return item > 0;
        }
        if (typeof item === 'string') {
            return item.toLowerCase() === 'true';
        }
        return false;
    }
}
