export class MathUtil {

    /**
     * 获取唯一id
     * @param number 长度，默认10
     */
    static getUUid(num: number = 10): string {
        return Number(Math.random().toString().substring(2, num) + Date.now()).toString(36);
    }

    /**
     * 获取随机字符串（可能重复）
     * @param length 字符串长度
     * @returns 
     */
    static getRandomStr(length) {
        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let values = new Uint32Array(length);
        window.crypto.getRandomValues(values);
        let str = '';
        for (let i = 0; i < length; i++) {
            str += charset[values[i] % charset.length];
        }
        return str;
    }
}
