import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService } from '../_services';
import { NameInputComponent, SaveSvgPageComponent, PageUpLoadComponent, IconManageComponent, ModulePropComponent} from '../_modals';
import { Module, Page } from '../_models';
import { ModuleApi } from '../api';
import { PageApi } from '../api/pageApi';
import { DataCache } from '../cache';

/**
 * 模块右键菜单
 */
@Component({
    selector: 'app-module-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y,'z-index':999}" (contextmenu)='menuRightClick($event)'>
            <li nz-menu-item (click)='reNameModule()'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <li nz-menu-item (click)='setIcon(false)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标
            </li>
            <li nz-menu-item (click)='setIcon(true)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标(激活状态)
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createModule()'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>创建模块
            </li>
            <li nz-menu-item (click)='deleteModule()' [nzDisabled]=!enableDeleteMenu>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模块
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='importPage($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage()'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setProperty($event)'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 224px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})
export class ModuleContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() module: Module;
    @Input() enableDeleteMenu: boolean;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private moduleApi: ModuleApi,
        private pageApi: PageApi
    ) { }

    /**
     * 重命名
     */
    reNameModule() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '模块-重命名',
            nzComponentParams: {
                tipMsg: '请输入新的模块名称...',
                inputName: this.module.text
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async res => {
                if (res) {
                    if (res === this.module.text) {
                        this.message.warning('新名称与原名称相同！');
                        return false;
                    }
                    const ret = await this.moduleApi.rename(this.module.id, res);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                    }
                }
                return res;
            })
        });
    }

    /**
     * 设置菜单图标
     */
    setIcon(isActive) {
        this.eventTransService.menuStateChangeEvent.next({});
        const icon = isActive ? this.module.iconActive : this.module.icon;
        this.modalService.create({
            nzTitle: '模块-图标设置',
            nzWidth: '80%',
            nzComponentParams: {
                selIconId: icon
            },
            nzOkText: '确认选择',
            nzContent: IconManageComponent,
            nzMaskClosable: true,
            nzOnOk: (ins) => ins.setIcon().then(async res => {
                if (!res) {
                    return false;
                }
                if (res.icon === icon) {
                    return true;
                }

                if (isActive) {
                    if (await this.moduleApi.setIconActive(this.module.id, res.icon)) {
                        this.message.success('操作成功！');
                        this.module.iconActive = res.icon;
                    }
                    return true;
                }
                if (await this.moduleApi.setIcon(this.module.id, res.icon)) {
                    this.message.success('操作成功！');
                    this.module.icon = res.icon;
                }
                return true;
            })
        });
    }

    /**
     * 创建模块
     */
    createModule() {
        this.eventTransService.menuStateChangeEvent.next({});
        let parentId = this.module.id;
        if (this.module.parentId) {
            parentId = this.module.parentId;
        }
        this.modalService.create({
            nzTitle: '模块-创建',
            nzComponentParams: {
                tipMsg: '请输入模块名称...',
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async res => {
                if (res) {
                    const ret = await this.moduleApi.createWithNameAndParent(parentId, res);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                    }
                }
                return res;
            })
        });
    }

    /**
     * 删除模块
     */
    deleteModule() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!this.enableDeleteMenu) {
            return;
        }
        this.modalService.confirm({
            nzTitle: `<i>确认删除模块：${this.module.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.moduleApi.delete(this.module.id);
                if (ret) {
                    this.message.success('操作成功');
                    this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                }
            }
        });
    }

    /**
     * 创建页面
     */
    createPage() {
        this.modalService.create({
            nzTitle: '创建页面',
            nzComponentParams: {
                moduleId: this.module.id
            },
            nzContent: SaveSvgPageComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async (page: Page) => {
                if (page) {
                    const ret = await this.pageApi.createEmpty(page);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadModuleEvent.next(this.module.id);
                    }
                }
                return page;
            })
        });
    }

    /**
     * 鼠标右键-阻止默认菜单
     * @param event 事件
     */
    menuRightClick(event: Event) {
        event.preventDefault();
    }

    /**
     * 导入页面
     * @param event 事件
     */
    importPage(event: Event) {
        this.modalService.create({
            nzTitle: '页面导入',
            nzComponentParams: {
                moduleId: this.module.id
            },
            nzContent: PageUpLoadComponent,
            nzOkText: '开始导入',
            nzOnOk: (ins) => ins.save()
        });
    }

    /**
     * 属性设置
     * @param event 事件
     */
    setProperty(event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '模块-属性设置',
            nzComponentParams: {
                module: this.module,
            },
            nzContent: ModulePropComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async (module: Module) => {
                if (module) {
                    const ret = await this.moduleApi.setProperty(module);
                    if (ret) {
                        this.message.success('操作成功');
                        this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                    }
                }
                return module;
            })
        });

    }
}

