import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->组件
 */
@Injectable({ providedIn: 'root' })
export class ComponentApi {
    private prefix = '/design/component';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 获取分类下所有组件数据
     * @param classifyId 分类id
     */
    async getAll(classifyId: string) {
        if (!classifyId) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        return await this.http.get(`${this.prefix}/getAll`, { classifyId });
    }

    /**
     * 组件上传
     * @param file 组件
     * @param classifyId 分类id
     */
    async upload(file: any, classifyId) {
        if (!file || !classifyId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/upload`, file, { classifyId });
    }

    /**
     * 重命名
     * @param id 组件id
     * @param name 组件名称
     */
    async rename(id: string, name: string) {
        if (!id || !name) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/rename`, { id, name });
    }

    /**
     * 删除
     * @param id 组件id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[组件id]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 更换组件分类
     * @param id 组件id
     * @param classifyId 分类id
     */
    async setParent(id: string, classifyId: string) {
        if (!id || !classifyId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setParent`, { id, classifyId });
    }

    /**
     * 获取组件内容
     * @param id 组件id
     */
    async getContent(id: string) {
        if (!id) {
            this.message.warning('参数错误[组件id]');
            return;
        }
        return await this.http.get(`${this.prefix}/getContent`, { id });
    }

    /**
     * 保存为组件
     */
    async save(data: { name: string, classifyId: string, content: string }) {
        if (!data.name || !data.classifyId || !data.content) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post(`${this.prefix}/save`, data);
    }
}
