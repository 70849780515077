import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataCache } from '../cache/dataCache';
import { ApiResult } from '../_models';
import { EventTransService } from './eventTrans.service';
import { timeout } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    })
};

const httpOptionsJson = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class HttpService {
    constructor(
        private http: HttpClient,
        private message: NzMessageService,
        private eventTransService: EventTransService
    ) {
        DataCache.init();
    }

    api(): string {
        if (!DataCache.sysConfig) {
            return;
        }
        return `${DataCache.sysConfig.domin}/${DataCache.sysConfig.version}`;
    }

    handle(resolve, reject, data) {
        if (data) {
            if (data.code === '0') {
                resolve(data.data);
                return;
            }
            let msg = '请求发生错误';
            if (data.msg) {
                msg = data.msg;
            }
            this.message.warning(`${msg} [错误码：${data.code}]`);
            reject();
        } else {
            this.message.warning(`请求发生了未经处理的异常，请联系管理员检查！`);
            reject();
        }
    }

    /**
     * GET 请求
     * @param url api
     * @param params 请求参数对象
     * @returns Promise
     */
    async get(url: string, params?): Promise<any> {
        try {
            const promise = new Promise((resolve, reject) => {
                if (!params) {
                    params = {};
                }
                let body = new HttpParams();
                for (const key in params) {
                    if (key) {
                        body = body.append(key, params[key]);
                    }
                }

                this.http.get<ApiResult>(this.api() + url,
                    {
                        params,
                        headers: httpOptions.headers
                    })
                    .subscribe(data => {
                        this.handle(resolve, reject, data);
                    }, err => {
                        this.message.error(err);
                        reject();
                    });
            });

            return await promise;
        } catch (err) {
            console.log(`%cHttpService->GET ${this.api()}${url} ERROR：` + err, 'color:red');
            this.eventTransService.globalLoadingEvent.next('');
            return null;
        }
    }

    /**
     * POST请求（json）
     * @param url api
     * @param params 参数对象
     */
    async post(url: string, params?): Promise<any> {
        try {
            const promise = new Promise((resolve, reject) => {
                if (!params) {
                    params = {};
                }

                this.http.post<ApiResult>(this.api() + url, params, httpOptionsJson)
                .pipe(timeout(600000))
                    .subscribe(data => {
                        this.handle(resolve, reject, data);
                    }, err => {
                        this.message.error(err);
                        reject();
                    });
            });

            return await promise;
        } catch (err) {
            console.log(`%cHttpService->POST(json) ${this.api()}${url} ERROR：` + err, 'color:red');
            this.eventTransService.globalLoadingEvent.next('');
            return null;
        }
    }

    /**
     * POST请求（query）
     * @param url api
     * @param params 请求参数对象
     */
    async post_str(url: string, params?): Promise<any> {
        try {
            const promise = new Promise((resolve, reject) => {
                if (!params) {
                    params = {};
                }
                let httpParam = new HttpParams();
                for (const key in params) {
                    if (key) {
                        httpParam = httpParam.append(key, params[key]);
                    }
                }
                this.http.post<ApiResult>(this.api() + url, httpParam, httpOptions)
                .pipe(timeout(600000))
                    .subscribe(data => {
                        this.handle(resolve, reject, data);
                    }, err => {
                        this.message.error(err);
                        reject();
                    });
            });

            return await promise;
        } catch (err) {
            console.log(`%cHttpService->POST ${this.api()}${url} ERROR：` + err, 'color:red');
            this.eventTransService.globalLoadingEvent.next('');
            return null;
        }
    }

    /**
     * 文件下载 （post / blob）
     * @param url api
     * @param params 请求参数
     */
    async download(url: string, params?): Promise<any> {
        try {
            const promise = new Promise((resolve, reject) => {
                if (!params) {
                    params = {};
                }
                let httpParam = new HttpParams();
                for (const key in params) {
                    if (key) {
                        httpParam = httpParam.append(key, params[key]);
                    }
                }

                this.http.post(this.api() + url, httpParam, {
                    responseType: 'blob',
                    headers: httpOptions.headers
                }).subscribe(data => {
                    resolve(data);
                }, err => {
                    this.message.error(err);
                    reject();
                });
            });

            return await promise;
        } catch (err) {
            console.log(`%cHttpService->download ${this.api()}${url} ERROR：` + err, 'color:red');
            this.eventTransService.globalLoadingEvent.next('');
            return null;
        }
    }

    /**
     * 文件上传
     * @param url api
     * @param file 文件对象
     * @param params 其他参数对象
     */
    async uploadFile(url, file: any, params?) {
        try {
            const promise = new Promise((resolve, reject) => {
                const formData: FormData = new FormData();
                formData.append('file', file);
                if (!params) {
                    params = {};
                }
                let httpParam = new HttpParams();
                for (const key in params) {
                    if (key) {
                        httpParam = httpParam.append(key, params[key]);
                    }
                }

                this.http.post<ApiResult>(this.api() + url, formData, { params: httpParam })
                    .subscribe(data => {
                        this.handle(resolve, reject, data);
                    }, err => {
                        this.message.error(err);
                        reject();
                    });
            });

            return await promise;
        } catch (err) {
            console.log(`%cHttpService->uploadFile ${this.api()}${url} ERROR：` + err, 'color:red');
            this.eventTransService.globalLoadingEvent.next('');
            return null;
        }
    }
}
