import { Component } from '@angular/core';

@Component({
    selector: 'app-help-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.css']
})
export class RadioHelpComponent {
    constructor(
    ) {
    }
}

