
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ThemeApi } from '../api';
import { Theme } from '../_models';
import { DataCache } from '../cache';

/**
 * 创建工程
 */
@Component({
    selector: 'app-create-project',
    template: `
        <input nz-input placeholder="请输入工程名称..." [(ngModel)]="name" autofocus />
        <div style='margin-top:10px;'></div>
        <label style='width:100%;'>请选择主题：</label>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <div nz-row [nzGutter]="4">
                <div nz-col [nzSpan]="4" *ngFor="let item of themes"  (click)="setTheme(item)">
                    <nz-card nzHoverable [nzCover]="coverTemplate" [nzTitle]="item.name" [nzExtra]="extraTemplate">
                        <nz-card-meta></nz-card-meta>
                    </nz-card>
                    <ng-template #coverTemplate>
                        <img alt="主题" [src]="item.preview" *ngIf="item.preview!=''" (dblclick)="preview(item)"/>
                    </ng-template>
                    <ng-template #extraTemplate>
                        <label nz-radio [(ngModel)]="item.id==themeId" (click)="setTheme(item)"></label>
                    </ng-template>
                </div>
            </div>
        </nz-spin>
        <nz-modal
            [(nzVisible)]="isVisible"
            [nzTitle]="previewTheme.name"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
            [nzFooter]="null"
            nzWidth="70%"
            nzHeight="70%"
        >
            <img *ngIf="isVisible" [src]="previewTheme.preview" style="width:100%;height:100%;"/>
        </nz-modal>
    `,
    styles: [
        `   
            input {
                margin-bottom: 10px;
            }

        `
    ]
})
export class CreateProjectComponent implements OnInit, OnDestroy {
    //工程名
    name: string;
    //工程主题
    themeId: string;

    themes: Theme[] = [];
    isLoading = true;
    isVisible = false;

    previewTheme: Theme = new Theme();

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private themeApi: ThemeApi
    ) { }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.themes = await this.themeApi.getAll();
        for (const theme of this.themes) {
            theme.preview = DataCache.sysConfig.domin + theme.preview;
        }
        this.isLoading = false;
    }

    setTheme(theme: Theme) {
        this.themeId = theme.id;
    }

    preview(theme: Theme) {
        this.previewTheme = theme;
        this.isVisible = true;
    }

    handleCancel() {
        this.isVisible = false;
    }

    handleOk() {
        this.isVisible = false;
    }

    save() {
        return new Promise(resolve => {
            if (!this.name) {
                this.message.warning('请输入工程名称！');
                resolve({ name: false });
                return;
            }
            resolve({
                name: this.name,
                themeId: this.themeId
            });
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
