/**
 * 属性名称
 */
export class DataAttrName {
    static id = 'data-attr-id';
    static iid = 'data-attr-iid';

    static meterType = 'data-attr-meter-type';
    /**
     * 指令类型
     */
    static orderType = 'data-attr-order-type';
    static text = 'data-attr-text';
    static type = 'data-attr-type';
    static chartType = 'data-attr-chart-type';

    static uid = 'data-attr-uid';
    static border = 'data-attr-border';
    static bgColor = 'data-attr-background-color';
    static bgOpacity = 'data-attr-background-opacity';
    static opacity = 'data-attr-opacity';

    static event = 'data-attr-event';
    static source = 'data-attr-source';
    static template = 'data-attr-template';

    static sourceMerge = 'data-attr-source-merge';
    static eventMerge = 'data-attr-event-merge';

    static display = 'data-attr-display';
    static level = 'data-attr-level';

    static src = 'data-attr-src';
    static borderColor = 'data-attr-border-color';
    static borderWidth = 'data-attr-border-width';
    static borderStyle = 'data-attr-border-style';
    static showBorder = 'data-attr-show-border';

    static len = 'data-attr-length';

    static icon = 'data-attr-icon';

    static value = 'data-attr-value';
    static index = 'data-attr-index';
    static items = 'data-attr-items';
    static iconKey = 'data-attr-icon-key';

    static accuracy = 'data-attr-accuracy';
    static minuteAccuracy = 'data-attr-minute-accuracy';

    static model = 'data-attr-model';
    static margin = 'data-attr-margin';

    static onKey = 'data-attr-on-key';
    static offKey = 'data-attr-off-key';
    static onText = 'data-attr-on-text';
    static offText = 'data-attr-off-text';

    static search = 'data-attr-search';
    static async = 'data-attr-async';
    static treeSource = 'data-attr-tree-source';
    static defaultSelect = 'data-attr-default-select';

    static clear = 'data-attr-clear';

    static merge = 'merge';

    static config = 'data-attr-config';
    /**
     * 动画属性
     */
    static anime = 'data-attr-anime';
    /**
     * 动画属性id
     */
    static animeId = 'data-attr-anime-id';
    /**
     * 系统html组件名称标志
     */
    static componentName = 'data-component-name';
    /**
     * 进度条基础色
     */
    static baseColor = 'data-attr-base-color';
    /**
     * 进度条主题色
     */
    static themeColor = 'data-attr-theme-color';
    /**
     * 显示文本
     */
    static showText = 'data-attr-show-text';
    /**
     * 弧度
     */
    static radian = 'data-attr-radian';

    /**
     * 所属列表面版id
     */
    static panel = 'data-attr-panel';
    /**
     * 高度
     */
    static height = 'data-attr-height';
    /**
     * 保持宽高比
     */
    static keepRatio = 'data-attr-keep-ratio';
    /**
     * 禁止选择
     */
    static disableSelect = 'data-attr-disable-select';
    /**
     * 设备序列号
     */
    static deviceSerial = 'data-attr-device-serial';
    /**
     * 通道号
     */
    static channel = 'data-attr-channel';
    /**
     * 清晰度
     */
    static definition = 'data-attr-definition';
    /**
     * 验证码
     */
    static code = 'data-attr-code';
    /**
     * 字体颜色
     */
    static textColor = 'data-attr-text-color';

    /**
     * 渐变色开始
     */
    static gradientStartColor = 'data-attr-gradient-start-color';
    /**
     * 渐变色结束
     */
    static gradientStopColor = 'data-attr-gradient-stop-color';
    /**
     * 显示外环
     */
    static showCircle = 'data-attr-show-circle';
    /**
     * 外环颜色
     */
    static circleColor = 'data-attr-circle-color';
    /**
     * 点击不改变状态
     */
    static clickNoneCheck = 'data-attr-click-none-check';
    static tooltip = 'data-attr-tooltip';
}
