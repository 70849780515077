/**
 * 画布相关数据
 */
export class CanvasConstData {
    /**
     * 选中组件包含的样式名称
     */
    static subjxClass = 'sjx-drag';
    /**
     * 选中组件包含的样式名称
     */
    static operateClass = 'sjx-svg-wrapper';
    /**
     * 文本控件id
     */
    static TEXT_ID = 'tw-dev-web-design-text';
    /**
     * 复选框控件id
     */
    static CHECKBOX_ID = 'tw-dev-web-design-checkbox';
    /**
     * 水平线控件id
     */
    static LINE_HOR_ID = 'tw-dev-web-design-line-hor';
    /**
     * 竖线控件id
     */
    static LINE_VER_ID = 'tw-dev-web-design-line-ver';
    /**
     * 矩形控件id
     */
    static RECT_ID = 'tw-dev-web-design-rect';
    /**
     * 文本输入框id
     */
    static INPUT_TEXT_ID = 'tw-dev-web-design-input-text';
    /**
     * 数字输入框id
     */
    static INPUT_NUMBER_ID = 'tw-dev-web-design-input-number';
    /**
     * 按钮
     */
    static BUTTON = 'tw-dev-web-design-button';
    /**
     * 复选框
     */
    static CHECKBOX = 'tw-dev-web-design-checkbox';

    /**
     * 系统组件分类id
     */
    static SYS_COM_CLASSIFY_ID = 'sys';
}
