import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Project, User } from '../_models';
import { DeleteInputComponent } from './deleteInput.component';
import { ProjectApi } from '../api';

@Component({
  selector: 'app-project-manage',
  template: `
    <input nz-input placeholder="请输入工程名称" [(ngModel)]="projectName" style="width:50%;" autofocus />
    <button nz-button nzType="primary" (click)="projectQuery()">查询</button>
    <button nz-button nzType="primary" nzDanger (click)="delete()">删除</button>
    <button nz-button nzType="dashed" (click)="export()">导出</button>
    <nz-table
      #editRowTable nzBordered
      [nzData]="listOfData"
      nzShowSizeChanger
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <thead>
        <tr>
          <th></th>
          <th>工程名称</th>
          <th>创建时间</th>
          <th>创建人</th>
          <th>是否系统工程</th>
          <th nzWidth="120px">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data" (click)="trClick(data.id)">
          <td nzShowCheckbox [(nzChecked)]="data.id==id" (nzCheckedChange)="onCheckChange($event,data.id)"></td>
          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else nameInputTpl">
              {{ data.name }}
            </ng-container>
            <ng-template #nameInputTpl>
              <input type="text" nz-input [(ngModel)]="editCache[data.id].data.name" />
            </ng-template>
          </td>
          <td>
            <ng-container>
              {{ data.createTime }}
            </ng-container>
          </td>
          <td>
            <ng-container>
              {{ data.creator }}
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else sysInputTpl">
              <label nz-checkbox [(ngModel)]="data.main" nzDisabled ></label>
            </ng-container>
            <ng-template #sysInputTpl>
              <label nz-checkbox [(ngModel)]="editCache[data.id].data.main"></label>
            </ng-template>
          </td>
          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
                <a (click)="startEdit(data.id)">编辑</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveEdit(data.id)">保存</a>
                <a nz-popconfirm nzPopconfirmTitle="确定取消更改吗?" (nzOnConfirm)="cancelEdit(data.id)">取消</a>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  `,
  styles: [
    `
      input,button{
        margin:5px 5px 5px 0;
      }
      .editable-row-operations a {
        margin-right: 8px;
      }
      tr {
        display:table-row;
      }
    `
  ]
})
export class ProjectManageComponent implements OnInit, OnDestroy {
  projectName = '';

  editCache: { [key: string]: { edit: boolean; data: Project } } = {};

  listOfData: Project[] = [];
  projects: Project[] = [];
  listOfDisplayData: Project[] = [];

  /**
   * 选中的工程id
   */
  id = '';

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private modalService: NzModalService,
    private projectApi: ProjectApi
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const projects = await this.projectApi.getAll();
    this.listOfData = projects;
    this.projects = projects;
    this.updateEditCache();
  }

  ngOnDestroy() {
    this.modal.destroy();
  }

  currentPageDataChange($event): void {
    this.listOfDisplayData = $event;
  }

  trClick(id: string) {
    this.id = id;
  }

  onCheckChange(checked: boolean, id: string) {
    if (!checked) {
      this.id = '';
      return;
    }
    this.id = id;
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const data = this.listOfData.find(item => item.id === id);
    Object.assign(this.editCache[id].data, data);

    this.editCache[id].data.main = data.main;
    this.editCache[id].edit = false;
  }

  async saveEdit(id: string) {
    const data = this.listOfData.find(item => item.id === id);
    Object.assign(data, this.editCache[id].data);

    const flag: boolean = this.editCache[id].data.main;
    data.main = flag;

    const ret = await this.projectApi.update(data);
    if (ret) {
      this.message.success('操作成功！');
      this.updateEditCache();
    }
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  /**
   * 查询
   */
  projectQuery() {
    this.listOfData = this.projects.filter(d => d.name.indexOf(this.projectName) >= 0);
  }

  /**
   * 删除工程
   */
  delete() {
    if (!this.id) {
      this.message.warning('请选择需要删除的工程！');
      return;
    }
    const project = this.projects.find(d => d.id === this.id);
    this.modalService.confirm({
      nzTitle: `确认删除工程：${project.name}？`,
      nzContent: `<b style='color:red;'>删除后将无法恢复！</b>`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.modalService.create({
          nzTitle: '请输入如下字符后确认删除',
          nzContent: DeleteInputComponent,
          nzMaskClosable: false,
          nzOnOk: (ins) => ins.save().then(async res => {
            if (res) {
              const ret = await this.projectApi.delete(this.id);
              if (ret) {
                this.message.success('操作成功');
                this.projects = this.projects.filter(d => d.id !== this.id);
                this.listOfData = this.projects;
                this.updateEditCache();
              }
            }
            return res;
          })
        });
      }
    });
  }

  /**
   * 导出工程
   */
  export() {
    if (!this.id) {
      this.message.warning('请选择需要导出的工程！');
      return;
    }
    const project = this.projects.find(d => d.id === this.id);
    this.modalService.confirm({
      nzTitle: `确认导出工程：${project.name}?`,
      nzOnOk: async () => {
        const data = await this.projectApi.download(project.id);
        if (!data) {
          return;
        }
        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, `${project.name}.zip`);
      }
    });
  }
}
