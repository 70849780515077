
    <input nz-input placeholder="请输入工程名称" [(ngModel)]="projectName" style="width:50%;" autofocus />
    <button nz-button nzType="primary" (click)="projectQuery()">查询</button>
    <button nz-button nzType="primary" nzDanger (click)="delete()">删除</button>
    <button nz-button nzType="dashed" (click)="export()">导出</button>
    <nz-table
      #editRowTable nzBordered
      [nzData]="listOfData"
      nzShowSizeChanger
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <thead>
        <tr>
          <th></th>
          <th>工程名称</th>
          <th>创建时间</th>
          <th>创建人</th>
          <th>是否系统工程</th>
          <th nzWidth="120px">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data" (click)="trClick(data.id)">
          <td nzShowCheckbox [(nzChecked)]="data.id==id" (nzCheckedChange)="onCheckChange($event,data.id)"></td>
          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else nameInputTpl">
              {{ data.name }}
            </ng-container>
            <ng-template #nameInputTpl>
              <input type="text" nz-input [(ngModel)]="editCache[data.id].data.name" />
            </ng-template>
          </td>
          <td>
            <ng-container>
              {{ data.createTime }}
            </ng-container>
          </td>
          <td>
            <ng-container>
              {{ data.creator }}
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else sysInputTpl">
              <label nz-checkbox [(ngModel)]="data.main" nzDisabled ></label>
            </ng-container>
            <ng-template #sysInputTpl>
              <label nz-checkbox [(ngModel)]="editCache[data.id].data.main"></label>
            </ng-template>
          </td>
          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
                <a (click)="startEdit(data.id)">编辑</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveEdit(data.id)">保存</a>
                <a nz-popconfirm nzPopconfirmTitle="确定取消更改吗?" (nzOnConfirm)="cancelEdit(data.id)">取消</a>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  