import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const user = sessionStorage.getItem('user');

        if (user) {
            return true;
        }

        // this.router.navigate(['./login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['./login']);
        return false;
    }
}
