declare var js_beautify: any;

export class JsUtil {
    private static regex = '<(.*?)>';
    private static sign = 'TW';
    /**
     * js语法监测
     * @param js js
     */
    static check(js: string): boolean {
        try {
            if (!js) {
                return true;
            }
            const regex = /\<(.+?)\>/g;
            js = js.replace(regex, `$("MATCH")`).replace('*.value(*)', `$('*').value()`).replace('await','');
            eval(`function checkGrammar(){
                        ${js}
                 }`);
            return true;
        } catch (ex) {
            return false;
        }
    }

    /**
     * js脚本格式化
     * @param js js
     * @returns 格式化后的脚本
     */
    static format(js: string) {
        if (!js) {
            return js;
        }
        const map = new Map();
        let index = 99999;
        let code = js;
        code = code.replace('*.value(*)', '$TW.allvalue()');
        while (true) {
            const match = code.match(this.regex);
            if (!match || match.length <= 0) {
                break;
            }
            const id = `$${this.sign}${index--}`;
            code = code.replace(match[0], id);
            map.set(id, match[0]);
        }
        code = js_beautify(code);
        for (const key of map.keys()) {
            code = code.replace(key, map.get(key));
        }
        code = code.replace('$TW.allvalue()', '*.value(*)');
        return code;
    }
}
