import { AnimeDirection } from './animeDirection';
import { AnimeEasing } from './animeEasing';

/**
 * 旋转动画属性
 */
export class AnimeBreathProp {
    /**
     * 启用动画
     */
    enable = false;
    /**
     * 循环一次时间（毫秒）
     */
    duration = 1000;
}
