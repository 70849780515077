<nz-tabset>
  <nz-tab nzTitle="基本设置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <nz-link nzHref="#type" nzTitle="类型"></nz-link>
        <nz-link nzHref="#base-data-source" nzTitle="基础数据源"></nz-link>
        <nz-link nzHref="#data-source" nzTitle="动态数据源"></nz-link>
        <nz-link nzHref="#time" nzTitle="时间配置"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <div id="type">
        <span>类型:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.type" class="typeRadio">
            <label nz-radio nzValue="per">百分比</label>
            <label nz-radio nzValue="data">数值</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='base-data-source'>
        <span>基础数据源:</span>
        <div class="left">
          <nz-input-group nzAddOnBefore="固定值" nz-tooltip nzTooltipTitle="设置固定值后，该项数据源配置无效">
            <input nz-input [(ngModel)]="baseDataSource.fixedValue" />
          </nz-input-group>
          <div class="dataSource">
            <label nz-checkbox nz-tooltip nzTooltipTitle="动态数据源，需要在全局数据脚本中定义对应变量" [(ngModel)]="baseDataSource.dynamic"
              style="width: 20%;">动态</label>
          </div>
          <div class="dataSource">
            <nz-select [(ngModel)]="baseDataSource.type" style="width: 150px;">
              <nz-option nzValue="build" nzLabel="建筑"></nz-option>
              <nz-option nzValue="dept" nzLabel="分户"></nz-option>
              <nz-option nzValue="account" nzLabel="账套"></nz-option>
              <nz-option nzValue="device" nzLabel="设备"></nz-option>
              <nz-option nzValue="param" nzLabel="设备参数"></nz-option>
              <nz-option nzValue="formula" nzLabel="能耗关系"></nz-option>
            </nz-select>
            <ng-container [(ngSwitch)]="baseDataSource.type">
              <div *ngSwitchCase="'build'" style="display: flex;">
                <nz-input-group nzAddOnBefore="建筑id">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'dept'" style="display: flex;">
                <nz-input-group nzAddOnBefore="分户id">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'account'" style="display: flex;">
                <nz-input-group nzAddOnBefore="账套id">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'device'" style="display: flex;">
                <nz-input-group nzAddOnBefore="设备编码">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="参数类型">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'param'" style="display: flex;">
                <nz-input-group nzAddOnBefore="参数id">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'formula'" style="display: flex;">
                <nz-input-group nzAddOnBefore="关系id">
                  <input nz-input class="input" [(ngModel)]="baseDataSource.code" />
                </nz-input-group>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div id='data-source'>
        <span>动态数据源:</span>
        <div class="left">
          <div class="dataSource">
            <label nz-checkbox nz-tooltip nzTooltipTitle="动态数据源，需要在全局数据脚本中定义对应变量" [(ngModel)]="dataSource.dynamic"
              style="width: 20%;">动态</label>
          </div>
          <div class="dataSource">
            <nz-select [(ngModel)]="dataSource.type" style="width: 150px;">
              <nz-option nzValue="build" nzLabel="建筑"></nz-option>
              <nz-option nzValue="dept" nzLabel="分户"></nz-option>
              <nz-option nzValue="account" nzLabel="账套"></nz-option>
              <nz-option nzValue="device" nzLabel="设备"></nz-option>
              <nz-option nzValue="param" nzLabel="设备参数"></nz-option>
              <nz-option nzValue="formula" nzLabel="能耗关系"></nz-option>
            </nz-select>
            <ng-container [(ngSwitch)]="dataSource.type">
              <div *ngSwitchCase="'build'" style="display: flex;">
                <nz-input-group nzAddOnBefore="建筑id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'dept'" style="display: flex;">
                <nz-input-group nzAddOnBefore="分户id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'account'" style="display: flex;">
                <nz-input-group nzAddOnBefore="账套id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'device'" style="display: flex;">
                <nz-input-group nzAddOnBefore="设备编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="参数类型">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'param'" style="display: flex;">
                <nz-input-group nzAddOnBefore="参数id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'formula'" style="display: flex;">
                <nz-input-group nzAddOnBefore="关系id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div id='time'>
        <span>时间配置:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.timeConfig.timeType" style="width: 100%;">
            <label nz-radio nzValue="day">本日</label>
            <label nz-radio nzValue="week">本周</label>
            <label nz-radio nzValue="month">本月</label>
            <label nz-radio nzValue="season">本季度</label>
            <label nz-radio nzValue="year">本年</label>
          </nz-radio-group>
        </div>
      </div>

    </div>
  </nz-tab>
  <nz-tab nzTitle="图表显示配置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <nz-link nzHref="#title" nzTitle="图表标题"></nz-link>
        <nz-link nzHref="#seriesName" nzTitle="图例名"></nz-link>
        <nz-link nzHref="#dataName" nzTitle="数据名"></nz-link>
        <nz-link nzHref="#toolbox" nzTitle="工具箱"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <div id='title'>
        <span>图表标题:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.titleConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.titleConfig.position">
            <label nz-radio nzValue="top_left">左侧</label>
            <label nz-radio nzValue="top">居中</label>
          </nz-radio-group>
          <br>
          <label>标题内容：</label>
          <label nz-checkbox [(ngModel)]="prop.titleConfig.showTimeRange">时间区域</label>
          <nz-input-group nzAddOnBefore="特定内容">
            <input nz-input [(ngModel)]="prop.titleConfig.text" />
          </nz-input-group>
        </div>
      </div>

      <div id='seriesName'>
        <span>图例名:</span>
        <div class="left">
          <input nz-input [(ngModel)]="prop.seriesName" nz-tooltip nzTooltipTitle="提示框中展示的名称"/>
        </div>
      </div>

      <div id='dataName'>
        <span>数据名:</span>
        <div class="left">
          <input nz-input [(ngModel)]="prop.dataName" nz-tooltip nzTooltipTitle="用于展示在图表中，不配置则展示动态数据源名称"/>
        </div>
      </div>

      <div id='toolbox'>
        <span>工具箱:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.saveAsImg">保存为图片</label><br>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
