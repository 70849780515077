import { Property } from "../_models";

export class Path {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'fill': {
                let val = selectedObj.node.getAttribute(prop.name);
                if (!val) {
                    val = selectedObj.node.style.getPropertyValue(prop.name);
                }
                prop.setValue = val;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'fill': {
                selectedObj.attr(prop.name, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
