<div class="main">
    <div class="desc">
        <span class="title">改变<span class="mark">文本内容</span>:</span>
        <div class="code">
            <@p2.*>.value('新文本内容');
        </div>
    </div>

    <div class="desc">
        <span class="title">获取<span class="mark">文本内容</span>:</span>
        <div class="code">
            var txt = <@p2.*>.value();
        </div>
    </div>

    <div class="desc">
        <span class="title">改变<span class="mark">文本颜色</span>:</span>
        <div class="code">
            <div class="remark">p2为线条组件id，type为文本图层变量类型</div>
            <div class="remark">注：此处type不能使用*代替（包括其余组件，使用css命令时必须指定到具体图层）</div>
            <div>
                <@p2.type>.css('fill', '#f64949');
            </div>
        </div>
    </div>

    <div class="desc">
        <span class="title">文本<span class="mark">显示 / 隐藏</span>:</span>
        <div class="code">
            <@p2.*>.show();
        </div>
        <div class="code">
            <@p2.*>.hide();
        </div>
    </div>

    <div class="desc">
        <span class="title">文本<span class="mark">动态闪烁</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.flash();
            </div>
            <div class="remark">//200表示闪烁频率为200毫秒，默认为500毫秒</div>
            <div>
                <@p2.*>.flash(200);
            </div>
            <div class="remark">//清除闪烁效果</div>
            <div>
                <@p2.*>.clearFlash();
            </div>
        </div>
    </div>

</div>