import { Component } from '@angular/core';

@Component({
    selector: 'app-help-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.css']
})
export class TimeHelpComponent {
    constructor(
    ) {
    }
}

