import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventTransService } from './_services';
import { CommondAction } from './_helpers/commondAction';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  isLoading = false;
  tip = '';
  tipMsg = '';
  tipSuffix = '';

  timer;

  globalLoadingSub: Subscription;

  constructor(
    private eventTransService: EventTransService
  ) { }

  ngOnInit() {
    this.globalLoadingSub = this.eventTransService.globalLoadingEvent.subscribe((data: string) => {
      if (data) {
        this.isLoading = true;
        this.tipMsg = data;
        this.tip = this.tipMsg + this.tipSuffix;
        this.initTimer();
      } else {
        this.isLoading = false;
        this.tip = '';
        this.tipMsg = ''
        this.clearTimer();
      }
    });
  }

  initTimer() {
    if (this.timer || !this.tipMsg) {
      return;
    }
    this.timer = setInterval(() => {
      if (this.tipMsg) {
        if (this.tipSuffix.length == 3) {
          this.tipSuffix = '';
        }
        this.tipSuffix += '.';
        this.tip = this.tipMsg + this.tipSuffix;
      }
    }, 200);
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  ngOnDestroy() {
    this.globalLoadingSub.unsubscribe();
    this.clearTimer();
  }

  // 页面退出事件拦截
  @HostListener('window:beforeunload', ['$event'])
  async doSomething($event) {
    $event.preventDefault();
    if (CommondAction.isChange) {
      $event.returnValue = '页面发生改变，请先保存!';
    }
  }
}
