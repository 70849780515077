import { DataSourceConfig } from '../base/dataSourceConfig';
import { BaseProperty } from '../base/baseProperty';
import { GaugeTypeEnum } from './gaugeTypeEnum';

/**
 * 仪表盘配置属性
 */
export class GaugeProperty extends BaseProperty {
    /**
     * 类型
     */
    type = GaugeTypeEnum.PER;
    /**
     * 图例名
     */
    seriesName: string;
    /**
     * 数据名
     */
    dataName: string;
    /**
     * 基础数据源
     */
    baseDataSource: DataSourceConfig = new DataSourceConfig();
    /**
     * 动态数据源
     */
    dataSource: DataSourceConfig = new DataSourceConfig();
    /**
     * 自动刷新周期（单位：分钟），为0表示不自动刷新
     */
    refreshCycle = 2;
}
