import { DataEvent, DataSource } from '../_models';

export class CanvasCache {
    /**
     * 选中的组件对象集合(subjx observers)
     */
    static obs: any;
    /**
     * svg对象
     */
    static svg: any;
    /**
     * 过滤的组件类型
     */
    static filterComType = {
        LineHor: false,
        LineVer: false,
        Text: false,
        Rect: false,
        InputText: false,
        InputNumber: false,
        Checkbox: false
    };

    /**
     * 是否按下了ctrl键
     */
    static isCtrlDown = false;

    /**
     * 画布是否处于焦点状态
     */
    static isFoucus = true;

    /**
     * 是否处于绘图模式
     */
    static isDrawMode = false;

    /**
     * 是否点击了画布
     */
    static isCanvasMouseDown = false;

    /**
     * 正在批量操作
     */
    static isBatchAction = false;
    /**
     * 操作是否发生变化
     */
    static isOperateChange = false;

    /**
     * 画布内容
     */
    static canvasContent: string;

    /**
     * 画布缩放倍数
     */
    static svgScaleNum = 1;

    /**
     * 当前选中的组件
     */
    static selectedComponent: any;
    /**
     * 是否允许粘贴
     */
    static enablePaste = false;

    /**
     * 鼠标X轴位置
     */
    static mouseOffsetX = 0;
    /**
     * 鼠标Y轴位置
     */
    static mouseOffsetY = 0;

    /**
     * 复制的组件信息
     */
    static copyDoms = [];
    /**
     * 复制的组件数据源信息
     */
    static copyDataSources: DataSource[] = [];
    /**
     * 复制的组件事件信息
     */
    static copyDataEvents: DataEvent[] = [];
    /**
     * 复制的组件模板引用信息
     */
    static copyDataDefs = [];
    /**
     * 显示辅助线
     */
    static showGuideLine = false;
}
