import { DataAttrName } from '../const';
import { Property, TreeParam } from '../_models';
import { PxUtil } from '../utils';

export class Tree {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('ul');
        switch (prop.name) {
            case DataAttrName.search:
            case DataAttrName.async:
            case DataAttrName.defaultSelect: {
                const dataStr = setNode.attr(DataAttrName.treeSource);
                if (!dataStr) {
                    prop.setValue = false;
                    break;
                }
                const data: TreeParam = JSON.parse(dataStr);
                if (!data) {
                    prop.setValue = false;
                    break;
                }
                if (prop.name === DataAttrName.search) {
                    prop.setValue = data.search;
                } else if (prop.name === DataAttrName.async) {
                    prop.setValue = data.async;
                } else if (prop.name === DataAttrName.defaultSelect) {
                    prop.setValue = data.defaultSelect;
                }

                break;
            }
            case DataAttrName.treeSource: {
                const dataStr = setNode.attr(prop.name);
                if (!dataStr) {
                    prop.setValue = '';
                    return;
                }
                const data = JSON.parse(dataStr);
                if (data.type === 1) {
                    prop.setValue = '{ 注册树 }';
                    return;
                }
                switch (data.key) {
                    case 'build': {
                        prop.setValue = '{ 建筑树 }';
                        break;
                    }
                    case 'dept': {
                        prop.setValue = '{ 分户树 }';
                        break;
                    }
                    case 'energy': {
                        prop.setValue = '{ 能耗树 }';
                        break;
                    }
                }
                break;
            }
            case DataAttrName.showBorder: {
                prop.setValue = setNode.attr(prop.name);
                break;
            }
            case 'border-width':
            case 'border-radius':
            case 'border-color':
            case 'border-style': {
                prop.setValue = setNode.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('ul');
        switch (prop.name) {
            case DataAttrName.search:
            case DataAttrName.async:
            case DataAttrName.defaultSelect: {
                const dataStr = setNode.attr(DataAttrName.treeSource);
                let data: TreeParam = new TreeParam();
                if (dataStr) {
                    data = JSON.parse(dataStr);
                }
                if (prop.name === DataAttrName.search) {
                    data.search = prop.setValue;
                } else if (prop.name === DataAttrName.async) {
                    data.async = prop.setValue;
                } else if (prop.name === DataAttrName.defaultSelect) {
                    data.defaultSelect = prop.setValue;
                }

                setNode.attr(DataAttrName.treeSource, JSON.stringify(data));
                break;
            }
            case DataAttrName.showBorder: {
                setNode.attr(prop.name, prop.setValue);
                break;
            }
            case 'border-width':
            case 'border-radius': 
            case 'border-color':
            case 'border-style': {
                setNode.node.style.setProperty(prop.name, prop.setValue+PxUtil.get(prop.name));
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
