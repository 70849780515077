export class SocketMsgType {
    /**
     * 登录
     */
    static login = 'login';
    /**
     * 打开工程
     */
    static openProject = 'openProject';
    /**
     * 编辑通知
     */
    static edit = 'edit';
    /**
     * 页面引用模板变更
     */
    static templateSave = 'templateSave';
    /**
     * 下线
     */
    static logout = 'logout';
}
