/**
 * 曲线图类型
 */
export enum LineTypeEnum {
    /**
     * 基础折线图
     */
    BASE = 'base',
    /**
     * 基础平滑折线图
     */
    SMOOTH = 'smooth',
    /**
     * 面积图
     */
    AREA = 'area'
}
