import { ChartType } from '../_models';

export class ChartUtil {
    public static getChartName(chartType: string) {
        let chartName;
        switch (chartType) {
            case ChartType.Line: {
                chartName = '曲线图';
                break;
            }
            case ChartType.Bar: {
                chartName = '柱图';
                break;
            }
            case ChartType.Pie: {
                chartName = '饼图';
                break;
            }
            case ChartType.Gauge: {
                chartName = '仪表盘';
                break;
            }
            default: {
                chartName = chartType;
                break;
            }
        }
        return chartName;
    }
}
