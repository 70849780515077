import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { EventTransService } from '../../_services';
import {
  NameInputComponent, SelectProjectComponent, ProjectUpLoadComponent, SaveSvgPageComponent,
  IconManageComponent, VideoManageComponent, ProjectManageComponent, CreateProjectComponent,
  ProjectSetComponent, AudioManageModalComponent, ThemeSetComponent
} from '../../_modals';
import { Project, User, Page } from '../../_models';
import { CommondAction } from 'src/app/_helpers/commondAction';
import { ModuleApi, PageApi, ProjectApi } from 'src/app/api';
import { DataCache } from 'src/app/cache';
import { MathUtil } from 'src/app/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  project: Project;
  pageName: string;

  projectChangeEventSub: Subscription;
  canvasDataChangeSub: Subscription;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private eventTransService: EventTransService,
    private projectApi: ProjectApi,
    private moduleApi: ModuleApi,
    private pageApi: PageApi
  ) {
  }

  ngOnInit() {
    this.projectChangeEventSub = this.eventTransService.projectChangeEvent.subscribe((data: Project) => {
      this.project = data;
    });
    this.canvasDataChangeSub = this.eventTransService.canvasDataChange.subscribe((data: boolean) => {
      if (!data) {
        this.pageName = '';
        return;
      }
      if (DataCache.page) {
        this.pageName = `页面：${DataCache.page.text}`;
      } else {
        this.pageName = `模板：${DataCache.template.text}`;
      }
    });
  }

  ngOnDestroy() {
    this.projectChangeEventSub.unsubscribe();
    this.canvasDataChangeSub.unsubscribe();
  }

  /**
   * 文件-新建-工程
   */
  async createProject() {
    this.modalService.create({
      nzTitle: '新建工程',
      nzWidth: '80%',
      nzContent: CreateProjectComponent,
      nzMaskClosable: false,
      nzOnOk: (ins) => ins.save().then(async ({ name, themeId }) => {
        if (name) {
          const ret = await this.projectApi.create(name, themeId);
          if (ret) {
            this.message.success('操作成功！');
          }
        }
        return name;
      })
    });
  }

  /**
   * 文件-新建-模块
   */
  createModule() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.modalService.create({
      nzTitle: '新建模块',
      nzComponentParams: {
        tipMsg: '请输入模块名称...'
      },
      nzContent: NameInputComponent,
      nzMaskClosable: false,
      nzOnOk: (ins) => ins.save().then(async res => {
        if (res) {
          await this.moduleApi.createWithName(this.project.id, res);
          this.message.success('操作成功！');
          this.eventTransService.loadProjectEvent.next(this.project.id);
        }
        return res;
      })
    });
  }

  /**
   * 文件-新建-页面
   */
  createPage() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }

    this.modalService.create({
      nzTitle: '创建页面',
      nzContent: SaveSvgPageComponent,
      nzMaskClosable: false,
      nzOnOk: (ins) => ins.save().then(async (data: Page) => {
        if (data) {
          const ret = await this.pageApi.createEmpty(data);
          if (ret) {
            this.message.success('操作成功！');
            this.eventTransService.loadModuleEvent.next(data.moduleId);
          }
        }
        return data;
      })
    });
  }

  /**
   * 文件-打开工程
   */
  openProject() {
    this.modalService.create({
      nzTitle: '选择要打开的工程',
      nzContent: SelectProjectComponent,
      nzComponentParams: {
        selProjectId: DataCache.project ? DataCache.project.id : ''
      },
      nzMaskClosable: false,
      nzOnOk: (ins) => ins.save().then(res => {
        if (res) {
          if (DataCache.project && DataCache.project.id === res) {
            this.message.info('工程已打开！');
            return true;
          }
          this.eventTransService.loadProjectEvent.next(res);
        }
        return true;
      })
    });
  }

  /**
   * 文件-预览
   */
  async previewPage() {
    if (DataCache.template) {
      this.message.warning('模板文件无法预览！');
      return;
    }
    if (!DataCache.page) {
      this.message.warning('请先打开要预览的页面！');
      return;
    }
    if (!DataCache.project.routes || DataCache.project.routes.length == 0) {
      this.message.warning('请先配置地址路由！');
      return;
    }

    this.showConfirm(`当前页面尚未保存，确认预览？`).then(async (res: boolean) => {
      if (res) {
        this.eventTransService.globalLoadingEvent.next('页面编译中，请稍等');
        const ret = await this.pageApi.preview(DataCache.page.id);
        this.eventTransService.globalLoadingEvent.next('');
        if (ret) {
          const user: User = JSON.parse(sessionStorage.getItem('user'));
          const version = DataCache.sysConfig.version;

          let route = DataCache.project.routes.find(d => d.isDefault);
          if (!route) {
            route = DataCache.project.routes[0];
          }
          let tag = '';
          if (route.tag) {
            tag += '/' + route.tag;
          }
          const url = `${route.protocol}://${route.site}${tag}/preview/${DataCache.page.name}.html?uid=${MathUtil.getUUid()}&token=${user.token}&version=${version}`;
          window.open(url);
        }
      }
    });
  }

  /**
   * 项目发布
   */
  publish() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.showConfirm(`当前页面尚未保存，确认发布工程？`).then(async (res: boolean) => {
      if (!res) {
        return;
      }
      this.eventTransService.globalLoadingEvent.next('工程发布中，请稍等');
      const ret = await this.projectApi.publish(this.project.id);
      if (ret) {
        this.message.success('发布成功！');
      }
      this.eventTransService.globalLoadingEvent.next('');
    });
  }

  showConfirm(msg) {
    return new Promise((resolve) => {
      if (CommondAction.isChange) {
        this.modalService.confirm({
          nzTitle: msg,
          nzOnOk: () => {
            resolve(true);
          }, nzOnCancel: () => {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  /**
   * 文件-保存
   */
  savePage() {
    this.eventTransService.saveCanvasEvent.next(true);
  }

  /**
   * 注销
   */
  logout() {
    this.modalService.confirm({
      nzTitle: `<i>确认退出系统?</i>`,
      nzOkDanger: true,
      nzOnOk: () => {
        localStorage.removeItem('user');
        sessionStorage.clear();
        this.eventTransService.reset();
        this.router.navigate(['/login']);
      }
    });
  }

  /**
   * 帮助
   */
  help() {
    window.open(localStorage.getItem('Domain') + '/WebDesignCommon/helper/index.html', '_blank');
  }

  /**
   * 快捷键说明
   */
  keyDesc() {
    const modal = this.modalService.create({
      nzTitle: '快捷键说明',
      nzContent: `
                  移动组件：↑ ↓ ← → <br/>
                  删除组件： Ctrl+D （ Del ） 	<br/>
                  保存：Ctrl+S		<br/>
                  全选：Ctrl+A		<br/>	
                  复制：Ctrl+C		<br/>	
                  粘贴：Ctrl+V		<br/>
                  撤销：Ctrl+Z		<br/>	
                  重做：Ctrl+Y		<br/>	
                  查找：Ctrl+F		<br/>	
                  替换：Ctrl+H		<br/>	
                  选中画布：Esc   <br/>
                  `,
      nzClosable: false,
      nzFooter: [
        {
          label: '关闭',
          onClick: () => modal.destroy()
        }]
    });
  }

  /**
   * 工程管理
   */
  projectManage() {
    this.modalService.create({
      nzTitle: '工程管理',
      nzWidth: 850,
      nzContent: ProjectManageComponent,
      nzMaskClosable: true,
      nzFooter: []
    });
  }

  /**
   * 导入工程
   */
  projectImport() {
    this.modalService.create({
      nzTitle: '导入工程',
      nzContent: ProjectUpLoadComponent,
      nzOkText: '开始导入',
      nzOnOk: (ins) => ins.save()
    });
  }

  /**
   * 导出工程
   */
  projectExport() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.modalService.confirm({
      nzTitle: `<i>确认导出当前工程?</i>`,
      nzOnOk: async () => {
        const data = await this.projectApi.download(this.project.id);
        if (!data) {
          return;
        }
        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, `${this.project.name}.zip`);
      }
    });
  }

  /**
   * 工程配置
   */
  projectSet() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.openProjectSet();
  }

  /**
   * 工程配置
   */
  openProjectSet() {
    this.modalService.create({
      nzTitle: '工程配置',
      nzContent: ProjectSetComponent,
      nzMaskClosable: false,
      nzWidth: '700px',
      nzOnOk: (ins) => ins.save().then(async (data: Project) => {
        if (data) {
          const ret = await this.projectApi.update(this.project);
          if (ret) {
            this.message.success('操作成功！');
            this.eventTransService.projectThemeChange.next(true);
          }
        }
        return data;
      })
    });
  }

  /**
   * 打开运行界面
   */
  async gotoRun() {
    if (!this.project.routes || this.project.routes.length == 0) {
      this.message.warning('请先配置地址路由！');
      return;
    }
    const user: User = JSON.parse(sessionStorage.getItem('user'));
    var route = this.project.routes.find(d => d.isDefault);
    if (!route) {
      route = this.project.routes[0];
    }
    let path = '';
    if (route.tag) {
      path += '/' + route.tag;
    }

    window.open(`${route.protocol}://${route.site}${path}/index.html?uid=${MathUtil.getUUid()}&name=${user.name}&token=${user.token}`, '_blank');
  }

  /**
   * 工程-图标管理
   */
  iconManage() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.modalService.create({
      nzTitle: '图标文件管理',
      nzWidth: '80%',
      nzContent: IconManageComponent,
      nzMaskClosable: true,
      nzFooter: []
    });
  }

  /**
   * 音频文件管理
   */
  audioManage() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.modalService.create({
      nzTitle: '音频文件管理',
      nzComponentParams: {
        projectId: this.project.id
      },
      nzContent: AudioManageModalComponent,
      nzMaskClosable: false,
      nzFooter: []
    });
  }

  /**
   * 视频文件管理
   */
  videoManage() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.modalService.create({
      nzTitle: '视频文件管理',
      nzComponentParams: {
        projectId: this.project.id
      },
      nzContent: VideoManageComponent,
      nzMaskClosable: false,
      nzFooter: []
    });
  }

  /**
   * 项目初始化
   */
  async initProject() {
    const ret = await this.projectApi.init();
    if (ret) {
      this.message.success('项目初始化成功！');
      window.location.reload();
    }
  }

  /**
   * 页面是否已保存
   */
  get hasPageSaved() {
    return !CommondAction.isChange;
  }

  /**
   * 主题设置
   */
  themeSet() {
    if (!this.project) {
      this.message.warning('请先打开工程！');
      return;
    }
    if (!this.project.themeId) {
      this.message.warning('请先配置工程所使用的主题！');
      return;
    }
    this.modalService.create({
      nzTitle: '主题设置',
      nzContent: ThemeSetComponent,
      nzMaskClosable: true,
      nzWidth: '900px',
      nzFooter: []
    });
  }
}
