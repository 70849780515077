import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ComponentApi } from 'src/app/api';
import { CanvasCache } from 'src/app/cache';
import { CanvasConstData, DataAttrName } from 'src/app/const';
import { NameInputComponent, SaveToBaseModelComponent } from 'src/app/_modals';
import { EventTransService } from 'src/app/_services';
import { CommonFunc } from './commonFunc';

declare var $: any;

interface SortDom {
    index: number;
    width: number;
    height: number;
    dom: any;
}

/**
 * 事件处理
 */
@Injectable({ providedIn: 'root' })
export class ComponentTool {

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private commonFunc: CommonFunc,
        private componentApi: ComponentApi
    ) { }

    /**
     * 顶部对齐
     */
    alignToTop() {
        this.eventTransService.toolBarSetChange.next('1');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        let refeY = 99999;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if ((bbox.width > 0 || bbox.height > 0) && (bbox.y < refeY)) {
                refeY = bbox.y;
            }
        }
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if ((bbox.width > 0 || bbox.height > 0) && (Math.round(bbox.y) !== Math.round(refeY))) {
                item.exeDrag({ dx: 0, dy: refeY - bbox.y });
            }
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 底部对齐
     */
    alignToBottom() {
        this.eventTransService.toolBarSetChange.next('2');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        let refeY = -99999;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const subY = bbox.y + bbox.height;
                if (subY > refeY) {
                    refeY = subY;
                }
            }
        }
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const subY = bbox.y + bbox.height;
                if (Math.round(subY) !== Math.round(refeY)) {
                    item.exeDrag({ dx: 0, dy: refeY - subY });
                }
            }
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 左侧对齐
     */
    alignToLeft() {
        this.eventTransService.toolBarSetChange.next('3');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        let refeX = 99999;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                if (bbox.x < refeX) {
                    refeX = bbox.x;
                }
            }
        }
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                if (Math.round(bbox.x) !== Math.round(refeX)) {
                    item.exeDrag({ dx: refeX - bbox.x, dy: 0 });
                }
            }
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 右侧对齐
     */
    alignToRight() {
        this.eventTransService.toolBarSetChange.next('4');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        let refeX = -99999;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const subX = bbox.x + bbox.width;
                if (subX > refeX) {
                    refeX = subX;
                }
            }
        }
        for (const item of this.commonFunc.getSelectedDoms()) {
            const bbox = $(item.el).data('dom').getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const subX = bbox.x + bbox.width;
                if (Math.round(subX) !== (Math.round(refeX))) {
                    item.exeDrag({ dx: refeX - subX, dy: 0 });
                }
            }
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 垂直对齐
     */
    alignToVertical() {
        this.eventTransService.toolBarSetChange.next('5');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        const refeDom = this.commonFunc.getRefeDom();
        if (!refeDom) {
            this.message.error('参考组件获取失败，请刷新页面后重试！');
            return;
        }
        const refeBBox = $(refeDom.el).data('dom').getBBox();
        if (refeBBox.width === 0 && refeBBox.height === 0) {
            this.message.warning('操作发生异常，请刷新页面后重试！');
            return;
        }

        // 参考组件垂直中心点
        const verX = refeBBox.x + (refeBBox.width / 2);
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width !== 0 || bbox.height !== 0) {
                const subVerX = bbox.x + (bbox.width / 2);
                if (Math.round(subVerX) !== Math.round(verX)) {
                    item.exeDrag({ dx: verX - subVerX, dy: 0 });
                }
            }
        });
        CanvasCache.isOperateChange = true;
    }

    /**
     * 水平对齐
     */
    alignToMiddle() {
        this.eventTransService.toolBarSetChange.next('6');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        const refeDom = this.commonFunc.getRefeDom();
        if (!refeDom) {
            this.message.error('参考组件获取失败（可尝试刷新页面）！');
            return;
        }
        const refeBBox = $(refeDom.el).data('dom').getBBox();
        if (refeBBox.width === 0 && refeBBox.height === 0) {
            this.message.warning('操作发生异常，请刷新页面后重试！');
            return;
        }
        // 参考组件水平中心点
        const middleY = refeBBox.y + (refeBBox.height / 2);
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width !== 0 || bbox.height !== 0) {
                const subMiddleY = bbox.y + (bbox.height / 2);
                if (Math.round(subMiddleY) !== Math.round(middleY)) {
                    item.exeDrag({ dx: 0, dy: middleY - subMiddleY });
                }
            }
        });
        CanvasCache.isOperateChange = true;
    }

    /**
     * 中心点对齐
     */
    alignToCenter() {
        this.eventTransService.toolBarSetChange.next('7');
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        const refeDom = this.commonFunc.getRefeDom();
        if (!refeDom) {
            this.message.error('参考组件获取失败（可尝试刷新页面）！');
            return;
        }
        const refeBBox = $(refeDom.el).data('dom').getBBox();
        if (refeBBox.width === 0 && refeBBox.height === 0) {
            this.message.warning('操作发生异常，请刷新页面后重试！');
            return;
        }
        // 参考组件水平中心点
        const middleX = refeBBox.x + refeBBox.width / 2;
        const middleY = refeBBox.y + refeBBox.height / 2;
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width !== 0 || bbox.height !== 0) {
                const subMiddleX = bbox.x + bbox.width / 2;
                const subMiddleY = bbox.y + bbox.height / 2;
                if (Math.round(subMiddleY) !== Math.round(middleY) || Math.round(subMiddleX) !== Math.round(middleX)) {
                    item.exeDrag({ dx: middleX - subMiddleX, dy: middleY - subMiddleY });
                }
            }
        });
        CanvasCache.isOperateChange = true;
    }

    /**
     * 设为相同水平间距
     */
    alignMiddleSpace() {
        this.eventTransService.toolBarSetChange.next('8');
        if (this.commonFunc.getSelectedSize() < 3) {
            this.message.warning('执行该功能需选择3个及以上组件！');
            return;
        }
        let sortDoms: SortDom[] = [];
        let totalWidth = 0;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                sortDoms.push({
                    index: bbox.x,
                    width: bbox.width,
                    height: 0,
                    dom: item
                });
                totalWidth += bbox.width;
            }
        }
        sortDoms = sortDoms.sort((a, b) => a.index - b.index);

        let startX = sortDoms[0].index + sortDoms[0].width;
        const endX = sortDoms[sortDoms.length - 1].index;
        const diffWidth = ((endX - startX) - (totalWidth - sortDoms[0].width - sortDoms[sortDoms.length - 1].width))
            / (sortDoms.length - 1);
        for (let i = 1; i < sortDoms.length; i++) {
            sortDoms[i].dom.exeDrag({ dx: startX - sortDoms[i].index + diffWidth, dy: 0 });
            startX += sortDoms[i].width + diffWidth;
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 设为相同垂直间距
     */
    alignVerticalSpace() {
        this.eventTransService.toolBarSetChange.next('9');
        if (this.commonFunc.getSelectedSize() < 3) {
            this.message.warning('执行该功能需选择3个及以上组件！');
            return;
        }

        let sortDoms: SortDom[] = [];
        let totalHeight = 0;
        for (const item of this.commonFunc.getSelectedDoms()) {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                sortDoms.push({
                    index: bbox.y,
                    width: 0,
                    height: bbox.height,
                    dom: item
                });
                totalHeight += bbox.height;
            }
        }
        sortDoms = sortDoms.sort((a, b) => a.index - b.index);

        let startY = sortDoms[0].index + sortDoms[0].height;
        const endY = sortDoms[sortDoms.length - 1].index;
        const diffHeight = ((endY - startY) - (totalHeight - sortDoms[0].height - sortDoms[sortDoms.length - 1].height))
            / (sortDoms.length - 1);
        for (let i = 1; i < sortDoms.length; i++) {
            sortDoms[i].dom.exeDrag({ dx: 0, dy: startY - sortDoms[i].index + diffHeight });
            startY += sortDoms[i].height + diffHeight;
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 水平居中（相对画布）
     */
    centerToMiddle() {
        this.eventTransService.toolBarSetChange.next('t');
        if (this.commonFunc.getSelectedSize() < 1) {
            this.message.warning('执行该功能需选择1个及以上组件！');
            return;
        }
        const svgWidth = CanvasCache.svg.node.style.width;
        const middleX = parseFloat(svgWidth) / 2;
        const selMiddleX = this.commonFunc.getSelectedMiddleX();
        if (selMiddleX <= 0 || selMiddleX >= svgWidth) {
            return;
        }
        if (Math.round(middleX) !== Math.round(selMiddleX)) {
            this.commonFunc.getSelectedDoms().forEach((item: any) => {
                item.exeDrag({ dx: middleX - selMiddleX, dy: 0 });
            });
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 垂直居中（相对画布）
     */
    centerToVertical() {
        this.eventTransService.toolBarSetChange.next('y');
        if (this.commonFunc.getSelectedSize() < 1) {
            this.message.warning('执行该功能需选择1个及以上组件！');
            return;
        }
        const svgHeight = CanvasCache.svg.node.style.height;
        const middleY = parseFloat(svgHeight) / 2;
        const selMiddleY = this.commonFunc.getSelectedMiddleY();
        if (selMiddleY <= 0 || selMiddleY >= svgHeight) {
            return;
        }
        if (Math.round(middleY) !== Math.round(selMiddleY)) {
            this.commonFunc.getSelectedDoms().forEach((item: any) => {
                item.exeDrag({ dx: 0, dy: middleY - selMiddleY });
            });
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 置于顶层
     */
    setToTop() {
        this.eventTransService.toolBarSetChange.next('e');
        if (this.commonFunc.getSelectedSize() < 1) {
            this.message.warning('执行该功能需选择1个及以上组件！');
            return;
        }
        const selIds = [];
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            selIds.push($(item.el).attr('id'));
        });

        let lastNode = null;
        const nodes = [];
        CanvasCache.svg.children().forEach((item) => {
            if (this.commonFunc.isDataNode(item)) {
                const id = item.attr('id');
                if (selIds.indexOf(id) < 0) {
                    lastNode = item;
                } else {
                    nodes.push(item);
                }
            }
        });

        if (!lastNode) {
            return;
        }
        for (let i = nodes.length - 1; i >= 0; i--) {
            lastNode.after(nodes[i]);
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 置于底层
     */
    setToBottom() {
        this.eventTransService.toolBarSetChange.next('r');
        if (this.commonFunc.getSelectedSize() < 1) {
            this.message.warning('执行该功能需选择1个及以上组件！');
            return;
        }
        const selIds = [];
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            selIds.push($(item.el).attr('id'));
        });

        let firstNode = null;
        const nodes = [];
        CanvasCache.svg.children().forEach((item) => {
            if (this.commonFunc.isDataNode(item)) {
                const id = item.attr('id');
                if (selIds.indexOf(id) < 0) {
                    if (!firstNode) {
                        firstNode = item;
                    }
                } else {
                    nodes.push(item);
                }
            }
        });

        if (!firstNode) {
            return;
        }

        for (const node of nodes) {
            firstNode.before(node);
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 组件样式同步
     */
    syncCss() {
        const selDom = CanvasCache.selectedComponent;
        if (!selDom) {
            this.message.warning('请先选择组件！');
            return;
        }
        if (this.commonFunc.getSelectedSize() <= 1) {
            this.message.warning('请至少选择2个组件！');
            return;
        }
        if (selDom.node.children.length !== 1) {
            this.message.warning('该类型组件暂不支持样式同步功能！！！');
            return;
        }
        const gid = selDom.attr(DataAttrName.id);
        const tag = selDom.node.children[0];
        const tid = selDom.id;
        const tagName = tag.tagName.toLowerCase();
        switch (tagName) {
            case 'text': {
                this.commonFunc.getSelectedDoms().forEach((item: any) => {
                    const dom = $(item.el).data('dom');
                    if (dom.attr(DataAttrName.id) != gid || dom.id == tid) {
                        return true;
                    }
                    CanvasCache.isOperateChange = true;
                    dom.node.children[0].style.fill = tag.style.fill;
                    dom.node.children[0].style.fontSize = tag.style.fontSize;
                    dom.node.children[0].style.fontWeight = tag.style.fontWeight;
                    dom.node.children[0].style.fontFamily = tag.style.fontFamily;
                    dom.node.children[0].style.fontStyle = tag.style.fontStyle;
                    dom.node.children[0].style.textDecoration = tag.style.textDecoration;
                    dom.node.children[0].style.textAnchor = tag.style.textAnchor;
                    dom.node.children[0].style.letterSpacing = tag.style.letterSpacing;

                    dom.node.children[0].style.stroke = tag.style.stroke;
                    dom.node.children[0].style.strokeWidth = tag.style.strokeWidth;
                    dom.node.children[0].style.strokeDasharray = tag.style.strokeDasharray;
                    dom.node.children[0].style.strokeLinecap = tag.style.strokeLinecap;
                });
                break;
            }
            case 'line': {
                this.commonFunc.getSelectedDoms().forEach((item: any) => {
                    const dom = $(item.el).data('dom');
                    if (dom.id === tid) {
                        return true;
                    }
                    CanvasCache.isOperateChange = true;
                    dom.node.children[0].style.stroke = tag.style.stroke;
                    dom.node.children[0].style.strokeWidth = tag.style.strokeWidth;
                    dom.node.children[0].style.strokeDasharray = tag.style.strokeDasharray;
                    dom.node.children[0].style.strokeDashoffset = tag.style.strokeDashoffset;
                    dom.node.children[0].style.strokeLinecap = tag.style.strokeLinecap;

                    if ($(tag).attr(DataAttrName.anime)) {
                        $(dom.node.children[0]).attr(DataAttrName.anime, $(tag).attr(DataAttrName.anime));
                        this.eventTransService.animeSetChange.next($(dom.node.children[0]));
                    }

                    if (selDom.attr(DataAttrName.anime)) {
                        $(item.el).attr(DataAttrName.anime, selDom.attr(DataAttrName.anime));
                        this.eventTransService.animeSetChange.next($(item.el));
                    }
                });
                break;
            }
            case 'path':
            case 'circle':
            case 'polygon':
            case 'ellipse':
            case 'rect': {
                this.commonFunc.getSelectedDoms().forEach((item: any) => {
                    const dom = $(item.el).data('dom');
                    if (dom.attr(DataAttrName.id) !== gid || dom.id === tid) {
                        return true;
                    }
                    CanvasCache.isOperateChange = true;
                    dom.node.children[0].style.fill = tag.style.fill;
                    dom.node.children[0].style.stroke = tag.style.stroke;
                    dom.node.children[0].style.strokeWidth = tag.style.strokeWidth;
                    dom.node.children[0].style.strokeDasharray = tag.style.strokeDasharray;
                    dom.node.children[0].style.strokeDashoffset = tag.style.strokeDashoffset;
                    dom.node.children[0].style.strokeLinecap = tag.style.strokeLinecap;
                    if (tagName === 'rect') {
                        dom.node.children[0].style.rx = tag.getAttribute('rx');
                        dom.node.children[0].style.ry = tag.getAttribute('ry');
                    }

                    if ($(tag).attr(DataAttrName.anime)) {
                        $(dom.node.children[0]).attr(DataAttrName.anime, $(tag).attr(DataAttrName.anime));
                        this.eventTransService.animeSetChange.next($(dom.node.children[0]));
                    }

                    if (selDom.attr(DataAttrName.anime)) {
                        $(item.el).attr(DataAttrName.anime, selDom.attr(DataAttrName.anime));
                        this.eventTransService.animeSetChange.next($(item.el));
                    }
                });
                break;
            }
            case 'foreignobject': {
                const type = $(tag).attr(DataAttrName.componentName);
                this.commonFunc.getSelectedDoms().forEach((item: any) => {
                    const dom = $(item.el).data('dom');
                    if (dom.attr(DataAttrName.id) !== gid || dom.id === tid) {
                        return true;
                    }
                    CanvasCache.isOperateChange = true;

                    if (type.startsWith('input-')) {
                        dom.node.children[0].style.width = tag.style.width;
                        dom.node.children[0].style.height = tag.style.height;

                        dom.node.children[0].children[0].style.border = tag.children[0].style.border;
                        dom.node.children[0].children[0].style.borderRadius = tag.children[0].style.borderRadius;
                        dom.node.children[0].children[0].style.background = tag.children[0].style.background;
                        dom.node.children[0].children[0].style.fontSize = tag.children[0].style.fontSize;
                        dom.node.children[0].children[0].style.color = tag.children[0].style.color;
                        dom.node.children[0].children[0].style.fontFamily = tag.children[0].style.fontFamily;
                        dom.node.children[0].children[0].style.fontWeight = tag.children[0].style.fontWeight;
                        dom.node.children[0].children[0].style.fontStyle = tag.children[0].style.fontStyle;
                    }
                });
                break;
            }
            default: {
                this.message.warning('该功能只支持系统组件！');
                break;
            }
        }
    }

    /**
     * 画布所有组件移动至左上角
     */
    moveToCorner(doms, num = 0) {
        let minX = 99999;
        let minY = 99999;

        doms.forEach((item: any) => {
            if (!this.commonFunc.isDataNode(item)) {
                return true;
            }
            const bbox = item.getBBox();
            const width = bbox.width;
            const height = bbox.height;
            if (width === 0 && height === 0) {
                item.remove();
                return true;
            }
            if (bbox.x < minX) {
                minX = bbox.x;
            }
            if (bbox.y < minY) {
                minY = bbox.y;
            }
        });
        minX -= num;
        minY -= num;

        let maxX = 0;
        let maxY = 0;
        doms.forEach((item: any) => {
            if (!this.commonFunc.isDataNode(item)) {
                return true;
            }
            item.attr({
                transform: item.transform().local + (item.transform().local ? 'T' : 't') + `${-minX} ${-minY}`
            });
            const bbox = item.getBBox();
            const width = bbox.width;
            const height = bbox.height;
            if (bbox.x + width > maxX) {
                maxX = bbox.x + width;
            }
            if (bbox.y + height > maxY) {
                maxY = bbox.y + height;
            }
        });

        return { maxX, maxY };
    }

    /**
     * 大小相同
     */
    sameSize() {
        this.eventTransService.toolBarSetChange.next('0');
        const selDom = CanvasCache.selectedComponent;
        if (!selDom) {
            this.message.warning('请先选择组件！');
            return;
        }
        if (this.commonFunc.getSelectedSize() <= 1) {
            this.message.warning('请至少选择2个组件！');
            return;
        }
        const tid = selDom.id;
        const dataId = selDom.attr(DataAttrName.id);
        const tag = selDom.node.children[0];
        const refeBBox = selDom.getBBox();
        const refeWidth = refeBBox.width;
        const refeHeight = refeBBox.height;

        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            if (dom.id === tid) {
                return true;
            }

            if (dataId === CanvasConstData.TEXT_ID && dom.attr(DataAttrName.id) === dataId) {
                // 文本，修改字体大小
                dom.node.children[0].style.fontSize = tag.style.fontSize;
                return true;
            }

            if (dataId === CanvasConstData.LINE_HOR_ID || dataId === CanvasConstData.LINE_VER_ID) {
                let len = 0;
                if (dataId === CanvasConstData.LINE_HOR_ID) {
                    len = parseFloat(tag.getAttribute('x2')) - parseFloat(tag.getAttribute('x1'));
                } else {
                    len = parseFloat(tag.getAttribute('y2')) - parseFloat(tag.getAttribute('y1'));
                }
                if (dom.attr(DataAttrName.id) === CanvasConstData.LINE_VER_ID) {
                    const y1 = parseFloat(dom.node.children[0].getAttribute('y1'));
                    dom.node.children[0].setAttribute('y2', len - y1);
                    return true;
                } else if (dom.attr(DataAttrName.id) === CanvasConstData.LINE_HOR_ID) {
                    const x1 = parseFloat(dom.node.children[0].getAttribute('x1'));
                    dom.node.children[0].setAttribute('x2', len - x1);
                    return true;
                }
            }

            let count = 0;
            while (true) {
                count++;
                // TODO根据transform计算出缩放比率，计算出实际缩放大小
                const bbox = dom.getBBox();
                const dx = refeWidth === 0 ? 0 : refeWidth - bbox.width;
                const dy = refeHeight === 0 ? 0 : refeHeight - bbox.height;
                item.exeResize({ dx, dy });
                if ((Math.abs(dx) < 1 && Math.abs(dy) < 1) || count >= 100) {
                    break;
                }
            }
        });

        CanvasCache.isOperateChange = true;
    }

    /**
     * 宽度相同
     */
    sameWidth() {
        this.eventTransService.toolBarSetChange.next('0');
        const selDom = CanvasCache.selectedComponent;
        if (!selDom) {
            this.message.warning('请先选择组件！');
            return;
        }
        if (this.commonFunc.getSelectedSize() <= 1) {
            this.message.warning('请至少选择2个组件！');
            return;
        }
        const tid = selDom.id;
        const dataId = selDom.attr(DataAttrName.id);
        const refeWidth = selDom.getBBox().width;

        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            if (dom.id === tid) {
                return true;
            }

            if ((dataId === CanvasConstData.TEXT_ID && dom.attr(DataAttrName.id) === dataId) ||
                dataId === CanvasConstData.LINE_HOR_ID || dataId === CanvasConstData.LINE_VER_ID) {
                return true;
            }

            let count = 0;
            while (true) {
                count++;
                // TODO根据transform计算出缩放比率，计算出实际缩放大小
                const bbox = dom.getBBox();
                const dx = refeWidth === 0 ? 0 : refeWidth - bbox.width;
                item.exeResize({ dx, dy: 0 });
                if (Math.abs(dx) < 1 || count >= 10) {
                    break;
                }
            }
        });

        CanvasCache.isOperateChange = true;
    }

    /**
     * 高度相同
     */
    sameHeight() {
        this.eventTransService.toolBarSetChange.next('0');
        const selDom = CanvasCache.selectedComponent;
        if (!selDom) {
            this.message.warning('请先选择组件！');
            return;
        }
        if (this.commonFunc.getSelectedSize() <= 1) {
            this.message.warning('请至少选择2个组件！');
            return;
        }
        const tid = selDom.id;
        const dataId = selDom.attr(DataAttrName.id);
        const refeHeight = selDom.getBBox().height;

        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            if (dom.id === tid) {
                return true;
            }

            if ((dataId === CanvasConstData.TEXT_ID && dom.attr(DataAttrName.id) === dataId) ||
                dataId === CanvasConstData.LINE_HOR_ID || dataId === CanvasConstData.LINE_VER_ID) {
                return true;
            }

            let count = 0;
            while (true) {
                count++;
                const bbox = dom.getBBox();
                const dy = refeHeight === 0 ? 0 : refeHeight - bbox.height;
                item.exeResize({ dx: 0, dy });
                if (Math.abs(dy) < 1 || count >= 10) {
                    break;
                }
            }
        });

        CanvasCache.isOperateChange = true;
    }

    /**
     * 旋转
     * @param degree 旋转角度
     */
    rotate(degree: number) {
        const selCom = CanvasCache.selectedComponent;
        if (!selCom) {
            this.message.warning('请先选择组件！');
            return;
        }
        let isSet = false;
        if (selCom.data('ftStatus')) {
            selCom.data('ftStatus', -1);
            selCom.data('transTool').unplug();
            isSet = true;
        }
        const matrixObj = selCom.transform().localMatrix.split();
        selCom.attr({
            transform: selCom.transform().local + (selCom.transform().local ? 'R' : 'r') + `${degree - matrixObj.rotate}`
        });

        if (isSet) {
            this.commonFunc.setTransform(selCom);
        }
        CanvasCache.isOperateChange = true;
    }

    /**
     * 旋转-自定义角度
     */
    rotateCustom() {
        if (!CanvasCache.selectedComponent) {
            this.message.warning('请先选择组件！');
            return;
        }
        this.modalService.create({
            nzTitle: '旋转角度选择',
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(res => {
                this.rotate(res);
                return res;
            })
        });
    }

    /**
     * 组件移动
     * @param dx 横向移动距离
     * @param dy 纵向移动距离
     */
    move(dx: number, dy: number) {
        CanvasCache.isBatchAction = true;
        CanvasCache.showGuideLine = false;
        if (document.activeElement && document.activeElement.nodeName) {
            const nodeName = document.activeElement.nodeName.toLowerCase();
            // 焦点在文本框内
            if (nodeName === 'div' || nodeName === 'input') {
                return;
            }
        }
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            item.exeDrag({ dx, dy });
        });

        CanvasCache.isBatchAction = false;
        CanvasCache.showGuideLine = true;
        this.eventTransService.recordActionEvent.next(true);
        this.commonFunc.removeGuideLine();
    }

    /**
     * 元素保存至组件库
     */
    saveToBaseModel() {
        if (this.commonFunc.getSelectedSize() === 0) {
            this.message.warning('请先选择组件！');
            return;
        }
        this.modalService.create({
            nzTitle: '保存至组件库',
            nzComponentParams: {
                cname: '新组件'
            },
            nzContent: SaveToBaseModelComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async ({ name, classifyId }) => {
                if (name) {
                    const content = this.getSelectedContent();

                    const ret = await this.componentApi.save({ name, classifyId, content });
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.classifyDataChangeEvent.next(classifyId);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 获取选中的组件内容
     */
    getSelectedContent(): string {
        let content = '';
        let defs = '';
        const doms = [];
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            doms.push(dom.clone().attr('id', '').removeClass(CanvasConstData.subjxClass));
        });
        const { maxX, maxY } = this.moveToCorner(doms);

        let data = this.commonFunc.getSimpleHaflCanvas(maxX, maxY);

        doms.forEach(value => {
            content += value.toString();
            if (value.attr(DataAttrName.template)) {
                const node = CanvasCache.svg.select(`[id='${value.attr(DataAttrName.template)}']`);
                if (node) {
                    defs += node.toString();
                }
            }
            value.remove();
        });
        if (defs) {
            defs = `<defs>${defs}</defs>`;
        }

        data += defs + content + '</svg>';
        return data;
    }
}
