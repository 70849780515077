import { DataEvent } from './dataEvent';
import { DataSource } from './dataSource';

/**
 * 模板数据
 */
export class TemplateData {
    /**
     * 模板内容
     */
    content: string;
    /**
     * 数据源
     */
    source?: DataSource;
    /**
     * 事件
     */
    event?: DataEvent;
}
