/**
 * 右键菜单状态
 */
export class ContextMenuState {
    /**
     * 工程模块/页面标题右键菜单
     */
    PageTitle?: boolean = false;
    /**
     * 模块右键菜单
     */
    Module?: boolean = false;
    /**
     * 页面右键菜单
     */
    Page?: boolean = false;

    /**
     * 工程模板标题右键菜单
     */
    TemplateTitle?: boolean = false;
    /**
     * 工程模板分类右键菜单
     */
    TemplateClassify?: boolean = false;
    /**
     * 工程模板右键菜单
     */
    Template?: boolean = false;

    /**
     * 组件标题右键菜单
     */
    ComponentTitle?: boolean = false;
    /**
     * 组件分类内容右键菜单
     */
    ClassifyContent?: boolean = false;
    /**
     * 组件分类标题右键菜单
     */
    ClassifyTitle?: boolean = false;
    /**
     * 组件右键菜单
     */
    Component?: boolean = false;
    
    /**
     * svg画布右键菜单
     */
    SvgCanvas?: boolean = false;
}
