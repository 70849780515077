import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuState, JsData } from '../_models';
import { JsDataType } from '../enums';
import { EventTransService } from '../_services';
import { CanvasCache, DataCache } from '../cache';
import { JsUtil } from '../utils';
import { JsColor } from '../const';
import { CommondAction } from '../_helpers/commondAction';
import { ProjectApi } from '../api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('left', { static: true }) leftSideNav: any;
  @ViewChild('right', { static: true }) rightSideNav: any;

  jsWindowSubscrite: Subscription;

  content: string;
  showJs = false;
  jsType: JsDataType;

  codeBorderColor = JsColor.right;

  constructor(
    private message: NzMessageService,
    private eventTransService: EventTransService,
    private projectApi: ProjectApi
  ) { }

  ngOnInit() {
    this.initSubscrite();
  }

  ngOnDestroy() {
    this.jsWindowSubscrite.unsubscribe();
  }

  /**
   * 初始化事件订阅
   */
  initSubscrite() {
    this.jsWindowSubscrite = this.eventTransService.openJsWindow.subscribe((data: JsData) => {
      if (data) {
        this.showJs = true;
        this.content = data.content;
        this.jsType = data.type;
      } else {
        this.showJs = false;
        this.content = '';
        this.jsType = null;
      }
    });
  }

  /**
   * 鼠标单击事件：关闭右键菜单
   */
  globalClick(event: Event) {
    this.eventTransService.menuStateChangeEvent.next(new ContextMenuState());
  }

  /**
   * 鼠标右击事件：关闭右键菜单、阻止默认菜单显示
   * @param event 事件
   */
  globalRightClick(event: Event) {
    event.preventDefault();
    this.eventTransService.menuStateChangeEvent.next(new ContextMenuState());
  }

  getLeftState() {
    return this.leftSideNav.isCollapsed;
  }

  getRightState() {
    return this.rightSideNav.isCollapsed;
  }

  /**
   * 全局键盘事件
   */
  onKeydown(event: KeyboardEvent) {
    if (this.showJs) {
      return;
    }
    this.eventTransService.keyDownEvent.next(event);
    CanvasCache.isCtrlDown = event.ctrlKey || event.metaKey;
    if (!event || !event.code) {
      return;
    }
    const code = event.code.toLowerCase().replace('key', '');
    if (CanvasCache.isCtrlDown && code === 's') {
      event.preventDefault();
    }
  }

  /**
   * 全局键盘事件
   */
  onkeyup(event: KeyboardEvent) {
    this.eventTransService.keyUpEvent.next(event);
  }

  onChange(event) {
    if (JsUtil.check(this.content)) {
      this.codeBorderColor = JsColor.right;
    } else {
      this.codeBorderColor = JsColor.error;
    }
    if (this.jsType) {
      if (this.jsType === JsDataType.CustomPage) {
        DataCache.pageData.customJs = this.content;
        CommondAction.isChange = true;
      } else {
        this.eventTransService.jsDataChange.next({ type: this.jsType, content: this.content });
      }
    }
  }

  /**
   * 最小化
   * @param event 事件
   */
  setToMinus(event) {
    if (this.jsType === JsDataType.CustomProject) {
      if (this.content !== DataCache.project.js) {
        DataCache.project.js = this.content;
        this.projectApi.saveJs(DataCache.project.id, DataCache.project.js);
        this.message.success('工程全局脚本更新成功');
      }
    }
    this.showJs = false;
  }

  /**
   * 脚本格式化
   */
  formatCode() {
    this.content = JsUtil.format(this.content);
    this.onChange(null);
  }
}
