import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CanvasData, ContextMenuState, DragedData, JsData, OperateType, Project, PropChangeData, SelectedObj } from '../_models';

/**
 * 事件传递服务
 */
@Injectable({ providedIn: 'root' })
export class EventTransService {
    constructor() {
    }

    /**
     * 全局加载框
     */
    globalLoadingEvent: BehaviorSubject<string> = new BehaviorSubject('');

    /**
     * 鼠标右键菜单状态变更事件
     */
    menuStateChangeEvent: BehaviorSubject<ContextMenuState> = new BehaviorSubject({});

    /**
     * 当前项目变更事件
     */
    projectChangeEvent: BehaviorSubject<Project> = new BehaviorSubject(null);
    /**
     * 加载工程事件
     */
    loadProjectEvent: BehaviorSubject<string> = new BehaviorSubject('');

    /**
     * 加载模块事件
     */
    loadModuleEvent: BehaviorSubject<string> = new BehaviorSubject('');
    /**
     * 触发加载画布数据事件
     */
    loadCanvasDataEvent: BehaviorSubject<CanvasData> = new BehaviorSubject(null);
    /**
     * 触发加载画布缓存数据事件
     */
    loadCanvasCacheDataEvent: BehaviorSubject<CanvasData> = new BehaviorSubject(null);
    /**
     * 画布数据发生变化
     */
    canvasDataChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 保存画布事件
     */
    saveCanvasEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 模板分类数据发生变化
     */
    templateClassifyChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 模板数据发生变化，key：分类id
     */
    templateChange: BehaviorSubject<string> = new BehaviorSubject('');
    /**
     * 组件分类数据变更事件
     */
    classifysChangeEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * 组件分类数据变更事件
     */
    classifyDataChangeEvent: BehaviorSubject<string> = new BehaviorSubject('');

    /**
     * 拖拽事件（组件、模板）
     */
    dragDataEvent: BehaviorSubject<DragedData> = new BehaviorSubject(null);

    /**
     * 选中的对象变更事件
     */
    selectedObjChangeEvent: BehaviorSubject<SelectedObj> = new BehaviorSubject({});
    /**
     * 全局键盘事件
     */
    keyDownEvent: BehaviorSubject<KeyboardEvent> = new BehaviorSubject(null);
    /**
     * 全局键盘事件
     */
    keyUpEvent: BehaviorSubject<KeyboardEvent> = new BehaviorSubject(null);
    /**
     * 显示数据源页面
     */
    showDataSourcePage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 工具栏操作方法
     */
    toolBarFunc: BehaviorSubject<OperateType> = new BehaviorSubject(null);
    /**
     * 是否绘图模式
     */
    isDrawMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * svg画布缩放比例变更
     */
    svgScaleChange: BehaviorSubject<number> = new BehaviorSubject(0);
    /**
     * 右侧元件树选中节点变更事件
     */
    eleTreeNodeChange: BehaviorSubject<any> = new BehaviorSubject(null);
    /**
     * 打开js编辑器
     */
    openJsWindow: BehaviorSubject<JsData> = new BehaviorSubject(null);
    /**
     * js编辑器内容变更事件
     */
    jsDataChange: BehaviorSubject<JsData> = new BehaviorSubject(null);
    /**
     * 设置画布内容缓存
     */
    setCanvasCache: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 清除画布内容缓存
     */
    clearCanvasCache: BehaviorSubject<string> = new BehaviorSubject('');
    /**
     * 记录执行动作
     */
    recordActionEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);

    editPageChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    editTemplateChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 工具栏操作变更
     */
    toolBarSetChange: BehaviorSubject<string> = new BehaviorSubject('');
    /**
     * 工程主题发生变更
     */
    projectThemeChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 参数编码发生变更
     */
    paramTypeCodeChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 动画属性变更
     */
    animeSetChange: BehaviorSubject<any> = new BehaviorSubject(null);
    /**
     * 画布元素发生变化
     */
    canvasElementChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * 属性设置参数变更
     */
    propSetChange: BehaviorSubject<PropChangeData> = new BehaviorSubject(null);

    /**
     * 事件重置
     */
    reset() {
        this.menuStateChangeEvent.next({});
        this.projectChangeEvent.next(null);
        this.loadProjectEvent.next('');
        this.loadCanvasDataEvent.next(null);
        this.loadCanvasCacheDataEvent.next(null);
        this.canvasDataChange.next(false);
        this.saveCanvasEvent.next(false);
        this.templateClassifyChange.next(false);
        this.templateChange.next('');
        this.classifysChangeEvent.next(false);
        this.classifyDataChangeEvent.next('');
        this.dragDataEvent.next(null);
        this.selectedObjChangeEvent.next({});
        this.keyDownEvent.next(null);
        this.keyUpEvent.next(null);
        this.showDataSourcePage.next(false);
        this.toolBarFunc.next(null);
        this.isDrawMode.next(false);
        this.eleTreeNodeChange.next(null);
        this.openJsWindow.next(null);
        this.jsDataChange.next(null);
        this.setCanvasCache.next(false);
        this.clearCanvasCache.next('');
        this.recordActionEvent.next(false);
        this.editPageChange.next(false);
        this.editTemplateChange.next(false);
        this.toolBarSetChange.next('');
        this.projectThemeChange.next(false);
        this.paramTypeCodeChange.next(false);
        this.animeSetChange.next(null);
        this.canvasElementChange.next(null);
        this.propSetChange.next(null);
    }
}
