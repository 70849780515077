<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">动态绑定数据源1</span>:</span>
        <div class="code">
            <div class="remark">type值为：build、dept、account时，code值分别为建筑id.能耗编码、部门id.能耗编码、账套id.能耗编码</div>
            <div class="remark">type值为：device时，code值为设备编码.参数类型</div>
            <div class="remark">type值为：param时，code值为参数id</div>
            <div class="remark">type值为：formula时，code值为关系id</div>
            <div class="code">
                <@p2.*>.obj().setDataSource([{{'{'}}
                    type: 'build',
                    code: '1.A1000'
                    {{'}'}}]);
            </div>

        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">动态绑定数据源2</span>:</span>
        <div class="remark">type类型参照数据源1</div>
        <div class="code">
            <@p2.*>.obj().setData({{'{'}}
                type: 'build',
                id: 1
                {{'}'}}, 'A1000');
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">设置时间类型</span>:</span>
        <div class="remark">使用前提：图表已绑定数据源。</div>
        <div class="remark">可使用时间类型：day、two_day、week、month、season、year</div>
        <div class="code">
            <@p2.*>.obj().setTimeType('day');
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">设置时间段</span>:</span>
        <div class="remark">使用前提：图表已绑定数据源。</div>
        <div class="remark">时间参数必须按照如下格式：</div>
        <div class="code">
            <@p2.*>.obj().setTime('2024-03-01~2024-04-01');
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">图表当前查询时间</span>:</span>
        <div class="remark">返回格式：2024-03-01~2024-04-01</div>
        <div class="code">
            var time = <@p2.*>.obj().getTime();
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">图表ECharts对象</span>:</span>
        <div class="code">
            <@p2.*>.obj().getChart();
        </div>
    </div>
    <div class="desc">
        <span class="title">修改<span class="mark">图表option对象</span>:</span>
        <div class="remark">添加图表事件：《数据加载完成》。事件参数：e为option对象。</div>
        <div class="code">
            <@*>.dataLoadSuccess(function(e) {{'{'}}
                e.title.text = '测试';
                {{'}'}});
        </div>
    </div>
</div>