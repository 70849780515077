/**
 * 用户最近使用记录
 */
export class RecentUse {
    private static projectId = 'project_id';
    /**
     * 最近打开的工程id
     * @param id 工程id
     */
    static setProjectId(id: string) {
        window.localStorage.setItem(this.projectId, id);
    }
    /**
     * 获取最近打开的工程id
     */
    static getProjectId() {
        return window.localStorage.getItem(this.projectId);
    }
}
