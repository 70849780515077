
        <nz-input-group nzAddOnBefore="ID" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.name" placeholder="请输入页面ID" autofocus/>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="名称" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.text" placeholder="请输入页面名称"/>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label nz-checkbox [(ngModel)]="page.config.menu" style="width:25%;color:#ffa41af5">菜单页面</label>
        <div style="margin-top:10px;"></div>

        <div>
            <label nz-checkbox [(ngModel)]="page.config.scale" style="width:25%;">允许缩放</label>
            <nz-input-group nzAddOnBefore="初始缩放" style="width:75%;" *ngIf="page.config.scale">
                <nz-select [(ngModel)]="page.config.initScaleType" style="width:100%">
                    <nz-option nzValue="0" nzLabel="自动"></nz-option>
                    <nz-option nzValue="1" nzLabel="缩放"></nz-option>
                    <nz-option nzValue="2" nzLabel="不缩放"></nz-option>
                </nz-select>
            </nz-input-group>
        </div>
        
        <div style="margin-top:10px;"></div>

        <nz-input-group nzAddOnBefore="权限级别" style="width:50%;">
            <nz-input-number style="width:100%" [(ngModel)]="page.config.level" [nzMin]="0" [nzMax]="999" [nzStep]="1"
                    nzTooltipTitle="权限等级低于该值的用户无法访问该页面！" nzTooltipPlacement="bottom" nz-tooltip></nz-input-number>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label>所属模块：</label>
        <button nz-button nzType="link" (click)="clearModule()">清除选择(一级页面)</button>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="page.moduleId" [nzButtonStyle]="'solid'">
                    <label nz-radio-button *ngFor="let item of modules" nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    