import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MenuIconApi } from '../api';
import { MenuIcon } from '../_models';
import { DomSanitizer } from '@angular/platform-browser';
import { DataCache } from '../cache';

@Component({
  selector: 'app-icon-manage',
  template: `
    <div class="clearfix">
      <div style="display:flex;flex-wrap:wrap;">
        <div style="width:200px;">
          <nz-upload
            nzListType="picture-card"
            [(nzFileList)]="upList"
            [nzBeforeUpload]="beforeUpload"
            [nzRemove]="removeFile"
          >
            <i nz-icon nzType="plus"></i>
            <div class="ant-upload-text">上传</div>
          </nz-upload>
        </div>
        <div *ngFor="let item of fileList" style="width:200px;" (click)="iconClick($event,item)">
            <nz-card nzHoverable [nzActions]="[actionDelete, actionSel]">
                <nz-card-meta [nzDescription]="item.name" [nzAvatar]="avatar" style="height:50px;"></nz-card-meta>
                <label class='key'>ID：{{item.percent}}</label>
            </nz-card>
            <ng-template #avatar>
                <img alt="tw" [src]="item.response" />
            </ng-template>
            <ng-template #actionDelete>
              <i nz-icon nzType="delete" (click)="removeFile($event,item)" style="color:red;"></i>
            </ng-template>
            <ng-template #actionSel>
                <nz-radio-group [(ngModel)]="selIconId">
                    <label nz-radio [nzValue]="item.uid"></label>
                </nz-radio-group>
            </ng-template>
        </div>
    </div>
  </div>
  `,
  styles: [
    `
      i[nz-icon] {
        font-size: 32px;
        color: #999;
      }
      .ant-upload-text {
        margin-top: 8px;
        color: #666;
      }
      img {
        width:50px;
        height:50px;
      }
      .ant-card-meta-description {
        max-height: 100px !important;
      }
      .key{
        color:orange;
        font-size:16px;
      }
    `
  ]
})
export class IconManageComponent implements OnInit, OnDestroy {
  /**
   * 已选图标名称
   */
  @Input() selIconId: string;

  fileList: NzUploadFile[] = [];
  upList: NzUploadFile[] = [];

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private modalService: NzModalService,
    private menuIconApi: MenuIconApi,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const icons: MenuIcon[] = await this.menuIconApi.getAll(DataCache.project.id);
    for (const icon of icons) {
      this.fileList.push({
        uid: icon.id,
        name: icon.name,
        percent: icon.key,
        status: 'done',
        response: this.sanitizer.bypassSecurityTrustResourceUrl(`data:${icon.type};base64,${icon.base64}`)
      });
    }
  }

  /**
   * 图标上传
   * @param file 文件
   */
  beforeUpload = (file: NzUploadFile): boolean => {
    const isIcon = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      || file.type === 'image/svg+xml' || file.type === 'image/x-icon' || file.type === 'image/gif';
    if (!isIcon) {
      this.message.warning('文件格式错误，只支持：jqg、png、svg、ico 格式!');
      console.log(`upload file type:${file.type}`);
      return false;
    }
    const size = file.size! / 1024;
    if (size > 500) {
      this.message.warning('文件过大，不能超过 500 KB!');
      return false;
    }

    this.menuIconApi.upload(file, DataCache.project.id).then((data: MenuIcon) => {
      if (data) {
        this.message.success(`文件：${data.name}，上传成功！`);
        const newFile: NzUploadFile = {
          uid: data.id,
          name: data.name,
          percent: data.key,
          status: 'done',
          response: this.sanitizer.bypassSecurityTrustResourceUrl(`data:${data.type};base64,${data.base64}`)
        };
        this.fileList = [...this.fileList, newFile];
      }
    });

    return false;
  }

  /**
   * 移除文件
   * @param file 文件
   */
  removeFile(event, file: NzUploadFile) {
    this.modalService.confirm({
      nzTitle: `<i>确认删除所选图标？</i>`,
      nzOkDanger: true,
      nzOnOk: async () => {
        const ret = await this.menuIconApi.delete(file.uid);
        if (ret) {
          this.message.success(`操作成功！`);
          this.fileList = this.fileList.filter(d => d.uid !== file.uid);
        }
      }
    });
  }

  /**
   * 图标点击事件
   */
  iconClick(event, file: NzUploadFile) {
    if (this.selIconId === file.uid) {
      this.selIconId = '';
    } else {
      this.selIconId = file.uid;
    }
  }

  /**
   * 设置图标
   */
  setIcon(): any {
    return new Promise(resolve => {
      resolve({
        icon: this.selIconId
      });
    });
  }

  ngOnDestroy() {
    this.modal.destroy();
  }
}
