import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataSourceConfig, DataSourceType, DataViewConfig, HighLightConfig, LineProperty, TimeTypeEnum } from 'src/app/chartConfig';
import { MathUtil } from 'src/app/utils';

interface DataSourceEdit {
    dynamic: boolean;
    type: string;
    code?: string;
    param?: string;
}

@Component({
    selector: 'app-chart-line-config',
    templateUrl: './chartLineConfig.component.html',
    styleUrls: ['./chartLineConfig.component.css']
})

/**
 * 曲线图配置
 */
export class ChartLineConfigComponent implements OnInit, OnDestroy {
    @Input() prop: LineProperty = new LineProperty();

    dataSource: DataSourceEdit = {
        dynamic: false,
        type: 'build',
    };

    selDataSourceId: string;

    constructor(private modal: NzModalRef, private message: NzMessageService
    ) {
    }

    ngOnInit() {
        if (!this.prop.dataViewConfig) {
            this.prop.dataViewConfig = new DataViewConfig();
            this.prop.dataViewConfig.showSum = false;
        }
    }

    /**
     * 添加数据源
     */
    addDataSource(): void {
        if (!this.dataSource.code) {
            this.message.warning('参数错误，请检查！');
            return;
        }
        let param = '';
        if (this.dataSource.type !== DataSourceType.param && this.dataSource.type !== DataSourceType.formula) {
            if (!this.dataSource.param) {
                this.message.warning('参数错误，请检查！');
                return;
            }
            param = '.' + this.dataSource.param;
        }
        if (this.prop.dataSources.findIndex(d => d.dynamic === this.dataSource.dynamic &&
            d.type === this.dataSource.type && d.code === this.dataSource.code + param) >= 0) {
            this.message.warning('该数据源已存在！');
            return;
        }
        const id = MathUtil.getUUid();
        this.prop.dataSources.push({
            id,
            dynamic: this.dataSource.dynamic,
            type: this.dataSource.type,
            code: this.dataSource.code + param
        });
        this.message.success('添加成功！');
        this.selDataSourceId = id;
    }

    /**
     * 获取数据源描述信息
     */
    getDataSourceDesc(item: DataSourceConfig) {
        let name;
        switch (item.type) {
            case 'build':
                name = '建筑';
                break;
            case 'dept':
                name = '分户';
                break;
            case 'account':
                name = '账套';
                break;
            case 'device':
                name = '设备';
                break;
            case 'formula':
                name = '能耗关系';
                break;
            case 'device':
                name = '设备参数';
                break;
        }
        if (item.dynamic) {
            return `动态 - [${name}] ${item.code}`;
        }
        return `[${name}] ${item.code}`;
    }

    /**
     * 移除数据源
     */
    removeDataSource() {
        if (!this.selDataSourceId) {
            return;
        }
        this.prop.dataSources = this.prop.dataSources.filter(d => d.id !== this.selDataSourceId);
        this.message.success('操作成功！');
        this.selDataSourceId = '';
    }

    /**
     * 数据源选择
     */
    selDataSource() {
        if (!this.selDataSourceId) {
            return;
        }
        const data = this.prop.dataSources.find(d => d.id === this.selDataSourceId);
        this.dataSource.dynamic = data.dynamic;
        this.dataSource.type = data.type;
        const arr = data.code.split('.');
        this.dataSource.code = arr[0];
        if (arr.length > 1) {
            this.dataSource.param = arr[1];
        }
    }

    /**
     * 添加高亮区域
     */
    addHighLight() {
        const lighLight: HighLightConfig = {
            id: MathUtil.getUUid(),
            start: null,
            end: null,
            color: '#ffffff',
            name: ''
        };
        this.prop.highLights.push(lighLight);
    }

    /**
     * 删除高亮区域
     * @param id 序号
     */
    deleteHighLight(id: string) {
        this.prop.highLights = this.prop.highLights.filter(d => d.id !== id);
    }

    setStart(date: Date, hl: HighLightConfig) {
        hl.start = date.toTimeString().split(' ')[0];
    }

    setStop(date: Date, hl: HighLightConfig) {
        hl.end = date.toTimeString().split(' ')[0];
    }

    save(): any {
        return new Promise(resolve => {
            resolve(this.prop);
        });
    }

    checkType(type): boolean {
        return type === TimeTypeEnum.OTHER;
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
