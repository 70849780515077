<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">默认选中</span>:</span>
        <div class="code">
            <div class="remark">item1为按钮组中某一项的ID，可在【属性-集合】中配置。</div>
            <div>
                <@p2.*>.value('item1');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取当前<span class="mark">选中按钮的ID</span>:</span>
        <div class="code">
            <div class="remark">按钮的ID，可在【属性-集合】中配置。</div>
            <div>
                var id = <@p2.*>.value();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">自动触发按钮<span class="mark">点击事件</span>:</span>
        <div class="code">
            <div class="remark">写在按钮点击事件下方，页面加载完成后自动点击按钮。</div>
            <div>
                <@p2.*>.click();
            </div>
        </div>
    </div>
</div>