import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ItemData } from '../../_models';
import { MathUtil } from '../../utils';

/**
 * 系统页面
 */
@Component({
    selector: 'app-items-config',
    template: `
    <div nz-row>
        <div nz-col nzSpan="8">
            <h4>成员：</h4>
            <div class="wrap" [ngStyle]="{ 'width': '75%','marginRight':'5%', 'float':'left' }">
                <nz-list nzSize="small">
                    <nz-list-item *ngFor="let item of items" [ngStyle]="{'background': item.id===selItem.id?'#127ff1db':''}"
                        (click)="selectItem(item)">
                        {{ item.title }}
                    </nz-list-item>
                </nz-list>
            </div>
            <div [ngStyle]="{ 'width': '20%', 'float':'left' }">
                <button nz-button nzType="primary" [nzSize]="'large'" (click)="setSort(-1)">
                    <i nz-icon nzType="arrow-up" nzTheme="outline"></i>
                </button>
                <div style="margin-bottom:5px"></div>
                <button nz-button nzType="primary" [nzSize]="'large'" (click)="setSort(1)">
                    <i nz-icon nzType="arrow-down" nzTheme="outline"></i>
                </button>
            </div>
            <p style="padding-top:15px;clear:both;">
                <button nz-button nzType="primary" [ngStyle]="{ 'marginRight':'10px' }" (click)="addItem()">添加</button>
                <button nz-button nzType="primary" nzDanger (click)="removeItem()">移除</button>
            </p>
        </div>
        <div nz-col nzSpan="16" style="padding-left:3%;">
            <h4>Item属性：</h4>
            <div class="wrap1">
                <nz-form-item>
                    <nz-form-label nzFor="itemID">ID</nz-form-label>
                    <input type="text" id="itemID" nz-input [(ngModel)]="selItem.key" placeholder="请输入 Item ID"
                        autofocus/>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzFor="itemName">名称</nz-form-label>
                    <input type="text" id="itemName" nz-input [(ngModel)]="selItem.title" placeholder="请输入Item名称"/>
                </nz-form-item>
            </div>
        </div>
    </div>
    `,
    styles: [
        `.wrap{
            height:400px;
            border: 1px solid #404040;
            overflow-y:auto;
        }
        .wrap1{
            height:450px;
            border: 1px solid #404040;
        }
        nz-radio-group,label{
            width:100%;
        }
        nz-form-label{
            width:80px;
            text-align:right;
        }
        nz-input-group,input{
            width:80%;
        }
        nz-form-item {
            margin-top:15px;
            margin-bottom:0;
        }
        nz-list-item{
            cursor:pointer;
        }
        nz-list-item:hover{
            background:#2675c763;
        }
        `
    ]
})
export class ItemsConfigComponent implements OnInit, OnDestroy {
    @Input() items: ItemData[] = [];

    selItem: ItemData = {
        key: '', title: ''
    };

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
    ) { }

    ngOnInit() {
    }

    selectItem(item: ItemData) {
        this.selItem = item;
    }

    addItem() {
        this.items.push({
            id: MathUtil.getUUid(),
            key: '',
            title: 'title'
        });
    }

    removeItem() {
        if (!this.selItem || !this.selItem.id) {
            return;
        }
        this.items = this.items.filter(d => d.id !== this.selItem.id);
        this.selItem = {
            key: '', title: ''
        };
    }

    setSort(num: number) {
        if (!this.selItem || !this.selItem.id) {
            return;
        }
        const index = this.items.findIndex(d => d.id === this.selItem.id);
        if (num < 0 && index === 0 || num > 0 && index === this.items.length) {
            return;
        }
        this.items.splice(index, 1);
        this.items.splice(index + num, 0, this.selItem);
    }

    save(): any {
        return new Promise(resolve => {
            resolve(this.items);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
