import { Component } from '@angular/core';

@Component({
    selector: 'app-help-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.css']
})
export class TreeHelpComponent {
    constructor(
    ) {
    }
}

