import { ComponentType, DataAttrName, SvgNodeType } from '../const';

/**
 * svg 节点帮助类
 */
export class SvgNodeUtil {
    /**
     * name
     */
    public static getNodeOtherName(node: any): string {
        if (!node || !node.type) {
            return '';
        }
        let nodeName = node.type;

        const name = node.attr(DataAttrName.componentName);
        if (name) {
            const comName = ComponentType.getName(name);
            return comName ? comName : nodeName;
        }

        switch (node.type.toLocaleLowerCase()) {
            case SvgNodeType.use: {
                nodeName = '模板';
                break;
            }
            case SvgNodeType.text: {
                nodeName = '文本';
                break;
            }
            case SvgNodeType.path: {
                nodeName = '路径';
                break;
            }
            case SvgNodeType.line: {
                nodeName = '线条';
                if (node.attr('data-type') && node.attr('data-type').indexOf('Ex') > 0) {
                    nodeName += 'Ex';
                }
                break;
            }
            case SvgNodeType.circle: {
                nodeName = '圆';
                break;
            }
            case SvgNodeType.rect: {
                nodeName = '矩形';
                break;
            }
            case SvgNodeType.polygon: {
                nodeName = '多边形';
                break;
            }
            case SvgNodeType.ellipse: {
                nodeName = '椭圆';
                break;
            }
            case SvgNodeType.g: {
                nodeName = '容器';
                break;
            }
            case SvgNodeType.image: {
                nodeName = '图片';
                break;
            }
            default: {
                break;
            }
        }
        return nodeName;
    }
}
