/**
 * 位置信息
 */
export enum PositionEnum {
    TOP = 'top',
    TOP_LEFT = 'top_left',
    TOP_RIGHT = 'top_right',
    BOTTOM = 'bottom',
    BOTTOM_LEFT = 'bottom_left',
    BOTTOM_RIGHT = 'bottom_right'
}
