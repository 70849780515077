import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

/*文件-新建-模块 */
@Component({
    selector: 'app-createtable',
    template: `
        <label nz-checkbox [(ngModel)]="isSingle">单组件</label>
        <nz-input-group nzAddOnBefore="列数(C)">
            <nz-input-number [(ngModel)]="colNum" [nzMin]="1" [nzMax]="50" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="行数(R)">
            <nz-input-number [(ngModel)]="rowNum" [nzMin]="1" [nzMax]="50" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="列宽度(W)">
            <nz-input-number [(ngModel)]="width" [nzMin]="1" [nzMax]="500" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="行高(H)">
            <nz-input-number [(ngModel)]="height" [nzMin]="1" [nzMax]="500" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        `,
    styles: [
        `
        nz-input-group{
            margin-top:10px;
        }
        nz-input-number{
            width:100%;
        }
        `
    ]
})
export class CreateTableComponent implements OnInit, OnDestroy {
    colNum = 4;
    rowNum = 4;
    width = 100;
    height = 40;
    isSingle = true;

    constructor(private modal: NzModalRef
    ) {
    }

    ngOnInit() {
    }

    save(): any {
        return new Promise(resolve => {
            resolve({ ColNum: this.colNum, RowNum: this.rowNum, Width: this.width, Height: this.height, IsSingle: this.isSingle });
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
