import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SelectedObj, DataSource, JsData, DevInfo } from '../../_models';
import { CommondAction } from '../../_helpers/commondAction';
import { JsDataType } from 'src/app/enums';
import { EventTransService } from 'src/app/_services';
import { DataApi } from 'src/app/api';
import { DataCache, PropCache } from 'src/app/cache';
import { DataAttrName, JsColor } from 'src/app/const';
import { JsUtil, MathUtil } from 'src/app/utils';

@Component({
    selector: 'app-datasource',
    templateUrl: 'datasource.component.html',
    styleUrls: ['datasource.component.css'],
})
export class DataSourceComponent implements OnInit, OnDestroy {
    // 画布选择对象变更事件
    selectedObjChangeEventSub: Subscription;
    jsDataChangeSub: Subscription;

    // 当前选中的组件对象
    selectedComponent;
    // 选中的图层
    selectedElement;

    // 当前页面数据源
    dataSource: DataSource;
    // 执行的动作
    action = '';

    // 脚本背景色
    codeBorderColor = JsColor.right;

    // 元件图层选中变更事件
    eleTreeNodeSubscribe: Subscription;
    // 选中的元件图层
    eleTreeNode: any;

    // 是够动态编码
    isDynamic: boolean;
    // 设备编号
    devCode: string;
    // 参数类型
    paramTypeCode: string;

    constructor(
        private message: NzMessageService,
        private eventTransService: EventTransService,
    ) {
    }

    ngOnInit() {
        this.initSubscribe();
    }

    ngOnDestroy() {
        this.selectedObjChangeEventSub.unsubscribe();
        this.jsDataChangeSub.unsubscribe();
        this.eleTreeNodeSubscribe.unsubscribe();
    }

    /**
     * 初始化数据订阅事件
     */
    initSubscribe() {
        /**
         * 选中的对象发生变更
         */
        this.selectedObjChangeEventSub = this.eventTransService.selectedObjChangeEvent.subscribe((data: SelectedObj) => {
            this.handleObjChange(data);
        });
        /**
         * js脚本变更
         */
        this.jsDataChangeSub = this.eventTransService.jsDataChange.subscribe((data: JsData) => {
            if (data && data.type === JsDataType.Source) {
                this.action = data.content;
                this.onActionChange(null);
            }
        });
        /**
         * 图层选中节点变更
         */
        this.eleTreeNodeSubscribe = this.eventTransService.eleTreeNodeChange.subscribe((data: any) => {
            this.eleTreeNode = data;
        });
    }

    handleObjChange(data: SelectedObj) {
        if (!DataCache.page && !DataCache.template) {
            this.clearAll();
            return;
        }
        this.selectedElement = null;

        if (data && data.Element) {
            this.selectedElement = data.Element;
            this.paramTypeCode = this.selectedElement.attr(DataAttrName.meterType);
        }
        if (DataCache.page) {
            if (!data || data.Svg) {
                this.selectedComponent = null;
            } else if (data.Component) {
                this.selectedComponent = data.Component;
            } else {
                return;
            }
            this.initPageDataSource();
            return;
        }
        this.initTemplateDataSource();
    }

    clearAll() {
        this.isDynamic = false;
        this.devCode = null;
        this.action = null;
    }

    /**
     * 初始化页面数据源
     */
    initPageDataSource() {
        if (!this.selectedComponent || !DataCache.pageData.sources || DataCache.pageData.sources.length === 0) {
            this.dataSource = null;
            this.clearAll();
            return;
        }
        const dsId = this.selectedComponent.attr(DataAttrName.source);
        if (!dsId) {
            this.dataSource = null;
            this.clearAll();
            return;
        }
        this.dataSource = DataCache.pageData.sources.find(d => d.id === dsId);
        if (!this.dataSource) {
            this.clearAll();
            return;
        }
        this.initDataSource();
    }

    /**
     * 初始化模板数据源信息
     */
    initTemplateDataSource() {
        if (!DataCache.template.data || !DataCache.template.data.source) {
            this.dataSource = null;
            this.clearAll();
            return;
        }
        this.dataSource = DataCache.template.data.source;

        this.initDataSource();
    }

    initDataSource() {
        this.devCode = this.dataSource.code;
        this.isDynamic = this.dataSource.dynamic;
        this.action = this.dataSource.action;
        if (JsUtil.check(this.action)) {
            this.codeBorderColor = JsColor.right;
        } else {
            this.codeBorderColor = JsColor.error;
        }
    }

    /**
     * 动作内容变更事件
     */
    onActionChange(event: any) {
        if (!this.dataSource || !this.dataSource.code) {
            return;
        }
        this.dataSource.action = this.action;
        CommondAction.isChange = true;
        this.eventTransService.setCanvasCache.next(true);
        if (JsUtil.check(this.action)) {
            this.codeBorderColor = JsColor.right;
        } else {
            this.codeBorderColor = JsColor.error;
        }
    }


    /**
     * 放大脚本编辑器
     * @param event 事件
     */
    setToFull(event: any) {
        if (!this.dataSource) {
            this.message.warning('请先添加数据源！');
            return;
        }
        this.eventTransService.openJsWindow.next({ type: JsDataType.Source, content: this.dataSource.action });
    }

    /**
     * 脚本格式化
     */
    formatCode() {
        this.action = JsUtil.format(this.action);
        this.onActionChange(null);
    }

    /**
     * 数据源变更
     * @param event 事件
     */
    onDataSourceChange(event: any) {
        this.saveDataSource();
    }
    /**
     * 保存数据源
     */
    saveDataSource() {
        if (!DataCache.page && !DataCache.template) {
            this.message.warning('请先打开页面！');
            return;
        }
        if (DataCache.page && !this.selectedComponent) {
            this.message.warning('请先选择组件！');
            return;
        }

        CommondAction.isChange = true;

        let code = this.devCode;
        let isDynamic = this.isDynamic;

        if (this.dataSource) {
            if (code) {
                this.dataSource.code = code;
                this.dataSource.dynamic = isDynamic;
                this.dataSource.action = this.action;
            } else {
                // 移除数据源
                if (DataCache.page) {
                    DataCache.pageData.sources = DataCache.pageData.sources.filter(d => d.id !== this.dataSource.id);
                    this.selectedComponent.attr(DataAttrName.source, '');
                } else {
                    DataCache.template.data.source = null;
                }
                this.dataSource = null;
            }
        } else {
            if (!code) {
                return;
            }
            const dsId = MathUtil.getUUid();
            this.dataSource = {
                id: dsId,
                action: this.action,
                code,
                dynamic: isDynamic
            };
            if (DataCache.page) {
                this.selectedComponent.attr(DataAttrName.source, dsId);
                DataCache.pageData.sources.push(this.dataSource);
            } else {
                DataCache.template.data.source = this.dataSource;
            }
        }
    }

    /**
     * 参数类型
     */
    setParam() {
        if (!this.eleTreeNode || !this.selectedElement) {
            return;
        }
        let meterType = this.paramTypeCode;

        this.selectedElement.attr(DataAttrName.meterType, meterType);
        const match = this.eleTreeNode.title.match('<a>\\[(.+)\\]</a>');

        if (meterType) {
            meterType = `<a>[${meterType}]</a>`;
        }
        if (match) {
            this.eleTreeNode.title = this.eleTreeNode.title.replace(match[0], meterType);
        } else {
            this.eleTreeNode.title += meterType;
        }
    }
    /**
     * 变量类型发生变更
     */
    paramChange() {
        this.eventTransService.paramTypeCodeChange.next(true);
    }
}
