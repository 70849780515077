import { Property } from "../_models";
import { DataAttrName, SvgNodeType } from "../const";

export class Line {
    static getTag(selectedObj: any) {
        const lineType = selectedObj.attr(DataAttrName.type);
        return lineType === SvgNodeType.lineHor ? 'x' : 'y';
    }
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.len: {
                const tag = this.getTag(selectedObj);
                const t1 = selectedObj.attr(`${tag}1`);
                const t2 = selectedObj.attr(`${tag}2`);
                prop.setValue = parseFloat(t2) - parseFloat(t1);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.len: {
                const tag = this.getTag(selectedObj);
                const t1 = selectedObj.attr(`${tag}1`);
                const t2 = parseInt(t1, 10) + parseInt(prop.setValue, 10);;
                selectedObj.attr(`${tag}2`, t2);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
