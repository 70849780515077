import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->菜单图标
 */
@Injectable({ providedIn: 'root' })
export class MenuIconApi {
    private prefix = '/design/icon';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 获取工程下所有图标(Base64格式)
     * @param projectId 工程id
     */
    async getAll(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误[工程id]');
            return;
        }
        return await this.http.get(`${this.prefix}/getAll`, { projectId });
    }

    /**
     * 图标上传
     * @param file 文件对象
     * @param projectId 工程id
     */
    async upload(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/upload`, file, { projectId });
    }

    /**
     * 删除图标
     * @param id 图标id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[图标id]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }
}
