<nz-tabset>
  <nz-tab nzTitle="基本设置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentBase" [nzOffsetTop]="20">
        <nz-link nzHref="#type" nzTitle="类型"></nz-link>
        <nz-link nzHref="#event" nzTitle="事件"></nz-link>
        <nz-link nzHref="#dataSource" nzTitle="数据源"></nz-link>
        <nz-link nzHref="#dataTrans" nzTitle="数据转换"></nz-link>
        <nz-link nzHref="#multiY" nzTitle="多Y轴"></nz-link>
        <nz-link nzHref="#time" nzTitle="时间配置"></nz-link>
        <nz-link nzHref="#particleSize" nzTitle="数据粒度"></nz-link>
        <nz-link nzHref="#statisticTimeFrame" nzTitle="统计时段"></nz-link>
        <nz-link nzHref="#monthYear" nzTitle="同环比"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentBase>
      <div id='type'>
        <span>类型:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.type" class="typeRadio">
            <label nz-radio nzValue="base">基础柱状图</label>
            <label nz-radio nzValue="desc">降序柱图</label>
            <label nz-radio nzValue="asc">升序柱图</label>
          </nz-radio-group>
          <label nz-checkbox *ngIf="prop.type!=='base'" [(ngModel)]="prop.ver" style="width: 100%;">条形图</label>
        </div>
      </div>

      <div id='event'>
        <span>事件:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.enableClickEvent" style="width: 100%;">启用点击分解</label>
        </div>
      </div>

      <div id='dataSource'>
        <span>数据源:</span>
        <div class="left">
          <div>
            <div class="dataSource">
              <label nz-checkbox nz-tooltip nzTooltipTitle="动态数据源，需要在全局数据脚本中定义对应变量" [(ngModel)]="dataSource.dynamic"
                style="width: 20%;">动态</label>
              <nz-input-group nzAddOnBefore="标志" style="width: 80%;" nz-tooltip
                nzTooltipTitle="标志相同的数据源堆叠显示，不配置或不同时平铺显示">
                <input type="text" nz-input [(ngModel)]="dataSource.sign" />
              </nz-input-group>
            </div>
            <div class="dataSource">
              <nz-select [(ngModel)]="dataSource.type" style="width: 150px;">
                <nz-option nzValue="build" nzLabel="建筑"></nz-option>
                <nz-option nzValue="dept" nzLabel="分户"></nz-option>
                <nz-option nzValue="account" nzLabel="账套"></nz-option>
                <nz-option nzValue="device" nzLabel="设备"></nz-option>
                <nz-option nzValue="param" nzLabel="设备参数"></nz-option>
                <nz-option nzValue="formula" nzLabel="能耗关系"></nz-option>
              </nz-select>
              <ng-container [(ngSwitch)]="dataSource.type">
                <div *ngSwitchCase="'build'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="建筑id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'dept'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="分户id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'account'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="账套id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'device'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="设备编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="参数类型">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'param'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="参数id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'formula'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="关系id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                </div>
              </ng-container>
              <button nz-button nzType="default" (click)="addDataSource()">添加</button>
            </div>

          </div>
          <div class="dataSource-list">
            <nz-select [(ngModel)]="selDataSourceId" style="width: 86%;" (ngModelChange)="selDataSource()">
              <nz-option [(nzValue)]="item.id" [nzLabel]="getDataSourceDesc(item)"
                *ngFor="let item of prop.dataSources">
              </nz-option>
            </nz-select>
            <button nz-button nzType="default" nzDanger (click)="removeDataSource()">删除</button>
          </div>
        </div>
      </div>
      <div id='dataTrans'>
        <span>数据转换:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.toKgce" (ngModelChange)="setKgce()">转标煤</label><br>
          <label nz-checkbox [(ngModel)]="prop.toCO2" (ngModelChange)="setCO2()">转二氧化碳</label>
          <div>
            <nz-tag nzColor="warning">注：设备参数数据无法转换</nz-tag>
          </div>
        </div>
      </div>

      <div id='multiY'>
        <span>多Y轴:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.multiY" style="width: 100%;">多Y轴</label><br>
          <div>
            <nz-tag nzColor="warning">注：非多Y轴时，展示多种类型能耗时自动转为标煤（配置数据转换以数据转换配置项为准）</nz-tag>
          </div>
        </div>
      </div>

      <div id='time'>
        <span>时间配置:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.timeConfig.enable" style="width: 100%;">启用</label>
          <nz-radio-group [(ngModel)]="prop.timeConfig.timeType" style="width: 100%;">
            <label nz-radio nzValue="day">本日</label>
            <label nz-radio nzValue="two_day">近两日</label>
            <label nz-radio nzValue="week">本周</label>
            <label nz-radio nzValue="month">本月</label>
            <label nz-radio nzValue="season">本季度</label>
            <label nz-radio nzValue="year">本年</label>
            <label nz-radio nzValue="three_year">近三年</label>
            <label nz-radio nzValue="five_year">近五年</label>
          </nz-radio-group>
          <div>
            <label nz-checkbox [(ngModel)]="prop.timeConfig.showPreNext">显示上下周期</label>
            <nz-input-group nzAddOnBefore="默认周期" style="width: 200px;">
              <nz-input-number [(ngModel)]="prop.timeConfig.index" [nzMin]="-100" [nzMax]="0" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
          </div>
          <div>
            <label nz-checkbox [(ngModel)]="prop.timeConfig.enableCompare">显示对比周期</label>
            <nz-input-group nzAddOnBefore="默认周期" style="width: 200px;">
              <nz-input-number [(ngModel)]="prop.timeConfig.compareIndex" [nzMin]="-100" [nzMax]="0" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
          </div>
        </div>
      </div>
      <div id='particleSize'>
        <span>数据粒度:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.dataSize">
            <label nz-radio nzValue="hour">小时</label>
            <label nz-radio nzValue="day">日</label>
            <label nz-radio nzValue="month">月</label>
            <label nz-radio nzValue="year">年</label>
            <label nz-radio nzValue="all" nz-tooltip [nzTooltipTitle]="titleTemplate">智能(根据查询时间范围决定)</label>
          </nz-radio-group>
          <ng-template #titleTemplate>
            <div>小时：时间范围小于3天</div>
            <div>日：3天 ~ 3月</div>
            <div>月：3月 ~ 3年</div>
            <div>年：3年 以上</div>
          </ng-template>
          <div>
            <nz-tag nzColor="warning">注：类型为降序/升序柱图时该配置无效</nz-tag>
          </div>
        </div>
      </div>
      <div id='statisticTimeFrame'>
        <span>统计时段:</span>
        <div class="left">
          <div>
            <span>展示方式</span>
            <nz-radio-group style="margin-left: 10px;" [(ngModel)]="prop.sumTimeConfig.type">
              <label nz-tooltip [nzTooltipTitle]="stack" nz-radio nzValue="stack">堆叠(多数据源)</label>
              <label nz-tooltip [nzTooltipTitle]="diff" nz-radio nzValue="diff">对比(多数据源)</label>
              <label nz-tooltip [nzTooltipTitle]="sum" nz-radio nzValue="sum">汇总</label>
            </nz-radio-group>
            <ng-template #stack>
              <div>
                <span>说明1:</span>
                <p>将多个时段的数据堆叠展示(此时数据源的堆叠配置自动无效)</p>
                <span>说明2:</span>
                <p>数据粒度为小时时无效</p>
              </div>
            </ng-template>
            <ng-template #diff>
              <div>
                <span>说明1:</span>
                <p>将多个时段的数据对比展示(此时数据源的堆叠配置自动无效)</p>
                <span>说明2:</span>
                <p>数据粒度为小时时无效</p>
              </div>
            </ng-template>
            <ng-template #sum>
              <div>
                <span>说明1:</span>
                <p>将多个时段的数据汇总后展示</p>
                <span>说明2:</span>
                <p>数据粒度为小时时无效</p>
              </div>
            </ng-template>
            <div>
            </div>
          </div>

          <div class="time">
            <nz-time-picker [(ngModel)]="sumTime.start" nzFormat="HH:00">
            </nz-time-picker>
            <span>~</span>
            <nz-time-picker [(ngModel)]="sumTime.end" nzFormat="HH:00" style="margin-right: 10px;">
            </nz-time-picker>
            <nz-input-group nzAddOnBefore="别名">
              <input nz-input [(ngModel)]="sumTime.name" />
            </nz-input-group>
            <button (click)="addSumTime()" nz-radio-button nzType="primary">添加</button>
          </div>

          <div>
            <nz-select [(ngModel)]="selSumTimeId" style="width: 86%;">
              <nz-option [(nzValue)]="item.id" [nzLabel]="getSumTimeDesc(item)"
                *ngFor="let item of prop.sumTimeConfig.timeRanges">
              </nz-option>
            </nz-select>
            <button nz-button nzType="default" nzDanger (click)="removeSumTime()">删除</button>
          </div>

          <div>
            <nz-tag nzColor="warning">注1：不配置默认为全时段</nz-tag>
          </div>
          <div>
            <nz-tag nzColor="warning">注2：包含开始小时、结束小时；</nz-tag>
          </div>
          <div>
            <nz-tag nzColor="warning">注3：开始小时大于结束小时表示时段跨天；</nz-tag>
          </div>
          <div>
            <nz-tag nzColor="warning">注4：别名为空时以时间段命名；</nz-tag>
          </div>
          <div>
            <nz-tag nzColor="warning">注5：数据粒度为小时时无效</nz-tag>
          </div>
        </div>
      </div>
      <div id='monthYear'>
        <span>同环比:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.yearMonthConfig.showMonth">显示环比</label><br>
          <label nz-checkbox [(ngModel)]="prop.yearMonthConfig.showYear">显示同比</label>
        </div>
      </div>
    </div>
  </nz-tab>
  <nz-tab nzTitle="图表显示配置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <nz-link nzHref="#title" nzTitle="图表标题"></nz-link>
        <nz-link nzHref="#legend" nzTitle="图例"></nz-link>
        <nz-link nzHref="#dataZoom" nzTitle="区域缩放"></nz-link>
        <nz-link nzHref="#maxMin" nzTitle="最大最小值"></nz-link>
        <nz-link nzHref="#avg" nzTitle="平均值"></nz-link>
        <nz-link nzHref="#toolbox" nzTitle="工具箱"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <div id='title'>
        <span>图表标题:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.titleConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.titleConfig.position">
            <label nz-radio nzValue="top_left">左侧</label>
            <label nz-radio nzValue="top">居中</label>
          </nz-radio-group>
          <br>
          <label>标题内容：</label>
          <label nz-checkbox [(ngModel)]="prop.titleConfig.showTimeRange">时间区域</label>
          <nz-input-group nzAddOnBefore="特定内容">
            <input nz-input [(ngModel)]="prop.titleConfig.text" />
          </nz-input-group>
        </div>
      </div>
      <div id='legend'>
        <span>图例:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.legendConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.legendConfig.position">
            <label nz-radio nzValue="top">上</label>
            <label nz-radio nzValue="bottom">下</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='dataZoom'>
        <span>区域缩放:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.dataZoomConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.dataZoomConfig.position">
            <label nz-radio nzValue="top">上</label>
            <label nz-radio nzValue="bottom">下</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='maxMin'>
        <span>最大最小值:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.maxMinConfig">
            <label nz-checkbox nzValue="none">不显示</label><br>
            <label nz-radio nzValue="all">全部显示</label><br>
            <label nz-radio nzValue="smart">智能显示(<mark>所有数据源中的最大最小值</mark>)</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='avg'>
        <span>平均值:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.avgConfig">
            <label nz-radio nzValue="none">不显示</label><br>
            <label nz-radio nzValue="all">显示</label><br>
          </nz-radio-group>
        </div>
      </div>
      <div id='toolbox'>
        <span>工具箱:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.saveAsImg">保存为图片</label><br>
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.showArea">区域选择</label><br>
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.showDataView">数据视图</label><br>

          <div *ngIf="prop.toolBoxConfig.showDataView">
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showData">显示图表数据</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showMax">显示最大值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showMin">显示最小值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showAvg">显示平均值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showSum">显示合计值</label>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
