import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService, } from '../_services';
import {
    SaveSvgPageComponent, PageUpLoadComponent, IconManageComponent, PageSaveAsComponent,
    BackUpComponent
} from '../_modals';
import { Page } from '../_models';
import { CommondAction } from '../_helpers/commondAction';
import { PageApi, ProjectApi } from '../api';
import { DataCache } from '../cache';

/**
 * 页面内容空白区域右键菜单
 */
@Component({
    selector: 'app-page-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='setMenuPage()'>
                <i nz-icon nzType="menu" nzTheme="outline"></i>设为菜单
            </li>
            <li nz-menu-item (click)='setIcon(false)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标
            </li>
            <li nz-menu-item (click)='setIcon(true)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标(激活状态)
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='saveAs()'>
                <i nz-icon nzType="save" nzTheme="outline"></i>另存为
            </li>
            <li nz-menu-item (click)='importPage()'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage()'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='download()'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载页面
            </li>
            <li nz-menu-item (click)='deletePage()'>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='loadCache()'>
                <i nz-icon nzType="reload" nzTheme="outline"></i>加载缓存数据
            </li>
            <li nz-menu-item (click)='backup()'>
                <i nz-icon nzType="file-search" nzTheme="outline"></i>历史备份
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='publish()'>
                <i nz-icon nzType="global" nzTheme="outline"></i>发布
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setPageProperty()'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 224px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})
export class PageContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() page: Page;

    pageDataChangeSubscrite: Subscription;

    constructor(
        private message: NzMessageService,
        private modalService: NzModalService,
        private eventTransService: EventTransService,
        private projectApi: ProjectApi,
        private pageApi: PageApi
    ) { }

    /**
     * 设为首页
     */
    setHomePage() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.confirm({
            nzTitle: `<i>确认将页面：${this.page.text}设为首页？</i>`,
            nzOnOk: async () => {
                const ret = await this.projectApi.setHome(this.page.id);
                if (ret) {
                    this.message.success('操作成功');
                    this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                }
            }
        });
    }

    /**
     * 设为菜单
     */
    async setMenuPage() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (this.page.config.menu) {
            return;
        }
        const ret = await this.pageApi.setMenu(this.page.id);
        if (ret) {
            this.message.success('操作成功');
            this.page.config.menu = true;
            this.eventTransService.loadModuleEvent.next(this.page.moduleId);
        }
    }

    /**
     * 设置菜单图标
     */
    setIcon(isActive) {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!this.page.config.menu) {
            this.message.warning('所选页面不是菜单页面！');
            return;
        }
        const icon = isActive ? this.page.config.iconActive : this.page.config.icon;
        this.modalService.create({
            nzTitle: '图标选择',
            nzWidth: '80%',
            nzComponentParams: {
                selIconId: icon
            },
            nzOkText: '确认选择',
            nzContent: IconManageComponent,
            nzMaskClosable: true,
            nzOnOk: (ins) => ins.setIcon().then(async res => {
                if (!res) {
                    return false;
                }
                if (res.icon === icon) {
                    return true;
                }

                if (isActive) {
                    if (await this.pageApi.setIconActive(this.page.id, res.icon)) {
                        this.message.success('操作成功！');
                        this.page.config.iconActive = res.icon;
                    }
                    return true;
                }
                if (await this.pageApi.setIcon(this.page.id, res.icon)) {
                    this.message.success('操作成功！');
                    this.page.config.icon = res.icon;
                }
                return true;
            })
        });
    }


    /**
     * 页面克隆-另存为
     */
    saveAs() {
        this.modalService.create({
            nzTitle: '页面另存为',
            nzComponentParams: {
                page: this.page
            },
            nzContent: PageSaveAsComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async data => {
                if (data && data.page) {
                    const ret = await this.pageApi.saveAs(data.page, data.copyEvent, data.copyData);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadModuleEvent.next(data.page.moduleId);
                    }
                }
                return data;
            })
        });
    }

    /**
     * 创建页面
     */
    createPage() {
        this.modalService.create({
            nzTitle: '创建页面',
            nzComponentParams: {
                moduleId: this.page.moduleId
            },
            nzContent: SaveSvgPageComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async (page: Page) => {
                if (page) {
                    page.sort = this.page.sort;
                    const ret = await this.pageApi.createEmpty(page);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadModuleEvent.next('');
                    }
                }
                return page;
            })
        });
    }

    /**
     * 导入页面
     */
    importPage() {
        this.modalService.create({
            nzTitle: '页面导入',
            nzComponentParams: {
                moduleId: this.page.moduleId
            },
            nzContent: PageUpLoadComponent,
            nzOkText: '开始导入',
            nzOnOk: (ins) => ins.save()
        });
    }

    /**
     * 下载页面
     */
    async download() {
        const data: Blob = await this.pageApi.download(this.page.id);
        if (!data) {
            return;
        }
        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, `${this.page.text}(${this.page.name}).zip`);
    }

    /**
     * 删除页面
     */
    deletePage() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.confirm({
            nzTitle: `<i>确认删除页面：${this.page.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.pageApi.delete(this.page.id);
                if (ret) {
                    this.message.success('操作成功');
                    if (DataCache.page && this.page.id === DataCache.page.id) {
                        this.eventTransService.canvasDataChange.next(null);
                    }
                    this.eventTransService.loadModuleEvent.next(this.page.moduleId);
                }
            }
        });
    }

    /**
     * 普通页面属性设置
     */
    setPageProperty() {
        const origModuleId = this.page.moduleId;
        this.modalService.create({
            nzTitle: '属性设置-页面',
            nzComponentParams: {
                page: this.page
            },
            nzContent: SaveSvgPageComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async (data: Page) => {
                if (data) {
                    const ret = await this.pageApi.save(data);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadModuleEvent.next(data.moduleId);
                        if (data.moduleId !== origModuleId) {
                            this.eventTransService.loadModuleEvent.next(origModuleId);
                        }
                    }
                }
                return data;
            })
        });
    }

    /**
     * 加载缓存数据
     */
    loadCache() {
        if (CommondAction.isChange) {
            this.showConfirm().then((res: boolean) => {
                if (res) {
                    this.eventTransService.saveCanvasEvent.next(true);
                } else {
                    this.loadPageCache();
                }
            });
        } else {
            this.loadPageCache();
        }
    }

    loadPageCache() {
        this.eventTransService.loadCanvasCacheDataEvent.next({
            id: this.page.id,
            data: this.page,
            isPage: true
        });
    }

    showConfirm() {
        return new Promise((resolve) => {
            this.modalService.confirm({
                nzTitle: '当前页面尚未保存，是否保存?',
                nzOkText: '是',
                nzCancelText: '否',
                nzOnOk: () => {
                    resolve(true);
                }, nzOnCancel: () => {
                    resolve(false);
                }
            });
        });
    }

    /**
     * 历史备份
     */
    backup() {
        this.modalService.create({
            nzTitle: '历史备份记录',
            nzComponentParams: {
                id: this.page.id
            },
            nzWidth: 700,
            nzContent: BackUpComponent,
            nzMaskClosable: false,
            nzFooter: []
        });
    }

    async publish() {
        if (CommondAction.isChange) {
            this.message.warning('当前页面尚未保存，请保存后再发布！');
            return;
        }
        this.eventTransService.globalLoadingEvent.next('页面发布中，请稍等');
        const ret = await this.pageApi.publish(this.page.id);
        if (ret) {
            this.message.success('页面发布成功！');
        }
        this.eventTransService.globalLoadingEvent.next('');
    }
}
