<div class="rulers" style="display:block;" [hidden]="!showRuler">
  <button nz-button nzType="{{lockCanvas?'primary':'dashed'}}" (click)="lockChange()" class="lockBtn">
    <i nz-icon nzType="{{lockCanvas?'lock':'unlock'}}" nzTheme="{{lockCanvas?'outline':'fill'}}"></i>
  </button>
  <div id="ruler_x" #rulerX>
    <div style="width: 50000px;">
      <canvas #canvasX height="15" width="50000"></canvas>
    </div>
  </div>
  <div id="ruler_y" #rulerY>
    <div style="height: 50000px;">
      <canvas #canvasY width="15" height="50000"></canvas>
    </div>
  </div>
</div>

<div class="workarea">
  <div class="divCanvas" #divCanvas (scroll)="canvasScroll($event)" onselectstart="return false;">
    <app-svg-contextmenu *ngIf="menuState.SvgCanvas" [x]="contextmenuX" [y]="contextmenuY"
      (menuClickType)="menuClick($event)"></app-svg-contextmenu>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="svgCanvas" #svgCanvas
      style="width: 800px;height: 600px;background:#000000;" (drop)="drop($event)" (dragover)="allowDrop($event)"
      (contextmenu)="svgRightClick($event)" (click)="svgClick($event)" (mousedown)="svgMouseDown($event)"
      (mouseup)="svgMouseUp($event)" (mousemove)="svgMouseMove($event)" data-attr-keep-ratio="true">
      <g id="canvasGrid" *ngIf="showGridLine">
        <symbol>
          <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
            <path d="M 8 0 L 0 0 0 8" fill="none" stroke="rgba(0, 0, 0, 0.2)" stroke-width="0.5" stroke="#dadada30" />
          </pattern>
          <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
            <rect width="80" height="80" fill="url(#smallGrid)" />
            <path d="M 80 0 L 0 0 0 80" fill="none" stroke="rgba(0, 0, 0, 0.1)" stroke-width="1" stroke="#d6d2d20f" />
          </pattern>
        </symbol>
        <rect class="gridLine" width="100%" height="100%" fill="url(#grid)" *ngIf="showGridLine" />
      </g>

      <g id="showRoate" *ngIf="showRoate">
        <text x="0" y="30" style="font-size: 18px; font-family: 微软雅黑; fill: rgb(4 255 39); "
          xml:space="preserve">text</text>
      </g>
    </svg>
  </div>
</div>

<div class="footer">
  <button nz-button nzType="default" (click)="clearBackGround()">
    <i nz-icon nzType="clear" nzTheme="outline"></i>清除画布背景
  </button>
  <button nz-button nzType="default" (click)="clearId()">
    <i nz-icon nzType="clear" nzTheme="outline"></i>清除组件id(选中)
  </button>
  <button nz-button nzType="default" (click)="repairConfig()" style="color: #ff7c40;">
    <i nz-icon nzType="retweet" nzTheme="outline"></i>配置异常修复
  </button>
  <button nz-button nzType="default" (click)="cuotomPageJs()">
    <span class="iconfont iconjs"></span>页面自定义JS
  </button>
  <button nz-button nzType="default" (click)="cuotomProjectJs()" style="color: #3fd447;">
    <span class="iconfont iconjs"></span>工程全局JS
  </button>

  <nz-tag [nzColor]="pageStateColor" style="position: absolute;;right: 5px;">{{pageStateStr}}</nz-tag>
</div>