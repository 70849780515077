/**
 * 数据源类型
 */
export enum DataSourceType {
    // * 数据源类型，TreeNodeType
    // * device   :code 格式--> DeviceCode.ParamType
    // * formula  :code 格式--> 1(关系id)
    // * param    :code 合适--> 1(设备参数id)
    // * build    :code 格式--> 1.01000
    // * dept     :code 格式--> 2.01000
    // * account  :code 格式--> 1.01000

    /**
     * 建筑
     */
    build = 'build',
    /**
     * 分户
     */
    dept = 'dept',
    /**
     * 账套
     */
    account = 'account',
    /**
     * 设备
     */
    device = 'device',
    /**
     * 关系
     */
    formula = 'formula',
    /**
     * 参数
     */
    param = 'param'
}
