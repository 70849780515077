
    <div class="clearfix">
      <div style="display:flex;flex-wrap:wrap;">
        <div style="width:200px;">
          <nz-upload
            [(nzFileList)]="upList"
            [nzBeforeUpload]="beforeUpload"
            [nzRemove]="removeFile"
          >
            <button nz-button><i nz-icon nzType="upload"></i>上传</button>
          </nz-upload>
        </div>
        <nz-list nzBordered>
          <nz-list-item *ngFor="let item of fileList">
            {{ item.name }}
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="removeFile(item.name)">
                  <i nz-icon nzType="delete" nzTheme="outline" style="color:red;"></i>
                </a>
              </nz-list-item-action>
            </ul>
            <span nz-typography>{{item.lastModified}}</span>
          </nz-list-item>
        </nz-list>
    </div>
  </div>
  