import { Component } from '@angular/core';

@Component({
    selector: 'app-help-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.css']
})
export class SwitchHelpComponent {
    constructor(
    ) {
    }
}

