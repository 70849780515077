<div [ngSwitch]="com">
    <ng-container *ngSwitchCase="'文本.svg'">
        <app-help-text></app-help-text>
    </ng-container>
    <ng-container *ngSwitchCase="'文本-换行.svg'">
        <app-help-text-multi></app-help-text-multi>
    </ng-container>
    <ng-container *ngSwitchCase="'直线.svg'">
        <app-help-line></app-help-line>
    </ng-container>
    <ng-container *ngSwitchCase="'竖线.svg'">
        <app-help-line></app-help-line>
    </ng-container>
    <ng-container *ngSwitchCase="'圆形.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'矩形.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'三角形.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'多边形.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'椭圆.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'五角星.svg'">
        <app-help-circle></app-help-circle>
    </ng-container>
    <ng-container *ngSwitchCase="'按钮.svg'">
        <app-help-button></app-help-button>
    </ng-container>
    <ng-container *ngSwitchCase="'图标按钮.svg'">
        <app-help-button></app-help-button>
    </ng-container>
    <ng-container *ngSwitchCase="'按钮组.svg'">
        <app-help-button-group></app-help-button-group>
    </ng-container>
    <ng-container *ngSwitchCase="'开关.svg'">
        <app-help-switch></app-help-switch>
    </ng-container>
    <ng-container *ngSwitchCase="'文本输入框.svg'">
        <app-help-input></app-help-input>
    </ng-container>
    <ng-container *ngSwitchCase="'数字输入框.svg'">
        <app-help-input></app-help-input>
    </ng-container>
    <ng-container *ngSwitchCase="'复选框.svg'">
        <app-help-checkbox></app-help-checkbox>
    </ng-container>
    <ng-container *ngSwitchCase="'单选框.svg'">
        <app-help-radio></app-help-radio>
    </ng-container>
    <ng-container *ngSwitchCase="'下拉列表.svg'">
        <app-help-select></app-help-select>
    </ng-container>
    <ng-container *ngSwitchCase="'平铺列表.svg'">
        <app-help-select-panel></app-help-select-panel>
    </ng-container>
    <ng-container *ngSwitchCase="'日期选择.svg'">
        <app-help-date></app-help-date>
    </ng-container>
    <ng-container *ngSwitchCase="'时间选择.svg'">
        <app-help-time></app-help-time>
    </ng-container>
    <ng-container *ngSwitchCase="'树.svg'">
        <app-help-tree></app-help-tree>
    </ng-container>
    <ng-container *ngSwitchCase="'折线图.svg'">
        <app-help-chart-line></app-help-chart-line>
    </ng-container>
    <ng-container *ngSwitchCase="'柱状图.svg'">
        <app-help-chart-line></app-help-chart-line>
    </ng-container>
    <ng-container *ngSwitchCase="'饼图.svg'">
        <app-help-chart-line></app-help-chart-line>
    </ng-container>
    <ng-container *ngSwitchCase="'仪表盘.svg'">
        <app-help-chart-gauge></app-help-chart-gauge>
    </ng-container>
    <ng-container *ngSwitchCase="'水球占比图.svg'">
        <app-help-chart-liquid></app-help-chart-liquid>
    </ng-container>
    <ng-container *ngSwitchCase="'环形进度条.svg'">
        <app-help-chart-liquid></app-help-chart-liquid>
    </ng-container>
    <ng-container *ngSwitchCase="'条形进度条.svg'">
        <app-help-chart-liquid></app-help-chart-liquid>
    </ng-container>
    <ng-container *ngSwitchCase="'滑块.svg'">
        <app-help-slider></app-help-slider>
    </ng-container>
    <ng-container *ngSwitchCase="'页面容器.svg'">
        <app-help-iframe></app-help-iframe>
    </ng-container>
    <ng-container *ngSwitchCase="'萤石视频.svg'">
        <app-help-video-ys></app-help-video-ys>
    </ng-container>
    <ng-container *ngSwitchCase="'表格.svg'">
        <app-help-table></app-help-table>
    </ng-container>
</div>