import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { saveAs } from 'file-saver';
import { EventTransService } from '../_services';
import { NameInputComponent, SelectComClassifyComponent, FileUpLoadComponent } from '../_modals';
import { ComClassify, ComponentData } from '../_models';
import { CommondAction } from '../_helpers/commondAction';
import { ComponentApi } from '../api';
import { ImgType } from '../const';
import { ComponentCache } from '../cache';

declare var Snap: any;
@Component({
    selector: 'app-com-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='reNameComponent()' *ngIf="!classify.base">
                <i nz-icon nzType="redo" nzTheme="outline"></i>重命名
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='addComponent($event)' *ngIf="!classify.base">
                <i nz-icon nzType="file-add" nzTheme="outline"></i>添加组件
            </li>
            <li nz-menu-item (click)='deleteComponent()' *ngIf="!classify.base" [nzDisabled]=component.sys>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除组件
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='updateClassify()' *ngIf="!classify.base">
                <i nz-icon nzType="partition" nzTheme="outline"></i>更换组件分类
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='downLoad()'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载至本地
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='setBG()' *ngIf="!classify.base">
                <i nz-icon nzType="file-image" nzTheme="outline"></i>设为画布背景
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})
/**
 * 组件右键菜单
 */
export class ComContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() classify: ComClassify;
    @Input() component: ComponentData;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private componentApi: ComponentApi
    ) { }

    /**
     * 重命名
     */
    reNameComponent() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '修改组件名称',
            nzComponentParams: {
                tipMsg: '请输入新的组件名称...',
                inputName: this.component.text
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    if (name === this.component.text) {
                        this.message.warning('新名称不能与原名称相同！');
                        return false;
                    }
                    const ret = await this.componentApi.rename(this.component.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.classifyDataChangeEvent.next(this.classify.id);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 添加组件
     */
    addComponent(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '组件批量上传',
            nzComponentParams: {
                classify: this.classify
            },
            nzContent: FileUpLoadComponent,
            nzMaskClosable: false,
            nzOkText: '开始上传',
            nzOnOk: (ins) => ins.save()
        });
    }

    /**
     * 删除组件
     */
    deleteComponent() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (this.component.sys) {
            return;
        }
        this.modalService.confirm({
            nzTitle: `<i>确认删除组件：${this.component.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.componentApi.delete(this.component.id);
                if (ret) {
                    this.message.success('操作成功');
                    this.eventTransService.classifyDataChangeEvent.next(this.classify.id);
                }
            }
        });
    }

    /**
     * 更换组件分类
     */
    updateClassify() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '选择组件分类',
            nzContent: SelectComClassifyComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(res => {
                if (res) {
                    if (res === this.classify.id) {
                        return true;
                    } else {
                        this.updateNewClassify(res);
                    }
                }
                return res;
            })
        });
    }

    async updateNewClassify(classifyId: string) {
        const ret = await this.componentApi.setParent(this.component.id, classifyId);
        if (ret) {
            this.message.success('操作成功');
            this.eventTransService.classifyDataChangeEvent.next(this.classify.id);
            this.eventTransService.classifyDataChangeEvent.next(classifyId);
        }
    }

    /**
     * 下载至本地
     */
    async downLoad() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!ComponentCache.pull(this.component.id)) {
            const data = await this.componentApi.getContent(this.component.id);
            if (!data.data) {
                return;
            }
            this.component.width = data.width;
            this.component.height = data.height;
            ComponentCache.push(this.component.id, data);
        }
        this.beginDownLoad();
    }

    beginDownLoad() {
        const cache = ComponentCache.pull(this.component.id);
        if (this.component.type === ImgType.Svg) {
            const blob = new Blob([cache.data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, this.component.text);
        } else {
            const contentType = this.component.type;
            const blob = this.b64toBlob(cache.data, contentType);
            saveAs(blob, this.component.text);
        }
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    /**
     * 设置画布背景
     */
    async setBG() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!ComponentCache.pull(this.component.id)) {
            const data = await this.componentApi.getContent(this.component.id);
            if (!data.data) {
                return;
            }
            this.component.width = data.width;
            this.component.height = data.height;
            ComponentCache.push(this.component.id, data);
        }
        this.setSvgBackground(ComponentCache.pull(this.component.id).data);
    }

    setSvgBackground(content) {
        const svg = Snap('#svgCanvas');
        let src = ``;
        if (this.component.type === ImgType.Svg) {
            src = `url(data:${this.component.type};base64,${btoa(content)})`;
        } else {
            src = `url(data:${this.component.type};base64,${content})`;
        }
        svg.node.style.setProperty('background-size', '100% 100%');
        svg.node.style.setProperty('background-image', src);
        this.message.success('操作成功！');
        CommondAction.isChange = true;
        this.eventTransService.setCanvasCache.next(true);
    }
}

