
        <ul [ngStyle]="{'left.px':x, 'top.px':y}" (contextmenu)='menuRightClick($event)'>
            <li nz-menu-item (click)='reNameClassify()' *ngIf="!classify.base">
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='addComponent($event)' *ngIf="!classify.base">
                <i nz-icon nzType="file-add" nzTheme="outline"></i>添加组件
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='createClassify()'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>添加组件分类
            </li>
            <li nz-menu-item (click)='deleteClassify()' *ngIf="!classify.base" [nzDisabled]=!enableDeleteMenu>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除组件分类
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='packDownload()' *ngIf="!classify.base">
                <i nz-icon nzType="download" nzTheme="outline"></i>打包下载
            </li>
        </ul>
    