import { PositionEnum } from './positionEnum';

/**
 * 数据区域缩放配置
 */
export class DataZoomConfig {
    /**
     * 是否显示
     */
    show = true;
    /**
     * 位置
     */
    position = PositionEnum.BOTTOM;
}
