import { AnimeBreathProp } from './animeBreathProp';
import { AnimeFlowProp } from './animeFlowProp';
import { AnimeRotateProp } from './animeRotateProp';
import { AnimeTranslateProp } from './animeTranslateProp';
import { AnimeTwinkleProp } from './animeTwinkleProp';

/**
 * 动画属性
 */
export class AnimeProp {
    rotate = new AnimeRotateProp();
    twinkle = new AnimeTwinkleProp();
    breath = new AnimeBreathProp();
    flow = new AnimeFlowProp();
    translate = new AnimeTranslateProp();
}
