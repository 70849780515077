import { ProjectConfig } from './projectConfig';
import { DataCenter } from './dataCenter';
import { Platform } from './platform';
import { ProjectRoute } from './projectRoute';

export class Project {
    /**
     * 工程ID
     */
    id: string;
    /**
     * 工程名
     */
    name: string;
    /**
     * 工程创建人
     */
    creator: string;
    /**
     * 工程创建时间
     */
    createTime: string;
    /**
     * 是否主工程
     */
    main: boolean;
    /**
     * 站点路径
     */
    sitePath: string;
    /**
     * 工程路由
     */
    routes: ProjectRoute[] = [];
    /**
     * 主题id
     */
    themeId: string;
    /**
     * 工程配置信息
     */
    config: ProjectConfig = new ProjectConfig();
    /**
     * 平台接入
     */
    platform: Platform = new Platform();
    /**
     * 全局js
     */
    js: string = '';
}
