import { Property } from '../_models';
import { PxUtil } from '../utils';

export class Button {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'text': {
                prop.setValue = selectedObj.select('button').node.innerText;
                break;
            }
            case 'font-size': {
                prop.setValue = selectedObj.select('button').node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'text': {
                selectedObj.select('button').node.innerText = prop.setValue;
                break;
            }
            case 'font-size': {
                selectedObj.select('button').node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
