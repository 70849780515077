
        <label nz-checkbox [(ngModel)]="isSingle">单组件</label>
        <nz-input-group nzAddOnBefore="列数(C)">
            <nz-input-number [(ngModel)]="colNum" [nzMin]="1" [nzMax]="50" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="行数(R)">
            <nz-input-number [(ngModel)]="rowNum" [nzMin]="1" [nzMax]="50" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="列宽度(W)">
            <nz-input-number [(ngModel)]="width" [nzMin]="1" [nzMax]="500" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="行高(H)">
            <nz-input-number [(ngModel)]="height" [nzMin]="1" [nzMax]="500" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        