import { Component, Output, Input, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { Property } from '../../_models';
import { ColorHelper } from '../../_helpers/colorHelper';
@Component({
  selector: 'app-color-picker',
  templateUrl: './colorPicker.component.html',
  styleUrls: ['./colorPicker.component.css']
})
export class ColorPickerComponent {
  @ViewChild('colorObj') colorObj: any;

  // 初始化颜色是从父组件中传递过来的，属性
  @Input() color = '#ffffff';
  @Input() position = 'left';
  @Input() property: Property;

  // 选择颜色以后调用父组件中的方法，将数据传递出去，方法
  @Output() selectedColor = new EventEmitter();

  presentColors = [];

  constructor(public vcRef: ViewContainerRef
  ) {
    if (!this.color) {
      this.color = '#ffffff';
    }
    this.presentColors = ColorHelper.COLORS;
  }

  // 当选择颜色以后
  colorPickerChangeFun(event) {
    if (this.property) {
      this.property.setValue = this.color;
      this.selectedColor.emit(this.property);
    }
  }

  open(event: any) {
    this.presentColors = ColorHelper.COLORS;
    const pos = this.colorObj.nativeElement.getBoundingClientRect();
    const { left: x, top: y } = pos;
    for (const item of this.colorObj.nativeElement.children) {
      if (item.localName === 'color-picker') {
        const { positionX, positionY } = this.getColorPostion({ x, y, width: 200, height: 393 });

        item.style.position = 'fixed';
        item.style.zIndex = '99999';
        item.style.top = `${positionY}px`;
        item.style.left = `${positionX}px`;
        item.children[0].children[0].style.top = `${y - positionY}px`;
      }
    }
  }

  getColorPostion({ height, width, x, y }) {
    x -= 20;
    y -= 100;
    height += 20;
    const { innerHeight, innerWidth } = window;
    if (y + height > innerHeight) {
      y = innerHeight - height;
    }
    if (x + width > innerWidth) {
      x = innerWidth - width;
    }
    return { positionX: x, positionY: y };
  }

  close() {
    this.presentColors = ColorHelper.setColors(this.color);
  }

}
