
/**
 * 数据粒度配置
 */
export enum DataSizeConfigEnum {
    /**
     * 小时
     */
    HOUR = 'hour',
    /**
     * 日
     */
    DAY = 'day',
    /**
     * 月
     */
    MONTH = 'month',
    /**
     * 年
     */
    YEAR = 'year',
    /**
     * 自动（根据查询时段决定）
     */
    ALL = 'all'
}
