import { Component } from '@angular/core';

@Component({
    selector: 'app-help-chart-gauge',
    templateUrl: './chartGauge.component.html',
    styleUrls: ['./chartGauge.component.css']
})
export class ChartGaugeHelpComponent {
    constructor(
    ) {
    }
}

