import { PageConfig } from './pageConfig';
/**
 * 页面数据
 */
export class Page {
    /**
     * 页面id（唯一、自动生成）
     */
    id: string;
    /**
     * 页面名（唯一）
     */
    name: string;
    /**
     * 页面名称
     */
    text: string;
    /**
     * 所属模块id
     */
    moduleId: string;
    /**
     * 所属工程id
     */
    projectId: string;
    /**
     * cms页面url
     */
    url: string;
    /**
     * 用户页面
     */
    userPage: string;
    /**
     * 页面参数
     */
    param: string;
    /**
     * 排序编号
     */
    sort: number;
    /**
     * 页面配置信息
     */
    config: PageConfig = new PageConfig();
    /**
     * 页面内容
     */
    content: string;
}
