import { IndexPageConfig } from "./indexPageConfig";
import { LoginPageConfig } from "./loginPageConfig";

/**
 * 主题配置
 */
export class ThemeConfig {
    /**
     * 工程id
     */
    id: string;
    /**
     * 登录页配置
     */
    login: LoginPageConfig = new LoginPageConfig();
    /**
     * 首页配置
     */
    index: IndexPageConfig = new IndexPageConfig();
}