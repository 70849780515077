import { BarTypeEnum } from './barTypeEnum';
import { DataSizeConfigEnum } from './dataSizeConfigEnum';
import { BaseProperty } from '../base/baseProperty';
import { DataSourceConfig } from '../base/dataSourceConfig';
import { SumTimeConfig } from './sumTimeConfig';
import { YearMonthConfig } from '../base/yearMonthConfig';
import { ShowTypeEnum } from '../base/showTypeEnum';
import { DataZoomConfig } from '../base/dataZoomConfig';

/**
 * 柱图属性
 */
export class BarProperty extends BaseProperty {
    /**
     * 类型
     */
    type = BarTypeEnum.BASE;
    /**
     * 条形图
     */
    ver = false;
    /**
     * 启用点击分解
     */
    enableClickEvent = false;
    /**
     * 数据源配置
     */
    dataSources: DataSourceConfig[] = [];
    /**
     * 转标煤
     */
    toKgce = false;
    /**
     * 转二氧化碳
     */
    toCO2 = false;
    /**
     * 多Y轴
     */
    multiY = false;
    /**
     * 数据粒度
     */
    dataSize = DataSizeConfigEnum.ALL;
    /**
     * 统计时间段配置
     */
    sumTimeConfig: SumTimeConfig = new SumTimeConfig();
    /**
     * 同环比配置
     */
    yearMonthConfig: YearMonthConfig = new YearMonthConfig();
    /**
     * 数据缩略视图配置
     */
    dataZoomConfig: DataZoomConfig = new DataZoomConfig();
    /**
     * 最大最小值配置
     */
    maxMinConfig = ShowTypeEnum.NONE;
    /**
     * 平均值展示配置
     */
    avgConfig = ShowTypeEnum.NONE;
}
