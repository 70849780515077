import { PositionEnum } from './positionEnum';

/**
 * 图例配置
 */
export class LegendConfig {
    /**
     * 显示图例
     */
    show = true;
    /**
     * 位置
     */
    position = PositionEnum.BOTTOM;
    /**
     * 是否垂直显示
     */
    ver = false;
}
