import { Component } from '@angular/core';

@Component({
    selector: 'app-help-circle',
    templateUrl: './circle.component.html',
    styleUrls: ['./circle.component.css']
})
export class CircleHelpComponent {
    constructor(
    ) {
    }
}

