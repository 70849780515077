<div class="main">
    <div class="desc">
        <span class="title">改变<span class="mark">按钮文本</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value('按钮1');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">自动触发按钮<span class="mark">点击事件</span>:</span>
        <div class="code">
            <div class="remark">写在按钮点击事件下方，页面加载完成后自动点击按钮。</div>
            <div>
                <@p2.*>.click();
            </div>
        </div>
    </div>
</div>