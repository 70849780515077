
        <span>查找内容：</span>
        <nz-radio-group [(ngModel)]="type" (ngModelChange)="typeChange()">
          <label nz-radio [nzValue]="findType.source">数据源编号</label>
          <label nz-radio [nzValue]="findType.id">组件id</label>
          <label nz-radio [nzValue]="findType.sourceJs">数据源脚本</label>
          <label nz-radio [nzValue]="findType.eventJs">事件脚本</label>
        </nz-radio-group>
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
            <input type="text" nz-input [(ngModel)]="content" placeholder="查找的内容" />
        </nz-input-group>
        <ng-template #addOnBeforeTemplate>
            <nz-select [(ngModel)]="where">
                <nz-option nzLabel="等于" [nzValue]="findWhere.equal"></nz-option>
                <nz-option nzLabel="包含" [nzValue]="findWhere.like"></nz-option>
                <nz-option nzLabel="开头" [nzValue]="findWhere.start"></nz-option>
                <nz-option nzLabel="结尾" [nzValue]="findWhere.end"></nz-option>
            </nz-select>
        </ng-template>
    