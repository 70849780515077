<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">新增列</span>:</span>
        <div class="code">
            <@p2.*>.obj().addCol('列名');
        </div>
        <div class="remark">批量新增列：</div>
        <div class="code">
            <@p2.*>.obj().addCols(['列1','列2','列3']);
        </div>
        <div class="remark">插入列：参数1：插入的位置(从0开始)；参数2：列名</div>
        <div class="code">
            <@p2.*>.obj().insertCol(0, '列0');
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">更新列名</span>:</span>
        <div class="remark">参数1：列的位置(从0开始)；参数2：新的列名</div>
        <div class="code">
            <@p2.*>.obj().updateCol(0,'新列名');
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">删除列</span>:</span>
        <div class="remark">参数：列的位置(从0开始)</div>
        <div class="code">
            <@p2.*>.obj().deleteCol(0);
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">新增行</span>:</span>
        <div class="remark">参数为列表格式，列表数量需与表格列数一致</div>
        <div class="code">
            <@p2.*>.obj().addRow(['row1', 'row2', 'row3']);
        </div>
        <div class="remark">批量新增行(二维列表)：</div>
        <div class="code">
            <@p2.*>.obj().addRows([['row1', 'row2', 'row3'], ['row11', 'row22', 'row33']]);
        </div>
        <div class="remark">插入行：参数1：插入的位置(从0开始)；参数2：行数据</div>
        <div class="code">
            <@p2.*>.obj().insertRow(0, ['row1', 'row2', 'row3']);
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">删除行</span>:</span>
        <div class="remark">参数：行的位置(从0开始)</div>
        <div class="code">
            <@p2.*>.obj().deleteRow(0);
        </div>
        <div class="remark">删除所有行数据</div>
        <div class="code">
            <@p2.*>.obj().clearRow();
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">导出</span>:</span>
        <div class="code">
            <@p2.*>.obj().export('文件名');
        </div>
    </div>

</div>