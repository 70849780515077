import { AnimeDirection } from './animeDirection';
import { AnimeEasing } from './animeEasing';

/**
 * 旋转动画属性
 */
export class AnimeRotateProp {
    /**
     * 启用动画
     */
    enable = false;
    /**
     * 旋转角度
     */
    angle = 360;
    /**
     * 循环次数，0表示无限循环
     */
    loop = 0;
    /**
     * 循环一次时间（毫秒）
     */
    duration = 1000;
    /**
     * 循环方向
     */
    direction = AnimeDirection.normal;
    /**
     * 动画效果
     */
    easing = AnimeEasing.linear;
}
