/**
 * 图表数据源配置项
 */
export class DataSourceConfig {
    id?: string;
    /**
     * 是否动态数据源
     */
    dynamic = false;
    /**
     * 数据源类型，TreeNodeType
     * device   :code 格式--> DeviceCode.ParamType
     * formula  :code 格式--> 1(关系id)
     * param    :code 合适--> 1(设备参数id)
     * build    :code 格式--> 1.01000
     * dept     :code 格式--> 2.01000
     * account  :code 格式--> 1.01000
     */
    type: string;
    /**
     * 数据源编码
     */
    code: string;
    /**
     * 数据源标志(柱图用)
     * 标志相同的数据源堆叠显示，未配置或不同平铺显示
     */
    sign?: string;
    /**
     * 固定值（仪表盘用）
     */
    fixedValue?: number;
}
