import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CommonApi } from '../api';
import { ServiceSetComponent } from '../_modals';

import { Md5 } from 'ts-md5/dist/md5';
import { DataCache } from '../cache';
import { User } from '../_models';
import { DateUtil } from '../utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  isLoading = false;

  private sysConfigKey = 'sysConfig';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NzModalService,
    private message: NzMessageService,
    private commonApi: CommonApi,
  ) {

  }

  ngOnInit(): void {
    const form = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.validateForm = form;

    setTimeout(() => {
      if (!this.initSysConfig()) {
        this.modalService.warning({
          nzTitle: '请先配置服务器信息',
          nzOnOk: () => this.setService()
        });
        return;
      }
    }, 0);
  }

  async submitForm() {
    if (!this.initSysConfig()) {
      this.modalService.warning({
        nzTitle: '请先配置服务器信息',
        nzOnOk: () => this.setService()
      });
      return;
    }

    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls[i]) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.invalid) {
      return;
    }

    this.isLoading = true;

    const param = {
      userName: this.validateForm.controls.userName.value,
      password: Md5.hashStr(this.validateForm.controls.password.value)
    };

    let data: any = await this.commonApi.login(param);
    this.isLoading = false;

    if (!data) {
      return;
    }
    this.setLogin(param.userName, data);
  }

  setLogin(name, data: any) {
    const user: User = {
      name,
      token: data.token,
      expiratime: DateUtil.addSeconds(new Date(), data.expiration)
    };
    sessionStorage.setItem('user', JSON.stringify(user));

    this.router.navigate(['/']);
  }


  /**
   * 服务器信息配置
   */
  setService(): void {
    let protocol = 'http://';
    let address = '';
    let version = 'v1';
    if (DataCache.sysConfig) {
      if (DataCache.sysConfig.domin) {
        if (DataCache.sysConfig.domin.indexOf('https:') >= 0) {
          protocol = 'https://';
        }
        address = DataCache.sysConfig.domin.replace(protocol, '');
      }
      version = DataCache.sysConfig.version;
    }
    this.modalService.create({
      nzTitle: '服务器配置',
      nzComponentParams: {
        protocol, address, version
      },
      nzContent: ServiceSetComponent,
      nzMaskClosable: false,
      nzOnOk: (ins) => ins.save()
    });
  }

  initSysConfig(): boolean {
    if (DataCache.sysConfig && DataCache.sysConfig.domin) {
      return true;
    }
    if (!localStorage.getItem(this.sysConfigKey)) {
      return false;
    }
    DataCache.sysConfig = JSON.parse(localStorage.getItem(this.sysConfigKey));
    if (!DataCache.sysConfig || !DataCache.sysConfig.domin) {
      return false;
    }
    return true;
  }
}
