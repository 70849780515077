<div class="main">
    <div class="desc">
        <span class="title">设置<span class="mark">图表实时值</span>:</span>
        <div class="remark">设定的实时值范围：0~100</div>
        <div class="code">
            <@p2.*>.obj().setReal(60);
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">图表ECharts对象</span>:</span>
        <div class="code">
            <@p2.*>.obj().getChart();
        </div>
    </div>
    <div class="desc">
        <span class="title">修改<span class="mark">图表option对象</span>:</span>
        <div class="remark">添加图表事件：《数据加载完成》。事件参数：e为option对象。</div>
        <div class="code">
            <@*>.dataLoadSuccess(function(e) {{'{'}}
                e.title.text = '测试';
                {{'}'}});
        </div>
    </div>
</div>