import { Component, Input, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { DataCache } from '../cache';
import { JsColor } from '../const';
import { JsUtil } from '../utils';
import { CommondAction } from '../_helpers/commondAction';


/*js脚本编辑器 */
@Component({
    selector: 'app-js-event',
    template: `
        <button nz-button nzType="primary" (click)="format()"><span class="iconfont iconmeihuadaima"></span>格式化</button>
        <button nz-button nzType="primary" (click)="addFunc()"><span class="iconfont iconhanshu"></span>新增空方法</button>
        <button nz-button nzDanger (click)="close()"><i nz-icon nzType="close-circle" nzTheme="outline"></i>关闭</button>

        <code-input ngDefaultControl lang="javascript" style="width: 100%;height: 90%;border: 1px solid;"
            [(ngModel)]="action" (ngModelChange)="onActionChange()" [style.border-color]="codeBorderColor">
        </code-input>
    `,
    styles: [
        `
        [nz-button] {
            margin: -6px 10px 5px 0;
        }
        `
    ]
})

export class JsEventComponent implements OnInit, OnDestroy {
    action = '';
    funcIndex = 0;
    codeBorderColor = JsColor.right;

    constructor(
        private drawerRef: NzDrawerRef
    ) { }

    ngOnInit() {
        this.action = DataCache.pageData.globalEvent;
        if (JsUtil.check(this.action)) {
            this.codeBorderColor = JsColor.right;
        } else {
            this.codeBorderColor = JsColor.error;
        }
    }

    ngOnDestroy() {
    }

    format() {
        this.action = JsUtil.format(this.action);
        this.onActionChange();
    }

    /**
     * 新增空方法
     */
    addFunc() {
        this.funcIndex++;
        if (!this.action) {
            this.action = '';
        }
        this.action += '\n';
        this.action += `var funcName${this.funcIndex} = function () {`;
        this.action += '\n  \n';
        this.action += '};';

        this.onActionChange();
    }

    onActionChange() {
        CommondAction.isChange = true;
        DataCache.pageData.globalEvent = this.action;
        if (JsUtil.check(this.action)) {
            this.codeBorderColor = JsColor.right;
        } else {
            this.codeBorderColor = JsColor.error;
        }
    }

    /**
     * 关闭
     */
    close() {
        this.drawerRef.close();
    }
}
