/**
 * 展示类型
 */
export enum ShowTypeEnum {
    /**
     * 不显示
     */
    NONE = 'none',
    /**
     * 显示所有
     */
    ALL = 'all',
    /**
     * 智能显示
     */
    SMART = 'smart'
}
