import { DataAttrName } from '../const';
import { Property } from '../_models';
import { PxUtil } from '../utils';
declare var $: any;
export class InputRadio {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                prop.setValue = selectedObj.select('label').node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            case DataAttrName.items: {
                const lst = [];
                selectedObj.selectAll('label').forEach(item => {
                    lst.push(`${item.node.innerText.trim()}`);
                });
                prop.setValue = lst.join(',');
                break;
            }
            case 'display': {
                prop.setValue = selectedObj.node.style.getPropertyValue('display');
                break;
            }
            case DataAttrName.margin: {
                const labels = selectedObj.selectAll('label');
                if (labels.length <= 1) {
                    prop.setValue = '0';
                } else {
                    let margin = 'margin-left';
                    const display = selectedObj.node.style.getPropertyValue('display');
                    if (display && display === 'flex') {
                        margin = 'margin-top';
                    }

                    prop.setValue = labels[1].node.style.getPropertyValue(margin).replace('px', '');
                    if (!prop.setValue) {
                        prop.setValue = '0';
                    }
                }
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                selectedObj.selectAll('label').forEach(item => {
                    item.node.style.setProperty(prop.name, prop.setValue + 'px');
                });
                break;
            }
            case 'display': {
                const labels = selectedObj.selectAll('label');
                if (labels.length > 1) {
                    let margin = 'margin-left';
                    let origMargin = 'margin-top';
                    if (prop.setValue === 'flex') {
                        margin = 'margin-top';
                        origMargin = 'margin-left';
                    }
                    const setVal = labels[1].node.style.getPropertyValue(origMargin);
                    for (let i = 1; i < labels.length; i++) {
                        labels[i].node.style.setProperty(margin, setVal);
                        labels[i].node.style.setProperty(origMargin, '0px');
                    }
                }
                selectedObj.node.style.setProperty('display', prop.setValue);
                break;
            }
            case DataAttrName.margin: {
                const labels = selectedObj.selectAll('label');
                if (labels.length > 1) {
                    let margin = 'margin-left';
                    const display = selectedObj.node.style.getPropertyValue('display');
                    if (display && display === 'flex') {
                        margin = 'margin-top';
                    }
                    for (let i = 1; i < labels.length; i++) {
                        labels[i].node.style.setProperty(margin, prop.setValue + 'px');
                    }
                }
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
