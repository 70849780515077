import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ColorPickerModule } from 'ngx-color-picker';

/**
 * 引入UI：NG-ZORRO
 */
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**
 * 配置 angular i18n
 */
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
registerLocaleData(zh);

import { JwtInterceptor, ErrorInterceptor, GlobalErrorHandler } from './_helpers';

import { CodeInputComponent } from './home/codeinput';

/**
 * 自定义组件
 */
import { LoginComponent } from './login';
import {
  HomeComponent, HeaderComponent, LeftSideNavComponent, RightSideNavComponent,
  SvgCanvasComponent, ColorPickerComponent, PropertyComponent, AnimateComponent,
  DataSourceComponent, DataEventComponent, ProjectComponent, ComComponent, ToolBarComponent
} from './home';

/**
 * 自定义模态对话框
 */
import {
  SelectProjectComponent, SelectComClassifyComponent, NameInputComponent, JsEventComponent, SaveSvgPageComponent,
  FileUpLoadComponent, CreateTableComponent, SaveToBaseModelComponent, ProjectUpLoadComponent, PageUpLoadComponent, PageSaveAsComponent,
  ProjectManageComponent, TemplateSaveAsComponent, ReplaceComponent, FindComponent, NumberInputComponent,
  ProjectSetComponent, IconManageComponent, DeleteInputComponent, VideoManageComponent, ChartLineConfigComponent, ChartBarConfigComponent,
  ChartPieConfigComponent, ChartGaugeConfigComponent,
  ServiceSetComponent, BackUpComponent,
  CreateProjectComponent, AudioManageModalComponent, ItemsConfigComponent, SelectConfigComponent,
  ModulePropComponent, ThemeSetComponent
} from './_modals';

/**
 * 鼠标右键菜单
 */
import {
  ClassifyContentContextMenuComponent, ClassifyTitleContextMenuComponent, ComContextMenuComponent, SvgContextMenuComponent,
  ModuleContextMenuComponent, PageContextMenuComponent, TemplateContextMenuComponent, TemplateTitleContextMenuComponent,
  TemplateClassifyContextMenuComponent, PageTitleContextMenuComponent, ComTitleContextMenuComponent
} from './rightContextMenu';

/**
 * 自定义管道
 */
import { SvgElementPipe, BoolPipe, PageTypePipe, TimePipe, Base64Pipe, SuffixPipe, ClassifyTitlePipe } from './pipe';


// 路由守卫
import { CanDeactivateGuardService } from './_guards/can-deactivate/can-deactivate-guard.service';
import { CanDeactivateComponent } from './_guards/can-deactivate/can-deactivate.component';
import { ButtonGroupHelpComponent, ButtonHelpComponent, ChartGaugeHelpComponent, ChartLineHelpComponent, ChartLiquidHelpComponent, CheckboxHelpComponent, CircleHelpComponent, DateHelpComponent, HomeHelpComponent, IframeHelpComponent, InputHelpComponent, LineHelpComponent, RadioHelpComponent, SelectHelpComponent, SelectPanelHelpComponent, SliderHelpComponent, SwitchHelpComponent, TableHelpComponent, TextHelpComponent, TextMultiHelpComponent, TimeHelpComponent, TreeHelpComponent, VideoYsHelpComponent } from './helpComponents';
@NgModule({
  declarations: [
    AppComponent,
    CanDeactivateComponent,
    LoginComponent,

    HomeComponent, LeftSideNavComponent, RightSideNavComponent, HeaderComponent, ProjectComponent, ComComponent,
    ToolBarComponent,
    SvgCanvasComponent, ColorPickerComponent, PropertyComponent, AnimateComponent,
    DataSourceComponent, DataEventComponent,

    SelectProjectComponent, SelectComClassifyComponent, NameInputComponent, SaveSvgPageComponent, JsEventComponent,
    FileUpLoadComponent, CreateTableComponent, SaveToBaseModelComponent, ProjectUpLoadComponent, PageUpLoadComponent, PageSaveAsComponent,
    ProjectManageComponent, TemplateSaveAsComponent, ReplaceComponent, FindComponent, NumberInputComponent,
    ProjectSetComponent, IconManageComponent, DeleteInputComponent, VideoManageComponent, ChartLineConfigComponent, ChartBarConfigComponent,
    ChartPieConfigComponent, ChartGaugeConfigComponent,
    ServiceSetComponent, BackUpComponent,
    CreateProjectComponent,
    AudioManageModalComponent, ItemsConfigComponent, SelectConfigComponent, ModulePropComponent,

    ClassifyContentContextMenuComponent, ClassifyTitleContextMenuComponent, ComContextMenuComponent, SvgContextMenuComponent,
    ModuleContextMenuComponent, PageContextMenuComponent, TemplateContextMenuComponent, TemplateTitleContextMenuComponent,
    TemplateClassifyContextMenuComponent, PageTitleContextMenuComponent, ComTitleContextMenuComponent,

    SvgElementPipe, BoolPipe, PageTypePipe, TimePipe, Base64Pipe, SuffixPipe, ClassifyTitlePipe,
    ThemeSetComponent,
    // PrismComponent
    CodeInputComponent, HomeHelpComponent, TextHelpComponent, TextMultiHelpComponent, LineHelpComponent, CircleHelpComponent,
    ButtonHelpComponent, ButtonGroupHelpComponent, SwitchHelpComponent, InputHelpComponent, CheckboxHelpComponent,
    RadioHelpComponent, SelectHelpComponent, SelectPanelHelpComponent, DateHelpComponent, TimeHelpComponent, TreeHelpComponent,
    ChartLineHelpComponent, ChartGaugeHelpComponent, ChartLiquidHelpComponent, SliderHelpComponent, IframeHelpComponent,
    VideoYsHelpComponent, TableHelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    ColorPickerModule,
    NzModalModule, NzSpaceModule, NzMessageModule, NzDropDownModule, NzDrawerModule, NzMenuModule, NzFormModule,
    NzGridModule, NzLayoutModule, NzCheckboxModule, NzInputModule, NzInputNumberModule, NzSelectModule, NzSwitchModule,
    NzTreeSelectModule, NzUploadModule, NzCardModule, NzCollapseModule, NzListModule, NzPopoverModule, NzTableModule,
    NzTabsModule, NzToolTipModule, NzTreeModule, NzPopconfirmModule, NzSpinModule, NzDividerModule, NzButtonModule,
    NzIconModule, NzRadioModule, NzTagModule, NzAnchorModule, NzTimePickerModule, NzBadgeModule,
  ],
  entryComponents: [
    SelectProjectComponent, SelectComClassifyComponent, NameInputComponent, SaveSvgPageComponent, JsEventComponent,
    FileUpLoadComponent, CreateTableComponent, SaveToBaseModelComponent, ProjectUpLoadComponent, PageUpLoadComponent, PageSaveAsComponent,
    ProjectManageComponent, TemplateSaveAsComponent, ReplaceComponent, FindComponent, NumberInputComponent,
    ProjectSetComponent, IconManageComponent, DeleteInputComponent, VideoManageComponent, ChartLineConfigComponent, ChartBarConfigComponent,
    ChartPieConfigComponent, ChartGaugeConfigComponent,
    ServiceSetComponent, BackUpComponent,
    CreateProjectComponent,
    AudioManageModalComponent, ItemsConfigComponent, SelectConfigComponent,
    CodeInputComponent, ModulePropComponent, ThemeSetComponent
  ],
  providers: [
    CanDeactivateGuardService,
    /**
     * 全局异常处理
     */
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    /**
     * 配置 ng-zorro-antd 国际化（文案 及 日期）
     */
    { provide: NZ_I18N, useValue: zh_CN },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: []
})
export class AppModule { }
