import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TemplateApi } from 'src/app/api';
import { CanvasCache, DataCache } from 'src/app/cache';
import { DataAttrName, LoadingMsg } from 'src/app/const';
import { CommondAction } from 'src/app/_helpers/commondAction';
import { TemplateSaveAsComponent } from 'src/app/_modals';
import { DataEvent, DataSource, Template } from 'src/app/_models';
import { EventTransService } from 'src/app/_services';
import { CommonFunc } from './commonFunc';
import { ComponentTool } from './componentTool';

/**
 * 事件处理
 */
@Injectable({ providedIn: 'root' })
export class TemplateTool {
    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private commonFunc: CommonFunc,
        private componentTool: ComponentTool,
        private templateApi: TemplateApi
    ) {
    }

    /**
     * 保存为模板
     */
    saveAsTemplate() {
        if (!DataCache.project) {
            this.message.warning('请先打开项目！');
            return;
        }
        if (this.commonFunc.getSelectedSize() === 0) {
            this.message.warning('请先选择组件！');
            return;
        }

        this.modalService.create({
            nzTitle: '保存模板',
            nzContent: TemplateSaveAsComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async data => {
                if (data) {
                    this.eventTransService.globalLoadingEvent.next(LoadingMsg.update);
                    const content = this.componentTool.getSelectedContent();
                    const template: Template = {
                        text: data.text,
                        classifyId: data.classifyId,
                        projectId: DataCache.project.id,
                        data: { content }
                    };

                    const ret = await this.templateApi.create(template);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateChange.next(data.classifyId);
                    }
                    this.eventTransService.globalLoadingEvent.next('');
                }
                return data;
            })
        });
    }

    /**
     * 还原引用模板
     */
    restoreTemplate() {
        const selCom = CanvasCache.selectedComponent;
        if (!DataCache.project) {
            this.message.warning('请先打开项目！');
            return;
        }
        if (!selCom || !selCom.attr(DataAttrName.template)) {
            this.message.warning('请先选择引用模板的组件！');
            return;
        }
        const tempId = selCom.attr(DataAttrName.template);

        const node = CanvasCache.svg.select(`[id='${tempId}']`);
        if (!node) {
            this.message.warning('该组件引用的模板无效，请联系管理员检查！');
            return;
        }

        const text = selCom.attr(DataAttrName.text);

        this.modalService.create({
            nzTitle: '还原引用模板',
            nzContent: TemplateSaveAsComponent,
            nzComponentParams: {
                text
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async data => {
                if (data) {
                    const template: Template = this.getTempalteData(node, selCom);
                    template.text = data.text;
                    template.classifyId = data.classifyId;

                    const ret = await this.templateApi.restore(template);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateChange.next(data.classifyId);
                    }
                }
                return data;
            })
        });
    }

    getTempalteData(node: any, selCom: any): Template {
        const bbox = node.getBBox();
        let data = this.commonFunc.getSimpleHaflCanvas();

        let content = '';
        let defs = '';

        node.children().forEach((item: any) => {
            if (this.commonFunc.isDataNode(item)) {
                if (item.attr(DataAttrName.template)) {
                    const defNode = CanvasCache.svg.select(`[id='${item.attr(DataAttrName.template)}']`);
                    if (defNode) {
                        defs += defNode.toString();
                    }
                }
                content += item.toString();
            }
        });
        if (defs) {
            defs = `<defs>${defs}</defs>`;
        }
        data += defs + content + '</svg>';

        let event: DataEvent;
        let source: DataSource;
        const id = selCom.attr(DataAttrName.id);
        const dsId = selCom.attr(DataAttrName.source);
        const deId = selCom.attr(DataAttrName.event);
        if (dsId) {
            const ds = DataCache.pageData.sources.find(d => d.id === dsId);
            if (ds) {
                source = JSON.parse(JSON.stringify(ds));
            }
        }
        if (deId) {
            const de = DataCache.pageData.events.find(d => d.id === deId);
            if (de) {
                event = JSON.parse(JSON.stringify(de));
            }
        }

        const template: Template = {
            id,
            projectId: DataCache.project.id,
            data: {
                content: data,
                event,
                source
            }
        };
        return template;
    }

    /**
     * 保存模板
     */
    async saveTemplate() {
        this.eventTransService.globalLoadingEvent.next(LoadingMsg.update);
        const { maxX, maxY } = this.componentTool.moveToCorner(CanvasCache.svg.children());
        DataCache.template.data.content = this.commonFunc.getCanvasData(false);
        const ret = await this.templateApi.save(DataCache.template);
        if (ret) {
            this.message.success('操作成功！');
            this.eventTransService.globalLoadingEvent.next('');
            CommondAction.isChange = false;
            this.eventTransService.clearCanvasCache.next(DataCache.template.id);
        }
    }
}
