import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ComClassify } from '../_models';
import { ComClassifyApi, ComponentApi } from '../api';
import { CanvasConstData } from '../const';


/*选择组件分类 */
@Component({
    selector: 'app-select-com-classify',
    template: `
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="selId" [nzButtonStyle]="'solid'" *ngFor="let item of classifies">
                    <label nz-radio-button nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 300px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        nz-radio-group,label{
            width:100%;
        }
        `
    ]
})
export class SelectComClassifyComponent implements OnInit, OnDestroy {
    classifies: ComClassify[];
    selId = '';
    isLoading = true;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private classifyApi: ComClassifyApi
    ) { }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.classifies = await this.classifyApi.getAll();
        this.classifies = this.classifies.filter(d => d.id !== CanvasConstData.SYS_COM_CLASSIFY_ID);
        this.isLoading = false;
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.selId) {
                this.message.info(`请选择组件分类！`);
                resolve(false);
            }
            resolve(this.selId);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
