import { DataAttrName, SelectDataType } from '../const';
import { Property } from '../_models';
import { PxUtil } from '../utils';

export class Select {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.model: {
                const type = selectedObj.select('select').attr('multiple');
                prop.setValue = type ? type : '';
                break;
            }
            case DataAttrName.items: {
                let name = '';
                const dataStr = selectedObj.select('select').attr(DataAttrName.items);
                if (dataStr) {
                    const data = JSON.parse(dataStr);
                    if (data) {
                        name = SelectDataType.getName(data.type);
                    }
                }
                prop.setValue = `{${name}}`;
                break;
            }
            case DataAttrName.index:
            case DataAttrName.iconKey: {
                prop.setValue = selectedObj.select('select').attr(DataAttrName.index);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.model: {
                const tag = 'multiple';
                if (prop.setValue) {
                    selectedObj.select('select').attr(tag, tag);
                } else {
                    selectedObj.select('select').node.removeAttribute(tag);
                }
                break;
            }
            case DataAttrName.index:
            case DataAttrName.iconKey: {
                selectedObj.select('select').attr(DataAttrName.index, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
