/**
 * 脚本数据类型
 */
export enum JsDataType {
    /**
     * 事件
     */
    Event = 1,
    /**
     * 数据源
     */
    Source = 2,
    /**
     * 页面自定义JS
     */
    CustomPage = 3,
    /**
     * 工程全局自定义JS
     */
    CustomProject = 4
}
