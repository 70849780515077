import { DataViewConfig } from './dataViewConfig';
import { LegendConfig } from './legendConfig';
import { TimeConfig } from './timeConfig';
import { TitleConfig } from './titleConfig';
import { ToolBoxConfig } from './toolboxConfig';

/**
 * 图表基础配置属性
 */
export class BaseProperty {
    /**
     * 图表标题配置
     */
    titleConfig: TitleConfig = new TitleConfig();
    /**
     * 图例配置
     */
    legendConfig: LegendConfig = new LegendConfig();
    /**
     * 时间配置
     */
    timeConfig: TimeConfig = new TimeConfig();
    /**
     * 工具箱配置
     */
    toolBoxConfig: ToolBoxConfig = new ToolBoxConfig();
    /**
     * 数据视图配置
     */
    dataViewConfig: DataViewConfig = new DataViewConfig();
}
