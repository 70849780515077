import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ComClassify } from '../_models';
import { ComClassifyApi } from '../api';
import { CanvasConstData } from '../const';

/*选择项目 */
@Component({
    selector: 'app-save-to-base-model',
    template: `
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <label>组件名称：</label>
            <input #input nz-input placeholder="组件名称" [(ngModel)]="cname" autofocus/>
            <label>组件分类：</label>
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group class="classify" [(ngModel)]="classifyId" [nzButtonStyle]="'solid'" *ngFor="let item of classifies">
                    <label nz-radio-button nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 300px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        nz-radio-group{
            width:100%;
        }
        .selType{
            margin-bottom:10px;
        }
        .selType label{
            width:50%;
        }
        .classify label{
            width:100%;
        }
        `
    ]
})
export class SaveToBaseModelComponent implements OnInit, OnDestroy {
    @Input() cname: string;
    isLoading = true;

    classifies: ComClassify[] = [];
    classifyId: string;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private classifyApi: ComClassifyApi
    ) {
    }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.classifies = await this.classifyApi.getAll();
        this.classifies = this.classifies.filter(d => d.id !== CanvasConstData.SYS_COM_CLASSIFY_ID);
        this.isLoading = false;
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.cname) {
                this.message.warning('请输入组件名称');
                resolve(false);
                return;
            }
            if (!this.classifyId) {
                this.message.warning('请选择组件分类');
                resolve(false);
                return;
            }
            resolve({ name: this.cname, classifyId: this.classifyId });
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
