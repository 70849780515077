<nz-spin [nzSpinning]="loadingClassify">
  <li class="com" nz-submenu nzPlacement="right" [nzOpen]=true nzTitle="组件" nzIcon="credit-card"
    style="font-weight: bold;background-color: #6a6a7180;" (contextmenu)="comTitleRightClick($event)">
    <ul>
      <li nz-submenu *ngFor="let classify of classifies,let i=index" [(nzOpen)]="$any(classify).id==clickedClassifyId"
        (nzOpenChange)="openClassify($event,classify.id)">
        <span title class="nav-text" (contextmenu)="classifyTitleRightClick($event,classify)"><i nz-icon
            nzType="appstore" nzTheme="outline"></i>{{classify.text + (classify.count?'('+classify.count+
          ')':'')}}</span>
        <nz-spin [nzSpinning]="loadingCom">
          <nz-card nzTitle="" [nzBordered]=false (contextmenu)="classifyContentRightClick($event,classify)">
            <div class="component" nz-card-grid [ngStyle]="gridStyle" *ngFor="let cpt of componentMap.get(classify.id)"
              draggable="true" (dragstart)="drag($event,cpt)" (contextmenu)="comRightClick($event,classify,cpt)">
              <img [src]="cpt.base64|base64Pipe:cpt.type" width="35" height="35" draggable="true" />
              <p>{{cpt.text|suffixPipe}}</p>
              <span *ngIf="cpt.sys" class="help" nz-icon nzType="info-circle" nzTheme="outline" nz-popover
                [nzPopoverTitle]="cpt.text" [nzPopoverContent]="helpTemplate" nzPopoverTrigger="click"></span>
              <ng-template #helpTemplate>
                <app-help-home [com]="cpt.text"></app-help-home>
              </ng-template>
              <ng-container #vc></ng-container>
            </div>
          </nz-card>
        </nz-spin>
      </li>
    </ul>
  </li>
</nz-spin>

<app-com-title-contextmenu *ngIf="menuState.ComponentTitle" [x]="contextmenuX" [y]="contextmenuY">
</app-com-title-contextmenu>
<app-classify-title-contextmenu *ngIf="menuState.ClassifyTitle" [x]="contextmenuX" [y]="contextmenuY"
  [classify]="selectedClassify" [enableDeleteMenu]="enableDeleteMenu">
</app-classify-title-contextmenu>
<app-classify-content-contextmenu *ngIf="menuState.ClassifyContent" [x]="contextmenuX" [y]="contextmenuY"
  [classify]="selectedClassify"></app-classify-content-contextmenu>
<app-com-contextmenu *ngIf="menuState.Component" [x]="contextmenuX" [y]="contextmenuY" [classify]="selectedClassify"
  [component]="selectedComponent">
</app-com-contextmenu>