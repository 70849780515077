import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Page } from '../_models';
import { PageData } from '../_models/pageData';
import { HttpService } from '../_services';


/**
 * web组态API-工程->页面
 */
@Injectable({ providedIn: 'root' })
export class PageApi {
    private prefix = '/design/page';
    constructor(
        private message: NzMessageService,
        private http: HttpService
    ) {
    }

    /**
     * 创建页面
     * @param page 页面对象
     */
    async createEmpty(page: Page) {
        const content = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        id='svgCanvas' viewBox='0 0 800 600'></svg>`;

        return await this.createWithContent(page, content);
    }

    async createWithContent(page: Page, content: string) {
        page.content = content;
        return await this.http.post(`${this.prefix}/create`, page);
    }

    /**
     * 页面配置-设为菜单
     * @param id 页面key
     */
    async setMenu(id: string) {
        if (!id) {
            this.message.warning('参数错误[页面id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/setMenu`, { id });
    }

    /**
     * 设置菜单图标
     * @param id 页面id
     * @param iconId 图标id
     */
    async setIcon(id: string, iconId: string) {
        if (!id) {
            this.message.warning('参数错误[页面id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/setIcon`, { id, iconId });
    }

    /**
     * 设置菜单图标
     * @param id 页面id
     * @param iconId 图标id
     */
    async setIconActive(id: string, iconId: string) {
        if (!id) {
            this.message.warning('参数错误[页面id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/setIconActive`, { id, iconId });
    }

    /**
     * 删除页面
     * @param id 页面id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[页面id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 保存页面配置信息
     * @param page 页面对象
     */
    async save(page: Page) {
        return await this.http.post(`${this.prefix}/save`, page);
    }

    /**
     * 获取页面数据
     * @param id 页面id
     */
    async getData(id: string) {
        if (!id) {
            this.message.warning('参数错误[页面id]');
            return false;
        }
        return await this.http.get(`${this.prefix}/getData`, { id });
    }

    /**
     * 保存页面数据
     * @param data PageData
     */
    async saveData(data: PageData) {
        return await this.http.post(`${this.prefix}/saveData`, data);
    }

    /**
     * 页面另存为
     * @param page Page
     * @param copyEvent 复制事件
     * @param copyData 复制数据源
     */
    async saveAs(page: Page, copyEvent: boolean, copyData: boolean) {
        if (!page) {
            this.message.warning('参数错误[页面]');
            return false;
        }
        const pageEx = JSON.parse(JSON.stringify(page));
        pageEx.copyEvent = copyEvent;
        pageEx.copyData = copyData;
        return await this.http.post(`${this.prefix}/saveAs`, pageEx);
    }

    /**
     * 获取页面备份信息
     * @param id 页面id
     */
    async getBackups(id: string) {
        if (!id) {
            this.message.warning('参数错误 ！');
            return false;
        }
        return this.http.get(`${this.prefix}/getBackups`, { id });
    }

    /**
     * 页面预览
     * @param id 页面id
     */
    async preview(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.post_str(`${this.prefix}/preview`, { id });
    }

    /**
     * 获取备份数据
     * @param id 备份id
     */
    async getBackUp(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.get(`${this.prefix}/getBackUp`, { id });
    }

    /**
     * 下载页面
     * @param id 页面id
     */
    async download(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.download(`${this.prefix}/download`, { id });
    }


    /**
     * 导入页面(zip)
     * @param file 文件
     * @param name 页面id
     * @param text 页面名称
     * @param moduleId 工程id
     * @param moduleId 模块id
     * @param menu 是否菜单页面
     * @param scale 允许缩放
     * @param initScaleType 初始缩放
     * @param level 权限等级
     */
    async upload(
        file: any, name: string, text: string, projectId: string, moduleId: string, menu: boolean, scale: boolean,
        initScaleType: string, level: number) {
        if (!file || !name || !text) {
            this.message.warning('参数错误 ！');
            return;
        }
        if (!moduleId) {
            moduleId = '';
        }
        return await this.http.uploadFile(`${this.prefix}/upload`, file, {
            name, text, projectId, moduleId, menu, scale,
            initScaleType, level
        });
    }

    /**
     * 获取用户页面
     */
    async getUserPages() {
        return await this.http.get(`${this.prefix}/getUserPages`);
    }

    /**
     * 页面发布
     * @param id 页面id
     */
    async publish(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.post_str(`${this.prefix}/publish`, { id });
    }
}
