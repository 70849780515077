import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService } from '../_services';
import { NameInputComponent, FileUpLoadComponent } from '../_modals';
import { TemplateClassify } from '../_models';
import { TemplateClassifyApi } from '../api/templateClassifyApi';

@Component({
    selector: 'app-template-classify-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='reName($event)'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='import($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createClassify($event)'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>创建模板分类
            </li>
            <li nz-menu-item (click)='deleteClassify($event)' [nzDisabled]=!enableDelete>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模板分类
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})

/**
 * 工程模板右键菜单
 */
export class TemplateClassifyContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    /**
     * 工程模板分类
     */
    @Input() classify: TemplateClassify;
    /**
     * 允许删除模板分类
     */
    @Input() enableDelete = true;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private templateClassifyApi: TemplateClassifyApi
    ) { }

    /**
     * 重命名
     * @param event 事件
     */
    reName(event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '模板分类重命名',
            nzComponentParams: {
                tipMsg: '请输入新的分类名称...',
                inputName: this.classify.text
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    if (name === this.classify.text) {
                        this.message.warning('新名称不能与原名称相同！');
                        return false;
                    }
                    const ret = await this.templateClassifyApi.rename(this.classify.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateClassifyChange.next(true);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 导入模板
     */
    import(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '模板批量导入',
            nzComponentParams: {
                projectId: this.classify.projectId,
                templateClassifyID: this.classify.id,
                fileType: 'application/x-zip-compressed',
                fileTypeName: 'zip'
            },
            nzContent: FileUpLoadComponent,
            nzMaskClosable: false,
            nzOkText: '开始导入',
            nzOnOk: (ins) => ins.saveTemplate()
        });
    }

    /**
     * 添加模板分类
     * @param event 事件
     */
    createClassify(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '创建模板分类',
            nzComponentParams: {
                tipMsg: '请输入模板分类名称...'
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    const ret = await this.templateClassifyApi.create(this.classify.projectId, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateClassifyChange.next(true);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 删除模板分类
     * @param event 事件
     */
    deleteClassify(event) {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!this.enableDelete) {
            return;
        }
        this.modalService.confirm({
            nzTitle: `<i>确认删除工程模板分类：${this.classify.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.templateClassifyApi.delete(this.classify.id);
                if (ret) {
                    this.message.success('操作成功！');
                    this.eventTransService.templateClassifyChange.next(true);
                }
            }
        });
    }
}

