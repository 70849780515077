import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Module } from '../_models';
import { HttpService } from '../_services';


/**
 * web组态API-工程->模块
 */
@Injectable({ providedIn: 'root' })
export class ModuleApi {
    private prefix = '/design/module';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 获取工程所有模块信息
     * @param projectId 工程id
     */
    async getAll(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误[工程id]');
            return false;
        }
        return await this.http.get(`${this.prefix}/getAll`, { projectId });
    }

    /**
     * 新增模块
     * @param projectId 工程id
     * @param name 模块名
     */
    async createWithName(projectId: string, name: string) {
        if (!projectId || !name) {
            this.message.warning('参数错误 ！');
            return false;
        }
        const param = { projectId, name };
        return await this.http.post_str(`${this.prefix}/createWithName`, param);
    }

    async createWithNameAndParent(parentId: string, name: string) {
        if (!parentId || !name) {
            this.message.warning('参数错误 ！');
            return false;
        }
        const param = { parentId, name };
        return await this.http.post_str(`${this.prefix}/createWithNameAndParent`, param);
    }

    /**
     * 模块重命名
     * @param id 模块id
     * @param name 模块名
     */
    async rename(id: string, name: string) {
        if (!id || !name) {
            this.message.warning('参数错误 ！');
            return false;
        }
        const param = { id, name };
        return await this.http.post_str(`${this.prefix}/rename`, param);
    }

    /**
     * 设置菜单图标
     * @param id 模块id
     * @param iconId 图标id
     */
    async setIcon(id: string, iconId: string) {
        if (!id) {
            this.message.warning('参数错误[模块id]！');
            return false;
        }
        const param = { id, iconId };
        return await this.http.post_str(`${this.prefix}/setIcon`, param);
    }

    /**
     * 设置菜单图标
     * @param id 模块id
     * @param iconId 图标id
     */
    async setIconActive(id: string, iconId: string) {
        if (!id) {
            this.message.warning('参数错误[模块id]！');
            return false;
        }
        const param = { id, iconId };
        return await this.http.post_str(`${this.prefix}/setIconActive`, param);
    }

    /**
     * 删除模块
     * @param id 模块id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[模块id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 属性设置
     * @param module 模块
     */
    async setProperty(module: Module) {
        if (!module || !module.id || !module.text) {
            this.message.warning('参数错误');
            return false;
        }
        return await this.http.post(`${this.prefix}/setProperty`, module);
    }
}
