<nz-sider nzCollapsible [nzWidth]="width" [nzCollapsedWidth]="0">
  <div class="content">
    <!--元件属性配置-->
    <div class="layer" *ngIf="selectedComponent">
      <div class="layer-title">
        <i nz-icon nzType="qrcode" nzTheme="outline"></i>
        <span class="nav-text">&nbsp;元件</span>
      </div>
      <div class="layer-tree">
        <span class="nav-text">{{selectedComponentName}}</span>
        <div>
          <nz-tree [nzData]="treeNodes" nzShowLine (nzClick)="domClick($event)" (nzContextMenu)="domRightClick($event)">
          </nz-tree>
        </div>
      </div>
    </div>

    <!--文本属性配置-->
    <nz-tabset class="tab" nzType="card" [nzTabBarGutter]=0>
      <nz-tab [nzTitle]="propertyTitle" (mousedown)="propertyClick($event)">
        <ng-template #propertyTitle>
          <i nz-icon nzType="setting" nzTheme="outline"></i>属性
        </ng-template>
        <div style="height:100%;overflow:hidden;">
          <div class="style text-style" *ngFor="let property of props" (mousedown)="propertyClick($event)">
            <app-property [property]="property"></app-property>
          </div>
          <div class="style text-style" style="display: flex;" *ngIf="showPanelSet">
            <span style="width: 33%;">所属面版</span>
            <nz-select style="width: 66%;" [(ngModel)]="selPanelId" (ngModelChange)="selectPanel()">
              <nz-option nzValue="{{id}}" nzLabel="{{id}}" *ngFor="let id of panelIds"></nz-option>
            </nz-select>
          </div>
          <div class="style text-style">
            <app-animate *ngIf="showAnimate"></app-animate>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="dataTitle">
        <ng-template #dataTitle>
          <i nz-icon nzType="database" nzTheme="outline"></i>数据
        </ng-template>
        <div class="twcontent" style="height:100%;overflow:hidden;">
          <app-datasource (mousedown)="propertyClick($event)"></app-datasource>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="eventTitle">
        <ng-template #eventTitle>
          <i nz-icon nzType="control" nzTheme="outline"></i>事件
        </ng-template>
        <div style="height:100%;overflow:hidden;">
          <app-data-event (mousedown)="propertyClick($event)"></app-data-event>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-sider>
