
        <nz-input-group nzAddOnBefore="模块名称" style="width:100%;">
            <input type="text" nz-input [(ngModel)]="module.text" placeholder="请输入模块名称"/>
        </nz-input-group>
        <div style="margin-top:5px;"></div>
        <label>上级模块：</label>
        <button nz-button nzType="link" (click)="clearParent()">清除选择(一级模块)</button>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="module.parentId" [nzButtonStyle]="'solid'">
                    <label nz-radio-button *ngFor="let item of modules" nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    