
    <div class="clearfix">
      <div style="display:flex;flex-wrap:wrap;">
        <div style="width:200px;">
          <nz-upload
            nzListType="picture-card"
            [(nzFileList)]="upList"
            [nzBeforeUpload]="beforeUpload"
            [nzRemove]="removeFile"
          >
            <i nz-icon nzType="plus"></i>
            <div class="ant-upload-text">上传</div>
          </nz-upload>
        </div>
        <div *ngFor="let item of fileList" style="width:200px;" (click)="iconClick($event,item)">
            <nz-card nzHoverable [nzActions]="[actionDelete, actionSel]">
                <nz-card-meta [nzDescription]="item.name" [nzAvatar]="avatar" style="height:50px;"></nz-card-meta>
                <label class='key'>ID：{{item.percent}}</label>
            </nz-card>
            <ng-template #avatar>
                <img alt="tw" [src]="item.response" />
            </ng-template>
            <ng-template #actionDelete>
              <i nz-icon nzType="delete" (click)="removeFile($event,item)" style="color:red;"></i>
            </ng-template>
            <ng-template #actionSel>
                <nz-radio-group [(ngModel)]="selIconId">
                    <label nz-radio [nzValue]="item.uid"></label>
                </nz-radio-group>
            </ng-template>
        </div>
    </div>
  </div>
  