/**
 * 组件数据缓存
 */
export class ComponentCache {
    /**
     * 默认缓存数量
     */
    private static cacheNum = 100;
    private static map = new Map();
    private static stack: Array<string> = [];

    static pull(key: string) {
        return this.map.get(key);
    }

    static push(key: string, data: any) {
        if (this.stack.length >= this.cacheNum) {
            this.map.delete(this.stack.shift());
        }
        this.map.set(key, data);
        this.stack.push(key);
    }
}
