
        <div class="space">
            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzAddOnAfter=":" style="width:70%;">
                <input type="text" nz-input [(ngModel)]="ip" />
            </nz-input-group>
            <nz-input-number style="width:30%;" [(ngModel)]="port" [nzMin]="1" [nzMax]="65535" [nzStep]="1"></nz-input-number>
        </div>

        <nz-input-group nzAddOnBefore="API 版本">
            <nz-select [(ngModel)]="version">
                <nz-option nzLabel="v1" nzValue="v1"></nz-option>
            </nz-select>
        </nz-input-group>

        <ng-template #addOnBeforeTemplate>
            <nz-select [(ngModel)]="protocol">
                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
            </nz-select>
        </ng-template>
    