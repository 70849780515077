import { Component } from '@angular/core';

@Component({
    selector: 'app-help-select-panel',
    templateUrl: './selectPanel.component.html',
    styleUrls: ['./select.component.css']
})
export class SelectPanelHelpComponent {
    constructor(
    ) {
    }
}

