
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='setMenuPage()'>
                <i nz-icon nzType="menu" nzTheme="outline"></i>设为菜单
            </li>
            <li nz-menu-item (click)='setIcon(false)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标
            </li>
            <li nz-menu-item (click)='setIcon(true)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标(激活状态)
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='saveAs()'>
                <i nz-icon nzType="save" nzTheme="outline"></i>另存为
            </li>
            <li nz-menu-item (click)='importPage()'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage()'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='download()'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载页面
            </li>
            <li nz-menu-item (click)='deletePage()'>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='loadCache()'>
                <i nz-icon nzType="reload" nzTheme="outline"></i>加载缓存数据
            </li>
            <li nz-menu-item (click)='backup()'>
                <i nz-icon nzType="file-search" nzTheme="outline"></i>历史备份
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='publish()'>
                <i nz-icon nzType="global" nzTheme="outline"></i>发布
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setPageProperty()'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    