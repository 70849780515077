<div nz-row>

  <nz-collapse style="width: 100%;">
    <nz-collapse-panel nzHeader="动画" [nzActive]="true">
      <ng-container>

        <nz-collapse class="anime" *ngIf="showRotate">
          <nz-collapse-panel nzHeader="旋转" [nzActive]="prop.rotate.enable">
            <div>
              <label nz-checkbox [(ngModel)]="prop.rotate.enable" (ngModelChange)="setAnime()">启用</label>
            </div>
            <div>
              <nz-input-group nzAddOnBefore="旋转角度">
                <input type="number" nz-input [(ngModel)]="prop.rotate.angle" min="1" max="360"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>
            <div>
              <nz-input-group nzAddOnBefore="时间" nzAddOnAfter="毫秒">
                <input type="number" nz-input [(ngModel)]="prop.rotate.duration" min="50" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>

            <div>
              <label nz-col nzSpan="5">方向</label>
              <nz-select [(ngModel)]="prop.rotate.direction" (ngModelChange)="setAnime()">
                <nz-option nzValue="normal" nzLabel="正方向"></nz-option>
                <nz-option nzValue="reverse" nzLabel="反方向"></nz-option>
                <nz-option nzValue="alternate" nzLabel="往返"></nz-option>
              </nz-select>
            </div>

            <div>
              <label nz-col nzSpan="5">动画效果</label>
              <nz-select [(ngModel)]="prop.rotate.easing" (ngModelChange)="setAnime()">
                <nz-option nzValue="linear" nzLabel="线性"></nz-option>
                <nz-option nzValue="steps" nzLabel="台阶式"></nz-option>
                <nz-option nzValue="easeInOutSine" nzLabel="由快至慢"></nz-option>
                <nz-option nzValue="easeInOutCirc" nzLabel="突然减速"></nz-option>
              </nz-select>
            </div>

            <div>
              <nz-input-group nzAddOnBefore="循环次数">
                <input type="number" nz-input [(ngModel)]="prop.rotate.loop" min="0" max="999999999" nz-tooltip
                  nzTooltipTitle="0表示无限次" (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>

          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse class="anime">
          <nz-collapse-panel nzHeader="闪烁" [nzActive]="prop.twinkle.enable">
            <div>
              <label nz-checkbox [(ngModel)]="prop.twinkle.enable" (ngModelChange)="setAnime()">启用</label>
            </div>
            <div>
              <nz-input-group nzAddOnBefore="时间" nzAddOnAfter="毫秒">
                <input type="number" nz-input [(ngModel)]="prop.twinkle.duration" min="50" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse class="anime">
          <nz-collapse-panel nzHeader="呼吸灯" [nzActive]="prop.breath.enable">
            <div>
              <label nz-checkbox [(ngModel)]="prop.breath.enable" (ngModelChange)="setAnime()">启用</label>
            </div>
            <div>
              <nz-input-group nzAddOnBefore="时间" nzAddOnAfter="毫秒">
                <input type="number" nz-input [(ngModel)]="prop.breath.duration" min="50" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse class="anime" *ngIf="showFlow">
          <nz-collapse-panel nzHeader="水流" [nzActive]="prop.flow.enable">
            <div>
              <label nz-checkbox [(ngModel)]="prop.flow.enable" (ngModelChange)="setAnime()">启用</label>
            </div>
            <div>
              <nz-input-group nzAddOnBefore="时间" nzAddOnAfter="毫秒">
                <input type="number" nz-input [(ngModel)]="prop.flow.duration" min="50" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>
            <div>
              <label nz-col nzSpan="5">方向</label>
              <nz-select [(ngModel)]="prop.flow.direction" (ngModelChange)="setAnime()">
                <nz-option nzValue="normal" nzLabel="正方向"></nz-option>
                <nz-option nzValue="reverse" nzLabel="反方向"></nz-option>
              </nz-select>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse class="anime" *ngIf="showTranslate">
          <nz-collapse-panel nzHeader="位移" [nzActive]="prop.translate.enable">
            <div>
              <label nz-checkbox [(ngModel)]="prop.translate.enable" (ngModelChange)="setAnime()">启用</label>
            </div>

            <div>
              <label nz-col nzSpan="5">位移方向</label>
              <nz-select [(ngModel)]="prop.translate.transDirection" (ngModelChange)="setAnime()">
                <nz-option nzValue="X" nzLabel="水平"></nz-option>
                <nz-option nzValue="Y" nzLabel="垂直"></nz-option>
              </nz-select>
            </div>

            <div>
              <nz-input-group nzAddOnBefore="位移距离">
                <input type="number" nz-input [(ngModel)]="prop.translate.distance" min="-999999999" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>

            <div>
              <nz-input-group nzAddOnBefore="时间" nzAddOnAfter="毫秒">
                <input type="number" nz-input [(ngModel)]="prop.translate.duration" min="50" max="999999999"
                  (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>

            <div>
              <label nz-col nzSpan="5">方向</label>
              <nz-select [(ngModel)]="prop.translate.direction" (ngModelChange)="setAnime()">
                <nz-option nzValue="normal" nzLabel="正方向"></nz-option>
                <nz-option nzValue="reverse" nzLabel="反方向"></nz-option>
                <nz-option nzValue="alternate" nzLabel="往返"></nz-option>
              </nz-select>
            </div>

            <div>
              <label nz-col nzSpan="5">动画效果</label>
              <nz-select [(ngModel)]="prop.translate.easing" (ngModelChange)="setAnime()">
                <nz-option nzValue="linear" nzLabel="线性"></nz-option>
                <nz-option nzValue="steps" nzLabel="台阶式"></nz-option>
                <nz-option nzValue="easeInOutSine" nzLabel="由快至慢"></nz-option>
                <nz-option nzValue="easeInOutCirc" nzLabel="突然减速"></nz-option>
              </nz-select>
            </div>

            <div>
              <nz-input-group nzAddOnBefore="循环次数">
                <input type="number" nz-input [(ngModel)]="prop.translate.loop" min="0" max="999999999" nz-tooltip
                  nzTooltipTitle="0表示无限次" (ngModelChange)="setAnime()" />
              </nz-input-group>
            </div>

          </nz-collapse-panel>
        </nz-collapse>


      </ng-container>
    </nz-collapse-panel>
  </nz-collapse>
</div>
