import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const error = err.message || err.error.message || err.statusText;
            console.log('%c ErrorInterceptor--->' + error, 'color:red');
            let errMsg = `请求发生异常 ！`;
            if (err.status === 401) {
                sessionStorage.removeItem('user');
                errMsg = '登录令牌失效，请重新登录！';
                setTimeout(() => {
                    location.reload();
                }, 2000);
            } else if (err.status === 404) {
                errMsg = '请求的接口不存在，请检查！';
            } else if (err.name && err.name === 'HttpErrorResponse') {
                errMsg = '请求发生异常，请检查网络状况';
            }

            return throwError(errMsg);
        }));
    }
}
