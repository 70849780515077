import { DataAttrName } from '../const';
import { Property } from '../_models';
import { PxUtil } from '../utils';

export class ButtonGroup {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                prop.setValue = selectedObj.select('button').node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            case DataAttrName.items: {
                const lst = [];
                selectedObj.selectAll('button').forEach(item => {
                    lst.push(`${item.node.innerText.trim()}`);
                });

                prop.setValue = lst.join(',');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                selectedObj.selectAll('button').forEach(item => {
                    item.node.style.setProperty(prop.name, prop.setValue + 'px');
                });
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
