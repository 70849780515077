import { Component } from '@angular/core';

@Component({
    selector: 'app-help-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.css']
})
export class IframeHelpComponent {
    constructor(
    ) {
    }
}

