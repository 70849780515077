/**
 * 数据视图配置
 */
export class DataViewConfig {
    /**
     * 显示图表数据
     */
    showData = true;
    /**
     * 显示最大值
     */
    showMax = true;
    /**
     * 显示最小值
     */
    showMin = true;
    /**
     * 显示平均值
     */
    showAvg = true;
    /**
     * 显示合计值
     */
    showSum = true;
}
