import { Property } from '../_models';
import { PxUtil } from '../utils';

export class InputText {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const input = selectedObj.select('input');
        switch (prop.name) {
            case 'width':
            case 'height': {
                prop.setValue = selectedObj.node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            case 'value': {
                prop.setValue = input.attr(prop.name);
                break;
            }
            case 'font-size':
            case 'letter-spacing':
            case 'font-family':
            case 'font-weight':
            case 'font-style':
            case 'text-decoration': {
                prop.setValue = input.node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            case 'fill': {
                prop.setValue = input.node.style.getPropertyValue('color');
                break;
            }
            case 'text-anchor': {
                let val = input.node.style.getPropertyValue('text-align');
                if (!val) {
                    val = 'start';
                } else if (val === 'center') {
                    val = 'middle';
                } else {
                    val = 'end';
                }
                prop.setValue = val;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const input = selectedObj.select('input');
        switch (prop.name) {
            case 'width':
            case 'height': {
                selectedObj.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name))
                break;
            }
            case 'value': {
                input.attr(prop.name, prop.setValue);
                break;
            }
            case 'font-size':
            case 'letter-spacing':
            case 'font-family':
            case 'font-weight':
            case 'font-style':
            case 'text-decoration': {
                input.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name));
                break;
            }
            case 'fill': {
                input.node.style.setProperty('color', prop.setValue);
                break;
            }
            case 'text-anchor': {
                let val = prop.setValue
                if (!val) {
                    val = 'start';
                } else if (val === 'middle') {
                    val = 'center';
                } else {
                    val = 'end';
                }
                input.node.style.setProperty('text-align', val);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
