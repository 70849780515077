import { Component } from '@angular/core';

@Component({
    selector: 'app-help-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonHelpComponent {
    constructor(
    ) {
    }
}

