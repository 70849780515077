import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** 自定义组件 **/
import { LoginComponent } from './login';
import { HomeComponent } from './home';
import { AuthGuard } from './_guards';
// 路由守卫
import { CanDeactivateGuardService } from './_guards/can-deactivate/can-deactivate-guard.service';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
