<div class="main">
    <div class="desc">
        <span class="title">设置<span class="mark">当前进度值</span>:</span>
        <div class="code">
            <@p2.*>.value(60);
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">当前进度值</span>:</span>
        <div class="code">
            <@p2.*>.value();
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">格式化事件</span>:</span>
        <div class="remark">组件默认为数字格式，可使用《格式化》事件，替换为其他格式</div>
        <div class="remark">示例：将组件格式化为时间格式：</div>
        <div class="code">
            <@.*>.format(function(e) {{'{'}}
                var start = '00:00:00';
                return start.toTime().addSeconds(e).toStr('HH:mm:ss');
                {{'}'}});
        </div>
    </div>
</div>