<nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
  <div class="main">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="content" [nzOffsetTop]="20">
        <nz-link nzHref="#login" nzTitle="**登录页配置">
          <nz-link nzHref="#login-left" nzTitle="左侧图片"></nz-link>
          <nz-link nzHref="#login-logo" nzTitle="LOGO"></nz-link>
          <nz-link nzHref="#login-valid" nzTitle="验证码"></nz-link>
          <nz-link nzHref="#login-bg" nzTitle="背景图片"></nz-link>
          <nz-link nzHref="#login-css" nzTitle="自定义CSS"></nz-link>
        </nz-link>
        <nz-link nzHref="#index" nzTitle="**首页配置">
          <nz-link nzHref="#index-menu" nzTitle="菜单栏"></nz-link>
          <nz-link nzHref="#index-logo" nzTitle="LOGO"></nz-link>
          <nz-link nzHref="#index-title" nzTitle="标题"></nz-link>
          <nz-link nzHref="#index-time" nzTitle="时间"></nz-link>
          <nz-link nzHref="#index-real" nzTitle="实时数据"></nz-link>
          <nz-link nzHref="#index-name" nzTitle="登录名图标"></nz-link>
          <nz-link nzHref="#index-css" nzTitle="自定义CSS"></nz-link>
        </nz-link>
      </nz-anchor>
    </div>
    <div class="content" #content>
      <div class="title">登录页配置</div>
      <div id='login-left'>
        <nz-divider nzText="左侧图片配置"></nz-divider>
        <span>说明：建议像素：540*540，最大5M。</span>
        <div class="data">
          <img alt="tw" *ngIf="config.login.left.base64" [src]="getSafeSrc(config.login.left)" />
          <div class="action">
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeLoginLeftImgUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteLoginLeftImg()">删除</button>
          </div>
        </div>
      </div>

      <div id='login-logo'>
        <nz-divider nzText="LOGO配置"></nz-divider>
        <span>说明：建议像素：420*40，最大500KB。</span>
        <div class="data">
          <img alt="tw" *ngIf="config.login.logo.base64" [src]="getSafeSrc(config.login.logo)" />
          <div class="action">
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeLoginLogoUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteLoginLogoImg()">删除</button>
          </div>
        </div>
      </div>

      <div id='login-valid'>
        <nz-divider nzText="验证码配置"></nz-divider>
        <label nz-checkbox [(ngModel)]="config.login.hideValidCode" (ngModelChange)="setLoginValidHide()">隐藏验证码</label>
      </div>

      <div id='login-bg'>
        <nz-divider nzText="背景图片配置"></nz-divider>
        <span>说明：建议像素：1920*865，最大5MB。</span>
        <div class="data">
          <img alt="tw" *ngIf="config.login.bg.base64" [src]="getSafeSrc(config.login.bg)" />
          <div class="action">
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeLoginBgUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteLoginBgImg()">删除</button>
          </div>
        </div>
      </div>

      <div id='login-css'>
        <nz-divider nzText="自定义CSS样式"></nz-divider>
        <span>说明：需要结合F12调试模式（获取节点id、class）使用。</span>
        <div class="data">
          <code-input ngDefaultControl lang="css" style="width: 100%;border: 1px solid;" [(ngModel)]="config.login.css"
            [style.border-color]="codeBorderColor"></code-input>
          <button nz-button nzType="primary" (click)="saveLoginCss()">保存</button>
        </div>
      </div>

      <div class="title">首页配置</div>

      <div id='index-menu'>
        <nz-divider nzText="菜单栏配置"></nz-divider>
        <label nz-checkbox [(ngModel)]="config.index.expandMenuBar"
          (ngModelChange)="setIndexMenuExpand()">菜单栏默认展开</label>
      </div>

      <div id='index-logo'>
        <nz-divider nzText="LOGO配置"></nz-divider>
        <span>说明：建议像素：130*44，最大500KB。</span>
        <div class="data">
          <img alt="tw" *ngIf="config.index.logo.base64" [src]="getSafeSrc(config.index.logo)" />
          <div class="action">
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeIndexLogoUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteIndexLogoImg()">删除</button>
          </div>
        </div>
      </div>

      <div id='index-title'>
        <nz-divider nzText="标题配置"></nz-divider>
        <span>说明：默认标题为工程名，默认无副标题。</span>
        <div class="data">
          <div class="action">
            <nz-input-group nzAddOnBefore="标题：">
              <input type="text" nz-input [(ngModel)]="config.index.title.text" />
            </nz-input-group>
            <nz-input-group nzAddOnBefore="副标题：">
              <input type="text" nz-input [(ngModel)]="config.index.title.subText" />
            </nz-input-group>
          </div>
          <button nz-button nzType="primary" (click)="saveIndexTitle()">保存</button>
        </div>
      </div>

      <div id='index-time'>
        <nz-divider nzText="时间配置"></nz-divider>
        <span>说明：YYYY-MM-DD HH:mm:ss</span>
        <label nz-checkbox [(ngModel)]="config.index.time.hide" (ngModelChange)="setIndexTimeHide()">隐藏时间</label>
        <label *ngIf="!config.index.time.hide" class="week" nz-checkbox [(ngModel)]="config.index.time.hideWeek"
          (ngModelChange)="setIndexTimeWeekHide()">隐藏周</label>
        <div class="data" *ngIf="!config.index.time.hide">
          <nz-input-group nzAddOnBefore="格式：">
            <input type="text" nz-input [(ngModel)]="config.index.time.format" />
          </nz-input-group>
          <button nz-button nzType="primary" (click)="saveIndexTimeFormat()">保存格式</button>
        </div>
        <label *ngIf="!config.index.time.hide" nz-checkbox [(ngModel)]="config.index.time.hideIcon"
          (ngModelChange)="setIndexTimeIconHide()">隐藏图标</label>
        <span *ngIf="!config.index.time.hide&&!config.index.time.hideIcon">说明：建议像素：24*24，最大50KB。</span>
        <div class="icon" *ngIf="!config.index.time.hide&&!config.index.time.hideIcon">
          <img alt="tw" *ngIf="config.index.time.icon.base64" [src]="getSafeSrc(config.index.time.icon)" />
          <div>
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeIndexTimeIconUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteIndexTimeIcon()">删除</button>
          </div>
        </div>
      </div>

      <div id='index-real'>
        <nz-divider nzText="实时数据配置"></nz-divider>
        <div class="data">
          <div class="set">
            <label style="width: 108px;">已配数据源：</label>
            <nz-select [(ngModel)]="realData.id" (ngModelChange)="selRealChange()">
              <nz-option [nzValue]="real.id" [nzLabel]="getRealName(real)"
                *ngFor="let real of config.index.reals"></nz-option>
            </nz-select>
            <button nz-button nzType="dashed" nzDanger (click)="deleteRealData()">删除</button>
          </div>
          <div class="content">
            <div class="where">
              <div class="from">
                <label>数据源：</label>
                <nz-radio-group [(ngModel)]="realData.from">
                  <label nz-radio [nzValue]="i" *ngFor="let name of froms,let i=index">{{name}}</label>
                </nz-radio-group>
              </div>
              <label nz-checkbox [(ngModel)]="realData.showIcon">显示图标</label>
              <span *ngIf="realData.showIcon">说明：建议像素：30*30，最大50KB。</span>
              <div class="icon" *ngIf="realData.showIcon">
                <img alt="tw" *ngIf="realData.icon.base64" [src]="getSafeSrc(realData.icon)" />
                <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeIndexRealIconUpload">
                  <i nz-icon nzType="plus"></i>
                  <div class="ant-upload-text">上传</div>
                </nz-upload>
              </div>
              <div class="param">
                <div class="select" *ngIf="realData.from==0">
                  <label>天气数据：</label>
                  <nz-select [(ngModel)]="realData.param">
                    <nz-option nzValue="temp" nzLabel="温度"></nz-option>
                    <nz-option nzValue="feelsLike" nzLabel="体感温度"></nz-option>
                    <nz-option nzValue="text" nzLabel="天气状况(阴晴雨雪等)"></nz-option>
                    <nz-option nzValue="windDir" nzLabel="风向"></nz-option>
                    <nz-option nzValue="windScale" nzLabel="风力等级"></nz-option>
                    <nz-option nzValue="windSpeed" nzLabel="风速"></nz-option>
                    <nz-option nzValue="humidity" nzLabel="湿度"></nz-option>
                    <nz-option nzValue="precip" nzLabel="当前小时累计降水量"></nz-option>
                    <nz-option nzValue="pressure" nzLabel="大气压强"></nz-option>
                    <nz-option nzValue="vis" nzLabel="能见度"></nz-option>
                    <nz-option nzValue="cloud" nzLabel="云量"></nz-option>
                    <nz-option nzValue="dew" nzLabel="露点温度"></nz-option>
                    <nz-option nzValue="aqi" nzLabel="空气质量指数"></nz-option>
                    <nz-option nzValue="pm10" nzLabel="PM10"></nz-option>
                    <nz-option nzValue="pm2p5" nzLabel="PM2.5"></nz-option>
                  </nz-select>
                </div>
                <div class="select" *ngIf="realData.from==0">
                  <label>所在城市：</label>
                  <nz-select nzShowSearch [(ngModel)]="realData.city">
                    <nz-option [nzLabel]="ct.name" [nzValue]="ct.id" *ngFor="let ct of citys"></nz-option>
                  </nz-select>
                </div>

                <nz-input-group nzAddOnBefore="数据名称：">
                  <input type="text" nz-input [(ngModel)]="realData.name" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="数据单位：">
                  <input type="text" nz-input [(ngModel)]="realData.unit" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="数据保留小数位：">
                  <input type="text" nz-input [(ngModel)]="realData.fix" />
                </nz-input-group>
              </div>
            </div>
            <div class="operation">
              <button nz-button nzType="primary" (click)="addRealData()">新增</button>
            </div>
          </div>
        </div>
      </div>

      <div id='index-name'>
        <nz-divider nzText="登录名图标配置"></nz-divider>
        <label nz-checkbox [(ngModel)]="config.index.hideNameIcon"
          (ngModelChange)="setIndexNameIconHide()">隐藏登录名图标</label>
        <span *ngIf="!config.index.hideNameIcon">说明：建议像素：34*34，最大50KB。</span>
        <div class="data" *ngIf="!config.index.hideNameIcon">
          <img alt="tw" *ngIf="config.index.nameIcon.base64" [src]="getSafeSrc(config.index.nameIcon)" />
          <div class="action">
            <nz-upload nzListType="picture-card" [nzBeforeUpload]="beforeIndexNameIconUpload">
              <i nz-icon nzType="plus"></i>
              <div class="ant-upload-text">上传</div>
            </nz-upload>
            <button nz-button nzType="dashed" nzDanger (click)="deleteIndexNameIcon()">删除</button>
          </div>
        </div>
      </div>
      <div id='index-css'>
        <nz-divider nzText="自定义CSS样式"></nz-divider>
        <span>说明：需要结合F12调试模式（获取节点id、class）使用。</span>
        <div class="data">
          <code-input ngDefaultControl lang="css" style="width: 100%;border: 1px solid;" [(ngModel)]="config.index.css"
            [style.border-color]="codeBorderColor"></code-input>
          <button nz-button nzType="primary" (click)="saveIndexCss()">保存</button>
        </div>
      </div>

    </div>
  </div>
</nz-spin>