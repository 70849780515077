import { TemplateData } from './templateData';
/**
 * 模板
 */
export class Template {
    /**
     * 模板id
     */
    id?: string;
    /**
     * 模板名称
     */
    text?: string;
    /**
     * 排序编号
     */
    sort?: number;
    /**
     * 模板分类id
     */
    classifyId?: string;
    /**
     * 工程id
     */
    projectId: string;
    /**
     * 模板数据
     */
    data: TemplateData = new TemplateData();
    /**
     * 是否正在编辑
     */
    edit?: boolean;
}
