export class Module {
    id: string;
    /**
     * 所属工程id
     */
    projectId: string;
    /**
     * 模块名
     */
    text: string;
    /**
     * 上级模块id
     */
    parentId: string;
    /**
     * 模块排序编号
     */
    sort: number;
    /**
     * 模块图标
     */
    icon = '';
    /**
     * 模块图标(激活状态)
     */
    iconActive = '';
}
