import { Component } from '@angular/core';

@Component({
    selector: 'app-help-chart-liquid',
    templateUrl: './chartLiquid.component.html',
    styleUrls: ['./chartLiquid.component.css']
})
export class ChartLiquidHelpComponent {
    constructor(
    ) {
    }
}

