/**
 * 组件事件
 */
export class DataEvent {
    /**
     * 事件ID
     */
    id: string;
    /**
     * 事件内容
     */
    action: string;
}
