import { Property } from "../_models";
import { SvgNodeType } from "../const";
import { PxUtil } from "../utils";
import { Circle } from "./circle";
import { Image } from "./image";
import { Line } from "./line";
import { Path } from "./path";
import { Rect } from "./rect";
import { Svg } from "./svg";

export class SvgNodesProp {
    static get(selectedObj: any, prop: Property) {
        let ret = false;
        const type = selectedObj.type;
        switch (type) {
            case SvgNodeType.line: {
                ret = Line.get(selectedObj, prop);
                break;
            }
            case SvgNodeType.circle: {
                ret = Circle.get(selectedObj, prop);
                break;
            }
            case SvgNodeType.rect: {
                ret = Rect.get(selectedObj, prop);
                break;
            }
            case SvgNodeType.image: {
                ret = Image.get(selectedObj, prop);
                break;
            }
            case SvgNodeType.path: {
                ret = Path.get(selectedObj, prop);
                break;
            }
            case SvgNodeType.svg: {
                ret = Svg.get(selectedObj, prop);
                break;
            }
        }
        if (!ret) {
            if (selectedObj.node.style.hasOwnProperty(prop.name)) {
                prop.setValue = selectedObj.node.style.getPropertyValue(prop.name);
            } else {
                prop.setValue = selectedObj.attr(prop.name);
            }
            if (prop.setValue) {
                prop.setValue = prop.setValue.replace('px', '');
            }
        }
    }

    static set(selectedObj: any, prop: Property) {
        let ret = false;
        const type = selectedObj.type;
        switch (type) {
            case SvgNodeType.line: {
                ret = Line.set(selectedObj, prop);
                break;
            }
            case SvgNodeType.circle: {
                ret = Circle.set(selectedObj, prop);
                break;
            }
            case SvgNodeType.rect: {
                ret = Rect.set(selectedObj, prop);
                break;
            }
            case SvgNodeType.image: {
                ret = Image.set(selectedObj, prop);
                break;
            }
            case SvgNodeType.path: {
                ret = Path.set(selectedObj, prop);
                break;
            }
            case SvgNodeType.svg: {
                ret = Svg.set(selectedObj, prop);
                break;
            }
        }
        if (!ret) {
            if (selectedObj.node.style.hasOwnProperty(prop.name)) {
                selectedObj.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name));
            } else {
                selectedObj.attr(prop.name, prop.setValue + PxUtil.get(prop.name));
            }
        }
    }
}