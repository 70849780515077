/**
 * 时间周期枚举
 */
export enum TimeTypeEnum {
    /**
     * 分钟
     */
    MINUTE = 'minute',
    /**
     * 小时
     */
    HOUR = 'hour',
    /**
     * 本日
     */
    DAY = 'day',
    /**
     * 近两日
     */
    TWO_DAY = 'two_day',
    /**
     * 本周
     */
    WEEK = 'week',
    /**
     * 本月
     */
    MONTH = 'month',
    /**
     * 本季度
     */
    SEASON = 'season',
    /**
     * 本年
     */
    YEAR = 'year',
    /**
     * 近三年
     */
    THREE_YEAR = 'three_year',
    /**
     * 近五年
     */
    FIVE_YEAR = 'five_year',
    /**
     * 其它
     */
    OTHER = 'other'
}
