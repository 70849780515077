<div nz-row [ngSwitch]="property.type">
  <ng-container *ngSwitchCase="'text'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="16">
      <input nz-input nzSize="default" [(ngModel)]="property.setValue" (ngModelChange)="setProperty(property)"
        spellcheck="false" nz-tooltip [nzTooltipTitle]="property.tooltip" />
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="16">
      <nz-input-number style="width:100% !important" [nzMin]="property.min" [nzMax]="property.max"
        [(ngModel)]="property.setValue" (ngModelChange)="setProperty(property);" nz-tooltip
        [nzTooltipTitle]="property.tooltip"></nz-input-number>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'color'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="16">
      <app-color-picker [color]="property.setValue" [property]="property" (selectedColor)="setProperty($event)">
      </app-color-picker>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'list'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <nz-select nz-col nzSpan="16" [(ngModel)]="property.setValue" (ngModelChange)="setProperty(property)">
      <nz-option [nzValue]="item.name" [nzLabel]="item.desc" *ngFor="let item of property.values"></nz-option>
    </nz-select>
  </ng-container>
  <ng-container *ngSwitchCase="'bool'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="16">
      <nz-switch [ngModel]="property.setValue|boolPipe" (ngModelChange)="setBoolProperty($event,property)">
      </nz-switch>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'mention'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="16">
      <input nz-input nzSize="default" [(ngModel)]="property.setValue" (ngModelChange)="setProperty(property)"
        [attr.list]="'inputmention'+property.name" spellcheck="false" />
      <datalist id="inputmention{{property.name}}">
        <option label="{{item.desc}}" value="{{item.name}}" *ngFor="let item of property.values">
      </datalist>
    </div>
  </ng-container>

  <nz-collapse *ngSwitchCase="'fold'" style="width: 100%;">
    <nz-collapse-panel [nzHeader]="property.desc" [nzActive]="true">
      <div class="style text-style" style="padding:0 10px;margin-top:5px;" *ngFor="let item of property.children">
        <app-property [property]="item"></app-property>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <ng-container *ngSwitchCase="'list-add'">
    <label nz-col nzSpan="8">{{property.desc}}</label>
    <div nz-col nzSpan="12">
      <input nz-input [(ngModel)]="property.setValue" />
    </div>
    <div nz-col nzSpan="4">
      <button nz-button nzType="primary" (click)="setItems(property)">...</button>
    </div>
  </ng-container>

  <label nz-col nzSpan="8" *ngSwitchDefault>{{property.desc}}:{{property.type}}</label>
</div>
