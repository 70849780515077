import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ItemData } from '../../_models';
import { MathUtil } from '../../utils';
import { SelectData } from '../../_models';
import { SelectApi } from 'src/app/api';
import { SelectDataType } from 'src/app/const';

/**
 * 系统页面
 */
@Component({
    selector: 'app-select-config',
    templateUrl: `./selectConfig.component.html`,
    styles: [
        `.wrap{
            height:400px;
            border: 1px solid #404040;
            overflow-y:auto;
        }
        .wrap1{
            height:450px;
            border: 1px solid #404040;
        }
        nz-radio-group,label{
            width:100%;
        }
        nz-form-label{
            width:80px;
            text-align:right;
        }
        nz-input-group,input{
            width:80%;
        }
        nz-form-item {
            margin-top:15px;
            margin-bottom:0;
        }
        nz-list-item{
            cursor:pointer;
        }
        nz-list-item:hover{
            background:#2675c763;
        }
        `
    ]
})
export class SelectConfigComponent implements OnInit, OnDestroy {
    @Input() data: SelectData = new SelectData();

    items: ItemData[] = [];
    selItem: ItemData = {
        key: '', title: ''
    };

    fixedItems = [];
    fixedDatas = [];
    selFixedId;

    regItems: ItemData[] = [];
    regDatas: ItemData[] = [];
    selRegId;

    dctSelectData;
    dctRegData;

    regMap = new Map();

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private modalService: NzModalService,
        private selectApi: SelectApi
    ) { }

    ngOnInit() {
        if (this.data.data && this.data.data.length > 0) {
            if (this.data.type === SelectDataType.custom) {
                this.items = this.data.data;
            } else if (this.data.type === SelectDataType.data) {
                this.selFixedId = this.data.data[0].key;
            } else {
                this.selRegId = this.data.data[0].key;
            }
        }

        this.init();
    }

    async init() {
        const dataPromise = this.selectApi.getDataList();
        const regPromise = this.selectApi.getRegList();

        this.dctSelectData = await dataPromise;
        this.dctRegData = await regPromise;

        this.fixedItems = Object.keys(this.dctSelectData);
        for (const item of this.dctRegData) {
            this.regItems.push({
                id: item.code,
                key: item.code,
                title: item.name
            });
        }
    }

    selectItem(item: ItemData) {
        this.selItem = item;
    }

    addItem() {
        this.items.push({
            id: MathUtil.getUUid(),
            key: '',
            title: 'title'
        });
    }

    removeItem() {
        if (!this.selItem || !this.selItem.id) {
            return;
        }
        this.items = this.items.filter(d => d.id !== this.selItem.id);
        this.selItem = {
            key: '', title: ''
        };
    }

    clearItem() {
        this.modalService.confirm({
            nzTitle: `确定清空数据源 ？`,
            nzOkDanger: true,
            nzOnOk: () => {
                this.items = [];
            }
        });
    }

    setSort(num: number) {
        if (!this.selItem || !this.selItem.id) {
            return;
        }
        const index = this.items.findIndex(d => d.id === this.selItem.id);
        if (num < 0 && index === 0 || num > 0 && index === this.items.length) {
            return;
        }
        this.items.splice(index, 1);
        this.items.splice(index + num, 0, this.selItem);
    }

    selectFixedItem(id) {
        this.selFixedId = id;
        this.fixedDatas = this.dctSelectData[id];
    }

    async selectRegItem(code: string) {
        this.selRegId = code;
        if (this.regMap.has(code)) {
            this.regDatas = this.regMap.get(code);
            return;
        }
        const nodes = await this.selectApi.getRegItems(code);
        this.regDatas = [];
        for (const item of nodes) {
            this.regDatas.push({
                key: item.key,
                title: item.title
            });
        }
        this.regMap.set(code, this.regDatas);
    }

    save(): any {
        return new Promise(resolve => {
            this.data.data = [];
            if (this.data.type === SelectDataType.custom) {
                if (this.items.length > 0) {
                    this.data.data = this.items;
                }
            } else if (this.data.type === SelectDataType.data) {
                if (this.selFixedId) {
                    this.data.data.push({
                        key: this.selFixedId, title: ''
                    });
                }
            } else {
                if (this.selRegId) {
                    this.data.data.push({
                        key: this.selRegId, title: ''
                    });
                }
            }
            resolve(this.data);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
