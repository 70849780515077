import { TimeRangeConfig } from './timeRangeConfig';
import { TimeRangeModalEnum } from './timeRangeModalEnum';

/**
 * 统计时间段配置
 */
export class SumTimeConfig {
    timeRanges: TimeRangeConfig[] = [];
    type = TimeRangeModalEnum.STACK;
}
