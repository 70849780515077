import { Component } from '@angular/core';

@Component({
    selector: 'app-help-chart-line',
    templateUrl: './chartLine.component.html',
    styleUrls: ['./chartLine.component.css']
})
export class ChartLineHelpComponent {
    constructor(
    ) {
    }
}

