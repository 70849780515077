
        <ul [ngStyle]="{'left.px':x, 'top.px':y,'z-index':999}" (contextmenu)='menuRightClick($event)'>
            <li nz-menu-item (click)='reNameModule()'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <li nz-menu-item (click)='setIcon(false)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标
            </li>
            <li nz-menu-item (click)='setIcon(true)'>
                <i nz-icon nzType="picture" nzTheme="outline"></i>设置菜单图标(激活状态)
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createModule()'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>创建模块
            </li>
            <li nz-menu-item (click)='deleteModule()' [nzDisabled]=!enableDeleteMenu>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模块
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='importPage($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage()'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setProperty($event)'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    