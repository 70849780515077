<div class="main">
    <div class="desc">
        <span class="title">改变<span class="mark">填充色</span>:</span>
        <div class="code">
            <div class="remark">p2为组件id，type为图层变量类型</div>
            <div>
                <@p2.type>.css('fill', '#e93a3a');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">改变<span class="mark">边框颜色</span>:</span>
        <div class="code">
            <div class="remark">p2为组件id，type为图层变量类型</div>
            <div>
                <@p2.type>.css('stroke', '#e93a3a');
            </div>
        </div>
    </div>
</div>