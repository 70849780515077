import { Component, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

/**
 * 工程模板另存为
 */
@Component({
    selector: 'app-replace',
    template: `
        <span>替换内容：</span>
        <nz-radio-group [(ngModel)]="replaceType">
          <label nz-radio nzValue="id">组件id</label>
          <label nz-radio nzValue="text">文本</label>
          <label nz-radio nzValue="source">数据源编号</label>
          <label nz-radio nzValue="paramType">变量类型</label>
          <label nz-radio nzValue="sourceJs">数据源脚本</label>
          <label nz-radio nzValue="event">事件脚本</label>
        </nz-radio-group>
        <textarea rows="4" nz-input [(ngModel)]="oldStr" placeholder="待替换的内容"></textarea>
        <textarea rows="4" nz-input [(ngModel)]="newStr" placeholder="新内容"></textarea>
    `,
    styles: [
        `
            textarea{
                width:100%;
                margin-top:10px;
            }
        `
    ]
})
export class ReplaceComponent implements OnDestroy {
    /**
     * 替换内容
     */
    replaceType = 'id';
    oldStr = '';
    newStr = '';

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService
    ) { }

    /**
     * 保存
     */
    save(): any {
        return new Promise(resolve => {
            if (this.oldStr === this.newStr) {
                this.message.warning(`输入的内容相同，请检查！`);
                resolve(false);
                return;
            }

            resolve({
                Type: this.replaceType,
                OldStr: this.oldStr,
                NewStr: this.newStr
            });
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
