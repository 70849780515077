import { TimeRangeModalEnum } from './timeRangeModalEnum';

/**
 * 统计时间段
 */
export class TimeRangeConfig {
    id?: string;
    /**
     * 开始时间
     */
    start: string;
    /**
     * 结束时间
     */
    end: string;
    /**
     * 时段别名
     */
    name: string = '';
}
