/**
 * 工程路由信息
 */
export class ProjectRoute {
    protocol: string = 'http';
    site: string;
    tag: string;
    apiPath: string;
    isDefault: boolean;
}
