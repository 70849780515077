/**
 * 首页报警次数展示
 */
export class AlarmCount {
    show: boolean;
    position = 'rb';
    sql: string;
    days: number = 0;
    link: string;
    /**
     * 0：跳转；1：弹窗
     */
    linkType = '0';
}