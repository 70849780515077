import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CanvasCache, DataCache } from 'src/app/cache';
import { DataEvent, DataSource, OperateType } from 'src/app/_models';
import { ComponentTool } from './componentTool';
import { CanvasTool } from './canvasTool';
import { CommonFunc } from './commonFunc';
import { CommondAction } from 'src/app/_helpers/commondAction';
import { EventTransService } from 'src/app/_services';
import { TemplateTool } from './templateTool';
import { CanvasConstData, DataAttrName } from 'src/app/const';
import { MathUtil } from 'src/app/utils';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class EventHandle {

    constructor(
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private componentTool: ComponentTool,
        private canvasTool: CanvasTool,
        private commonFunc: CommonFunc,
        private templateTool: TemplateTool
    ) {
    }

    /**
     * 键盘按下事件处理
     */
    keyDown(event: KeyboardEvent) {
        if (!CanvasCache.isFoucus) {
            return;
        }
        // if (event.code.startsWith('F')) {
        //     event.preventDefault();
        // }
        const code = event.code.toLowerCase().replace('key', '');
        CanvasCache.isCtrlDown = event.ctrlKey || event.metaKey;

        if (code.startsWith('arrow')) {
            this.handleArrowEvent(code);
            return;
        }

        if (CanvasCache.isCtrlDown) {
            event.preventDefault();
            // 按住ctrl键
            this.handleCtrlEvent(code);
            return;
        }
        if (event.altKey) {
            // 按住alt键
            this.handleAltEvent(code);
            return;
        }
        if (code === 'delete') {
            // Del删除
            this.operate(OperateType.Delete);
            return;
        }
        if (code === 'escape') {
            // Esc
            this.commonFunc.clearSelected();
            this.eventTransService.selectedObjChangeEvent.next({ Svg: CanvasCache.svg });
        }
    }

    /**
     * 方向键处理
     * @param code key code
     */
    handleArrowEvent(code: string): void {
        switch (code) {
            case 'arrowleft': {
                this.componentTool.move(-1, 0);
                break;
            }
            case 'arrowup': {
                this.componentTool.move(0, -1);
                break;
            }
            case 'arrowright': {
                this.componentTool.move(1, 0);
                break;
            }
            case 'arrowdown': {
                this.componentTool.move(0, 1);
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * ctrl相关事件
     * @param code key code
     */
    handleCtrlEvent(code: string) {
        switch (code) {
            case 's': {
                // Ctrl+S保存
                this.canvasTool.saveCanvas();
                break;
            }
            case 'a': {
                // Ctrl+A全选
                this.operate(OperateType.SelectAll);
                break;
            }
            case 'd': {
                // Ctrl+D删除
                this.operate(OperateType.Delete);
                break;
            }
            case 'c': {
                // Ctrl+C复制
                this.operate(OperateType.Copy);
                break;
            }
            case 'v': {
                // Ctrl+V粘贴
                this.operate(OperateType.Paste);
                break;
            }
            case 'z': {
                // Ctrl+Z撤销
                this.commonFunc.clearSelected();
                const fragment = CommondAction.undo();
                // tslint:disable-next-line: no-unused-expression
                fragment && this.canvasTool.loadCanvas(fragment);
                this.eventTransService.selectedObjChangeEvent.next({ Svg: CanvasCache.svg });
                CanvasCache.canvasContent = fragment;
                this.eventTransService.setCanvasCache.next(true);
                break;
            }
            case 'y': {
                // Ctrl+Y重做
                this.commonFunc.clearSelected();
                const fragment = CommondAction.redo();
                // tslint:disable-next-line: no-unused-expression
                fragment && this.canvasTool.loadCanvas(fragment);
                CanvasCache.canvasContent = fragment;
                this.eventTransService.setCanvasCache.next(true);
                break;
            }
            case 'f': {
                // Ctrl+F查找
                this.operate(OperateType.Find);
                break;
            }
            case 'h': {
                // Ctrl+H替换
                this.operate(OperateType.Replace);
                break;
            }
        }
    }

    /**
     * alt相关事件
     * @param code key code
     */
    handleAltEvent(code: string) {
        code = code.replace('digit', '');
        switch (code) {
            case '1': {
                this.operate(OperateType.AlignToTop);
                break;
            }
            case '2': {
                this.operate(OperateType.AlignToBottom);
                break;
            }
            case '3': {
                this.operate(OperateType.AlignToLeft);
                break;
            }
            case '4': {
                this.operate(OperateType.AlignToRight);
                break;
            }
            case '5': {
                this.operate(OperateType.AlignToVertical);
                break;
            }
            case '6': {
                this.operate(OperateType.AlignToMiddle);
                break;
            }
            case '7': {
                this.operate(OperateType.Center);
                break;
            }
            case '8': {
                this.operate(OperateType.AlignMiddleSpace);
                break;
            }
            case '9': {
                this.operate(OperateType.AlignVerticalSpace);
                break;
            }
            case '0': {
                this.operate(OperateType.SameSize);
                break;
            }
            case 'q': {
                this.operate(OperateType.MergeCom);
                break;
            }
            case 'w': {
                this.operate(OperateType.SplitCom);
                break;
            }
            case 'e': {
                this.operate(OperateType.SetToTop);
                break;
            }
            case 'r': {
                this.operate(OperateType.SetToBottom);
                break;
            }
            case 't': {
                this.operate(OperateType.CenterToMiddle);
                break;
            }
            case 'y': {
                this.operate(OperateType.CenterToVertical);
                break;
            }
            case 'u': {
                this.operate(OperateType.RemoveBlank);
                break;
            }
        }
    }

    /**
     * 画布操作
     */
    operate(type: number) {
        // 隐藏菜单
        this.eventTransService.menuStateChangeEvent.next({});
        CanvasCache.isBatchAction = true;

        CanvasCache.isOperateChange = false;
        CanvasCache.showGuideLine = false;
        switch (type) {
            case OperateType.Copy: {
                this.canvasTool.copy();
                break;
            }
            case OperateType.Paste: {
                this.canvasTool.paste();
                break;
            }
            case OperateType.Delete: {
                this.canvasTool.delete();
                break;
            }
            case OperateType.SelectAll: {
                this.canvasTool.selectAll();
                break;
            }
            case OperateType.Find: {
                this.canvasTool.doFind();
                break;
            }
            case OperateType.Replace: {
                this.canvasTool.doReplace();
                break;
            }
            case OperateType.SaveToTemplate: {
                this.templateTool.saveAsTemplate();
                break;
            }
            case OperateType.SaveToBaseModel: {
                this.componentTool.saveToBaseModel();
                break;
            }
            case OperateType.SyncCss: {
                this.componentTool.syncCss();
                break;
            }
            case OperateType.JsEvent: {
                this.canvasTool.openJsEvent();
                break;
            }
            case OperateType.AlignToTop: {
                this.componentTool.alignToTop();
                break;
            }
            case OperateType.AlignToBottom: {
                this.componentTool.alignToBottom();
                break;
            }
            case OperateType.AlignToLeft: {
                this.componentTool.alignToLeft();
                break;
            }
            case OperateType.AlignToRight: {
                this.componentTool.alignToRight();
                break;
            }
            case OperateType.AlignToVertical: {
                this.componentTool.alignToVertical();
                break;
            }
            case OperateType.AlignToMiddle: {
                this.componentTool.alignToMiddle();
                break;
            }
            case OperateType.AlignMiddleSpace: {
                this.componentTool.alignMiddleSpace();
                break;
            }
            case OperateType.AlignVerticalSpace: {
                this.componentTool.alignVerticalSpace();
                break;
            }
            case OperateType.CenterToMiddle: {
                this.componentTool.centerToMiddle();
                break;
            }
            case OperateType.CenterToVertical: {
                this.componentTool.centerToVertical();
                break;
            }
            case OperateType.SetToTop: {
                this.componentTool.setToTop();
                break;
            }
            case OperateType.SetToBottom: {
                this.componentTool.setToBottom();
                break;
            }
            case OperateType.MergeCom: {
                this.mergeComs();
                break;
            }
            case OperateType.SplitCom: {
                this.splitComs();
                break;
            }
            case OperateType.DrawLine: {
                CanvasCache.isDrawMode = true;
                this.message.info('开启折线绘制模式，鼠标右键完成绘制！');
                break;
            }
            case OperateType.CreateTable: {
                this.canvasTool.createTable();
                break;
            }
            case OperateType.ExportPage: {
                this.canvasTool.export();
                break;
            }
            case OperateType.Rotate0: {
                this.componentTool.rotate(0);
                break;
            }
            case OperateType.Rotate90: {
                this.componentTool.rotate(90);
                break;
            }
            case OperateType.Rotate180: {
                this.componentTool.rotate(180);
                break;
            }
            case OperateType.RotateCustom: {
                this.componentTool.rotateCustom();
                break;
            }
            case OperateType.Save: {
                this.canvasTool.saveCanvas();
                break;
            }
            case OperateType.RestoreTemplate: {
                this.templateTool.restoreTemplate();
                break;
            }
            case OperateType.RemoveBlank: {
                this.canvasTool.removeBlank();
                break;
            }
            case OperateType.SameSize: {
                this.componentTool.sameSize();
                break;
            }
            case OperateType.SameWidth: {
                this.componentTool.sameWidth();
                break;
            }
            case OperateType.SameHeight: {
                this.componentTool.sameHeight();
                break;
            }
            case OperateType.Center: {
                this.componentTool.alignToCenter();
                break;
            }
            default: {
                this.message.warning(`无效的操作类型：${type}`);
                break;
            }
        }

        CanvasCache.isBatchAction = false;
        CanvasCache.showGuideLine = true;
        if (CanvasCache.isOperateChange) {
            this.eventTransService.recordActionEvent.next(true);
            this.commonFunc.removeGuideLine();
        }
    }

    /**
     * 组件合并
     */
    mergeComs() {
        if (this.commonFunc.getSelectedSize() < 2) {
            this.message.warning('执行该功能需选择2个及以上组件！');
            return;
        }
        // 创建新的g标签，将拖拽的元素放入g标签内
        const g = CanvasCache.svg.paper.g().attr(DataAttrName.text, '合并组件');
        let count = 0;
        const nodes = CanvasCache.svg.selectAll(`[${DataAttrName.meterType}^='${DataAttrName.merge}']`);
        nodes.forEach(item => {
            const type = item.attr(DataAttrName.meterType);
            if (!type) {
                return true;
            }
            const idx = parseInt(type.replace(DataAttrName.merge, ''), 10);
            if (idx > count) {
                count = idx;
            }
        });
        const selDoms = this.commonFunc.getSelectedDoms(true);
        selDoms.forEach((item: any) => {
            count++;
            const dom = $(item.el).data('dom');
            dom.unmousedown();
            dom.unclick();
            dom.removeClass(CanvasConstData.subjxClass);
            // dom.attr(DataAttrName.mergeId,)
            g.add(dom);

            // 事件合并
            this.mergeEvent(dom, g, count);
            // 数据源合并
            this.mergeSource(dom, g);
        });
        this.commonFunc.clearSelected();
        // 初始化组件相关事件
        this.canvasTool.bindEvent(g);
        this.canvasTool.svgDomMouseDown(g, false);
        this.message.success('组件已合并！');
        CanvasCache.isOperateChange = true;
    }

    /**
     * 组件事件合并
     * @param dom 原组件
     * @param g 合并后的新组件
     * @param count 序号
     */
    mergeEvent(dom: any, g: any, count: number) {
        if (!DataCache.page) {
            return;
        }
        const eid = dom.attr(DataAttrName.event);
        if (!eid) {
            return;
        }
        const event: DataEvent = DataCache.pageData.events.find(d => d.id === eid);
        if (!event || !event.action) {
            return;
        }
        dom.attr(DataAttrName.eventMerge, eid);
        dom.attr(DataAttrName.event, '');
        
        let action = event.action;
        if (action.indexOf('<@*>.') >= 0) {
            const type = `${DataAttrName.merge}${count}`;
            action = action.replace('<@*>.', `<@${type}>.`);
            dom.attr(DataAttrName.meterType, type);
        }

        let gEvent: DataEvent = null;
        const gEid = g.attr(DataAttrName.event);
        if (gEid) {
            gEvent = DataCache.pageData.events.find(d => d.id === gEid);
        }
        if (gEvent == null) {
            gEvent = {
                id: MathUtil.getUUid(),
                action: ''
            };
            DataCache.pageData.events.push(gEvent);
            g.attr(DataAttrName.event, gEvent.id);
        }
        gEvent.action += '\r' + action;
    }

    /**
     * 组件数据源合并
     * @param dom 原组件
     * @param g 合并后的心组件
     */
    mergeSource(dom: any, g: any) {
        if (!DataCache.page) {
            return;
        }
        const sid = dom.attr(DataAttrName.source);
        if (!sid) {
            return;
        }
        const source: DataSource = DataCache.pageData.sources.find(d => d.id === sid);
        if (!source || !source.action) {
            return;
        }

        dom.attr(DataAttrName.sourceMerge, sid);
        dom.attr(DataAttrName.source, '');

        let gSource: DataSource = null;
        const gSid = g.attr(DataAttrName.source);
        if (gSid) {
            gSource = DataCache.pageData.sources.find(d => d.id === gSid);
        }
        if (gSource == null) {
            gSource = new DataSource();
            gSource.id = MathUtil.getUUid();
            gSource.action = '';

            DataCache.pageData.sources.push(gSource);
            g.attr(DataAttrName.source, gSource.id);
        }
        if (!gSource.code) {
            gSource.code = source.code;
        }
        if (gSource.action) {
            gSource.action += '\r';
        }
        gSource.action += source.action;
    }

    /**
     * 组件拆分
     */
    splitComs() {
        const selCom = CanvasCache.selectedComponent;
        if (!selCom) {
            this.message.warning('请先选择要拆分的组件！');
            return;
        }
        if (selCom.attr(DataAttrName.template)) {
            this.message.warning('不支持拆分模板！');
            return;
        }
        if (selCom.children().length === 0) {
            this.message.warning('所选组件无法拆分！');
            return;
        }
        let canSplit = true;
        const doms = [];
        selCom.children().forEach((child: any) => {
            if (child.type !== 'g') {
                canSplit = false;
                return false;
            }
            doms.push(child);
        });
        if (!canSplit) {
            this.message.warning('所选组件无法拆分[只支持拆分后期合并的组件]！');
            return;
        }
        const parentTrans = selCom.transform().local;
        const parentSource = selCom.attr(DataAttrName.source);
        const parentEvent = selCom.attr(DataAttrName.event);
        doms.forEach(child => {
            //拆分后id处理，防止id重复
            const id = child.attr('id');
            if (id) {
                const idNode = CanvasCache.svg.select(`svg>g[id='${id}']`);
                if (idNode) {
                    child.attr('id', '');
                }
            }
            CanvasCache.svg.append(child);
            if (parentTrans) {
                // 相加的变量顺序不能颠倒
                child.transform(parentTrans + child.transform().local);
            }
            const dsId = child.attr(DataAttrName.sourceMerge);
            if (dsId) {
                this.canvasTool.splitRestoreDataSource(child, dsId);
            }

            const deId = child.attr(DataAttrName.eventMerge);
            if (deId) {
                this.canvasTool.setDataEvent(child, deId);
            } else if (parentEvent) {
                this.canvasTool.setDataEvent(child, parentEvent);
            }
            this.canvasTool.bindEvent(child);
        });
        if (DataCache.page) {
            // 删除缓存中原数据源、事件数据
            if (parentSource) {
                DataCache.pageData.sources = DataCache.pageData.sources.filter(d => d.id !== parentSource);
            }
            if (parentEvent) {
                DataCache.pageData.events = DataCache.pageData.events.filter(d => d.id !== parentEvent);
            }
        }

        $(selCom.node).data('drag').disable();
        selCom.remove();
        CanvasCache.isOperateChange = true;
        this.message.success('组件已拆分！');
    }
}
