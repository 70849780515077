
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='edit($event)' style='font-weight:bold'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>编辑模板
            </li>
            <li nz-menu-item (click)='loadCache()'>
             <i nz-icon nzType="reload" nzTheme="outline"></i>加载缓存数据
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='reName()'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <li nz-menu-item (click)='delete($event)'>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='saveAs($event)'>
                <i nz-icon nzType="save" nzTheme="outline"></i>另存为
            </li>
            <li nz-menu-item (click)='import($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='downLoad($event)'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setProperty($event)'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    