<div class="main">
    <div class="desc">
        <span class="title">动态设置<span class="mark">开关状态</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value('1');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取当前<span class="mark">开关状态</span>:</span>
        <div class="code">
            <div class="remark">具体值可以【属性>开-key与关-key】中设置。</div>
            <div>
                var state = <@p2.*>.value();
            </div>
        </div>
    </div>
</div>