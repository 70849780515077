import { Img } from "./img";

/**
 * 标题配置
 */
export class TimeConfig {
    hide: boolean;
    hideIcon: boolean;
    icon: Img = new Img();
    format: string = 'MM月DD日 HH:mm:ss';
    hideWeek: boolean;
}