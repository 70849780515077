<div class="main">
    <div class="desc">
        <span class="title">使用前提：需要在【工程-工程配置-萤石云平台接入】录入AppKey、AppSecret信息</span>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">动态绑定配置（也可在【组件->属性】中配置）</span>:</span>
        <div class="remark">serial:序列化；channel:通道号；code：验证码</div>
        <div class="code">
            <@p2.*>.obj().config({{'{'}}
                serial: 'xxx',
                channel: 1,
                code: 'xxx'
                {{'}'}});
        </div>
    </div>

    <div class="desc">
        <span class="title">设置<span class="mark">回放时间</span>:</span>
        <div class="remark">针对视频回放功能,参数分别为开始回放时间、结束回放时间，必填！</div>
        <div class="code">
            <@p2.*>.obj().setTime('2024-05-01 00:00:00', '2024-05-01 10:00:00');
        </div>
    </div>

    <div class="desc">
        <span class="title"><span class="mark">开始播放</span>:</span>
        <div class="code">
            <@p2.*>.obj().play();
        </div>
    </div>

    <div class="desc">
        <span class="title"><span class="mark">停止播放</span>:</span>
        <div class="code">
            <@p2.*>.obj().stop();
        </div>
    </div>
</div>