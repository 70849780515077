<div class="main">
    <div class="desc">
        <span class="title">获取<span class="mark">选中项ID</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的ID</div>
            <div class="remark">多选模式返回结果为列表：[1]、[1 , 2]</div>
            <div>
                var id = <@p2.*>.value();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">选中项文本</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的名称</div>
            <div class="remark">多选模式返回结果为列表：['name1']、['name1' , 'name2']</div>
            <div>
                var name = <@p2.*>.obj().getText();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">根据ID选中某项</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value(2);
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">动态绑定数据源</span>:</span>
        <div class="code">
            <div>
                var items = [{{ '{' }}
                key: '1',
                title: '文本1'
                {{ '}' }},
                {{ '{' }}
                key: '2',
                title: '文本2'
                {{ '}' }}
                ];
            </div>
            <div>
                <@p2.*>.obj().initData(items);
            </div>
        </div>
    </div>
</div>