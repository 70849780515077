
/**
 * 饼图类型
 */
export enum GaugeTypeEnum {
    /**
     * 百分比
     */
    PER = 'per',
    /**
     * 数值
     */
    DATA = 'data'
}
