import { Property } from "../_models";
import { DataAttrName } from "../const";
import { BoolCheckUtil, PxUtil } from "../utils";

export class Image {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'width':
            case 'height': {
                prop.setValue = selectedObj.attr(prop.name).replace('px', '');
                break;
            }
            case DataAttrName.keepRatio: {
                //图片默认保持宽高比
                let keepRatio = selectedObj.attr(DataAttrName.keepRatio);
                if (keepRatio == null || keepRatio == undefined || keepRatio == '' || keepRatio == ' ') {
                    keepRatio = true;
                    selectedObj.attr(DataAttrName.keepRatio, true);
                }
                prop.setValue = keepRatio;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'width':
            case 'height': {
                let keepRatio = selectedObj.attr(DataAttrName.keepRatio);
                if (BoolCheckUtil.check(keepRatio)) {
                    const w = parseFloat(selectedObj.attr('width').replace('px', ''));
                    const h = parseFloat(selectedObj.attr('height').replace('px', ''));
                    const ratio = w / h;
                    if (prop.name === 'width') {
                        selectedObj.attr('height', parseFloat(prop.setValue) / ratio + PxUtil.get(prop.name));
                    } else {
                        selectedObj.attr('width', parseFloat(prop.setValue) * ratio + PxUtil.get(prop.name));
                    }
                }
                selectedObj.attr(prop.name, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
