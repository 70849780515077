import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TemplateClassify } from '../_models';
import { TemplateClassifyApi } from '../api/templateClassifyApi';
import { DataCache } from '../cache';

/**
 * 工程模板另存为
 */
@Component({
    selector: 'app-template-save-as',
    template: `
        <nz-input-group nzAddOnBefore="名称" style="width:100%;">
            <input type="text" nz-input [(ngModel)]="text" placeholder="请输入模板名称"/>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label>所属模板分类：</label>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="classifyId" [nzButtonStyle]="'solid'" *ngFor="let item of classifies">
                    <label nz-radio-button nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 200px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        input{
            margin-bottom:10px;
        }
        nz-radio-group,label{
            width:100%;
        }
        `
    ]
})
export class TemplateSaveAsComponent implements OnInit, OnDestroy {
    /**
     * 默认模板名称
     */
    @Input() text = '';
    /**
     * 选择的分类ID
     */
    @Input() classifyId = '';
    /**
     * 模板分类
     */
    classifies: TemplateClassify[] = [];
    isLoading = true;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private templateClassifyApi: TemplateClassifyApi
    ) { }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.classifies = await this.templateClassifyApi.getAll(DataCache.project.id);
        this.isLoading = false;
    }

    /**
     * 保存
     */
    save(): any {
        return new Promise(resolve => {
            if (!this.text) {
                this.message.warning(`请输入模板名称`);
                resolve(false);
                return;
            }
            if (!this.classifyId) {
                this.message.warning(`请选择模板分类`);
                resolve(false);
                return;
            }

            resolve({
                text: this.text,
                classifyId: this.classifyId
            });
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
