import { DataAttrName } from '../const';
import { Property } from '../_models';
import { PxUtil } from '../utils';

export class ChartLiquid {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const div = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.showBorder:
            case DataAttrName.showCircle:
            case DataAttrName.textColor:
            case DataAttrName.circleColor:
            case DataAttrName.bgColor:
            case DataAttrName.gradientStartColor:
            case DataAttrName.gradientStopColor: {
                prop.setValue = div.attr(prop.name);
                break;
            }
            case 'border-color':
            case 'border-width':
            case 'border-radius':
            case 'border-style': {
                prop.setValue = div.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const div = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.showBorder:
            case DataAttrName.showCircle:
            case DataAttrName.textColor:
            case DataAttrName.circleColor:
            case DataAttrName.bgColor:
            case DataAttrName.gradientStartColor:
            case DataAttrName.gradientStopColor: {
                div.attr(prop.name, prop.setValue);
                break;
            }
            case 'border-color':
            case 'border-width':
            case 'border-radius':
            case 'border-style': {
                div.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name));
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
