import { BaseProperty } from '../base/baseProperty';
import { HighLightConfig } from './highLightConfig';
import { LineTypeEnum } from './lineTypeEnum';
import { YearMonthConfig } from '../base/yearMonthConfig';
import { ShowTypeEnum } from '../base/showTypeEnum';
import { DataZoomConfig } from '../base/dataZoomConfig';
import { DataSourceConfig } from '../base/dataSourceConfig';
import { TimeTypeEnum } from '../base/timeTypeEnum';

export class LineProperty extends BaseProperty {
    /**
     * 线条类型
     */
    type = LineTypeEnum.SMOOTH;
    /**
     * 数据源
     */
    dataSources: DataSourceConfig[] = [];
    /**
     * 降采策略
     */
    sampling = 'lttb';
    /**
     * 数据展示粒度（单位：秒），为0表示按照数据真实情况展示
     */
    interval = 0;
    /**
     * 自动刷新周期（单位：秒），为0表示不自动刷新
     */
    refreshCycle = 0;
    /**
     * 是否连接空数据
     */
    connectNulls = false;
    /**
     * 同环比配置
     */
    yearMonthConfig: YearMonthConfig = new YearMonthConfig();
    /**
     * 高亮区域
     */
    highLights: HighLightConfig[] = [];
    /**
     * 最大最小值配置
     */
    maxMinConfig = ShowTypeEnum.SMART;
    /**
     * 平均值展示配置
     */
    avgConfig = ShowTypeEnum.NONE;
    /**
     * 数据缩略视图配置
     */
    dataZoomConfig: DataZoomConfig = new DataZoomConfig();
}
