import { DataAttrName } from '../const';
import { Property } from '../_models';

/**
 * 组件配置属性缓存
 */
export class PropCache {
    /**
     * 组件配置属性缓存
     */
    private static map = new Map();

    /**
     * 初始化
     */
    static init(data: any) {
        this.map = new Map(Object.entries(data));
    }

    /**
     * 集合是否为空
     */
    static isEmpty() {
        return this.map.size === 0;
    }

    /**
     * 是否包含key
     * @param key key
     */
    static hasKey(key: string): boolean {
        return this.map.get(key) && this.map.get(key).length > 0;
    }

    /**
     * 获取配置属性
     * @param key key
     */
    static get(key: string): Property[] {
        return this.map.get(key);
    }

    /**
     * 获取通用配置属性
     */
    static getCommon(): Property[] {
        return this.map.get('common');
    }

    /**
     * 获取svg配置属性
     */
    static getSvg(): Property[] {
        return this.map.get('svg');
    }

    /**
     * svg需要排除的配置属性
     */
    static getSvgExProp() {
        return ['id', DataAttrName.meterType, DataAttrName.display, DataAttrName.level];
    }

    /**
     * 组件需要排除的配置属性
     */
    static getComExProp() {
        return [DataAttrName.meterType];
    }

    /**
     * 组件元素需要排除的配置属性
     */
    static getElementExProp() {
        return [DataAttrName.level];
    }

    /**
     * 模板页面需要排除的属性
     */
    static getTemplateExProp() {
        return ['id'];
    }

    /**
     * 通用属性
     */
    static getComponentCommonProp() {
        return ['id', DataAttrName.display, DataAttrName.opacity, DataAttrName.level, DataAttrName.tooltip, DataAttrName.meterType];
    }
}
