import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->数据
 */
@Injectable({ providedIn: 'root' })
export class DataApi {
    private prefix = '/design/data';

    constructor(private http: HttpService, private message: NzMessageService) {
    }
}
