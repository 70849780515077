import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';
import { AlarmConfig, RealDataConfig } from '../_models';


/**
 * web组态API-工程->主题设置
 */
@Injectable({ providedIn: 'root' })
export class ThemeConfigApi {
    private prefix = '/design/themeConfig';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    async getConfig(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.get(`${this.prefix}/getConfig`, { projectId });
    }

    /**
     * 登录页-左侧图片上传
     * @param file 文件对象
     * @param projectId 工程id
     */
    async uploadLoginLeftImg(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadLoginLeftImg`, file, { projectId });
    }

    /**
     * 登录页-删除左侧图片
     * @param projectId 工程id
     * @returns 
     */
    async deleteLoginLeftImg(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteLoginLeftImg`, { projectId });
    }

    /**
     * 登录页-logo上传
     * @param file 
     * @param projectId 
     * @returns 
     */
    async uploadLoginLogoImg(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadLoginLogoImg`, file, { projectId });
    }

    /**
     * 登录页-删除左侧图片
     * @param projectId 工程id
     * @returns 
     */
    async deleteLoginLogoImg(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteLoginLogoImg`, { projectId });
    }

    /**
     * 登录页-设置底部图片是否显示
     * @param projectId 
     * @param show 
     * @returns 
     */
    async setLoginValidHide(projectId: string, hide: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setLoginValidHide`, { projectId, hide });
    }

    /**
     * 登录页-背景图片上传
     * @param file 
     * @param projectId 
     * @returns 
     */
    async uploadLoginBgImg(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadLoginBgImg`, file, { projectId });
    }

    /**
     * 登录页-删除背景图片
     * @param projectId 工程id
     * @returns 
     */
    async deleteLoginBgImg(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteLoginBgImg`, { projectId });
    }

    /**
     * 保存登录页自定义样式
     * @param projectId 工程id
     * @param css 
     * @returns 
     */
    async saveLoginCss(projectId: string, css: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/saveLoginCss`, { projectId, css });
    }

    async setDefaultFull(projectId: string, value: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setDefaultFull`, { projectId, value });
    }

    async setShowFullBtn(projectId: string, value: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setShowFullBtn`, { projectId, value });
    }

    /**
     * 首页-菜单栏默认状态设置
     * @param projectId 
     * @param expand 
     * @returns 
     */
    async setIndexMenuExpand(projectId: string, expand: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setIndexMenuExpand`, { projectId, expand });
    }

    /**
     * 首页-logo上传
     * @param file 
     * @param projectId 
     * @returns 
     */
    async uploadIndexLogoImg(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadIndexLogoImg`, file, { projectId });
    }

    /**
     * 首页-删除左侧图片
     * @param projectId 工程id
     * @returns 
     */
    async deleteIndexLogoImg(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteIndexLogoImg`, { projectId });
    }

    /**
     * 保存首页自定义样式
     * @param projectId 工程id
     * @param css 
     * @returns 
     */
    async saveIndexCss(projectId: string, css: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/saveIndexCss`, { projectId, css });
    }

    /**
     * 保存首页标题
     * @param projectId 工程id
     * @param css 
     * @returns 
     */
    async saveIndexTitle(projectId: string, text: string, subText: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/saveIndexTitle`, { projectId, text, subText });
    }

    /**
     * 首页-设置时间是否显示
     * @param projectId 
     * @param show 
     * @returns 
     */
    async setIndexTimeHide(projectId: string, hide: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setIndexTimeHide`, { projectId, hide });
    }

    /**
     * 首页-设置时间-周是否显示
     * @param projectId 
     * @param show 
     * @returns 
     */
    async setIndexTimeWeekHide(projectId: string, hide: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setIndexTimeWeekHide`, { projectId, hide });
    }

    async setIndexTimeIconHide(projectId: string, hide: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setIndexTimeIconHide`, { projectId, hide });
    }

    async saveIndexTimeFormat(projectId: string, format: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/saveIndexTimeFormat`, { projectId, format });
    }

    async uploadIndexTimeIcon(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadIndexTimeIcon`, file, { projectId });
    }

    async deleteIndexTimeIcon(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteIndexTimeIcon`, { projectId });
    }

    async setIndexNameIconHide(projectId: string, hide: boolean) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/setIndexNameIconHide`, { projectId, hide });
    }

    async uploadIndexNameIcon(file: any, projectId: string) {
        if (!file || !projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadIndexNameIcon`, file, { projectId });
    }

    async deleteIndexNameIcon(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/deleteIndexNameIcon`, { projectId });
    }

    async addRealData(file, data: RealDataConfig) {
        var obj = {
            id: data.id,
            projectId: data.projectId,
            from: data.from,
            city: data.city,
            showIcon: data.showIcon,
            param: data.param,
            name: data.name,
            unit: data.unit,
            fix: data.fix
        };

        const ret = await this.http.post(`${this.prefix}/addRealData`, obj)
        if (!ret) {
            return false;
        }
        if (!data.showIcon || !file) {
            return true;
        }
        return await this.http.uploadFile(`${this.prefix}/uploadRealDataIcon`, file, {
            projectId: data.projectId,
            realId: data.id
        });
    }

    async deleteRealData(projectId: string, realId: string) {
        return await this.http.post_str(`${this.prefix}/deleteRealData`, { projectId, realId });
    }
}
