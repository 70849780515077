
      <nz-spin [nzSpinning]="isLoading">
        <input nz-input placeholder="请输入工程名称" [(ngModel)]="name" autofocus />
        <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="false"
            [nzLimit]="1"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="fileList">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
        </nz-upload>
        </cdk-virtual-scroll-viewport>
      </nz-spin>
    