import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

/*文件-新建-模块 */
@Component({
    selector: 'app-name-input',
    template: `<input #input nz-input [placeholder]="tipMsg" [(ngModel)]="inputName" />`,
    styles: [
        ``
    ]
})
export class NameInputComponent implements OnInit, OnDestroy {
    @Input() tipMsg: string;
    @Input() inputName: string;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService
    ) {
    }

    ngOnInit() {
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.inputName) {
                this.message.warning('名称不能为空！');
                resolve(false);
                return;
            }
            resolve(this.inputName);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
