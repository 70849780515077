import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->音频文件管理
 */
@Injectable({ providedIn: 'root' })
export class AudioApi {
    private prefix = '/design/audio';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 上传音频文件
     * @param file 音频文件
     */
    async upload(file: any) {
        if (!file) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/upload`, file);
    }
}
