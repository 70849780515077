import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { CanvasCache } from 'src/app/cache';
import { OperateType } from 'src/app/_models';
import { EventTransService } from 'src/app/_services';
import { CanvasTool } from '../svgCanvas/canvasTool';

@Component({
    selector: 'app-tool-bar',
    templateUrl: './toolBar.component.html',
    styleUrls: ['./toolBar.component.css']
})

export class ToolBarComponent implements OnInit, OnDestroy {

    isDrawModeSub: Subscription;
    svgScaleChangeSub: Subscription;
    toolBarSetChangeSub: Subscription;

    operateType = OperateType;

    isDrawMode = false;

    svgScale = '1.0';
    svgScaleInit = [];

    filterText = false;
    filterLineHor = false;
    filterLineVer = false;
    filterRect = false;
    filterCheckbox = false;
    filterInputText = false;
    filterInputNumber = false;

    // 工具栏设置类型
    setType: string;

    blankValue = 30;

    constructor(
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private canvasTool: CanvasTool,
    ) {
    }

    ngOnInit() {
        this.init();
        this.initSubscrite();
    }

    ngOnDestroy() {
        this.isDrawModeSub.unsubscribe();
        this.svgScaleChangeSub.unsubscribe();
        this.toolBarSetChangeSub.unsubscribe();
    }

    init() {
        for (let i = 0.2; i < 3.1; i += 0.1) {
            this.svgScaleInit.push({
                Text: (i * 100).toFixed(0) + '%',
                Value: i.toFixed(1).toString()
            });
        }
    }

    /**
     * 初始化事件订阅
     */
    initSubscrite() {
        this.isDrawModeSub = this.eventTransService.isDrawMode.subscribe((event: boolean) => {
            this.isDrawMode = event;
        });
        this.svgScaleChangeSub = this.eventTransService.svgScaleChange.subscribe((data: number) => {
            if (!data) {
                return;
            }
            this.svgScale = data.toFixed(1);
        });
        this.toolBarSetChangeSub = this.eventTransService.toolBarSetChange.subscribe((data: string) => {
            this.setType = data;
        });
    }

    /**
     * 工具栏点击事件
     */
    toolBarClick(event: OperateType) {
        this.eventTransService.toolBarFunc.next(event);
    }

    /**
     * 页面留白处理
     * @param num 留白大小
     */
    removeBlank(num) {
        this.canvasTool.removeBlank(num);
    }

    /**
     * svg画布缩放比例变更
     */
    scaleChange() {
        this.eventTransService.svgScaleChange.next(parseFloat(this.svgScale));
    }

    /**
     * 重置svg画布缩放比例
     */
    resetScale() {
        this.svgScale = '1';
        this.eventTransService.svgScaleChange.next(1.0);
    }

    /**
     * 组件过滤选择
     */
    filterSelChange() {
        let text = '';
        if (this.filterText) {
            text += '文本，';
        }
        if (this.filterLineHor) {
            text += '直线，';
        }
        if (this.filterLineVer) {
            text += '竖线，';
        }
        if (this.filterRect) {
            text += '矩形，';
        }
        if (this.filterCheckbox) {
            text += '复选框，';
        }
        if (this.filterInputText) {
            text += '文本输入框，';
        }
        if (this.filterInputNumber) {
            text += '数字输入框，';
        }
        if (text) {
            this.message.info(`已禁止批量选择组件：${text.substr(0, text.length - 1)}`);
        }
        CanvasCache.filterComType = {
            LineHor: this.filterLineHor,
            LineVer: this.filterLineVer,
            Text: this.filterText,
            Rect: this.filterRect,
            Checkbox: this.filterCheckbox,
            InputText: this.filterInputText,
            InputNumber: this.filterInputNumber
        };
    }
}
