import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzFormatBeforeDropEvent, NzFormatEmitEvent, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd/tree';
import { Observable, of, Subscription } from 'rxjs';
import { ProjectApi, TemplateApi } from 'src/app/api';
import { TemplateClassifyApi } from 'src/app/api/templateClassifyApi';
import { DataCache } from 'src/app/cache';
import { ImgType, MenuData } from 'src/app/const';
import { RecentUse } from 'src/app/_habit';
import { CommondAction } from 'src/app/_helpers/commondAction';
import { ContextMenuState, Module, Page, Template, TemplateClassify } from 'src/app/_models';
import { NodeType } from 'src/app/_models/nodeType';
import { Tree } from 'src/app/_models/tree';
import { EventTransService } from 'src/app/_services';
@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit, OnDestroy {
    gridStyle = {
        width: '33.3%',
        'text-align': 'center'
    };

    loadProjectSubscrite: Subscription;
    canvasDataChangeSub: Subscription;
    templateClassifySubscrite: Subscription;
    templateChangeSubscrite: Subscription;
    loadModuleSubscrite: Subscription;
    menuDataSubscrite: Subscription;
    editPageChangeSub: Subscription;
    editTemplateChangeSub: Subscription;

    /**
     * 加载工程
     */
    loadingProject = true;
    /**
     * 加载模板分类
     */
    loadingTemplateClassify = true;
    /**
     * 加载模板
     */
    loadingTemplate = false;

    /**
     * 工程树检索值
     */
    searchValue: string;

    @ViewChild('nzTree') nzTree: NzTreeComponent;
    /**
     * 工程树形数据
     */
    projectTree: any[];

    /**
     * 模板分类数据
     */
    templateClassifies: TemplateClassify[] = [];
    /**
     * key:模板分类id；
     * value：模板数据集
     */
    templateMap = new Map();
    /**
     * 最后点击的模板分类id
     */
    clickedTemplateClassifyId: string;

    /**
     * 选择的页面
     */
    selectedPage: Page;
    /**
     * 选择的模块
     */
    selectedModule: Module;

    /**
     * 选择的工程模板
     */
    selectedTemplate: Template;
    /**
     * 选择的工程模板分类
     */
    selectedTemplateClassify: TemplateClassify;

    /**
     * 右键菜单X位置
     */
    contextmenuX = 0;
    /**
     * 右键菜单Y位置
     */
    contextmenuY = 0;
    /**
     * 允许使用删除菜单
     */
    enableDeleteMenu = false;
    /**
     * 右键菜单状态
     */
    menuState: ContextMenuState;

    /**
     * 当前加载的页面id
     */
    loadPageId: string;
    /**
     * 当前加载的模板id
     */
    loadTemplateId: string;
    /**
     * 当前编辑的页面id集合
     */
    editPageIds = [];

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private projectApi: ProjectApi,
        private templateClassifyApi: TemplateClassifyApi,
        private templateApi: TemplateApi
    ) {
    }

    ngOnInit() {
        this.getProjectData();
        this.initSubscrite();
    }

    ngOnDestroy() {
        this.loadProjectSubscrite.unsubscribe();
        this.canvasDataChangeSub.unsubscribe();
        this.templateClassifySubscrite.unsubscribe();
        this.templateChangeSubscrite.unsubscribe();
        this.loadModuleSubscrite.unsubscribe();
        this.menuDataSubscrite.unsubscribe();
        this.editPageChangeSub.unsubscribe();
        this.editTemplateChangeSub.unsubscribe();
    }

    /**
     * 事件订阅
     */
    initSubscrite() {
        // 项目数据变更监听
        this.loadProjectSubscrite = this.eventTransService.loadProjectEvent.subscribe((data: string) => {
            if (data) {
                this.getProjectData(data);
            }
        });
        this.canvasDataChangeSub = this.eventTransService.canvasDataChange.subscribe((data: boolean) => {
            this.loadPageId = '';
            this.loadTemplateId = '';
            if (!data) {
                return;
            }
            if (DataCache.page) {
                this.loadPageId = DataCache.page.id;
            } else {
                this.loadTemplateId = DataCache.template.id;
            }
        });
        // 右键菜单状态变更监听
        this.menuDataSubscrite = this.eventTransService.menuStateChangeEvent.subscribe((data: ContextMenuState) => {
            this.menuState = data;
        });
        this.loadModuleSubscrite = this.eventTransService.loadModuleEvent.subscribe((data: string) => {
            this.getModuleData(data);
        });
        this.templateClassifySubscrite = this.eventTransService.templateClassifyChange.subscribe((data: boolean) => {
            if (data) {
                this.getTemplateClassifies();
            }
        });
        this.templateChangeSubscrite = this.eventTransService.templateChange.subscribe((id: string) => {
            if (!id) {
                return;
            }
            if (this.templateMap.has(id)) {
                this.templateMap.delete(id);
            }
            this.openTemplateClassify(true, id);
        });
        this.editPageChangeSub = this.eventTransService.editPageChange.subscribe((data: boolean) => {
            if (data) {
                this.editPageChange();
            }
        });
        this.editTemplateChangeSub = this.eventTransService.editTemplateChange.subscribe((data: boolean) => {
            if (data) {
                this.editTemplateChange();
            }
        });
    }

    /**
     * 加载工程
     * @param projectId 工程id
     */
    async getProjectData(id?: string) {
        this.editPageIds = [];
        if (id && DataCache.project && id !== DataCache.project.id) {
            this.eventTransService.loadCanvasDataEvent.next(null);
        }
        if (!id) {
            id = RecentUse.getProjectId();
        }
        DataCache.project = await this.projectApi.get(id);
        this.loadingProject = false;
        if (!DataCache.project) {
            this.loadingTemplateClassify = false;
            return;
        }
        RecentUse.setProjectId(DataCache.project.id);
        await this.initTree();
        this.eventTransService.projectChangeEvent.next(DataCache.project);
        this.eventTransService.projectThemeChange.next(true);

        this.getTemplateClassifies();
    }

    /**
     * 加载工程模块数据
     * @param id 模块id
     */
    async getModuleData(id) {
        if (!DataCache.project) {
            return;
        }

        if (!id) {
            this.getProjectData(DataCache.project.id);
            return;
        }

        const moduleNode = this.nzTree.getTreeNodeByKey(id);
        if (!moduleNode) {
            return;
        }

        const nodes: Tree[] = await this.projectApi.getNodesByModuleId(id);
        moduleNode.clearChildren();
        for (const node of nodes) {
            if (node.type === NodeType.Page || node.type === NodeType.Sys || node.type === NodeType.User) {
                moduleNode.addChildren([this.getPageNode(node, node.type)]);
            } else {
                const subModuleNode = this.getModuleNode(node);
                this.initSubTree(subModuleNode, node);
                moduleNode.addChildren([subModuleNode]);
            }
        }
        moduleNode.isExpanded = true;
    }

    /**
     * 组装工程树
     */
    async initTree() {
        const trees: Tree[] = await this.projectApi.getTree(DataCache.project.id);
        this.projectTree = [];
        for (const root of trees) {
            if (root.type === NodeType.Page || root.type === NodeType.Sys || root.type === NodeType.User) {
                this.projectTree.push(this.getPageNode(root, root.type));
            } else {
                const moduleNode = this.getModuleNode(root);
                this.initSubTree(moduleNode, root);
                this.projectTree.push(moduleNode);
            }
        }
    }

    /**
     * 组装工程树
     * @param rootNode 上级节点
     * @param root Tree
     */
    initSubTree(rootNode, root: Tree) {
        root.children = root.children.sort((a, b) => a.sort - b.sort);
        for (const tree of root.children) {
            if (tree.type === NodeType.Page || tree.type === NodeType.Sys || tree.type === NodeType.User) {
                rootNode.children.push(this.getPageNode(tree, tree.type, root.id));
            } else {
                const moduleNode = this.getModuleNode(tree, root.id);
                this.initSubTree(moduleNode, tree);
                rootNode.children.push(moduleNode);
            }
        }
    }

    /**
     * 组装模块节点
     * @param node Tree Node
     * @param parentId 上级节点id
     */
    getModuleNode(node: Tree, parentId?: string) {
        return {
            key: node.id,
            title: `${node.text}`,
            isLeaf: false,
            children: [],
            icon: 'group',
            self: node.self,
            type: node.type,
            expanded: false,
            parentId
        };
    }

    /**
     * 组装页面节点
     * @param node Tree node
     * @param type 页面类型
     * @param parentId 上级节点id
     */
    getPageNode(node: Tree, type: number, parentId?: string) {
        let iconTag = node.menu ? 'profile' : 'file';
        // if (DataCache.project.config && DataCache.project.config.homeId === node.id) {
        //     node.menu = true;
        // }
        return {
            key: node.id,
            title: `${node.text}(${node.name})`,
            isLeaf: true,
            icon: iconTag,
            self: node.self,
            type: node.type,
            parentId,
            menu: node.menu,
            isSys: type === NodeType.Sys,
            isUser: type === NodeType.User,
            edit: false
        };
    }

    /**
     * 树节点拖拽事件
     */
    beforeDrop(arg: NzFormatBeforeDropEvent): Observable<boolean> {
        return of(true);
    }

    /**
     * 树节点拖拽事件
     */
    onDrop(event: NzFormatEmitEvent): void {
        const data = [];
        let num = 0;
        for (const root of this.nzTree.getTreeNodes()) {
            num++;
            data.push({ id: root.key, sort: num, parent: '', isModule: NodeType.Module == root.origin.type });
            for (const sub of root.children) {
                num++;
                data.push({ id: sub.key, sort: num, parent: root.key, isModule: NodeType.Module == sub.origin.type });

                for (const leaf of sub.children) {
                    num++;
                    data.push({ id: leaf.key, sort: num, parent: sub.key, isModule: NodeType.Module == leaf.origin.type });
                }
            }
        }
        this.projectApi.setTreeSort(data);
    }

    /**
     * 模板拖拽事件
     */
    async dragTemplate(event: any, id: string) {
        this.eventTransService.dragDataEvent.next(null);
        // 模板内容不缓存，每次都从服务器获取最新的模板数据
        const template: Template = await this.templateApi.get(id);
        if (!template || !template.data || !template.data.content) {
            this.message.warning('模板数据获取失败！');
            return;
        }
        this.eventTransService.dragDataEvent.next({
            id,
            text: template.text,
            type: ImgType.Svg,
            content: template.data.content,
            isTemplate: true,
            source: template.data.source,
            event: template.data.event
        });
    }

    /**
     * 模板右键菜单
     * @param event 事件
     * @param data 模板
     */
    templateRightClick(event: any, data: Template) {
        event.stopPropagation();
        event.preventDefault();

        this.selectedTemplate = data;

        const x = event.x + 20;
        let y = event.y;

        if (y + MenuData.template > document.body.clientHeight) {
            y = y - MenuData.template;
        }
        if (y < 55) y = 55;
        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ Template: true });
    }

    /**
     * 工程模板标题右键
     * @param event 事件
     */
    templateTitleRightClick(event) {
        event.stopPropagation();
        event.preventDefault();

        const x = event.x + 20;
        let y = event.y;

        if (y + MenuData.templateTitle > document.body.clientHeight) {
            y = y - MenuData.templateTitle;
        }
        if (y < 55) y = 55;
        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ TemplateTitle: true });
    }

    /**
     * 工程模板分类右键
     * @param event 事件
     */
    templateClassifyRightClick(event, classify: TemplateClassify) {
        event.stopPropagation();
        event.preventDefault();

        const x = event.x + 20;
        let y = event.y;

        if (y + MenuData.templateClassify > document.body.clientHeight) {
            y = y - MenuData.templateClassify;
        }
        if (y < 55) y = 55;
        this.contextmenuX = x;
        this.contextmenuY = y;
        this.selectedTemplateClassify = classify;

        this.enableDeleteMenu = !this.templateMap.has(classify.id) || this.templateMap.get(classify.id).length <= 0;

        this.eventTransService.menuStateChangeEvent.next({ TemplateClassify: true });
    }

    /**
     * 工程模块/页面树列表右键菜单
     */
    treeRightClick(event: any): void {
        event.event.stopPropagation();
        if (!event.node) {
            return;
        }

        const x = event.event.x + 20;
        let y = event.event.y;
        const type = event.node.origin.type;

        if (type === NodeType.Page || type === NodeType.Sys || type === NodeType.User) {
            this.selectedPage = event.node.origin.self;
            if (y + MenuData.pageHeight > document.body.clientHeight) {
                y = y - MenuData.pageHeight;
            }
            if (y < 55) y = 55;

            this.contextmenuX = x;
            this.contextmenuY = y;

            this.eventTransService.menuStateChangeEvent.next({ Page: true });
            return;
        }

        this.selectedModule = event.node.origin.self;
        if (y + MenuData.moduleHeight > document.body.clientHeight) {
            y = y - MenuData.moduleHeight;
        }
        if (y < 55) y = 55;
        this.contextmenuX = x;
        this.contextmenuY = y;

        if (event.node.children.length > 0) {
            this.enableDeleteMenu = false;
        } else {
            this.enableDeleteMenu = true;
        }
        this.eventTransService.menuStateChangeEvent.next({ Module: true });
    }

    /**
     * 树节点单击事件
     */
    treeClick(event: any): void {
        this.eventTransService.menuStateChangeEvent.next({});
    }

    /**
     * 树节点双击事件
     * @param data 树节点
     */
    treeDbClick(data: NzTreeNode | Required<NzFormatEmitEvent>): void {
        if (data instanceof NzTreeNode) {
            data.isExpanded = !data.isExpanded;
            return;
        }
        const node = data.node;
        if (node) {
            node.isExpanded = !node.isExpanded;
            if (node.isLeaf) {
                node.isSelected = true;
                // 加载页面内容
                const page: Page = node.origin.self;
                if (CommondAction.isChange) {
                    this.showConfirm().then((res: boolean) => {
                        if (res) {
                            this.eventTransService.saveCanvasEvent.next(true);
                        } else {
                            this.loadPageData(page);
                        }
                    });
                } else {
                    this.loadPageData(page);
                }
            }
        }
    }

    showConfirm() {
        return new Promise((resolve) => {
            this.modalService.confirm({
                nzTitle: '当前页面尚未保存，是否保存?',
                nzOkText: '是',
                nzCancelText: '否',
                nzOnOk: () => {
                    resolve(true);
                }, nzOnCancel: () => {
                    resolve(false);
                }
            });
        });
    }

    /**
     * 工程模块/页面标题右键事件
     */
    pageTitleRightClick(event: any) {
        event.stopPropagation();
        event.preventDefault();

        const x = event.x + 20;
        let y = event.y;

        if (y + MenuData.pageTitleHeight > document.body.clientHeight) {
            y = y - MenuData.pageTitleHeight;
        }
        if (y < 55) y = 55;
        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ PageTitle: true });
    }

    /**
     * 加载页面数据
     * @param page 页面
     */
    loadPageData(page: Page) {
        this.eventTransService.loadCanvasDataEvent.next({
            id: page.id,
            data: page,
            isPage: true
        });
    }

    /**
     * 获取模板分类数据
     */
    async getTemplateClassifies() {
        this.templateClassifies = await this.templateClassifyApi.getAll(DataCache.project.id);
        this.loadingTemplateClassify = false;
        if (!this.templateClassifies || this.templateClassifies.length <= 0) {
            return;
        }
        for (const classify of this.templateClassifies) {
            if (this.templateMap.has(classify.id)) {
                classify.count = this.templateMap.get(classify.id).length;
            }
        }
    }

    /**
     * 折叠、展开模板分类
     * @param event 事件类型
     * @param id 分类id
     */
    async openTemplateClassify(event: boolean, id: string) {
        this.clickedTemplateClassifyId = id;
        if (!event || this.templateMap.has(id)) {
            return;
        }
        this.loadingTemplate = true;
        const templates: Template[] = await this.templateApi.getAll(id);
        const classify = this.templateClassifies.find(d => d.id === id);
        if (classify) {
            classify.count = templates.length;
        }
        if (templates.length > 0) {
            this.templateMap.set(id, templates);
        }
        this.loadingTemplate = false;
        this.editTemplateChange();
    }

    /**
     * 模板双击事件
     * @param id 模板id
     */
    templateDbClick(id: string) {
        if (CommondAction.isChange) {
            this.showConfirm().then((res: boolean) => {
                if (res) {
                    this.eventTransService.saveCanvasEvent.next(true);
                    return;
                } else {
                    this.editTemplate(id);
                }
            });
        } else {
            this.editTemplate(id);
        }
    }

    editTemplate(id: string) {
        this.eventTransService.loadCanvasDataEvent.next({
            id,
            isPage: false
        });
    }

    /**
     * 当前处于编辑状态的页面信息
     */
    editPageChange() {
        this.editPageIds.forEach(id => {
            const node = this.nzTree.getTreeNodeByKey(id);
            if (node) {
                node.origin.edit = false;
            }
        });

        this.editPageIds = [];
        const ids: [] = JSON.parse(JSON.stringify(DataCache.editPages));
        ids.forEach(id => {
            const node = this.nzTree.getTreeNodeByKey(id);
            if (node) {
                node.origin.edit = true;
                this.editPageIds.push(id);
            }
        });
    }

    /**
     * 当前处于编辑状态的模板信息
     */
    editTemplateChange() {
        const templates: Template[] = this.templateMap.get(this.clickedTemplateClassifyId);
        if (!templates) {
            return;
        }
        templates.forEach(d => {
            if (DataCache.editTemplates.indexOf(d.id) >= 0) {
                d.edit = true;
            } else {
                d.edit = false;
            }
        });
    }
}
