import { Component, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FindType, FindWhere } from '../_models';
/**
 * 工程模板另存为
 */
@Component({
    selector: 'app-find',
    template: `
        <span>查找内容：</span>
        <nz-radio-group [(ngModel)]="type" (ngModelChange)="typeChange()">
          <label nz-radio [nzValue]="findType.source">数据源编号</label>
          <label nz-radio [nzValue]="findType.id">组件id</label>
          <label nz-radio [nzValue]="findType.sourceJs">数据源脚本</label>
          <label nz-radio [nzValue]="findType.eventJs">事件脚本</label>
        </nz-radio-group>
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
            <input type="text" nz-input [(ngModel)]="content" placeholder="查找的内容" />
        </nz-input-group>
        <ng-template #addOnBeforeTemplate>
            <nz-select [(ngModel)]="where">
                <nz-option nzLabel="等于" [nzValue]="findWhere.equal"></nz-option>
                <nz-option nzLabel="包含" [nzValue]="findWhere.like"></nz-option>
                <nz-option nzLabel="开头" [nzValue]="findWhere.start"></nz-option>
                <nz-option nzLabel="结尾" [nzValue]="findWhere.end"></nz-option>
            </nz-select>
        </ng-template>
    `,
    styles: [
        `
            nz-input-group{
                width:100%;
                margin-top:10px;
            }
            input{
                width:80%;
            }
        `
    ]
})
export class FindComponent implements OnDestroy {
    findType = FindType;
    findWhere = FindWhere;
    /**
     * 替换内容
     */
    type = FindType.source;
    where = FindWhere.equal;
    content = '';

    constructor(private modal: NzModalRef, private message: NzMessageService
    ) { }

    /**
     * 保存
     */
    save(): any {
        return new Promise(resolve => {
            if (!this.content) {
                this.message.warning(`请输入查找内容！`);
                resolve(false);
                return;
            }

            resolve({
                type: this.type,
                where: this.where,
                content: this.content,
            });
        });
    }

    typeChange() {
        if (this.type === FindType.id || this.type == FindType.source) {
            this.where = FindWhere.equal;
        } else {
            this.where = FindWhere.like;
        }
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
