<nz-tabset>
  <nz-tab nzTitle="基本设置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="content" [nzOffsetTop]="20">
        <nz-link nzHref="#type" nzTitle="类型"></nz-link>
        <nz-link nzHref="#dataSource" nzTitle="数据源"></nz-link>
        <nz-link nzHref="#sampling" nzTitle="降采策略"></nz-link>
        <nz-link nzHref="#time" nzTitle="时间配置"></nz-link>
        <nz-link nzHref="#interval" nzTitle="数据间隔"></nz-link>
        <nz-link nzHref="#autoRefresh" nzTitle="自动刷新"></nz-link>
        <nz-link nzHref="#monthYear" nzTitle="同环比"></nz-link>
        <nz-link nzHref="#highlight" nzTitle="高亮区域"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #content>
      <div id='type'>
        <span>类型:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.type" class="typeRadio">
            <label nz-radio nzValue="base">基础折线图</label>
            <label nz-radio nzValue="smooth">基础平滑折线图</label>
            <label nz-radio nzValue="area">面积图</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='dataSource'>
        <span>数据源:</span>
        <div class="left">
          <div class="dataSource">
            <label nz-checkbox nz-tooltip nzTooltipTitle="动态数据源，需要在全局数据脚本中定义对应变量" [(ngModel)]="dataSource.dynamic"
              style="width: 80px;">动态</label>
            <nz-select [(ngModel)]="dataSource.type" style="width: 150px;">
              <nz-option nzValue="build" nzLabel="建筑"></nz-option>
              <nz-option nzValue="dept" nzLabel="分户"></nz-option>
              <nz-option nzValue="account" nzLabel="账套"></nz-option>
              <nz-option nzValue="device" nzLabel="设备"></nz-option>
              <nz-option nzValue="param" nzLabel="设备参数"></nz-option>
              <nz-option nzValue="formula" nzLabel="能耗关系"></nz-option>
            </nz-select>
            <ng-container [(ngSwitch)]="dataSource.type">
              <div *ngSwitchCase="'build'" style="display: flex;">
                <nz-input-group nzAddOnBefore="建筑id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'dept'" style="display: flex;">
                <nz-input-group nzAddOnBefore="分户id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'account'" style="display: flex;">
                <nz-input-group nzAddOnBefore="账套id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="能耗编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'device'" style="display: flex;">
                <nz-input-group nzAddOnBefore="设备编码">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="参数类型">
                  <input nz-input class="input" [(ngModel)]="dataSource.param" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'param'" style="display: flex;">
                <nz-input-group nzAddOnBefore="参数id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
              </div>
              <div *ngSwitchCase="'formula'" style="display: flex;">
                <nz-input-group nzAddOnBefore="关系id">
                  <input nz-input class="input" [(ngModel)]="dataSource.code" />
                </nz-input-group>
              </div>
            </ng-container>
            <button nz-button nzType="default" (click)="addDataSource()">添加</button>
          </div>
          <div class="dataSource-list">
            <nz-select [(ngModel)]="selDataSourceId" style="width: 90%;" (ngModelChange)="selDataSource()">
              <nz-option [(nzValue)]="item.id" [nzLabel]="getDataSourceDesc(item)"
                *ngFor="let item of prop.dataSources">
              </nz-option>
            </nz-select>
            <button nz-button nzType="default" nzDanger (click)="removeDataSource()">删除</button>
          </div>
        </div>
      </div>

      <div id='sampling'>
        <span>降采策略:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.sampling" style="width: 100%;">
            <label nz-radio nzValue="">关闭</label>
            <label nz-radio nzValue="lttb" nz-tooltip nzTooltipTitle="大数据渲染效率提升，但可能会导致极值、平均值存在偏差">开启</label>
          </nz-radio-group>
        </div>
      </div>

      <div id='time'>
        <span>时间配置:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.timeConfig.enable" style="width: 100%;">启用</label>
          <nz-radio-group [(ngModel)]="prop.timeConfig.timeType" style="width: 100%;">
            <label nz-radio nzValue="day">本日</label>
            <label nz-radio nzValue="two_day">近两日</label>
            <label nz-radio nzValue="week">本周</label>
            <label nz-radio nzValue="month">本月</label>
            <label nz-radio nzValue="season">本季度</label>
            <label nz-radio nzValue="year">本年</label>
            <label nz-radio nzValue="other">其它</label>
          </nz-radio-group>

          <div *ngIf="checkType(prop.timeConfig.timeType)" style="display: flex;margin-bottom: 10px;">
            <nz-input-group nzAddOnBefore="近" style="width: auto;">
              <nz-input-number [(ngModel)]="prop.timeConfig.otherTimeNum" [nzMin]="1" [nzMax]="10000" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
            <nz-select [(ngModel)]="prop.timeConfig.otherTimeType" style="width: 100px;">
              <nz-option nzValue="minute" nzLabel="分钟"></nz-option>
              <nz-option nzValue="hour" nzLabel="小时"></nz-option>
              <nz-option nzValue="day" nzLabel="日"></nz-option>
              <nz-option nzValue="week" nzLabel="周"></nz-option>
              <nz-option nzValue="month" nzLabel="月"></nz-option>
            </nz-select>
          </div>

          <div>
            <label nz-checkbox [(ngModel)]="prop.timeConfig.showPreNext">显示上下周期</label>
            <nz-input-group nzAddOnBefore="默认周期" style="width: 200px;">
              <nz-input-number [(ngModel)]="prop.timeConfig.index" [nzMin]="-100" [nzMax]="0" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
          </div>
          <div>
            <label nz-checkbox [(ngModel)]="prop.timeConfig.enableCompare">显示对比周期</label>
            <nz-input-group nzAddOnBefore="默认周期" style="width: 200px;">
              <nz-input-number [(ngModel)]="prop.timeConfig.compareIndex" [nzMin]="-100" [nzMax]="0" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
          </div>
        </div>
      </div>
      <div id="interval">
        <span>数据间隔:</span>
        <div class="left">
          <nz-input-group nzAddOnBefore="数据展示粒度(秒)" style="margin-top:3px;">
            <nz-input-number [(ngModel)]="prop.interval" [nzMin]="0" [nzMax]="3600" [nzStep]="1" nz-tooltip
              nzTooltipTitle="0表示不处理，按照数据真实粒度展示">
            </nz-input-number>
          </nz-input-group>
        </div>
      </div>
      <div id='autoRefresh'>
        <span>自动刷新:</span>
        <div class="left">
          <nz-input-group nzAddOnBefore="刷新周期(秒)" style="margin-top:3px;">
            <nz-input-number [(ngModel)]="prop.refreshCycle" [nzMin]="0" [nzMax]="600" [nzStep]="1" nz-tooltip
              nzTooltipTitle="0表示不启用">
            </nz-input-number>
          </nz-input-group>
        </div>
      </div>
      <div id='monthYear'>
        <span>同环比:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.yearMonthConfig.showMonth">显示环比</label><br>
          <label nz-checkbox [(ngModel)]="prop.yearMonthConfig.showYear">显示同比</label>
        </div>
      </div>
      <div id="highlight">
        <span>高亮区域:</span>
        <div class="left">
          <div *ngFor="let hl of prop.highLights;">
            <nz-time-picker [ngModel]="hl.start|timePipe" (ngModelChange)="setStart($event,hl)" [nzAllowEmpty]="false"
              nzFormat="HH:mm" nzNowText=" " [nzMinuteStep]="10">
            </nz-time-picker>
            <span> ~ </span>
            <nz-time-picker [ngModel]="hl.end|timePipe" (ngModelChange)="setStop($event,hl)" [nzAllowEmpty]="false"
              nzFormat="HH:mm" nzNowText=" " [nzMinuteStep]="10">
            </nz-time-picker>
            <nz-input-group [nzAddOnBefore]="'名称'" style="width: 132px;margin-top:4px;">
              <input type="text" nz-input [(ngModel)]="hl.name" />
            </nz-input-group>
            <input style="margin-left: 10px;" type="color" [(ngModel)]="hl.color" />
            <button style="margin-left: 10px;" nz-button nzDanger (click)="deleteHighLight(hl.id)"><i nz-icon
                nzType="delete" nzTheme="outline"></i></button>
            <nz-divider></nz-divider>
          </div>
          <button nz-button nzType="primary" (click)="addHighLight()"><i nz-icon nzType="plus"
              nzTheme="outline"></i></button>
        </div>
      </div>
    </div>
  </nz-tab>
  <nz-tab nzTitle="图表显示配置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <nz-link nzHref="#title" nzTitle="图表标题"></nz-link>
        <nz-link nzHref="#legend" nzTitle="图例"></nz-link>
        <nz-link nzHref="#dataZoom" nzTitle="区域缩放"></nz-link>
        <nz-link nzHref="#nulls" nzTitle="空值处理"></nz-link>
        <nz-link nzHref="#maxMin" nzTitle="最大最小值"></nz-link>
        <nz-link nzHref="#avg" nzTitle="平均值"></nz-link>
        <nz-link nzHref="#toolbox" nzTitle="工具箱"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <div id='title'>
        <span>图表标题:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.titleConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.titleConfig.position">
            <label nz-radio nzValue="top_left">左侧</label>
            <label nz-radio nzValue="top">居中</label>
          </nz-radio-group>
          <br>
          <label>标题内容：</label>
          <label nz-checkbox [(ngModel)]="prop.titleConfig.showTimeRange">时间区域</label>
          <nz-input-group nzAddOnBefore="特定内容">
            <input nz-input [(ngModel)]="prop.titleConfig.text" />
          </nz-input-group>
        </div>
      </div>
      <div id='legend'>
        <span>图例:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.legendConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.legendConfig.position">
            <label nz-radio nzValue="top">上</label>
            <label nz-radio nzValue="bottom">下</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='dataZoom'>
        <span>区域缩放:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.dataZoomConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.dataZoomConfig.position">
            <label nz-radio nzValue="top">上</label>
            <label nz-radio nzValue="bottom">下</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='nulls'>
        <span>空值处理:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.connectNulls">连接空值</label>
        </div>
      </div>
      <div id='maxMin'>
        <span>最大最小值:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.maxMinConfig">
            <label nz-radio nzValue="none">不显示</label><br>
            <label nz-radio nzValue="all">全部显示</label><br>
            <label nz-radio nzValue="smart">智能显示(<mark>所有数据源中的最大最小值</mark>)</label>
          </nz-radio-group>
        </div>
      </div>
      <div id='avg'>
        <span>平均值:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.avgConfig">
            <label nz-radio nzValue="none">不显示</label><br>
            <label nz-radio nzValue="all">显示</label><br>
          </nz-radio-group>
        </div>
      </div>
      <div id='toolbox'>
        <span>工具箱:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.saveAsImg">保存为图片</label><br>
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.showArea">区域选择</label><br>
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.showDataView">数据视图</label><br>

          <div *ngIf="prop.toolBoxConfig.showDataView">
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showData">显示图表数据</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showMax">显示最大值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showMin">显示最小值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showAvg">显示平均值</label>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
