import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CanvasCache, DataCache } from 'src/app/cache';
import { CanvasConstData, ComponentType, DataAttrName } from 'src/app/const';
import { EventTransService } from 'src/app/_services';

declare var $: any;
declare var subjx: any;
/**
 * 事件处理
 */
@Injectable({ providedIn: 'root' })
export class CommonFunc {

    guideLine = { s1: null, s2: null, s3: null, s4: null };
    guideText: any;

    constructor(
        private message: NzMessageService,
        private eventTransService: EventTransService
    ) { }

    /**
     * 设置组件id
     */
    setId(dom) {
        const id = dom.attr('id');
        if (id) {
            return;
        }

        const type = 'p';

        const ids = CanvasCache.svg.selectAll('[id^="' + type + '"]');
        let idx = 1;

        for (const item of ids.items) {
            const idStr = item.attr('id');
            if (!idStr) {
                continue;
            }
            const id = parseInt(idStr.replace(type, ''), 10);
            if (id > idx) {
                idx = id;
            }
        }
        idx++;
        dom.attr('id', type + idx);
    }

    /**
     * 获取选中对象的数量
     */
    getSelectedSize(): number {
        if (!CanvasCache.obs || !CanvasCache.obs.observers ||
            !CanvasCache.obs.observers.onapply || CanvasCache.obs.observers.onapply.length <= 0) {
            return 0;
        }
        return CanvasCache.obs.observers.onapply.length;
    }

    /**
     * 获取选中的对象集合
     * sort 是否按照真实顺序排序
     */
    getSelectedDoms(sort = false) {
        if (this.getSelectedSize() === 0) {
            return [];
        }
        const doms = CanvasCache.obs.observers.onapply;
        if (!sort || doms.length <= 1) {
            return doms;
        }
        const domMap = new Map();
        doms.forEach((item: any) => {
            let id = $(item.el).attr('id');
            if (!id) {
                this.setId($(item.el));
                id = $(item.el).attr('id');
            }
            domMap.set(id, item);
        });
        const sortDoms = [];
        CanvasCache.svg.children().forEach((item: any) => {
            // 排除网格标签
            if (this.isDataNode(item)) {
                const id = item.attr('id');
                if (domMap.has(id)) {
                    sortDoms.push(domMap.get(id));
                }
            }
        });
        return sortDoms;
    }

    /**
     * 清除所有选中效果
     */
    clearSelected() {
        if (this.getSelectedSize() === 0) {
            return;
        }
        for (let i = CanvasCache.obs.observers.onapply.length - 1; i >= 0; i--) {
            CanvasCache.obs.observers.onapply[i].disable();
        }
    }

    /**
     * 是否需要缩放
     */
    isNeedScale(dom) {
        let neeScale = false;
        const types = ['text', 'rect', 'line', 'path', 'ellipse', 'foreignobject', 'image', 'circle'];
        for (const item of dom.children) {
            const type = item.nodeName.toLowerCase();
            if (type.indexOf('#') >= 0) {
                continue;
            }
            if (types.indexOf(type) < 0) {
                neeScale = true;
                break;
            }
        }
        return neeScale;
    }

    /**
     * 是否数据节点（排除网格、无效节点、操作框）
     */
    isDataNode(item) {
        if (item.type !== 'g') {
            return false;
        }
        if (item.node.id === 'canvasGrid' || item.node.id === 'showRoate') {
            return false;
        }
        if (item.hasClass(CanvasConstData.operateClass)) {
            return false;
        }
        return true;
    }

    isUsefulNode(item) {
        if (item.type.indexOf('#') >= 0) {
            return false;
        }
        if (item.node.id === 'canvasGrid' || item.node.id === 'showRoate') {
            return false;
        }
        if (item.hasClass(CanvasConstData.operateClass)) {
            return false;
        }
        return true;
    }

    /**
     * 获取参考组件
     */
    getRefeDom(): any {
        return this.getSelectedDoms()[this.getSelectedDoms().length - 1];
    }

    /**
     * 添加操作边框
     * @param dom 组件对象
     */
    setTransform(dom) {
        const that = this;
        const methods = {
            onInit(el) {
                $(el).data('drag', this);
                const $controls = subjx(this.controls);
                $controls.on('mousedown', e => {
                    e.stopPropagation();
                });
                $controls.on('click', e => {
                    e.stopPropagation();
                });
            },
            onMove({ clientX, clientY, dx, dy, transform }) {
                that.showGuideLine($(this.el).data('dom'));
            },
            onResize({ clientX, clientY, dx, dy, transform, width, height }) {
                that.showGuideLine($(this.el).data('dom'));
            },
            onRotate({ clientX, clientY, delta, transform }) {
                that.showGuideLine($(this.el).data('dom'));
            },
            onDrop(e) {
                that.removeGuideLine();
                that.eventTransService.recordActionEvent.next(true);
            }
        };
        const scalable = this.isNeedScale(dom);
        const option = {
            // 显示旋转中心点
            rotationPoint: false,
            // 允许旋转
            rotatable: true,
            // 允许缩放
            resizable: true,
            // 只缩放根节点（开启后，缩放矩形边框大小会变化）
            scalable,
            each: {
                // 允许一起移动
                move: true,
            },
            snap: {
                x: 0,
                y: 0,
                angle: 0
            },
            cursorMove: 'move',
            cursorRotate: 'crosshair',
            cursorResize: 'pointer',
            ...methods
        };
        subjx(dom).drag(option, CanvasCache.obs);
    }

    /**
     * 显示辅助线
     */
    showGuideLine(dom) {
        if (!CanvasCache.showGuideLine) {
            CanvasCache.showGuideLine = true;
            return;
        }
        this.initGuideLine();

        const bbox = dom.getBBox();

        this.guideLine.s1.attr('d', `M${bbox.x - 1000} ${bbox.y}L${bbox.x + 1000} ${bbox.y}`);
        this.guideLine.s2.attr('d', `M${bbox.x} ${bbox.y - 1000}L${bbox.x} ${bbox.y + 1000}`);
        this.guideLine.s3.attr('d', `M${bbox.x + bbox.width} ${bbox.y - 1000}L${bbox.x + bbox.width} ${bbox.y + 1000}`);
        this.guideLine.s4.attr('d', `M${bbox.x - 1000} ${bbox.y + bbox.height}L${bbox.x + 1000} ${bbox.y + bbox.height}`);

        this.guideText.attr('x', bbox.x);
        this.guideText.attr('y', bbox.y - 4);
        this.guideText.node.textContent = `W: ${bbox.width.toFixed(1)} H: ${bbox.height.toFixed(1)}`;
    }

    /**
     * 移除辅助线
     */
    removeGuideLine() {
        if (this.guideLine.s1 == null) {
            return;
        }
        Object.keys(this.guideLine).forEach((key: any) => {
            this.guideLine[key].remove();
            this.guideLine[key] = null;
        })
        if (this.guideText) {
            this.guideText.remove();
            this.guideText = null;
        }
    }

    /**
     * 初始化辅助线
     */
    initGuideLine() {
        if (this.guideLine.s1 != null) {
            return;
        }
        Object.keys(this.guideLine).forEach((key: any) => {
            this.guideLine[key] = CanvasCache.svg.paper.path(`M0 0`).attr({
                stroke: '#a1a1a1',
                fill: 'rgba(177,196,239,0)',
                'stroke-dasharray': '3px',
                strokeWidth: 1
            });
        });
        this.guideText = CanvasCache.svg.paper.text(0, 0, 'W: 0 H: 0').attr('fill', '#fcfcfca3');
    }

    /**
     * 显示旋转角度
     * @param that this
     * @param x x
     * @param y y
     * @param transform transform
     */
    showRoateDeg(that, x, y, transform) {
        const aa = Math.round(180 * Math.asin(transform.a) / Math.PI);
        const bb = Math.round(180 * Math.acos(transform.b) / Math.PI);
        const cc = Math.round(180 * Math.asin(transform.c) / Math.PI);
        const dd = Math.round(180 * Math.acos(transform.d) / Math.PI);
        let deg = 0;
        if (aa === bb || -aa === bb) {
            deg = dd;
        } else if (-aa + bb === 180) {
            deg = 180 + cc;
        } else if (aa + bb === 180) {
            deg = 360 - cc || 360 - dd;
        }
        deg = deg >= 360 ? 0 : deg;

        $('#showRoate text').attr('x', x);
        $('#showRoate text').attr('y', y);
        $('#showRoate text').text(deg);
        that.showRoate = true;
    }

    /**
     * 获取所选组件的水平中心位置
     */
    getSelectedMiddleX(): number {
        let oriMin = 99999;
        let oriMax = -1;
        for (const item of this.getSelectedDoms()) {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const min = bbox.x;
                const max = bbox.x + bbox.width;
                if (min < oriMin) {
                    oriMin = min;
                }
                if (max > oriMax) {
                    oriMax = max;
                }
            }
        }
        return (oriMax - oriMin) / 2 + oriMin;
    }

    /**
     * 获取所选组件的水平中心位置
     */
    getSelectedMiddleY(): number {
        let min = 99999;
        let max = -1;
        for (const item of this.getSelectedDoms()) {
            const dom = $(item.el).data('dom');
            const bbox = dom.getBBox();
            if (bbox.width > 0 || bbox.height > 0) {
                const tmin = bbox.y;
                const tmax = bbox.y + bbox.height;
                if (tmin < min) {
                    min = tmin;
                }
                if (tmax > max) {
                    max = tmax;
                }
            }
        }
        return (max - min) / 2 + min;
    }

    /**
     * 组件id替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceId(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            let id = dom.attr('id');
            if (!id) {
                return true;
            }
            if (id === oldStr) {
                id = id.replace(new RegExp(oldStr, 'gm'), newStr);
                dom.attr('id', id);
                count++;
            }
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[文本] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 文本内容替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceText(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const arrs = dom.selectAll('text');
            if (!arrs || arrs.length === 0) {
                return true;
            }
            arrs.forEach(text => {
                if (text.attr('text') && text.attr('text').indexOf(oldStr) >= 0) {
                    text.attr('text', text.attr('text').replace(new RegExp(oldStr, 'gm'), newStr));
                    count++;
                }
            });
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[文本] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 数据源编号替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceSource(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const dsId = dom.attr(DataAttrName.source);
            if (!dsId) {
                return true;
            }
            const ds = DataCache.pageData.sources.find(d => d.id === dsId);
            if (!ds || !ds.code) {
                return true;
            }
            if (ds.code.indexOf(oldStr) >= 0) {
                ds.code = ds.code.replace(new RegExp(oldStr, 'gm'), newStr);
                count++;
            }
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[数据源编号] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 变量类型替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceParamType(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const arrs = dom.selectAll(`[${DataAttrName.meterType}]`);
            if (!arrs || arrs.length === 0) {
                return true;
            }
            arrs.forEach(mt => {
                let attr = mt.attr(DataAttrName.meterType);
                if (attr && attr.indexOf(oldStr) >= 0) {
                    mt.attr(DataAttrName.meterType, attr.replace(new RegExp(oldStr, 'gm'), newStr));
                    count++;
                }
            });
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[变量类型] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 数据源脚本内容替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceSourceJs(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const dsId = dom.attr(DataAttrName.source);
            if (!dsId) {
                return true;
            }
            const ds = DataCache.pageData.sources.find(d => d.id === dsId);
            if (!ds || !ds.code || !ds.action) {
                return true;
            }
            if (ds.action.indexOf(oldStr) >= 0) {
                ds.action = ds.action.replace(new RegExp(oldStr, 'gm'), newStr);
                count++;
            }
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[数据源脚本] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 事件脚本内容替换
     * @param oldStr 待替换的内容
     * @param newStr 新内容
     */
    replaceEvent(oldStr: string, newStr: string) {
        let count = 0;
        this.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            const deId = dom.attr(DataAttrName.event);
            if (!deId) {
                return true;
            }
            const de = DataCache.pageData.events.find(d => d.id === deId);
            if (!de || !de.action) {
                return true;
            }
            if (de.action.indexOf(oldStr) >= 0) {
                de.action = de.action.replace(new RegExp(oldStr, 'gm'), newStr);
                count++;
            }
        });
        if (count > 0) {
            CanvasCache.isOperateChange = true;
        }
        this.message.success(`[事件脚本] 替换完成，共计替换：${count} 个！`);
    }

    /**
     * 获取画布大小
     */
    getCanvasSize() {
        const w = CanvasCache.svg.node.style.width.replace('px', '');
        const h = CanvasCache.svg.node.style.height.replace('px', '');
        return { w, h };
    }

    getHalfEmptyCanvas(width?, height?) {
        if (!width || !height) {
            const { w, h } = this.getCanvasSize();
            width = w;
            height = h;
        }
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            id='${CanvasCache.svg.attr('id')}'
            style='background:${CanvasCache.svg.node.style.background};
            background-color:${CanvasCache.svg.node.style.backgroundColor};
            background-size:100% 100%;'
            ${DataAttrName.bgOpacity}=${CanvasCache.svg.attr(DataAttrName.bgOpacity)}
            viewBox='0 0 ${width} ${height}'>`;
    }

    getSimpleHaflCanvas(width?, height?) {
        if (!width || !height) {
            const { w, h } = this.getCanvasSize();
            width = w;
            height = h;
        }
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox='0 0 ${width} ${height}'>`;
    }

    getEmptyCanvas(width?, height?) {
        return `${this.getHalfEmptyCanvas(width, height)}</svg>`;
    }

    /**
     * 获取SVG画布数据
     */
    getCanvasData(isPage = true, width?, height?) {
        const header = isPage ? this.getHalfEmptyCanvas(width, height) : this.getSimpleHaflCanvas(width, height);

        let data = '';
        let defs = '';
        CanvasCache.svg.children().forEach((item: any) => {
            if (!this.isUsefulNode(item)) {
                return true;
            }

            if (item.type === 'g' || item.type.indexOf('animate') >= 0) {
                data += item.toString();
            }
            if (item.type === 'defs') {
                item.children().forEach((ele: any) => {
                    if (ele.type === 'g') {
                        defs += ele.toString();
                    }
                });
            }
        });
        if (defs) {
            defs = '<defs>' + defs + '</defs>';
        }

        data = header + defs + data + '</svg>';
        CanvasCache.canvasContent = data;

        return data;
    }

    /**
     * 清空画布
     */
    clearCanvas() {
        CanvasCache.svg.children().forEach((item: any) => {
            if (item.type.indexOf('#') >= 0 || item.type === 'g' && item.node.id === 'canvasGrid') {
                return true;
            }
            item.remove();
        });
        return true;
    }

    /**
     * 是否过滤组件
     * @param id dom data-attr-id
     * @returns boolean
     */
    isFilterDom(id: string): boolean {
        return CanvasCache.filterComType.LineHor && id === CanvasConstData.LINE_HOR_ID ||
            CanvasCache.filterComType.LineVer && id === CanvasConstData.LINE_VER_ID ||
            CanvasCache.filterComType.Text && id === CanvasConstData.TEXT_ID ||
            CanvasCache.filterComType.Rect && id === CanvasConstData.RECT_ID ||
            CanvasCache.filterComType.Checkbox && id === CanvasConstData.CHECKBOX_ID ||
            CanvasCache.filterComType.InputNumber && id === CanvasConstData.INPUT_NUMBER_ID ||
            CanvasCache.filterComType.InputText && id === CanvasConstData.INPUT_TEXT_ID;
    }
}
