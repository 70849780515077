<div class="main">
    <div class="desc">
        <span class="title">获取<span class="mark">当前选择的时间</span>:</span>
        <div class="code">
            <div class="remark">返回结果示例：02:30</div>
            <div>
                var time = <@p2.*>.value();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">设置控件默认时间</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value('03:10');
            </div>
        </div>
    </div>

</div>