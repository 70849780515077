
/**
 * 用户数据
 */
export class User {
    /**
     * 用户token
     */
    token: string;
    /**
     * 过期时间
     */
    expiratime: Date;
    /**
     * 当前用户名
     */
    name: string;
}
