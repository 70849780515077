import { Component, Input, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ComClassify } from '../_models';
import { EventTransService } from '../_services';
import { ComponentApi } from '../api';

/*组件上传 */
@Component({
    selector: 'app-file-upload',
    template: `
     <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="true"
            [nzLimit]="100"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="fileList">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p class="ant-upload-hint">支持的文件类型：{{fileTypeName}}</p>
        </nz-upload>
    </cdk-virtual-scroll-viewport>
    `,
    styles: [
        `.infinite-container{
            min-height:275px;
            max-height: 500px;
        }`
    ]
})
export class FileUpLoadComponent implements OnDestroy {
    @Input() classify: ComClassify;
    @Input() projectId: string;
    @Input() templateClassifyID = '';
    @Input() fileType = 'image/svg+xml,image/png,image/jpg,image/jpeg,image/bmp,image/gif,image/x-icon';
    @Input() fileTypeName = 'svg、png、jpg、jpeg、bmp、gif、ico';
    fileList: NzUploadFile[] = [];
    fileListBak: NzUploadFile[] = [];

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private componentApi: ComponentApi
    ) { }

    beforeUpload = (file: NzUploadFile): boolean => {
        if (this.fileList.length >= 100) {
            this.message.warning('批量上传文件数量不能超过100！');
            return false;
        }
        if (!file.size || file.size / 1024 / 1024 >= 15) {
            this.message.warning('文件过大，不能超过15MB ！');
            return false;
        }
        this.fileList = this.fileList.concat(file);
        return false;
    }

    /**
     * 组件上传
     */
    save(): any {
        if (!this.fileList || this.fileList.length === 0) {
            this.message.warning('请先添加需要上传的组件！');
            return false;
        }
        if (this.classify.count && this.classify.count >= 100) {
            this.message.warning('单个分类下组件数不能超过100！');
            return false;
        }
        this.fileListBak = Object.assign([], this.fileList);
        this.fileListBak.forEach(async (file) => {
            this.componentApi.upload(file, this.classify.id).then((ret) => {
                if (ret) {
                    this.fileList = this.fileList.filter(d => d.name !== file.name);
                    this.message.success(`文件：${file.name}上传成功！`);
                    if (this.fileList.length === 0) {
                        this.eventTransService.classifyDataChangeEvent.next(this.classify.id);
                    }
                }
            });
        });
        return false;
    }

    /**
     * 工程模板上传
     */
    saveTemplate(): any {
        if (!this.fileList || this.fileList.length === 0) {
            this.message.warning('请先添加需要上传的模板！');
            return false;
        }
        this.fileListBak = Object.assign([], this.fileList);
        this.fileListBak.forEach((file) => {
            // this.http.uploadFile('/LeftNav/ImportTemplate', file, {
            //     ProjectID: this.projectId,
            //     ParentID: this.templateClassifyID
            // }).then(res => {
            //     this.fileList = this.fileList.filter(d => d.name !== file.name);
            //     this.message.success(`文件：${file.name}上传成功！`);
            //     if (this.fileList.length === 0) {
            //         this.eventTransService.projectTemplatesChange.next(this.projectId);
            //     }
            // });
        });
        return false;
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
