<div class="root">
  <nz-divider nzDashed [nzText]="datasouceSel">
    <ng-template #datasouceSel>数据配置</ng-template>
  </nz-divider>
</div>

<div class="root">
  <div nz-row style="display: block;">
    <div nz-col nzSpan="24">
      <nz-input-group [nzSuffix]="addOnAfterDevice">
        <input nz-input spellcheck="false" placeholder="请输入设备编号" [(ngModel)]="devCode"
          (ngModelChange)="onDataSourceChange($event)" />
      </nz-input-group>
      <ng-template #addOnAfterDevice>
        <label nz-checkbox [(ngModel)]="isDynamic" class="dynamicLabel" nz-tooltip
          (ngModelChange)="onDataSourceChange($event)" nzTooltipTitle="注意：动态编号需要在全局脚本中定义">动态</label>
      </ng-template>
    </div>
  </div>
  <div nz-row style="margin-top: 5px;" *ngIf="selectedElement">
    <nz-input-group nzAddOnBefore="参数类型">
      <input type="text" nz-input spellcheck="false" [(ngModel)]="paramTypeCode" (ngModelChange)="setParam()"
        (blur)="paramChange()" />
    </nz-input-group>
  </div>
</div>

<div class="root">
  <nz-divider nzDashed [nzText]="dividerAction">
    <ng-template #dividerAction>数据变化执行动作</ng-template>
  </nz-divider>
  <div nz-row>
    <div class="code-tool">
      <span nz-tooltip nzTooltipTitle="格式化" class="iconfont iconmeihuadaima format" (click)="formatCode()" n></span>
      <i nz-icon nzType="fullscreen" nzTheme="outline" nz-tooltip nzTooltipTitle="最大化" class="full"
        (click)="setToFull($event)"></i>
    </div>
    <code-input ngDefaultControl lang="javascript" style="width: 100%;border: 1px solid;" [(ngModel)]="action"
      (ngModelChange)="onActionChange($event)" [style.border-color]="codeBorderColor"></code-input>
  </div>
</div>