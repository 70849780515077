<div nz-row style="margin-top: 10px;">
  <div nz-col nzSpan="11">
    <nz-select [(ngModel)]="selMeterType" style="width: 100%;">
      <nz-option [nzValue]="item" [nzLabel]="item" *ngFor="let item of meterTypes"></nz-option>
    </nz-select>
  </div>
  <div nz-col nzSpan="10">
    <nz-select [(ngModel)]="selFuncName" style="width: 100%;">
      <nz-option-group nzLabel="通用事件">
        <nz-option [nzValue]="item.name" [nzLabel]="item.desc" *ngFor="let item of commonEvents"></nz-option>
      </nz-option-group>
      <nz-option-group nzLabel="组件事件" *ngIf="componentEvents.length>0">
        <nz-option [nzValue]="item.name" [nzLabel]="item.desc" *ngFor="let item of componentEvents"></nz-option>
      </nz-option-group>
    </nz-select>
  </div>
  <div nz-col nzSpan="3">
    <button nz-button nzType="default" nzShape="circle" (click)="createEvent($event)" nz-tooltip nzTooltipTitle="生成事件">
      <i nz-icon nzType="plus" nzTheme="outline"></i></button>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24" class="parent">
    <div class="code-tool">
      <span nz-tooltip nzTooltipTitle="格式化" class="iconfont iconmeihuadaima format" (click)="formatCode()"
        n></span>
      <i nz-icon nzType="fullscreen" nzTheme="outline" nz-tooltip nzTooltipTitle="最大化" class="full"
        (click)="setToFull($event)"></i>
    </div>
    <code-input ngDefaultControl lang="javascript" style="width: 100%;border: 1px solid;"
      [(ngModel)]="action" (ngModelChange)="onEventChange($event)" [style.border-color]="codeBorderColor"></code-input>
  </div>
</div>
