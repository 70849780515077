export class AnimeDirection {
    /**
     * 正方向动画
     */
    static normal = 'normal';
    /**
     * 反方向动画
     */
    static reverse = 'reverse';
    /**
     * 往返动画
     */
    static alternate = 'alternate';
}
