import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DataSourceConfig, DataSourceType, GaugeProperty } from 'src/app/chartConfig';

interface DataSourceEdit {
    dynamic?: boolean;
    type: string;
    code?: string;
    param?: string;
    fixedValue?: number;
}

/**
 * 折线图配置
 */
@Component({
    selector: 'app-chart-gauge-config',
    templateUrl: './chartGaugeConfig.component.html',
    styleUrls: ['./chartGaugeConfig.component.css']
})
export class ChartGaugeConfigComponent implements OnInit, OnDestroy {
    @Input() prop: GaugeProperty = new GaugeProperty();
    /**
     * 基础数据源
     */
    baseDataSource: DataSourceEdit = { type: 'build' };
    /**
     * 动态数据源
     */
    dataSource: DataSourceEdit = { type: 'build' };

    constructor(private modal: NzModalRef, private message: NzMessageService) {
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        this.baseDataSource = this.getDataSource(this.prop.baseDataSource);
        this.dataSource = this.getDataSource(this.prop.dataSource);
    }

    getDataSource(source: DataSourceConfig): DataSourceEdit {
        let code = '';
        let param = '';
        if (source.code) {
            const arr = source.code.split('.');
            code = arr[0];
            if (arr.length > 1) {
                param = arr[1];
            }
        }
        let type = source.type;
        if (!type) {
            type = 'build';
        }
        return {
            dynamic: source.dynamic,
            fixedValue: source.fixedValue,
            type,
            code,
            param
        };
    }

    save(): any {
        return new Promise(resolve => {
            let param = '';
            if (this.baseDataSource.type !== DataSourceType.param
                && this.baseDataSource.type !== DataSourceType.formula) {
                param = '.' + this.baseDataSource.param;
            }

            this.prop.baseDataSource = {
                dynamic: this.baseDataSource.dynamic,
                type: this.baseDataSource.type,
                code: this.baseDataSource.code + param,
                fixedValue: this.baseDataSource.fixedValue
            };

            param = '';
            if (this.dataSource.type !== DataSourceType.param
                && this.dataSource.type !== DataSourceType.formula) {
                param = '.' + this.dataSource.param;
            }

            this.prop.dataSource = {
                dynamic: this.dataSource.dynamic,
                type: this.dataSource.type,
                code: this.dataSource.code + param
            };

            resolve(this.prop);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
