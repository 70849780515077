<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">动态绑定数据源</span>:</span>
        <div class="code">
            <div class="remark">key支持的类型，accMenu:累积量；accuMeter：累积量参数；build：建筑；buildDevice：建筑设备；</div>
            <div class="remark">buildFunc：建筑功能；dept：部门；dev：设备；deviceType：设备类型；energy：能耗；param：设备参数；video：视频</div>
            <div>
                <@p2.*>.obj().setTree({{'{'}}
                    key: 'build',
                    filterSql: 'state=1'
                    {{'}'}})
            </div>
            <div class="remark">其余支持的参数：</div>
            <div>建筑节点多选：checkbox:{{'{'}}build:true{{'}'}}</div>
            <div>子节点sql条件:'state=1'</div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">获取选中节点</span>:</span>
        <div class="code">
            <div class="remark">结果示例：{{'{'}}id: '18', name: '苏果奥体店', actId: 1, type: 'build'{{'}'}}</div>
            <div class="remark">多选模式返回的结果为列表格式。</div>
            <div>
                var node = <@p2.*>.value();
            </div>
            <div>
                var node = <@p2.*>.obj().getValue();
            </div>
        </div>
    </div>

    <div class="desc">
        <span class="title"><span class="mark">设置默认选中节点</span>:</span>
        <div class="code">
            <div class="remark">参数为节点id</div>
            <div>
                <@p2.*>.obj().setValue(1);
            </div>
            <div class="remark">多选模式，需要默认选择多个节点时，可传入列表参数：</div>
            <div>
                <@p2.*>.obj().setValue([1, 2, 3]);
            </div>
        </div>
    </div>

</div>