import moment from 'moment';
import 'moment/locale/pt-br';

export class DateUtil {
    static toDate(time: string): Date {
        return moment(time).toDate();
    }

    static addSeconds(time: Date, num: number): Date {
        return moment(time).add(num, 's').toDate();
    }

    static addMinuts(time: Date, num: number): Date {
        return moment(time).add(num, 'm').toDate();
    }

}
