/**
 * 音频报警
 */
export class AudioAlarmConfig {
    /**
     * 音频文件名
     */
    Id: string;
    /**
     * 播放时长（毫秒）
     */
    Time: number;
    /**
     * 文件别称
     */
    Name: string;
}