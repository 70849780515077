<nz-header style="position:fixed;width:100%">
  <ul class="menu" nz-menu [nzTheme]="'dark'" [nzMode]="'horizontal'" style="line-height: 50px;">
    <li nz-menu-item class="logo-title"><span>TW低代码开发平台-V1.0</span>
    </li>
    <li nz-submenu nzIcon="file">
      <span title>文件</span>
      <ul>
        <li nz-submenu>
          <span title><i nz-icon nzType="plus" nzTheme="outline"></i>新建</span>
          <ul>
            <li nz-menu-item (click)="createProject()"><i nz-icon nzType="project" nzTheme="outline"></i>工程</li>
            <li nz-menu-item (click)="createModule()"><i nz-icon nzType="group" nzTheme="outline"></i>模块</li>
            <li nz-menu-item (click)="createPage()"><i nz-icon nzType="file" nzTheme="outline"></i>页面</li>
          </ul>
        </li>
        <li nz-menu-item (click)="initProject()">
          <span title><i nz-icon nzType="play-circle" nzTheme="outline"></i>项目初始化</span>
        </li>
      </ul>
    </li>
    <li nz-submenu nzIcon="project">
      <span title>工程</span>
      <ul>
        <li nz-menu-item (click)="openProject()">
          <span title><i nz-icon nzType="folder-open" nzTheme="outline"></i>打开工程</span>
        </li>
        <li nz-menu-item (click)="projectImport()">
          <span title><i nz-icon nzType="import" nzTheme="outline"></i>导入工程</span>
        </li>
        <li nz-menu-item (click)="projectExport()">
          <span title><i nz-icon nzType="export" nzTheme="outline"></i>导出工程</span>
        </li>
        <li nz-menu-item (click)="projectSet()">
          <span title><i nz-icon nzType="setting" nzTheme="outline"></i>工程配置</span>
        </li>
        <li nz-menu-item (click)="projectManage()">
          <span title><i nz-icon nzType="form" nzTheme="outline"></i>工程管理</span>
        </li>
        <li nz-menu-item (click)="themeSet()">
          <span title><i nz-icon nzType="setting" nzTheme="outline"></i>主题设置</span>

        </li>
        <li nz-submenu>
          <span title><i nz-icon nzType="folder" nzTheme="outline"></i>资源文件管理</span>
          <ul>
            <li nz-menu-item (click)="iconManage()">
              <span title><i nz-icon nzType="picture" nzTheme="outline"></i>图标文件管理</span>
            </li>
            <li nz-menu-item (click)="audioManage()">
              <span title> <span class="iconfont iconmusicfill"></span>音频文件管理</span>
            </li>
            <li nz-menu-item (click)="videoManage()">
              <span title><i nz-icon nzType="video-camera" nzTheme="outline"></i>视频文件管理</span>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li nz-submenu nzIcon="question-circle">
      <span title>帮助</span>
      <ul>
        <li nz-menu-item (click)="help()">
          <a target="_blank" rel="noopener noreferrer">
            <i nz-icon nzType="question" nzTheme="outline"></i>帮助文档</a>
        </li>
        <li nz-menu-item (click)="keyDesc()">
          <a target="_blank" rel="noopener noreferrer">
            <i nz-icon nzType="info" nzTheme="outline"></i>快捷键说明</a>
        </li>
      </ul>
    </li>
    <li nz-menu-item (click)="logout()">
      <a target="_blank" rel="noopener noreferrer"><i nz-icon nzType="logout" nzTheme="outline"
          style="color: red;"></i>注销</a>
    </li>
    <li nz-menu-item class="project" *ngIf="project">
      <a target="_blank" rel="noopener noreferrer">
        <span>工程：{{project.name}}</span>
      </a>
    </li>
    <li nz-menu-item class="project" *ngIf="project&&pageName">
      <a target="_blank" rel="noopener noreferrer">
        <span>{{pageName}}</span>
      </a>
    </li>
    <li nz-menu-item class="shortcut" (click)="gotoRun()">
      <a target="_blank" rel="noopener noreferrer">
        <i nz-icon nzType="link" nzTheme="outline"></i>运行界面</a>
    </li>
    <li nz-menu-item class="shortcut">
      <nz-divider [nzType]="'vertical'"></nz-divider>
    </li>
    <li nz-menu-item class="shortcut" (click)="publish()">
      <a target="_blank" rel="noopener noreferrer">
        <i nz-icon nzType="global" nzTheme="outline" style="color: #00ff1f;"></i>发布</a>
    </li>
    <li nz-menu-item class="shortcut" (click)="previewPage()">
      <a target="_blank" rel="noopener noreferrer">
        <i nz-icon nzType="eye" nzTheme="outline"></i>预览</a>
    </li>
    <li nz-menu-item class="shortcut">
      <nz-divider [nzType]="'vertical'"></nz-divider>
    </li>

    <li nz-menu-item class="shortcut" (click)="savePage()">
      <a target="_blank" rel="noopener noreferrer" [style.color]="hasPageSaved?'rgba(255, 255, 255, 0.65)':'red'">
        <i nz-icon nzType="save" nzTheme="outline"></i>保存</a>
    </li>
  </ul>
</nz-header>