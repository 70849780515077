/**
 * 组件分类信息
 */
export class ComClassify {
    /**
     * 分类id
     */
    id: string;
    /**
     * 分类名称
     */
    text: string;
    /**
     * 排序编号
     */
    sort: number;
    /**
     * 是否基础分类
     */
    base: boolean;

    count: number;
}
