import { DataAttrName } from '../const';
import { Property } from '../_models';

export class ProgressLine {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.baseColor: {
                prop.setValue = selectedObj.select('.tw-progress-base').node.style.fill;
                break;
            }
            case DataAttrName.themeColor: {
                prop.setValue = selectedObj.select('.tw-progress-theme').node.style.fill;
                break;
            }
            case DataAttrName.radian: {
                prop.setValue = selectedObj.select('.tw-progress-base').node.style.rx;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.baseColor: {
                selectedObj.select('.tw-progress-base').node.style.setProperty('fill', prop.setValue);
                break;
            }
            case DataAttrName.themeColor: {
                selectedObj.select('.tw-progress-theme').node.style.setProperty('fill', prop.setValue);
                break;
            }
            case DataAttrName.radian: {
                selectedObj.select('.tw-progress-base').node.style.setProperty('rx', prop.setValue);
                selectedObj.select('.tw-progress-base').node.style.setProperty('ry', prop.setValue);
                selectedObj.select('.tw-progress-theme').node.style.setProperty('rx', prop.setValue);
                selectedObj.select('.tw-progress-theme').node.style.setProperty('ry', prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
