import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timePipe'
})

export class TimePipe implements PipeTransform {
    transform(item: any): Date {
        if (!item) {
            return null;
        }
        return new Date('2000-01-01 ' + item);
    }
}
