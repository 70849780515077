import { TimeRangeConfig } from '../bar/timeRangeConfig';
import { DataSourceConfig } from '../base/dataSourceConfig';
import { BaseProperty } from '../base/baseProperty';

/**
 * 饼图配置属性
 */
export class PieProperty extends BaseProperty {
    /**
     * 数据源
     */
    dataSources: DataSourceConfig[] = [];
    /**
     * 转标煤
     */
    toKgce = false;
    /**
     * 转二氧化碳
     */
    toCO2 = false;
    /**
     * 统计时段
     */
    timeRanges: TimeRangeConfig[] = [];
}
