
            <nz-table [nzLoading]="isLoading" #basicTable [nzShowPagination]="false" [nzData]="backs" [nzPageSize]="50" 
              [nzScroll]="{ y: '300px' }">
                <thead>
                  <tr>
                    <th>备份时间</th>
                    <th>备份人</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.timeStr }}</td>
                    <td>{{ data.user }}</td>
                    <td>
                      <a (click)="loadBackUp(data.id)">还原</a>
                    </td>
                  </tr>
                </tbody>
            </nz-table>
    