import { MathUtil } from "./mathUtil";

export class PageEncodeUtil {
    /**
     * page content encode
     */
    public static encode(content: string): string {
        if (!content) {
            return content;
        }
        return encodeURIComponent(btoa(encodeURIComponent(content)));
    }

    public static sysParamEncode(content) {
        if (!content) {
            return content;
        }
        content = content.replace('Tw ', '');
        content = encodeURIComponent(btoa(encodeURIComponent(content)))
        //头部增加10个随机字符
        content = MathUtil.getRandomStr(10) + content;
        content = encodeURIComponent(btoa(encodeURIComponent(content)))
        //尾部增加10个随机字符
        content += MathUtil.getRandomStr(10);
        return encodeURIComponent(btoa(encodeURIComponent(content)))
    }
}
