import { AlarmConfig } from "./alarmConfig";
import { Img } from "./img";
import { RealDataConfig } from "./realDataConfig";
import { TimeConfig } from "./timeConfig";
import { TitleConfig } from "./titleConfig";

/**
 * 首页配置
 */
export class IndexPageConfig {
    /**
     * 默认全屏
     */
    defaultFull: boolean;
    /**
     * 显示全屏按钮
     */
    showFullBtn: boolean;
    /**
     * 默认展开菜单栏
     */
    expandMenuBar: boolean;
    /**
     * logo
     */
    logo: Img = new Img();
    /**
     * 标题配置
     */
    title: TitleConfig = new TitleConfig();
    /**
     * 时间配置
     */
    time: TimeConfig = new TimeConfig();
    /**
     * 实时数据
     */
    reals: RealDataConfig[] = [];
    /**
     * 滚动报警配置
     */
    alarm: AlarmConfig = new AlarmConfig();
    /**
     * 隐藏登录名图标
     */
    hideNameIcon: boolean;
    /**
     * 登录名左侧图标
     */
    nameIcon: Img = new Img();
    /**
     * 自定义css样式
     */
    css: string;
}