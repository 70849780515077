import { DataSource } from "./dataSource";
import { DataEvent } from "./dataEvent";


export class PageDataBak {
    id: string;
    pageId: string;
    projectId: string;
    timeStr: string;
    user: string;
    content: string;
    sources: DataSource[];
    events: DataEvent[];
    globalEvent: string;
}
