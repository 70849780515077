import { DataAttrName } from '../const';
import { Property } from '../_models';

export class ProgressCircle {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.baseColor: {
                prop.setValue = selectedObj.select('.tw-progress-base').node.style.stroke;
                break;
            }
            case DataAttrName.themeColor: {
                prop.setValue = selectedObj.select('.tw-progress-theme').node.style.stroke;
                break;
            }
            case DataAttrName.showText: {
                prop.setValue = selectedObj.select('.tw-progress-text').node.style.display !== 'none';
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.baseColor: {
                selectedObj.select('.tw-progress-base').node.style.setProperty('stroke', prop.setValue);
                break;
            }
            case DataAttrName.themeColor: {
                selectedObj.select('.tw-progress-theme').node.style.setProperty('stroke', prop.setValue);
                selectedObj.select('.tw-progress-text').attr('fill', prop.setValue);
                break;
            }
            case DataAttrName.showText: {
                selectedObj.select('.tw-progress-text').node.style.setProperty('display', prop.setValue ? 'block' : 'none');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
