/**
 * 柱状图类型
 */
export enum BarTypeEnum {
    /**
     * 基础柱状图
     */
    BASE = 'base',
    /**
     * 降序柱图
     */
    DESC = 'desc',
    /**
     * 升序柱图
     */
    ASC = 'asc'
}
