/**
 * 树节点checkbox配置
 */
export class TreeCheckbox {
    build: boolean;
    dept: boolean;
    energy: boolean;
    device: boolean;
    param: boolean;
}
