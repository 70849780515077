import { TreeCheckbox } from './treeCheckbox';

/**
 * 树配置
 */
export class TreeParam {
    /**
     * 0树；1：下拉树
     */
    showType?: number = 0;
    /**
     * 是否异步加载
     */
    async?: boolean = false;
    /**
     * 是否显示搜索框
     */
    search?: boolean = false;
    /**
     * 是否默认选中
     */
    defaultSelect?: boolean = true;
    /**
     * sql过滤条件
     */
    filterSql?: string = '';
    subFilterSql?: string = '';
    /**
     * 节点复选框
     */
    checkbox?: TreeCheckbox = new TreeCheckbox();
    /**
     * 数据源类型，0：预置树；1：注册树
     */
    type: number;
    /**
     * 树类型key
     */
    key: string;
    /**
     * 其他配置属性
     */
    attr?: any = {};
}
