<div class="main">
    <div class="desc">
        <span class="title">获取<span class="mark">当前选择的日期</span>:</span>
        <div class="code">
            <div class="remark">单日期模式返回结果示例：2024-05-01</div>
            <div class="remark">双日期模式返回结果示例(结束日期默认加一)：2024-05-01~2024-05-14</div>
            <div>
                var date = <@p2.*>.value();
            </div>
            <div>
                var date = <@p2.*>.obj().getValue();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">设置控件默认日期</span>:</span>
        <div class="code">
            <div class="remark">单日期模式，精度：日：</div>
            <div>
                <@p2.*>.obj().setValue('2024-05-03');
            </div>
            <div class="remark">双日期模式(只传入一个参数时，另一个参数默认为当日)，精度：日：</div>
            <div>
                <@p2.*>.obj().setValue('2024-05-03', '2024-05-10');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">设置控件<span class="mark">展示时间类型（精度）</span>:</span>
        <div class="code">
            <div class="remark">分钟：</div>
            <div>
                <@p2.*>.obj().setType('minute');
            </div>
            <div class="remark">小时：</div>
            <div>
                <@p2.*>.obj().setType('hour');
            </div>
            <div class="remark">日：</div>
            <div>
                <@p2.*>.obj().setType('day');
            </div>
            <div class="remark">月：</div>
            <div>
                <@p2.*>.obj().setType('month');
            </div>
            <div class="remark">年：</div>
            <div>
                <@p2.*>.obj().setType('year');
            </div>
        </div>
    </div>

</div>