import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

/*文件-新建-模块 */
@Component({
    selector: 'app-number-input',
    template: `<nz-input-number [(ngModel)]="num" [nzMin]="0" [nzMax]="360" [nzStep]="1"></nz-input-number>`,
    styles: [
        `
        nz-input-number{
            width:100%;
        }
        `
    ]
})
export class NumberInputComponent implements OnInit, OnDestroy {
    @Input() num: number;

    constructor(private modal: NzModalRef
    ) {
    }

    ngOnInit() {
    }

    save(): any {
        return new Promise(resolve => {
            resolve(this.num);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
