
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='reNameComponent()' *ngIf="!classify.base">
                <i nz-icon nzType="redo" nzTheme="outline"></i>重命名
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='addComponent($event)' *ngIf="!classify.base">
                <i nz-icon nzType="file-add" nzTheme="outline"></i>添加组件
            </li>
            <li nz-menu-item (click)='deleteComponent()' *ngIf="!classify.base" [nzDisabled]=component.sys>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除组件
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='updateClassify()' *ngIf="!classify.base">
                <i nz-icon nzType="partition" nzTheme="outline"></i>更换组件分类
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='downLoad()'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载至本地
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='setBG()' *ngIf="!classify.base">
                <i nz-icon nzType="file-image" nzTheme="outline"></i>设为画布背景
            </li>
        </ul>
    