/**
 * 高亮配置
 */
export class HighLightConfig {
    id: string;
    /**
     * 开始时间
     */
    start: string;
    /**
     * 结束时间
     */
    end: string;
    /**
     * 名称
     */
    name: string;
    /**
     * 颜色
     */
    color: string;
}
