
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PageApi } from '../api';
import { PageDataBak } from '../_models';
import { EventTransService } from '../_services';

/*js脚本编辑器 */
@Component({
  selector: 'app-backup',
  template: `
            <nz-table [nzLoading]="isLoading" #basicTable [nzShowPagination]="false" [nzData]="backs" [nzPageSize]="50" 
              [nzScroll]="{ y: '300px' }">
                <thead>
                  <tr>
                    <th>备份时间</th>
                    <th>备份人</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.timeStr }}</td>
                    <td>{{ data.user }}</td>
                    <td>
                      <a (click)="loadBackUp(data.id)">还原</a>
                    </td>
                  </tr>
                </tbody>
            </nz-table>
    `,
  styles: [
    `
      tr {
        display:table-row;
      }
    `
  ]
})
export class BackUpComponent implements OnInit, OnDestroy {

  @Input() id: string;

  isLoading = true;
  backs: PageDataBak[] = [];
  constructor(
    private modal: NzModalRef,
    private eventTransService: EventTransService,
    private pageApi: PageApi
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.backs = await this.pageApi.getBackups(this.id);
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.modal.destroy();
  }

  loadBackUp(id) {
    this.eventTransService.loadCanvasDataEvent.next({
      id,
      isPage: true,
      isBackUp: true
    });
    this.modal.close();
  }
}
