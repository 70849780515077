
    <div nz-row>
        <div nz-col nzSpan="8">
            <h4>成员：</h4>
            <div class="wrap" [ngStyle]="{ 'width': '75%','marginRight':'5%', 'float':'left' }">
                <nz-list nzSize="small">
                    <nz-list-item *ngFor="let item of items" [ngStyle]="{'background': item.id===selItem.id?'#127ff1db':''}"
                        (click)="selectItem(item)">
                        {{ item.title }}
                    </nz-list-item>
                </nz-list>
            </div>
            <div [ngStyle]="{ 'width': '20%', 'float':'left' }">
                <button nz-button nzType="primary" [nzSize]="'large'" (click)="setSort(-1)">
                    <i nz-icon nzType="arrow-up" nzTheme="outline"></i>
                </button>
                <div style="margin-bottom:5px"></div>
                <button nz-button nzType="primary" [nzSize]="'large'" (click)="setSort(1)">
                    <i nz-icon nzType="arrow-down" nzTheme="outline"></i>
                </button>
            </div>
            <p style="padding-top:15px;clear:both;">
                <button nz-button nzType="primary" [ngStyle]="{ 'marginRight':'10px' }" (click)="addItem()">添加</button>
                <button nz-button nzType="primary" nzDanger (click)="removeItem()">移除</button>
            </p>
        </div>
        <div nz-col nzSpan="16" style="padding-left:3%;">
            <h4>Item属性：</h4>
            <div class="wrap1">
                <nz-form-item>
                    <nz-form-label nzFor="itemID">ID</nz-form-label>
                    <input type="text" id="itemID" nz-input [(ngModel)]="selItem.key" placeholder="请输入 Item ID"
                        autofocus/>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzFor="itemName">名称</nz-form-label>
                    <input type="text" id="itemName" nz-input [(ngModel)]="selItem.title" placeholder="请输入Item名称"/>
                </nz-form-item>
            </div>
        </div>
    </div>
    