<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">动态选中节点</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的ID</div>
            <div>
                <@p2.*>.value(1);
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">选中节点的值</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的ID</div>
            <div>
                var id = <@p2.*>.value();
            </div>
        </div>
    </div>
</div>