import { AnimeDirection } from './animeDirection';
import { AnimeEasing } from './animeEasing';

/**
 * 位移动画属性
 */
export class AnimeTranslateProp {
    /**
     * 启用动画
     */
    enable = false;
    /**
     * 循环次数，0表示无限循环
     */
    loop = 0;
    /**
     * 循环一次时间（毫秒）
     */
    duration = 1000;
    /**
     * 循环方向
     */
    direction = AnimeDirection.alternate;
    /**
     * 动画效果
     */
    easing = AnimeEasing.easeInOutSine;
    /**
     * 是否水平方向移动
     */
    transDirection = 'X';
    /**
     * 位移距离
     */
    distance = 100;
}
