export class MenuData {
    /**
     * 画布X轴偏移距离
     */
    static canvasShiftX = 265;
    /**
     * 画布Y轴偏移距离
     */
    static canvasShiftY = 120;
    /**
     * 画布右键菜单宽度
     */
    static canvasMenuWidth = 182;
    /**
     * 画布右键菜单高度
     */
    static canvasMenuHeight = 450;
    /**
     * 工程模板/页面右键菜单高度
     */
    static pageTitleHeight = 190;
    /**
     * 页面右键菜单高度
     */
    static pageHeight = 520;
    /**
     * 模块右键菜单高度
     */
    static moduleHeight = 375;
    /**
     * 工程模板右键菜单高度
     */
    static templateTitle = 40;
    /**
     * 模板右键菜单高度
     */
    static template = 300;
    /**
     * 模板分类右键菜单高度
     */
    static templateClassify = 150;

    /**
     * 组件标题右键菜单高度
     */
    static componentTitle = 40;
}