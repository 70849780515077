import { Property } from '../_models';

export class TextRow {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const div = selectedObj.select('div');
        switch (prop.name) {
            case 'text': {
                prop.setValue = div.node.textContent;
                break;
            }
            case 'letter-spacing':
            case 'font-size': {
                prop.setValue = div.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            case 'fill': {
                prop.setValue = div.node.style.getPropertyValue('color');
                break;
            }
            case 'font-family':
            case 'font-weight':
            case 'font-style':
            case 'text-decoration': {
                prop.setValue = div.node.style.getPropertyValue(prop.name);
                break;
            }
            case 'text-anchor': {
                const val = div.node.style.getPropertyValue('text-align');
                prop.setValue = val === 'center' ? 'middle' : val;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const div = selectedObj.select('div');
        switch (prop.name) {
            case 'text': {
                div.node.textContent = prop.setValue;
                break;
            }
            case 'letter-spacing':
            case 'font-size': {
                div.node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            case 'fill': {
                div.node.style.setProperty('color', prop.setValue);
                break;
            }
            case 'font-family':
            case 'font-weight':
            case 'font-style':
            case 'text-decoration': {
                div.node.style.setProperty(prop.name, prop.setValue);
                break;
            }
            case 'text-anchor': {
                div.node.style.setProperty('text-align', prop.setValue === 'middle' ? 'center' : prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
