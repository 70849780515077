import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'code-input, [code-input]',
    template: '<ng-content></ng-content>',
    styles: [`
    :host {
        margin:0;
        min-height:400px;
        height:auto;
    }
    `
    ]
})
export class CodeInputComponent implements OnInit, OnDestroy {
    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
