
        <ul nz-menu [ngStyle]="{'margin-left.px':x, 'margin-top.px':y}">
            <li nz-menu-item (click)='menuClick(operateType.Copy)'>
                <span class="iconfont iconfuzhi"></span>复制<span style='float:right;'>Ctrl+C</span>
            </li>
            <li nz-menu-item (click)='menuClick(operateType.Paste)' [nzDisabled]=!enablePaste>
                <span class="iconfont iconniantie"></span>粘贴<span style='float:right;'>Ctrl+V</span>
            </li>
            <li nz-menu-item (click)='menuClick(operateType.Delete)'>
                <span class="iconfont iconshanchu"></span>删除<span style='float:right;'>Ctrl+D</span>
            </li>
            <li nz-menu-item (click)='menuClick(operateType.SelectAll)'>
                <span class="iconfont iconquanxuan"></span>全选<span style='float:right;'>Ctrl+A</span>
            </li>
            <nz-divider></nz-divider>
            <li nz-submenu nzTitle="查找 / 替换" nzIcon="file-search" *ngIf="!isTemplate">
                <ul>
                    <li nz-menu-item (click)='menuClick(operateType.Find)'>
                        <i nz-icon nzType="search" nzTheme="outline"></i>查找<span style='float:right;'>Ctrl+F</span>
                    </li>
                    <li nz-menu-item (click)='menuClick(operateType.Replace)'>
                        <span class="iconfont iconGroup"></span>替换<span style='float:right;'>Ctrl+H</span>
                    </li>
                </ul>
            </li>
            <li nz-submenu nzTitle="旋转" nzIcon="redo">
                <ul>
                    <li nz-menu-item (click)='menuClick(operateType.Rotate90)'>90°</li>
                    <li nz-menu-item (click)='menuClick(operateType.Rotate180)'>180°</li>
                    <li nz-menu-item (click)='menuClick(operateType.Rotate0)'>还原(0°)</li>
                    <li nz-menu-item (click)='menuClick(operateType.RotateCustom)'>自定义</li>
                </ul>
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='menuClick(operateType.SaveToTemplate)' *ngIf="!isTemplate">
                <i nz-icon nzType="profile" nzTheme="outline"></i>保存为模板
            </li>
            <li nz-menu-item (click)='menuClick(operateType.SaveToBaseModel)'>
                <i nz-icon nzType="save" nzTheme="outline"></i>保存至组件库
            </li>
            <li nz-menu-item (click)='menuClick(operateType.RestoreTemplate)'>
                <i nz-icon nzType="undo" nzTheme="outline"></i>还原引用模板
            </li>
            <nz-divider></nz-divider>

            <li nz-menu-item (click)='menuClick(operateType.SyncCss)'>
                <span class="iconfont iconcss"></span>样式同步
            </li>
            <li nz-menu-item (click)='menuClick(operateType.JsEvent)' *ngIf="!isTemplate">
                <span class="iconfont iconjs"></span>事件脚本(全局)
            </li>
            <nz-divider></nz-divider>

            <li nz-menu-item (click)='menuClick(operateType.Save)'>
                <i nz-icon nzType="save" nzTheme="outline"></i>保存页面<span style='float:right;'>Ctrl+S</span>
            </li>
        </ul>
    