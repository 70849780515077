<nz-spin [nzSpinning]="loadingProject">
  <li class="project" nz-submenu nzPlacement="right" [nzOpen]=true nzTitle="工程模块/页面" nzIcon="file"
    (contextmenu)="pageTitleRightClick($event)">
    <ul>
      <li>
        <nz-input-group [nzSuffix]="suffixIcon">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" />
        </nz-input-group>
        <ng-template #suffixIcon>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <nz-tree #nzTree nzShowIcon nzDraggable [nzSearchValue]="searchValue" (nzClick)="treeClick($event)"
          [nzData]="projectTree" [nzBlockNode]="true" (nzDblClick)="treeDbClick($event)" [nzHideUnMatched]="true"
          [nzBeforeDrop]="(beforeDrop)" (nzOnDrop)="onDrop($event)" (nzContextMenu)="treeRightClick($event)"
          [nzTreeTemplate]="nzTreeTemplate">
        </nz-tree>
        <ng-template #nzTreeTemplate let-node let-origin="origin">
          <span class="custom-node">
            <span *ngIf="!node.isLeaf">
              <i nz-icon nzType="appstore"></i>
              <span class="projectModule">[模块]</span>
              <span class="folder-name">{{ node.title }}</span>
            </span>
            <span *ngIf="node.isLeaf">
              <i *ngIf="origin.menu" nz-icon [nzType]="node.icon" nzTheme="twotone"></i>
              <i *ngIf="!origin.menu" nz-icon [nzType]="node.icon"></i>
              <span class="sysPage" *ngIf="origin.isSys">[CMS]</span>
              <span class="sysPage" *ngIf="origin.isUser">[用户]</span>
              <span class="folder-name">{{ node.title }}</span>
              <i *ngIf="origin.edit" nz-icon nzType="edit"
                [ngStyle]="{'color': node.key===loadPageId?'#00ff66':'red'}"></i>
            </span>
          </span>
        </ng-template>
      </li>
    </ul>
  </li>
</nz-spin>


<app-page-title-contextmenu *ngIf="menuState.PageTitle" [x]="contextmenuX" [y]="contextmenuY">
</app-page-title-contextmenu>
<app-module-contextmenu *ngIf="menuState.Module" [module]="selectedModule" [x]="contextmenuX" [y]="contextmenuY"
  [enableDeleteMenu]="enableDeleteMenu">
</app-module-contextmenu>
<app-page-contextmenu *ngIf="menuState.Page" [x]="contextmenuX" [y]="contextmenuY" [page]="selectedPage">
</app-page-contextmenu>

<nz-spin [nzSpinning]="loadingTemplateClassify">
  <li class="template" nz-submenu nzPlacement="right" [nzOpen]=true nzTitle="工程模板" nzIcon="profile"
    style="font-weight: bold;background-color: #6a6a7180;" (contextmenu)="templateTitleRightClick($event)">
    <ul>
      <li nz-submenu *ngFor="let classify of templateClassifies,let i=index"
        [(nzOpen)]="$any(classify).id==clickedTemplateClassifyId"
        (nzOpenChange)="openTemplateClassify($event,classify.id)">
        <span title class="nav-text" (contextmenu)="templateClassifyRightClick($event,classify)"><i nz-icon
            nzType="group" nzTheme="outline"></i>{{classify.text}}</span>
        <nz-spin [nzSpinning]="loadingTemplate">
          <nz-card nzTitle="" [nzBordered]=false>
            <div nz-card-grid [ngStyle]="gridStyle" *ngFor="let template of templateMap.get(classify.id)"
              draggable="true" (dragstart)="dragTemplate($event,template.id)"
              (contextmenu)="templateRightClick($event,template)" (dblclick)="templateDbClick(template.id)">
              <i nz-icon nzType="edit" class="templateEdit" *ngIf="template.edit"
                [ngStyle]="{'color': template.id===loadTemplateId?'#00ff66':'red'}"></i>
              <img [src]="template.data.content|base64Pipe" width="30" height="30" draggable="true" />
              <p>{{template.text + (template.count?'('+template.count+')':'')}}</p>
            </div>
          </nz-card>
        </nz-spin>
      </li>
    </ul>
  </li>
</nz-spin>

<app-template-title-contextmenu *ngIf="menuState.TemplateTitle" [x]="contextmenuX" [y]="contextmenuY">
</app-template-title-contextmenu>
<app-template-classify-contextmenu *ngIf="menuState.TemplateClassify" [x]="contextmenuX" [y]="contextmenuY"
  [classify]="selectedTemplateClassify" [enableDelete]="enableDeleteMenu">
</app-template-classify-contextmenu>
<app-template-contextmenu *ngIf="menuState.Template" [x]="contextmenuX" [y]="contextmenuY"
  [template]="selectedTemplate">
</app-template-contextmenu>
