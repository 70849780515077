/**
 * 工程视频文件
 */
export class ProjectVideo {
    /**
     * 视频名称
     */
    Name: string;
    /**
     * 视频上传时间
     */
    Time: string;
}
