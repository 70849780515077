import { Property } from '../_models';

export class Slider {
    static get(selectedObj: any, prop: Property) {
        prop.setValue = selectedObj.select('input').attr(prop.name);
        return true;
    }

    static set(selectedObj: any, prop: Property) {
        selectedObj.select(`input`).attr(prop.name, prop.setValue);
        return true;
    }
}
