import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->模板分类
 */
@Injectable({ providedIn: 'root' })
export class TemplateClassifyApi {
    private prefix = '/design/templateClassify';
    constructor(
        private message: NzMessageService,
        private http: HttpService
    ) {
    }

    /**
     * 获取工程下所有分类
     * @param projectId 工程id
     */
    async getAll(projectId: string) {
        if (!projectId) {
            this.message.warning('参数错误[工程id]');
            return false;
        }
        return await this.http.get(`${this.prefix}/getAll`, { projectId });
    }

    /**
     * 创建分类
     * @param projectId 工程id
     * @param name 分类名称
     */
    async create(projectId: string, name: string) {
        if (!projectId) {
            this.message.warning('参数错误[工程id]');
            return;
        }
        if (!name) {
            this.message.warning('参数错误[分类名称]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/create`, { projectId, name });
    }

    /**
     * 重命名
     * @param id 分类id
     * @param name 分类名称
     */
    async rename(id: string, name: string) {
        if (!id) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        if (!name) {
            this.message.warning('参数错误[分类名称]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/rename`, { id, name });
    }

    /**
     * 删除分类
     * @param id 分类id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }
}
