import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService } from '../_services';
import { NameInputComponent } from '../_modals';
import { TemplateClassifyApi } from '../api/templateClassifyApi';
import { DataCache } from '../cache';

@Component({
    selector: 'app-template-title-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='createClassify($event)'>
                <i nz-icon nzType="group" nzTheme="outline"></i>创建模板分类
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})

/**
 * 工程模板右键菜单
 */
export class TemplateTitleContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private templateClassifyApi: TemplateClassifyApi
    ) { }

    /**
     * 添加模板分类
     * @param event 事件
     */
    createClassify(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '创建模板分类',
            nzComponentParams: {
                tipMsg: '请输入模板分类名称...'
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    const ret = await this.templateClassifyApi.create(DataCache.project.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateClassifyChange.next(true);
                    }
                }
                return name;
            })
        });
    }
}

