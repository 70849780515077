import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Module, Page } from '../_models';
import { ModuleApi } from '../api';
import { DataCache } from '../cache';

/**
 * 保存页面
 */
@Component({
    selector: 'app-save-svg-page',
    template: `
        <nz-input-group nzAddOnBefore="ID" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.name" placeholder="请输入页面ID" autofocus/>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="名称" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.text" placeholder="请输入页面名称"/>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label nz-checkbox [(ngModel)]="page.config.menu" style="width:25%;color:#ffa41af5">菜单页面</label>
        <div style="margin-top:10px;"></div>

        <div>
            <label nz-checkbox [(ngModel)]="page.config.scale" style="width:25%;">允许缩放</label>
            <nz-input-group nzAddOnBefore="初始缩放" style="width:75%;" *ngIf="page.config.scale">
                <nz-select [(ngModel)]="page.config.initScaleType" style="width:100%">
                    <nz-option nzValue="0" nzLabel="自动"></nz-option>
                    <nz-option nzValue="1" nzLabel="缩放"></nz-option>
                    <nz-option nzValue="2" nzLabel="不缩放"></nz-option>
                </nz-select>
            </nz-input-group>
        </div>
        
        <div style="margin-top:10px;"></div>

        <nz-input-group nzAddOnBefore="权限级别" style="width:50%;">
            <nz-input-number style="width:100%" [(ngModel)]="page.config.level" [nzMin]="0" [nzMax]="999" [nzStep]="1"
                    nzTooltipTitle="权限等级低于该值的用户无法访问该页面！" nzTooltipPlacement="bottom" nz-tooltip></nz-input-number>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label>所属模块：</label>
        <button nz-button nzType="link" (click)="clearModule()">清除选择(一级页面)</button>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="page.moduleId" [nzButtonStyle]="'solid'">
                    <label nz-radio-button *ngFor="let item of modules" nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 200px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        input{
            margin-bottom:10px;
        }
        nz-radio-group,label{
            width:100%;
        }
        nz-input-group{
            margin-top:-5px;
        }
        nz-input-group,input{
            width:80%;
        }
        `
    ]
})
export class SaveSvgPageComponent implements OnInit, OnDestroy {
    @Input() page: Page = new Page();
    @Input() moduleId?: string;

    modules: Module[] = [];
    isLoading = true;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private moduleApi: ModuleApi
    ) {
    }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.modules = await this.moduleApi.getAll(DataCache.project.id);
        this.page.projectId = DataCache.project.id;
        this.isLoading = false;
        if (this.moduleId) {
            this.page.moduleId = this.moduleId;
        }
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.page.name) {
                this.message.warning(`请输入页面ID！`);
                resolve(false);
                return;
            }
            if (!RegExp(/^[0-9a-zA-Z]*$/g).test(this.page.name)) {
                this.message.warning(`页面ID只能由字母、数字组成！`);
                resolve(false);
                return;
            }
            if (!this.page.text) {
                this.message.warning(`请输入页面名称！`);
                resolve(false);
                return;
            }

            resolve(this.page);
        });
    }

    clearModule() {
        this.page.moduleId = '';
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
