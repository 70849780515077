import { DataEvent } from './dataEvent';
import { DataSource } from './dataSource';


export class DragedData {
    id: string;
    text: string;
    type: string;
    content: any;
    isTemplate?: boolean;
    width?: number;
    height?: number;
    source?: DataSource;
    event?: DataEvent;
}
