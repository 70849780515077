/**
 * 属性
 */
export class EventData {
    Name: string;
    Desc: string;
}

/**
 * 通用属性
 */
export class CommonEvent {
    Version: string;
    LastUpdateTime: string;
    Events: EventData[] = [];
}
