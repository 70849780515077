export class RegexUtil {
    /**
     * ip校验
     */
    static isIp(str: string): boolean {
        // tslint:disable-next-line: max-line-length
        const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
        return reg.test(str);
    }
}
