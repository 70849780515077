export class ProjectConfig {
    /**
     * 工程首页id
     */
    homeId: string;
    /**
     * 压缩JS脚本
     */
    expressJs: boolean;
    /**
     * 页面无操作锁定时间
     */
    lockIdleMinute: number;
}
