<div #colorObj>
    <input nz-input nzSize="default" [(ngModel)] ="color" style="padding-left:30px;" />
    <input  
        [style.background]="color" 
        [(colorPicker)]="color"
        [cpWidth] = "200" 
        [cpPosition]="position"
        [cpPositionRelativeToArrow]="false"
        (colorPickerChange)="colorPickerChangeFun($event)" 
        [cpPresetColors]="presentColors"
        style="width: 15px; height: 15px;position: absolute;left: 15px;
        top: 9px; border-radius:25%; cursor: pointer;border:1px solid #e8e8e8" 
        (colorPickerOpen) = 'open($event)'
        (colorPickerClose) = 'close()'
        readonly
        [cpCancelButton] = 'true'
    />
</div>

