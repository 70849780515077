import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Project } from '../_models';
import { ProjectApi } from '../api';

/*选择项目 */
@Component({
    selector: 'app-select-project',
    template: `
    <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
        <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
            <nz-radio-group [(ngModel)]="selProjectId" [nzButtonStyle]="'solid'" *ngFor="let item of projectList">
                <label nz-radio-button nzValue="{{item.id}}">{{item.name}}</label>
            </nz-radio-group>
        </cdk-virtual-scroll-viewport>
    </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 300px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        nz-radio-group,label{
            width:100%;
        }
        `
    ]

})
export class SelectProjectComponent implements OnInit, OnDestroy {
    @Input() selProjectId = '';
    projectList: Project[] = [];

    isLoading = true;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private projectApi: ProjectApi
    ) {
    }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.projectList = await this.projectApi.getAll();
        this.isLoading = false;
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.selProjectId) {
                this.message.warning('请选择要打开的项目');
                resolve(false);
                return;
            }
            resolve(this.selProjectId);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
