<nz-layout (click)="globalClick($event)" (contextmenu)="globalRightClick($event)" (keydown)="onKeydown($event)"
  (keyup)="onkeyup($event)" tabindex="0">

  <app-header></app-header>

  <app-left-component #left></app-left-component>

  <nz-layout>
    <nz-content class="canvas"
      [ngStyle]="{'margin-left':getLeftState()?'80px':'250px','margin-right':getRightState()?'0':'300px'}">
      <app-tool-bar></app-tool-bar>
      <app-canvas></app-canvas>
    </nz-content>
  </nz-layout>

  <app-right #right></app-right>

  <div class="code" *ngIf="showJs">
    <div class="code-tool">
      <i nz-icon nzType="fullscreen-exit" nzTheme="outline" nz-tooltip nzTooltipTitle="最小化" class="iconMinus"
        (click)="setToMinus($event)"></i>
      <span nz-tooltip nzTooltipTitle="格式化" class="iconfont iconmeihuadaima format" (click)="formatCode()" n></span>

      <button nz-button nzType="primary">TODO</button>
    </div>
    <code-input ngDefaultControl lang="javascript" style="width: 100%;height: calc(100% - 40px);border: 1px solid;"
      [(ngModel)]="content" (ngModelChange)="onChange($event)" [style.border-color]="codeBorderColor"></code-input>
  </div>

</nz-layout>