/**
 * 弹窗报警
 */
export class AlarmAlert {
    show: boolean;
    /**
     * 来源。0：数据库；1：数据池
     */
    from: number;

    topics = [];
    sql: string;

    playAudio: boolean;
    /**
     * 语音播报次数(0：无限次，直到点击确定按钮)
     */
    playCount: number;
}