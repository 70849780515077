
/**
 * 数据源
 */
export class DataSource {
    /**
     * 数据源ID
     */
    id: string;
    /**
     * 设备编号
     */
    code: string;
    /**
     * 执行动作脚本
     */
    action: string;
    /**
     * 是否为动态数据源
     */
    dynamic: boolean;
}
