import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Template } from '../_models';
import { HttpService } from '../_services';


/**
 * web组态API-工程->模板
 */
@Injectable({ providedIn: 'root' })
export class TemplateApi {
    private prefix = '/design/template';
    constructor(
        private message: NzMessageService,
        private http: HttpService
    ) {
    }

    /**
     * 创建模板
     * @param template 模板
     */
    async create(template: Template) {
        if (!template) {
            this.message.warning('参数错误 ！');
            return false;
        }
        return await this.http.post(`${this.prefix}/create`, template);
    }

    /**
     * 获取模板数据
     * @param id 模板id
     */
    async get(id: string) {
        if (!id) {
            this.message.warning('参数错误[模板id]');
            return false;
        }
        return await this.http.get(`${this.prefix}/get`, { id });
    }

    /**
     * 获取分类下所有模板数据
     * @param classifyId 分类id
     */
    async getAll(classifyId: string) {
        if (!classifyId) {
            this.message.warning('参数错误[分类id]');
            return false;
        }
        return await this.http.get(`${this.prefix}/getAll`, { classifyId });
    }

    /**
     * 删除模板
     * @param id 模板id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[模板id]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 重命名
     * @param id 模板id
     * @param name 模板名称
     */
    async rename(id: string, name: string) {
        if (!id) {
            this.message.warning('参数错误[模板id]');
            return false;
        }
        if (!name) {
            this.message.warning('参数错误[模板名称]');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/rename`, { id, name });
    }

    /**
     * 属性配置
     * @param id 模板id
     * @param name 模板名称
     * @param classifyId 分类id
     */
    async saveConfig(id: string, name: string, classifyId: string) {
        if (!id || !name || !classifyId) {
            this.message.warning('参数错误');
            return false;
        }

        return await this.http.post_str(`${this.prefix}/saveConfig`, { id, name, classifyId });
    }

    /**
     * 另存为
     * @param id 模板id
     * @param name 模板名称
     * @param classifyId 分类id
     */
    async saveAs(id: string, name: string, classifyId: string) {
        if (!id || !name || !classifyId) {
            this.message.warning('参数错误');
            return false;
        }
        return await this.http.post_str(`${this.prefix}/saveAs`, { id, name, classifyId });
    }

    /**
     * 保存
     * @param template 模板
     */
    async save(template: Template) {
        if (!template) {
            this.message.warning('参数错误');
            return false;
        }
        return await this.http.post(`${this.prefix}/save`, template);
    }

    /**
     * 还原模板
     * @param template 模板
     */
    async restore(template: Template) {
        if (!template) {
            this.message.warning('参数错误');
            return false;
        }
        return await this.http.post(`${this.prefix}/restore`, template);
    }
}
