<div class="main">
    <div class="desc">
        <span class="title">绑定<span class="mark">组态页面</span>:</span>
        <div class="remark">id为组态页面id(包含用户页面)</div>
        <div class="code">
            <@p2.*>.value('id');
        </div>
        <div class="remark">页面传参</div>
        <div class="code">
            <@p2.*>.value('id?code='+1);
        </div>
        <div class="code">
            <@p2.*>.value('id?code='+1+'&name=测试');
        </div>
    </div>
    <div class="desc">
        <span class="title">绑定<span class="mark">CMS页面</span>:</span>
        <div class="remark">id为组态中添加的CMS页面id</div>
        <div class="code">
            <@p2.*>.value('${{'{'}}cms{{'}'}}/id');
        </div>
        <div class="code">
            <@p2.*>.value('${{'{'}}cms{{'}'}}/id?code='+1);
        </div>
        <div class="remark">当CMS页面未在组态中添加时，可直接使用CMS页面ID</div>
        <div class="code">
            <@p2.*>.value('${{'{'}}cms{{'}'}}/cmsId');
        </div>
    </div>
</div>