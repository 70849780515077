
        <span>替换内容：</span>
        <nz-radio-group [(ngModel)]="replaceType">
          <label nz-radio nzValue="id">组件id</label>
          <label nz-radio nzValue="text">文本</label>
          <label nz-radio nzValue="source">数据源编号</label>
          <label nz-radio nzValue="paramType">变量类型</label>
          <label nz-radio nzValue="sourceJs">数据源脚本</label>
          <label nz-radio nzValue="event">事件脚本</label>
        </nz-radio-group>
        <textarea rows="4" nz-input [(ngModel)]="oldStr" placeholder="待替换的内容"></textarea>
        <textarea rows="4" nz-input [(ngModel)]="newStr" placeholder="新内容"></textarea>
    