/**
 * 节点类型
 */
export enum NodeType {
    /**
     * 普通页面
     */
    Page = 0,
    /**
     * 系统页面
     */
    Sys = 1,
    /**
     * 模块
     */
    Module = 2,
    /**
     * 用户页面
     */
    User = 3
}
