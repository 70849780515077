/**
 * 查找条件
 */
export enum FindWhere {
    /**
     * 等于
     */
    equal = 1,
    /**
     * 包含
     */
    like = 2,
    /**
     * 以...开头
     */
    start = 3,
    /**
     * 以...结尾
     */
    end = 4
}
