import { Injectable } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PageApi } from 'src/app/api';
import { CanvasCache, DataCache } from 'src/app/cache';
import { CanvasConstData, ComponentType, DataAttrName, LoadingMsg } from 'src/app/const';
import { CommondAction } from 'src/app/_helpers/commondAction';
import { CreateTableComponent, FindComponent, JsEventComponent, ReplaceComponent, SaveSvgPageComponent } from 'src/app/_modals';
import { DataEvent, DataSource, FindType, FindWhere, Page } from 'src/app/_models';
import { PageData } from 'src/app/_models/pageData';
import { EventTransService } from 'src/app/_services';
import { TemplateTool } from './templateTool';

import { CommonFunc } from './commonFunc';
import { ComponentTool } from './componentTool';
import { MathUtil } from 'src/app/utils';

declare var Snap: any;
declare var $: any;

/**
 * 事件处理
 */
@Injectable({ providedIn: 'root' })
export class CanvasTool {

    constructor(
        private drawerService: NzDrawerService,
        private modalService: NzModalService,
        private message: NzMessageService,
        private pageApi: PageApi,
        private commonFunc: CommonFunc,
        private componentTool: ComponentTool,
        private templateTool: TemplateTool,
        private eventTransService: EventTransService
    ) {
    }

    /**
     * 组件按下事件
     * @param dom 组件
     * @param isSelectAll 是否全选模式
     */
    svgDomMouseDown(dom, isSelectAll = false) {
        CanvasCache.isFoucus = true;
        this.eventTransService.openJsWindow.next(null);
        if (CanvasCache.isDrawMode) {
            return;
        }
        //初始化组件id  
        this.commonFunc.setId(dom);
        CanvasCache.selectedComponent = dom;

        CanvasCache.isCanvasMouseDown = true;
        setTimeout(() => {
            CanvasCache.isCanvasMouseDown = false;  // 阻止事件冒泡
        }, 500);

        if (dom.hasClass(CanvasConstData.subjxClass)) {
            if (CanvasCache.isCtrlDown && !isSelectAll) {
                // ctrl键处于按下状态
                $(dom.node).data('drag').disable();
                this.eventTransService.selectedObjChangeEvent.next({ Component: null });
            } else {
                if (this.commonFunc.getSelectedSize() === 1) {
                    this.eventTransService.selectedObjChangeEvent.next({ Component: dom });
                }
            }
            return;
        }

        if (!CanvasCache.isCtrlDown && !isSelectAll) {
            this.commonFunc.clearSelected();
        }
        $(dom.node).data('dom', dom);
        this.commonFunc.setTransform(dom.node);
        // 多选时不发布变更通知
        if (this.commonFunc.getSelectedSize() <= 1) {
            this.eventTransService.selectedObjChangeEvent.next({ Component: dom });
        }
    }

    /**
     * 元素事件绑定
     */
    bindEvent(dom: any) {
        const that = this;
        dom.mousedown(function (e) {
            this.lastSelDom = this;
            that.svgDomMouseDown(this);
            e.stopPropagation();
        }).click((e: any) => {
            if (CanvasCache.isDrawMode) {
                return;
            }
            this.eventTransService.menuStateChangeEvent.next({});
            e.stopPropagation();
        }).dblclick(function (e) {
            const disable = $(this.node).attr(DataAttrName.disableSelect);
            if (disable) {
                $(this.node).attr(DataAttrName.disableSelect, '');
                this.mousedown(function (e) {
                    this.lastSelDom = this;
                    that.svgDomMouseDown(this);
                    e.stopPropagation();
                }).mousedown();
                that.message.success('组件已启用选中功能!');
            } else {
                $(this.node).attr(DataAttrName.disableSelect, '1');
                $(this.node).data('drag').disable();
                this.unmousedown();
                setTimeout(() => {
                    //防止事件重复绑定
                    this.unmousedown();
                }, 0);
                that.message.success('组件已禁用选中功能!');
            }
        });
    }

    /**
     * 全选
     */
    selectAll() {
        CanvasCache.svg.children().forEach((item: any) => {
            // 排除网格标签
            if (this.commonFunc.isDataNode(item)) {
                const bbox = item.getBBox();
                const width = bbox.width;
                const height = bbox.height;
                if (width === 0 && height === 0) {
                    item.remove();
                    return true;
                }
                const dataId = item.attr(DataAttrName.id);
                if (this.commonFunc.isFilterDom(dataId) || item.attr(DataAttrName.disableSelect)) {
                    return true;
                }
                this.svgDomMouseDown(item, true);
            }
        });
    }

    /**
     * 去除页面留白
     */
    removeBlank(num = 0) {
        this.eventTransService.toolBarSetChange.next('u');
        this.commonFunc.clearSelected();
        this.componentTool.moveToCorner(CanvasCache.svg.children(), num);

        let maxX = 0;
        let maxY = 0;
        CanvasCache.svg.children().forEach((item: any) => {
            if (!this.commonFunc.isDataNode(item)) {
                return true;
            }
            const bbox = item.getBBox();
            const width = bbox.width;
            const height = bbox.height;
            if (width === 0 && height === 0) {
                item.remove();
                return true;
            }
            if (bbox.x + width > maxX) {
                maxX = bbox.x + width;
            }
            if (bbox.y + height > maxY) {
                maxY = bbox.y + height;
            }
        });
        if (maxX === 0 || maxY === 0) {
            this.message.warning('页面没有任何元素！');
            return;
        }
        maxX += num;
        maxY += num;
        CanvasCache.svg.node.style.setProperty('width', maxX.toFixed(0));
        CanvasCache.svg.node.style.setProperty('height', maxY.toFixed(0));
        CanvasCache.isOperateChange = true;
    }

    /**
     * 查找
     */
    doFind() {
        if (DataCache.template) {
            this.message.warning('该功能不支持在模板中使用！');
            return;
        }
        if (!DataCache.page) {
            this.message.warning('请先打开页面！');
            return;
        }
        this.modalService.create({
            nzTitle: '查找',
            nzContent: FindComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(res => {
                if (!res) {
                    return res;
                }
                const type = res.type;
                const where = res.where;
                const content = res.content;
                let count = 0;
                switch (type) {
                    case FindType.source: {
                        count = this.findSource(where, content);
                        break;
                    }
                    case FindType.id: {
                        count = this.findId(where, content);
                        break;
                    }
                    case FindType.sourceJs: {
                        count = this.findSourceJs(where, content);
                        break;
                    }
                    case FindType.eventJs: {
                        count = this.findEventJs(where, content);
                        break;
                    }
                }
                if (count === 0) {
                    this.message.info('未查找到匹配项！');
                    return;
                }
                this.message.success(`匹配到的组件数：${count}`);
                return res;
            })
        });
    }

    /**
     * 按数据源编号查找
     * @param where 查找条件
     * @param content 查找内容
     */
    findSource(where: FindWhere, content: string): number {
        const ids = [];
        DataCache.pageData.sources.forEach((ds: DataSource) => {
            if (!ds || !ds.code) {
                return true;
            }
            switch (where) {
                case FindWhere.equal: {
                    if (ds.code === content) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.like: {
                    if (ds.code.indexOf(content) >= 0) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.start: {
                    if (ds.code.startsWith(content)) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.end: {
                    if (ds.code.endsWith(content)) {
                        ids.push(ds.id);
                    }
                    break;
                }
            }
        });
        this.commonFunc.clearSelected();
        let count = 0;
        CanvasCache.svg.children().forEach((item: any) => {
            if (this.commonFunc.isDataNode(item)) {
                if (item.attr(DataAttrName.source) &&
                    ids.indexOf(item.attr(DataAttrName.source)) >= 0) {
                    this.svgDomMouseDown(item, true);
                    count++;
                }
            }
        });
        return count;
    }

    /**
     * 根据组件id查找
     * @param where 查找条件
     * @param content 查找内容
     */
    findId(where: FindWhere, content: string): number {
        this.commonFunc.clearSelected();
        let count = 0;

        let tag;
        switch (where) {
            case FindWhere.equal: {
                tag = `[id='${content}']`;
                break;
            }
            case FindWhere.like: {
                tag = `[id*='${content}']`;
                break;
            }
            case FindWhere.start: {
                tag = `[id^='${content}']`;
                break;
            }
            case FindWhere.end: {
                tag = `[id$='${content}']`;
                break;
            }
        }
        const nodes = CanvasCache.svg.selectAll(tag);
        nodes.forEach(item => {
            this.svgDomMouseDown(item, true);
        })
        return nodes.length;
    }

    /**
     * 根据数据源脚本查找
     * @param where 查找条件
     * @param content 查找内容
     */
    findSourceJs(where: FindWhere, content: string): number {
        const ids = [];
        DataCache.pageData.sources.forEach((ds: DataSource) => {
            if (!ds || !ds.code || !ds.action) {
                return true;
            }
            switch (where) {
                case FindWhere.equal: {
                    if (ds.action === content) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.like: {
                    if (ds.action.indexOf(content) >= 0) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.start: {
                    if (ds.action.startsWith(content)) {
                        ids.push(ds.id);
                    }
                    break;
                }
                case FindWhere.end: {
                    if (ds.action.endsWith(content)) {
                        ids.push(ds.id);
                    }
                    break;
                }
            }
        });
        this.commonFunc.clearSelected();
        let count = 0;
        CanvasCache.svg.children().forEach((item: any) => {
            if (this.commonFunc.isDataNode(item)) {
                if (item.attr(DataAttrName.source) &&
                    ids.indexOf(item.attr(DataAttrName.source)) >= 0) {
                    this.svgDomMouseDown(item, true);
                    count++;
                }
            }
        });
        return count;
    }

    /**
     * 根据事件脚本查找
     * @param where 查找条件
     * @param content 查找内容
     */
    findEventJs(where: FindWhere, content: string): number {
        const ids = [];
        DataCache.pageData.events.forEach((de: DataEvent) => {
            if (!de || !de.action) {
                return true;
            }
            switch (where) {
                case FindWhere.equal: {
                    if (de.action === content) {
                        ids.push(de.id);
                    }
                    break;
                }
                case FindWhere.like: {
                    if (de.action.indexOf(content) >= 0) {
                        ids.push(de.id);
                    }
                    break;
                }
                case FindWhere.start: {
                    if (de.action.startsWith(content)) {
                        ids.push(de.id);
                    }
                    break;
                }
                case FindWhere.end: {
                    if (de.action.endsWith(content)) {
                        ids.push(de.id);
                    }
                    break;
                }
            }
        });
        this.commonFunc.clearSelected();
        let count = 0;
        CanvasCache.svg.children().forEach((item: any) => {
            if (this.commonFunc.isDataNode(item)) {
                if (item.attr(DataAttrName.event) &&
                    ids.indexOf(item.attr(DataAttrName.event)) >= 0) {
                    this.svgDomMouseDown(item, true);
                    count++;
                }
            }
        });
        return count;
    }

    /**
     * 替换
     */
    doReplace() {
        if (DataCache.template) {
            this.message.warning('该功能不支持在模板中使用！');
            return;
        }
        if (!DataCache.page) {
            this.message.warning('请先打开页面！');
            return;
        }
        if (this.commonFunc.getSelectedSize() === 0) {
            this.message.warning('请先选择组件！');
            return;
        }
        this.modalService.create({
            nzTitle: '替换',
            nzWidth: '700px',
            nzContent: ReplaceComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(res => {
                if (res) {
                    // 替换类型：文本、数据源、数据源脚本、事件脚本
                    const type = res.Type;
                    const oldStr = res.OldStr;
                    const newStr = res.NewStr;
                    if (type === 'id') {
                        this.commonFunc.replaceId(oldStr, newStr);
                    } else if (type === 'text') {
                        this.commonFunc.replaceText(oldStr, newStr);
                    } else if (type === 'source') {
                        this.commonFunc.replaceSource(oldStr, newStr);
                    } else if (type === 'paramType') {
                        this.commonFunc.replaceParamType(oldStr, newStr);
                    } else if (type === 'sourceJs') {
                        this.commonFunc.replaceSourceJs(oldStr, newStr);
                    } else if (type === 'event') {
                        this.commonFunc.replaceEvent(oldStr, newStr);
                    } else {
                        this.message.warning(`未识别的类型：${type}`);
                    }
                }
                return res;
            })
        });
    }

    /**
     * 复制
     */
    copy() {
        if (this.commonFunc.getSelectedSize() === 0) {
            this.message.warning('请先选择要复制的组件！');
            return;
        }
        if (DataCache.template) {
            this.message.warning('复制模板中的元素，将不会复制数据源与事件信息！！！');
        }

        CanvasCache.copyDoms = [];
        CanvasCache.copyDataDefs = [];
        CanvasCache.copyDataSources = [];
        CanvasCache.copyDataEvents = [];

        if (DataCache.pageData) {
            CanvasCache.copyDataSources = JSON.parse(JSON.stringify(DataCache.pageData.sources));
            CanvasCache.copyDataEvents = JSON.parse(JSON.stringify(DataCache.pageData.events));
        }

        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            dom.removeClass(CanvasConstData.subjxClass);
            CanvasCache.copyDoms.push(dom);

            if (dom.attr(DataAttrName.template)) {
                const node = CanvasCache.svg.select(`[id='${dom.attr(DataAttrName.template)}']`);
                if (node) {
                    // 嵌套模板处理
                    node.children().forEach(subNode => {
                        if (!this.commonFunc.isDataNode(subNode)) {
                            return true;
                        }
                        if (subNode.attr(DataAttrName.template)) {
                            const subDef = CanvasCache.svg.select(`[id='${subNode.attr(DataAttrName.template)}']`);
                            if (subDef) {
                                CanvasCache.copyDataDefs.push(subDef);
                            }
                        }
                    });
                    CanvasCache.copyDataDefs.push(node);
                }
            }
        });
        // 启用粘贴按钮
        CanvasCache.enablePaste = true;
        this.message.success(`已复制到粘贴板 [元素数：${this.commonFunc.getSelectedSize()}]！`);
    }

    /**
     * 粘贴
     */
    paste() {
        if (CanvasCache.copyDoms.length === 0) {
            this.message.warning('粘贴板为空，请先执行复制操作！');
            return;
        }

        let diffX = 0;
        let diffY = 0;
        let isFirst = true;

        // 粘贴组件模板信息
        CanvasCache.copyDataDefs.forEach(item => {
            const defs = CanvasCache.svg.select(`[id='${item.attr('id')}']`);
            if (defs) {
                return true;
            }
            const fragment = Snap.parse(item.toString());
            CanvasCache.svg.append(fragment);

            const defNode = CanvasCache.svg.children()[CanvasCache.svg.children().length - 1].toDefs();
            if (defNode.parent().parent().type !== 'svg') {
                CanvasCache.svg.add(defNode.parent());
            }
        });

        // 粘贴组件信息
        CanvasCache.copyDoms.forEach(item => {
            const fragment = Snap.parse(item.toString());
            CanvasCache.svg.append(fragment);

            const dom = CanvasCache.svg.children()[CanvasCache.svg.children().length - 1];
            if (isFirst) {
                diffX = CanvasCache.mouseOffsetX - dom.getBBox().x;
                diffY = CanvasCache.mouseOffsetY - dom.getBBox().y;
                isFirst = false;
            }

            dom.attr({
                transform: dom.transform().local + (dom.transform().local ? 'T' : 't') + `${diffX} ${diffY}`
            }).unmousedown();

            this.handlePasteDomData(dom);
            this.bindEvent(dom);

            //列表面版处理
            const panels = dom.selectAll(`[${DataAttrName.componentName}='${ComponentType.panel}']`);
            panels.forEach((panel: any) => {
                panel.select('svg').children().forEach((item: any) => {
                    if (!this.commonFunc.isDataNode(item)) {
                        return true;
                    }
                    this.handlePasteDomData(item);
                    this.bindEvent(item);
                });
            });
        });

        CanvasCache.isOperateChange = true;
    }

    handlePasteDomData(dom) {
        // 清除id
        dom.attr('id', '');
        dom.attr(DataAttrName.animeId, '');

        // 数据源处理
        let dsId = dom.attr(DataAttrName.source);
        if (dsId) {
            // 清除数据源ID
            dom.attr(DataAttrName.source, '');
            if (DataCache.page) {
                const dataSource = CanvasCache.copyDataSources.find(d => d.id === dsId);
                if (dataSource) {
                    dsId = MathUtil.getUUid();
                    dom.attr(DataAttrName.source, dsId);
                    const ds: DataSource = JSON.parse(JSON.stringify(dataSource));
                    ds.id = dsId;
                    DataCache.pageData.sources.push(ds);
                }
            }
        }

        //事件处理
        let deId = dom.attr(DataAttrName.event);
        if (deId) {
            // 清除事件ID
            dom.attr(DataAttrName.event, '');
            if (DataCache.page) {
                const dataEvent = CanvasCache.copyDataEvents.find(d => d.id === deId);
                if (dataEvent) {
                    deId = MathUtil.getUUid();
                    dom.attr(DataAttrName.event, deId);
                    const de: DataEvent = JSON.parse(JSON.stringify(dataEvent));
                    de.id = deId;
                    DataCache.pageData.events.push(de);
                }
            }
        }
        // 图表属性处理
        const uid = dom.attr(DataAttrName.uid);
        if (uid) {
            const newUid = MathUtil.getUUid();
            dom.attr(DataAttrName.uid, newUid);
        }
    }



    /**
     * 删除
     */
    delete() {
        if (this.commonFunc.getSelectedSize() === 0) {
            return;
        }
        CanvasCache.isOperateChange = true;
        this.commonFunc.getSelectedDoms().forEach((item: any) => {
            const dom = $(item.el).data('dom');
            if (DataCache.page) {
                const sid = dom.attr(DataAttrName.source);
                if (sid) {
                    DataCache.pageData.sources = DataCache.pageData.sources.filter(d => d.id !== sid);
                }
                const eid = dom.attr(DataAttrName.event);
                if (eid) {
                    DataCache.pageData.events = DataCache.pageData.events.filter(d => d.id !== eid);
                }
            }

            const templateId = dom.attr(DataAttrName.template);
            $(item.el).remove();

            if (templateId) {
                const tnode = CanvasCache.svg.select(`[${DataAttrName.template}='${templateId}']`);
                if (!tnode) {
                    const def = CanvasCache.svg.select(`[id='${templateId}']`);
                    if (def) {
                        def.remove();
                    }
                }
            }
        });
        this.commonFunc.clearSelected();
        this.eventTransService.selectedObjChangeEvent.next(null);
        this.eventTransService.canvasElementChange.next(true);
    }

    /**
     * 保存画布内容
     */
    saveCanvas() {
        if (!DataCache.project) {
            this.message.warning('请先打开项目！');
            return;
        }

        if (DataCache.template) {
            this.templateTool.saveTemplate();
            return;
        }
        if (DataCache.page) {
            this.savePageData();
            return;
        }

        this.modalService.create({
            nzTitle: '保存页面',
            nzContent: SaveSvgPageComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((page: Page) => {
                if (page) {
                    this.createPage(page);
                }
                return page;
            })
        });
    }

    /**
     * 保存页面数据
     */
    async savePageData() {
        this.eventTransService.globalLoadingEvent.next(LoadingMsg.update);
        if (CanvasCache.svg.select(`[${DataAttrName.componentName}='${ComponentType.panel}']`)) {
            this.commonFunc.clearSelected();
        }
        const data: PageData = {
            id: DataCache.page.id,
            projectId: DataCache.page.projectId,
            content: this.commonFunc.getCanvasData(),
            sources: DataCache.pageData.sources,
            events: DataCache.pageData.events,
            globalEvent: DataCache.pageData.globalEvent,
            customJs: DataCache.pageData.customJs
        };
        const ret = await this.pageApi.saveData(data);
        if (ret) {
            this.message.success('操作成功！');
            this.eventTransService.globalLoadingEvent.next('');
            CommondAction.isChange = false;
            this.eventTransService.clearCanvasCache.next(DataCache.page.id);
        }
    }

    /**
     * 创建页面
     */
    async createPage(page: Page) {
        this.eventTransService.globalLoadingEvent.next(LoadingMsg.update);
        const content = this.commonFunc.getCanvasData();

        const id = await this.pageApi.createWithContent(page, content);
        if (id) {
            page.id = id;
            DataCache.page = page;
            DataCache.pageData = {
                id,
                projectId: page.projectId,
                content,
                sources: [],
                events: [],
                globalEvent: ''
            };
            this.message.success('操作成功！');
            this.eventTransService.globalLoadingEvent.next('');
            CommondAction.isChange = false;

            this.eventTransService.loadModuleEvent.next(page.moduleId);
            this.eventTransService.canvasDataChange.next(true);
        }
    }

    async loadCanvas(PageContent: any) {
        this.commonFunc.clearCanvas();

        PageContent = PageContent.replace(/"NaN"/g, '"0"');
        const svgNode = Snap.parse(PageContent);

        const startIndex = PageContent.indexOf('<svg');
        if (startIndex > 0) {
            PageContent = PageContent.replace(PageContent.substring(0, startIndex), '');
        }
        const stopIndex = PageContent.indexOf('>');
        const startStr = PageContent.substring(0, stopIndex + 1);
        PageContent = PageContent.replace(startStr, '').replace('</svg>', '');

        const fragment = Snap.parse(PageContent);
        CanvasCache.svg.append(fragment);

        if (svgNode.node.id) {
            CanvasCache.svg.attr('id', svgNode.node.id);
        } else {
            CanvasCache.svg.attr('id', 'svgCanvas');
        }

        CanvasCache.svg.attr(DataAttrName.bgOpacity, svgNode.node.getAttribute(DataAttrName.bgOpacity));

        CanvasCache.svg.children().forEach((item: any) => {
            if (!this.commonFunc.isDataNode(item)) {
                return true;
            }
            item.removeClass(CanvasConstData.subjxClass);
            this.bindEvent(item);
        });

        //列表面版内部元素事件绑定
        const panels = CanvasCache.svg.selectAll(`[${DataAttrName.componentName}='${ComponentType.panel}']`);
        panels.forEach((panel: any) => {
            panel.select('svg').children().forEach((item: any) => {
                if (!this.commonFunc.isDataNode(item)) {
                    return true;
                }
                item.removeClass(CanvasConstData.subjxClass);
                this.bindEvent(item);
            });
        });

        // 复制页面样式
        if (svgNode.node.attributes && svgNode.node.attributes.style) {
            CanvasCache.svg.attr({ style: svgNode.node.attributes.style.value });
        }

        // 指定页面大小
        if (svgNode.node.attributes.viewBox.value) {
            const arr = svgNode.node.attributes.viewBox.value.split(' ');
            CanvasCache.svg.node.style.setProperty('width', arr[2]);
            CanvasCache.svg.node.style.setProperty('height', arr[3]);
        }
        if (CanvasCache.svgScaleNum != 1) {
            CanvasCache.svg.node.style.setProperty('transform', `scale(${CanvasCache.svgScaleNum})`);
        }
    }

    /**
     * 打开脚本编辑器
     */
    openJsEvent() {
        if (DataCache.template) {
            this.message.warning('模板不支持该功能！');
            return;
        }
        if (!DataCache.page) {
            this.message.warning('请先打开页面！');
            return;
        }
        this.drawerService.create({
            nzTitle: '脚本编辑器（页面加载时执行）',
            nzContent: JsEventComponent,
            nzMask: false,
            nzClosable: true,
            nzMaskClosable: true,
            nzPlacement: 'bottom',
            nzHeight: 600
        });
    }

    /**
     * 画布中插入表格
     */
    createTable() {
        this.modalService.create({
            nzTitle: '插入表格',
            nzContent: CreateTableComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((res: any) => {
                const colNum = res.ColNum;
                const rowNum = res.RowNum;
                const width = res.Width;
                const height = res.Height;
                const isSingle = res.IsSingle;

                const startX = 80;
                const startY = 80;

                const totalWidth = width * colNum;
                const totalHeight = height * rowNum;

                if (isSingle) {
                    const group = CanvasCache.svg.paper.g().attr({ 'data-attr-text': '自定义表格' });
                    for (let r = 0; r < rowNum; r++) {
                        group.add(CanvasCache.svg.paper.path(`M${startX} ${startY + r * height}
                                           L${startX + totalWidth} ${startY + r * height}
                                           L${startX + totalWidth} ${startY + (r + 1) * height}
                                           L${startX} ${startY + (r + 1) * height}Z`)
                            .attr({
                                stroke: '#a1a1a1',
                                fill: 'rgba(213,209,231,0.3)',
                                strokeWidth: 2
                            }));
                    }
                    for (let c = 0; c < colNum; c++) {
                        group.add(CanvasCache.svg.paper.path(`M${startX + c * width} ${startY}
                        L${startX + c * width} ${startY + totalHeight}Z`)
                            .attr({
                                stroke: '#a1a1a1',
                                fill: 'rgba(213,209,231,0.3)',
                                strokeWidth: 2
                            }));
                    }
                    this.bindEvent(group);
                } else {
                    const group = CanvasCache.svg.paper.g().attr({ 'data-attr-text': '自定义表格' });
                    group.add(CanvasCache.svg.paper.path(`M${startX} ${startY}L${startX + totalWidth} ${startY}
                                           L${startX + totalWidth} ${startY + totalHeight}
                                           L${startX} ${startY + totalHeight}Z`)
                        .attr({
                            stroke: '#a1a1a1',
                            fill: 'rgba(213,209,231,0.3)',
                            strokeWidth: 2
                        }));
                    this.bindEvent(group);

                    for (let r = 1; r < rowNum; r++) {
                        const groupX = CanvasCache.svg.paper.g().attr({ 'data-attr-text': '自定义表格-X' });
                        groupX.add(CanvasCache.svg.paper.path(`M${startX} ${startY + r * height}
                                                        L${startX + totalWidth} ${startY + r * height}`)
                            .attr({
                                stroke: '#a1a1a1',
                                fill: 'rgba(213,209,231,0.3)',
                                strokeWidth: 2
                            }));
                        this.bindEvent(groupX);
                    }

                    for (let c = 1; c < colNum; c++) {
                        const groupY = CanvasCache.svg.paper.g().attr({ 'data-attr-text': '自定义表格-Y' });
                        groupY.add(CanvasCache.svg.paper.path(`M${startX + c * width} ${startY}
                                                            L${startX + c * width} ${startY + totalHeight}`)
                            .attr({
                                stroke: '#a1a1a1',
                                fill: 'rgba(213,209,231,0.3)',
                                strokeWidth: 2
                            }));
                        this.bindEvent(groupY);
                    }
                }
                CanvasCache.isOperateChange = true;
                return true;
            })
        });
    }

    /**
     * 导出页面(保存发生异常时使用)
     */
    export() {
        if (!DataCache.page && !DataCache.template) {
            this.message.warning('未打开页面/模板，无法导出！');
            return;
        }
        if (DataCache.page) {
            this.exportPage();
            return;
        }
        this.exportTemplate();
    }

    exportPage() {
        const name = `页面-${DataCache.page.text}-${DataCache.page.id}`;

        const svgData = this.commonFunc.getCanvasData();
        const blob = new Blob([svgData], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `${name}.svg`);

        if (DataCache.pageData.sources.length > 0) {
            const ds = JSON.stringify(DataCache.pageData.sources);
            const dsBlob = new Blob([ds], { type: 'text/plain;charset=utf-8' });
            saveAs(dsBlob, `${name}.source`);
        }
        if (DataCache.pageData.events.length > 0) {
            const de = JSON.stringify(DataCache.pageData.events);
            const deBlob = new Blob([de], { type: 'text/plain;charset=utf-8' });
            saveAs(deBlob, `${name}.event`);
        }
        if (DataCache.pageData.globalEvent) {
            const ge = DataCache.pageData.globalEvent;
            const deBlob = new Blob([ge], { type: 'text/plain;charset=utf-8' });
            saveAs(deBlob, `${name}.globalEvent`);
        }
    }

    exportTemplate() {
        const name = `模板-${DataCache.template.text}-${DataCache.template.id}`;

        const svgData = this.commonFunc.getCanvasData();
        const blob = new Blob([svgData], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `${name}.svg`);

        if (DataCache.template.data.source) {
            const ds = JSON.stringify(DataCache.template.data.source);
            const dsBlob = new Blob([ds], { type: 'text/plain;charset=utf-8' });
            saveAs(dsBlob, `${name}.source`);
        }
        if (DataCache.template.data.event) {
            const de = JSON.stringify(DataCache.template.data.event);
            const deBlob = new Blob([de], { type: 'text/plain;charset=utf-8' });
            saveAs(deBlob, `${name}.event`);
        }
    }

    /**
     * 分解组件，还原数据源
     * @param dom 组件
     * @param dsId 数据源id
     * 
     */
    splitRestoreDataSource(dom: any, dsId: string) {
        dom.attr(DataAttrName.sourceMerge, '');
        if (!DataCache.page && !DataCache.template) {
            return;
        }
        if (DataCache.template) {
            return;
        }
        const source = DataCache.pageData.sources.find(d => d.id === dsId);
        if (!source) {
            return;
        }

        const nodes = CanvasCache.svg.selectAll(`[${DataAttrName.source}='${dsId}']`);
        if (nodes.length > 0) {
            const ds: DataSource = JSON.parse(JSON.stringify(source));
            source.id = MathUtil.getUUid();
            DataCache.pageData.sources.push(ds);
        }
        dom.attr(DataAttrName.source, source.id);
    }

    /**
     * 设置事件
     * @param dom 组件
     * @param deId 事件id
     */
    setDataEvent(dom: any, deId: string) {
        dom.attr(DataAttrName.eventMerge, '');
        dom.attr(DataAttrName.event, deId);
    }
}
