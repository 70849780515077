/**
 * 模板分类
 */
export class TemplateClassify {
    /**
     * 分类id
     */
    id: string;
    /**
     * 分类名称
     */
    text: string;
    /**
     * 排序
     */
    sort: number;
    /**
     * 工程id
     */
    projectId: string;
    /**
     * 分类下模板数量
     */
    count: number;
}
