import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Page } from '../_models';
import { EventTransService } from '../_services';
import { PageApi } from '../api';
import { DataCache } from '../cache';

/*选择组件分类 */
@Component({
    selector: 'app-page-upload',
    template: `
        <nz-input-group nzAddOnBefore="ID" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.name" placeholder="请输入页面ID"/>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="名称" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.text" placeholder="请输入页面名称"/>
        </nz-input-group>
        <div style="margin-top:5px;"></div>
        <label nz-checkbox [(ngModel)]="page.config.menu" style="width:25%;color:#ffa41af5">菜单页面</label>
        <label nz-checkbox [(ngModel)]="page.config.scale" style="width:25%;">允许缩放</label>
        <div style="margin-top:10px;"></div>
        <nz-input-group nzAddOnBefore="初始缩放" style="width:50%;">
              <nz-select [(ngModel)]="page.config.initScaleType" style="width:100%">
                <nz-option nzValue="0" nzLabel="自动"></nz-option>
                <nz-option nzValue="1" nzLabel="缩放"></nz-option>
                <nz-option nzValue="2" nzLabel="不缩放"></nz-option>
              </nz-select>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="权限级别" style="width:50%;">
            <nz-input-number style="width:100%" [(ngModel)]="page.config.level" [nzMin]="0" [nzMax]="999" [nzStep]="1"
                nzTooltipTitle="权限等级低于该值的用户无法访问该页面！" nzTooltipPlacement="bottom" nz-tooltip></nz-input-number>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="false"
            [nzLimit]="1"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="files">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p class="ant-upload-hint">支持的文件类型：zip</p>
        </nz-upload>
        </cdk-virtual-scroll-viewport>
    `,
    styles: [
        `.infinite-container{
            height: 200px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        nz-input-group{
            margin-bottom:10px;
        }
        `
    ]
})
export class PageUpLoadComponent implements OnInit, OnDestroy {
    @Input() moduleId = '';

    page: Page = new Page();

    files: NzUploadFile[] = [];

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private pageApi: PageApi
    ) { }

    ngOnInit() {
        if (!this.moduleId) {
            this.page.moduleId = this.moduleId;
        }
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        if (this.files && this.files.length >= 1) {
            return false;
        }

        this.files = this.files.concat(file);
        return false;
    }

    save(): any {
        if (!this.files || this.files.length === 0) {
            this.message.warning('请添加需要导入的页面！');
            return false;
        }
        if (!this.page.name) {
            this.message.warning(`请输入页面ID！`);
            return false;
        }
        if (!RegExp(/^[0-9a-zA-Z]*$/g).test(this.page.name)) {
            this.message.warning(`页面ID只能由字母、数字组成！`);
            return false;
        }
        if (!this.page.text) {
            this.message.warning(`请输入页面名称！`);
            return false;
        }

        this.files.forEach(async (file) => {
            const ret = await this.pageApi.upload(file, this.page.name, this.page.text, DataCache.project.id, this.moduleId,
                this.page.config.menu, this.page.config.scale, this.page.config.initScaleType,
                this.page.config.level);
            if (ret) {
                this.message.success(`页面${file.name}导入成功！`);
                this.eventTransService.loadModuleEvent.next(this.moduleId);
                this.files = [];
            }
        });
        return false;
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
