export class AnimeTwinkleProp {
    /**
     * 启用动画
     */
    enable = false;
    /**
     * 循环一次时间（毫秒）
     */
    duration = 300;
}
