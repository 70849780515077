<div class="main">
    <div class="desc">
        <div class="remark">
            使用方法和《文本》组件类似，文本换行组件可以根据组件宽高、文本内容自动换行。
        </div>
        <span class="title">改变<span class="mark">文本内容</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value('新文本内容新文本内容新文本内容新文本内容新文本内容新文本内容');
            </div>
        </div>

    </div>
</div>