<div class="toolbar">
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="顶端对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToTop)">
      <span class="iconfont icondingduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === '1'}">1</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="底端对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToBottom)">
      <span class="iconfont icondiduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === '2'}">2</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="左对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToLeft)">
      <span class="iconfont iconzuoduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === '3'}">3</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="右对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToRight)">
      <span class="iconfont iconyouduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === '4'}">4</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="垂直对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToVertical)">
      <span class="iconfont iconjuzhongduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === '5'}">5</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="水平对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignToMiddle)">
      <span class="iconfont iconchuizhijuzhong-"></span>
    </button>
    <div [ngClass]="{'set': setType === '6'}">6</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="中心点对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.Center)">
      <span class="iconfont iconzhongxindian"></span>
    </button>
    <div [ngClass]="{'set': setType === '7'}">7</div>
  </div>
  <div class="item driver">
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="水平间距对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignMiddleSpace)">
      <span class="iconfont iconshuipingjianju"></span>
    </button>
    <div [ngClass]="{'set': setType === '8'}">8</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="垂直间距对齐" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.AlignVerticalSpace)">
      <span class="iconfont iconchuizhijianju"></span>
    </button>
    <div [ngClass]="{'set': setType === '9'}">9</div>
  </div>

  <div class="item">
    <button nz-button nzType="link" nz-dropdown [nzDropdownMenu]="size" nz-tooltip nzTooltipTitle="大小相同"
      nzTooltipPlacement="top" (click)="toolBarClick(operateType.SameSize)">
      <span class="iconfont icondaxiaoxiangtong-01" style="font-size: 36px !important;margin: -20px 0 0 -15px;"></span>
      <i nz-icon nzType="down" style="margin-left: 15px;"></i>
    </button>
    <nz-dropdown-menu #size="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="toolBarClick(operateType.SameWidth)">
          <a>宽度相同</a>
        </li>
        <li nz-menu-item (click)="toolBarClick(operateType.SameHeight)">
          <a>高度相同</a>
        </li>
      </ul>
    </nz-dropdown-menu>
    <div style="top: -40px;" [ngClass]="{'set': setType === '0'}">0</div>
  </div>


  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="组件合并" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.MergeCom)">
      <span class="iconfont iconhebing" style="font-size: 24px !important;"></span>
    </button>
    <div style="top: -35px;" [ngClass]="{'set': setType === 'q'}">Q</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="组件拆分" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.SplitCom)">
      <span class="iconfont iconchaifen"></span>
    </button>
    <div [ngClass]="{'set': setType === 'w'}">W</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="置于顶层" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.SetToTop)">
      <span class="iconfont iconzhiyudingceng1" style="font-size: 24px !important;"></span>
    </button>
    <div style="top: -35px;" [ngClass]="{'set': setType === 'e'}">E</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="置于底层" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.SetToBottom)">
      <span class="iconfont iconzhiyudiceng1" style="font-size: 24px !important;"></span>
    </button>
    <div style="top: -35px;" [ngClass]="{'set': setType === 'r'}">R</div>
  </div>
  <div class="item driver">
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="水平居中(页面)" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.CenterToMiddle)">
      <span class="iconfont iconjuzhongduiqi-"></span>
    </button>
    <div [ngClass]="{'set': setType === 't'}">T</div>
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="垂直居中(页面)" nzTooltipPlacement="bottom"
      (click)="toolBarClick(operateType.CenterToVertical)">
      <span class="iconfont iconchuizhijuzhong-"></span>
    </button>
    <div [ngClass]="{'set': setType === 'y'}">Y</div>
  </div>


  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="去除页面留白" nzTooltipPlacement="top"
      (click)="toolBarClick(operateType.RemoveBlank)" [nzClickHide]="false" nz-dropdown [nzDropdownMenu]="blank">
      <span class="iconfont iconliubai"></span>
      <i nz-icon nzType="down" style="margin-left: 15px;"></i>
    </button>


    <nz-dropdown-menu #blank="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="removeBlank(10)">
          <a>留白10像素</a>
        </li>
        <li nz-menu-item (click)="removeBlank(20)">
          <a>留白20像素</a>
        </li>
        <li nz-menu-item style="display: flex;">
          <nz-input-number [(ngModel)]="blankValue" [nzMin]="0" [nzMax]="500" [nzStep]="1"></nz-input-number>
          <button nz-button nzType="dashed" (click)="removeBlank(blankValue)">确定</button>
        </li>
      </ul>
    </nz-dropdown-menu>
    <div [ngClass]="{'set': setType === 'u'}">U</div>
  </div>


  <div class="item driver">
  </div>
  <div class="item">
    <button nz-button nzType="link" nz-tooltip nzTooltipTitle="绘制折线" nzTooltipPlacement="bottom"
      [ngClass]="{btnDown: isDrawMode}" (click)="toolBarClick(operateType.DrawLine)">
      <span class="iconfont iconzhexian"></span>
    </button>
  </div>

  <div class="item" style="padding-bottom: 28px !important;">
    <a nz-dropdown [nzDropdownMenu]="filter" [nzClickHide]="false" nz-tooltip nzTooltipTitle="组件过滤"
      style="margin-left:6px;color: #ff7600d9;">
      <i class="iconfont iconguolv drop-left" style="font-size: 22px !important;"></i>
      <i nz-icon nzType="down" class="drop-right"></i>
    </a>
    <nz-dropdown-menu #filter="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterText" (ngModelChange)="filterSelChange()">文本</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterLineHor" (ngModelChange)="filterSelChange()">直线</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterLineVer" (ngModelChange)="filterSelChange()">竖线</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterRect" (ngModelChange)="filterSelChange()">矩形</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterCheckbox" (ngModelChange)="filterSelChange()">复选框</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterInputText" (ngModelChange)="filterSelChange()">文本输入框</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="filterInputNumber" (ngModelChange)="filterSelChange()">数字输入框</label>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>

  <div class="item driver">
  </div>

</div>

<div class="right">
  <div class="item">
    <a nz-dropdown [nzDropdownMenu]="tool" nz-tooltip nzTooltipTitle="工具箱">
      <i class="iconfont iconiconnormal drop-left"></i>
      <i nz-icon nzType="down" class="drop-right"></i>
    </a>
    <nz-dropdown-menu #tool="nzDropdownMenu">
      <ul nz-menu nzSelectable>

        <li nz-menu-item (click)="toolBarClick(operateType.ExportPage)">
          <span class="iconfont icondaochu-copy"></span>页面导出(网络中断时用)
        </li>

        <li nz-menu-item (click)="toolBarClick(operateType.CreateTable)">
          <span class="iconfont iconbiaoge"></span>插入表格
        </li>

      </ul>
    </nz-dropdown-menu>
  </div>

  <div class="size">
    <nz-select style="width: 80px;" nzShowSearch nzAllowClear nzPlaceHolder="Select Scale" nzSize="small"
      [(ngModel)]="svgScale" (ngModelChange)="scaleChange()">
      <nz-option [nzLabel]="item.Text" [nzValue]="item.Value" *ngFor="let item of svgScaleInit"></nz-option>
    </nz-select>
    <button nz-button nzType="link" (click)="resetScale()">重置</button>
  </div>
</div>
