import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'base64Pipe'
})

export class Base64Pipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }
    transform(base64: string, type: string): any {
        if (!base64) {
            return '/assets/img/null.png';
        }
        if (!type) {
            type = 'image/svg+xml';
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${type};base64,${base64}`);
    }
}
