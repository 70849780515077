<div class="main">
    <div class="desc">
        <span class="title">获取<span class="mark">选中项ID</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的ID</div>
            <div class="remark">多选模式返回结果为列表：[1]、[1 , 2]</div>
            <div>
                var id = <@p2.*>.value();
            </div>
            <div>
                var id = <@p2.*>.obj().getValue();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">选中项文本</span>:</span>
        <div class="code">
            <div class="remark">值为组件【属性>集合】中设置的名称</div>
            <div class="remark">多选模式返回结果为列表：['txt1']、['txt1' , 'txt2']</div>
            <div>
                var name = <@p2.*>.obj().getText();
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">选中项ID和文本</span>:</span>
        <div class="code">
            <div class="remark">返回数据格式：{{ '{' }}id : 1, name: 'title1'{{ '}' }}</div>
            <div>
                var obj = <@p2.*>.obj().getTextValue();
            </div>
            <div class="remark">使用方法，获取ID：obj.id；获取名称：obj.name</div>
            <div class="remark">多选模式返回数据格式：[{{ '{' }}id : 1, name: 'title1'{{ '}' }}]</div>
            <div class="remark">多选模式使用方法，获取第一项ID：obj[0].id；获取第一项名称：obj[0].name</div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">动态绑定数据源</span>:</span>
        <div class="code">
            <div>
                var items = [{{ '{' }}
                key: '1',
                title: '文本1'
                {{ '}' }},
                {{ '{' }}
                key: '2',
                title: '文本2'
                {{ '}' }}
                ];
            </div>
            <div>
                <@p2.*>.obj().initData(items);
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">节点前<span class="mark">增加图标</span>:</span>
        <div class="remark">1. 打开【工程->资源文件管理->图标文件管理】</div>
        <div class="remark">2. 上传所需图标，上传成功后会展示图标ID</div>
        <div class="remark">3. 平铺列表组件->属性->图标ID，输入图标ID后保存（所有节点都会使用该图标）</div>
        <div class="remark">4. 节点图标自定义：</div>
        <div class="remark">平铺列表组件->集合->自定义数据源，输入图标ID</div>
        <div class="remark">5. 使用脚本动态绑定带图标的数据源：</div>
        <div class="code">
            <div>
                var items = [{{ '{' }}
                key: '1',
                title: '文本1',
                icon: 0
                {{ '}' }},
                {{ '{' }}
                key: '2',
                title: '文本2',
                icon: 1
                {{ '}' }}
                ];
            </div>
            <div>
                <@p2.*>.obj().initData(items);
            </div>
        </div>
    </div>
</div>