import { Page } from './page';

export class CanvasData {
    /**
     * 页面/模板id
     */
    id: string;
    /**
     * 页面数据
     */
    data?: any;
    /**
     * 是否页面
     */
    isPage: boolean;

    isBackUp?: boolean;
}
