const cacheNum = 20; // 缓存的数目

/**
 * 画布操作缓存（撤销操作用）
 */
export class CommondAction {
    /**
     * 保存svg画布数组
     */
    static undoStack: Array<any> = [];
    /**
     * 当前所在的操作索引
     */
    static currentIndex = 0;
    /**
     * 是否有未保存的操作
     */
    static isChange: boolean;
    // 撤销
    static undo() {
        if (this.currentIndex > 1) {
            this.isChange = true;
            return this.undoStack[(--this.currentIndex - 1)];
        }
    }
    // 重做
    static redo() {
        if (this.currentIndex >= this.undoStack.length) {
            this.currentIndex = this.undoStack.length;
            console.log('没有可以撤销的步骤了');
            return false;
        }
        this.isChange = true;
        return this.undoStack[(++this.currentIndex - 1)];
    }
    // 清空栈
    static clear() {
        this.currentIndex = 0;
        this.undoStack = [];
        this.isChange = false;
    }
    // 画布压栈
    static doStack(svg: any) {
        if (this.undoStack.length >= cacheNum && this.undoStack.length > 1) {
            this.undoStack = this.undoStack.slice(1);
        }
        // 撤销到某一个节点时候，如果出现新的压栈就从该节点开始
        if (this.currentIndex < this.undoStack.length && this.undoStack.length > 1) {
            this.undoStack.splice(this.currentIndex);
        }

        this.undoStack.push(svg);
        this.currentIndex = this.undoStack.length;

        if (this.currentIndex > 1) {
            this.isChange = true;
        }
    }
}
