
     <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="true"
            [nzLimit]="100"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="fileList">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p class="ant-upload-hint">支持的文件类型：{{fileTypeName}}</p>
        </nz-upload>
    </cdk-virtual-scroll-viewport>
    