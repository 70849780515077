import { Injectable } from '@angular/core';
import { HttpService } from '../_services';


/**
 * web组态API-工程->组件->属性
 */
@Injectable({ providedIn: 'root' })
export class PropertyApi {
    private prefix = '/design/property';

    constructor(private http: HttpService) {
    }

    /**
     * 获取组件属性列表
     */
    async getAll() {
        return await this.http.get(`${this.prefix}/getAll`);
    }
}
