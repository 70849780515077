import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * 组件API
 */
@Injectable({ providedIn: 'root' })
export class SelectApi {
    private prefix = '/components/select';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 获取下拉列表固定数据集(dct_select_data)
     */
    async getDataList() {
        return await this.http.get(`${this.prefix}/getDataList`);
    }

    /**
     * 获取下拉列表注册数据集(dct_select_reg)
     */
    async getRegList() {
        return await this.http.get(`${this.prefix}/getRegList`);
    }

    /**
     * 获取下拉列表注册数据
     * @param code 注册编号
     */
    async getRegItems(code: string) {
        return await this.http.post(`${this.prefix}/getRegItems`, { code });
    }
}
