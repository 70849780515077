import { Page, Project, Template } from '../_models';
import { PageData } from '../_models/pageData';
import { SysConfig } from '../_models/sysConfig';

/**
 * 数据缓存
 */
export class DataCache {
    /**
     * 系统配置信息
     */
    static sysConfig: SysConfig = null;

    /**
     * 当前打开的项目
     */
    static project: Project = null;

    /**
     * 当前打开的页面
     */
    static page: Page = null;
    /**
     * 当前页面数据
     */
    static pageData: PageData = null;

    /**
     * 当前打开的模板
     */
    static template: Template = null;

    /**
     * 正在编辑的页面
     */
    static editPages = [];
    /**
     * 正在编辑的模板
     */
    static editTemplates = [];

    /**
     * 缓存初始化
     */
    static init() {
        const sysConfigKey = 'sysConfig';
        if (!this.sysConfig || !this.sysConfig.domin) {
            if (localStorage.getItem(sysConfigKey)) {
                this.sysConfig = JSON.parse(localStorage.getItem(sysConfigKey));
            }
        }
        if (!this.sysConfig || !this.sysConfig.domin) {
            sessionStorage.removeItem('user');
        }
    }

    /**
     * 清空画布中加载的缓存数据
     */
    static clearCanvasData() {
        this.page = null;
        this.pageData = null;
        this.template = null;
    }
}
