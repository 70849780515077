import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComClassifyApi, ComponentApi } from 'src/app/api';
import { ComponentCache } from 'src/app/cache';
import { MenuData } from 'src/app/const';
import { ComClassify, ComponentData, ContextMenuState, DragedData } from 'src/app/_models';
import { EventTransService } from 'src/app/_services';

@Component({
    selector: 'app-com',
    templateUrl: './com.component.html',
    styleUrls: ['./com.component.css']
})
export class ComComponent implements OnInit, OnDestroy {
    gridStyle = {
        width: '33.3%',
        'text-align': 'center'
    };

    menuDataSubscrite: Subscription;
    loadClassifySubscrite: Subscription;
    loadComponentByIDSubscrite: Subscription;

    loadingClassify = true;
    loadingCom = false;
    /**
     * 点击的组件分类id
     */
    clickedClassifyId: string;
    /**
     * 组件分类列表
     */
    classifies: ComClassify[] = [];
    /**
     * 组件数据
     * key：分类id
     * value：组件数据
     */
    componentMap = new Map();
    /**
     * 右键菜单X位置
     */
    contextmenuX = 0;
    /**
     * 右键菜单Y位置
     */
    contextmenuY = 0;
    /**
     * 右键菜单状态
     */
    menuState: ContextMenuState;

    /**
     * 右键选择的组件分类对象
     */
    selectedClassify: ComClassify;
    /**
     * 是否可以删除组件分类
     */
    enableDeleteMenu = false;
    /**
     * 右键选择的组件对象
     */
    selectedComponent: ComponentData;

    constructor(
        private resolver: ComponentFactoryResolver,
        private eventTransService: EventTransService,
        private classifyApi: ComClassifyApi,
        private componentApi: ComponentApi
    ) {

        // this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomComponent);
    }

    ngOnInit(): void {
        this.getClassifyData();
        this.initSubscrite();
    }

    ngOnDestroy(): void {
        this.menuDataSubscrite.unsubscribe();
        this.loadClassifySubscrite.unsubscribe();
        this.loadComponentByIDSubscrite.unsubscribe();
    }

    /**
     * 注册监听事件
     */
    initSubscrite() {
        /**
         * 右键菜单状态变更监听
         */
        this.menuDataSubscrite = this.eventTransService.menuStateChangeEvent.subscribe((data: ContextMenuState) => {
            this.menuState = data;
        });
        /**
         * 组件分类信息变更监听
         */
        this.loadClassifySubscrite = this.eventTransService.classifysChangeEvent.subscribe((data: boolean) => {
            if (data) {
                this.getClassifyData(false);
            }
        });
        /**
         * 组件数据变更监听
         */
        this.loadComponentByIDSubscrite = this.eventTransService.classifyDataChangeEvent.subscribe((data: string) => {
            if (!data) {
                return;
            }
            if (this.componentMap.has(data)) {
                this.componentMap.delete(data);
            }
            this.openClassify(true, data);
        });
    }

    /**
     * 获取组件分类数据
     */
    async getClassifyData(isOpen: boolean = true) {
        this.classifies = await this.classifyApi.getAll();
        this.loadingClassify = false;
        if (!this.classifies || this.classifies.length <= 0) {
            return;
        }
        for (const classify of this.classifies) {
            if (this.componentMap.has(classify.id)) {
                classify.count = this.componentMap.get(classify.id).length;
            }
        }
        if (isOpen) {
            this.openClassify(true, this.classifies[0].id);
        }
    }

    /**
     * 组件分类展开/折叠事件
     */
    async openClassify(event: boolean, id: string) {
        this.clickedClassifyId = id;
        if (!event || this.componentMap.has(id)) {
            return;
        }
        this.loadingCom = true;
        const components: ComponentData[] = await this.componentApi.getAll(id);
        const classify = this.classifies.find(d => d.id === id);
        if (classify) {
            classify.count = components.length;
        }
        if (components.length > 0) {
            this.componentMap.set(id, components);
        }
        this.loadingCom = false;
    }

    /**
    * 组件标题右键
    */
    comTitleRightClick(event) {
        event.stopPropagation();
        event.preventDefault();

        const x = event.x;
        let y = event.y;

        if (y + MenuData.componentTitle > document.body.clientHeight) {
            y = y - MenuData.componentTitle;
        }

        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ ComponentTitle: true });
    }

    /**
     * 组件分类内容空白区域右键
     */
    classifyContentRightClick(event, classify: ComClassify) {
        event.stopPropagation();
        event.preventDefault();
        this.eventTransService.menuStateChangeEvent.next({});
        if (classify.base) {
            return;
        }

        this.selectedClassify = classify;

        let x = event.x;
        let y = event.y;

        if (y + 40 > document.body.clientHeight) {
            y = y - 40;
        }

        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ ClassifyContent: true });
    }

    /**
     * 组件分类标题右键
     */
    classifyTitleRightClick(event, classify: ComClassify) {
        event.stopPropagation();
        event.preventDefault();

        this.selectedClassify = classify;

        if (this.componentMap.has(classify.id) && this.componentMap.get(classify.id).length > 0) {
            this.enableDeleteMenu = false;
        } else {
            this.enableDeleteMenu = true;
        }

        const x = event.x;
        let y = event.y;

        if (y + 190 > document.body.clientHeight) {
            y = y - 190;
        }

        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ ClassifyTitle: true });
    }

    /**
     * 组件右键事件
     */
    comRightClick(event, classify: ComClassify, cpt: ComponentData) {
        event.stopPropagation();
        event.preventDefault();

        this.selectedClassify = classify;
        this.selectedComponent = cpt;

        const x = event.x;
        let y = event.y;

        let height = 230;
        if (classify.base) {
            height = 35;
        }

        if (y + height > document.body.clientHeight) {
            y = y - height;
        }

        this.contextmenuX = x;
        this.contextmenuY = y;

        this.eventTransService.menuStateChangeEvent.next({ Component: true });
    }

    /**
     * 组件拖拽事件
     */
    async drag(event: Event, component: ComponentData) {
        this.eventTransService.dragDataEvent.next(null);
        if (!ComponentCache.pull(component.id)) {
            const data = await this.componentApi.getContent(component.id);
            if (!data.data) {
                return;
            }
            component.width = data.width;
            component.height = data.height;
            ComponentCache.push(component.id, data);
        }
        const cache = ComponentCache.pull(component.id);
        const dragedData: DragedData = {
            id: component.id,
            text: component.text,
            type: component.type,
            width: cache.width,
            height: cache.height,
            content: cache.data
        };
        this.eventTransService.dragDataEvent.next(dragedData);
    }
}
