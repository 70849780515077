import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SvgElementPipe } from '../../pipe';
import { SelectedObj, Property, AnimeProp, PropChangeData } from '../../_models';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PropertyApi } from 'src/app/api';
import { CanvasCache, DataCache, PropCache } from 'src/app/cache';
import { ComponentType, DataAttrName } from 'src/app/const';
import { EventTransService } from 'src/app/_services';
import { MathUtil, SvgNodeUtil } from 'src/app/utils';
import { CommonFunc } from '../svgCanvas/commonFunc';
import { ComponentTool } from '../svgCanvas/componentTool';

declare var anime: any;
declare var $: any;

@Component({
  selector: 'app-right',
  templateUrl: './rightSideNav.component.html',
  styleUrls: ['./rightSideNav.component.css'],
  providers: [SvgElementPipe]
})
export class RightSideNavComponent implements OnInit, OnDestroy {
  width = 300;
  value = '';

  selectedObj: SelectedObj;

  selectedObjSub: Subscription;
  canvasDataChangeSub: Subscription;
  animeSetChangeSub: Subscription;
  canvasElementChangeSub: Subscription;
  propSetChangeSub: Subscription;

  // 当前选中的组件对象
  selectedComponent;
  selectedComponentName;

  // 组件属性
  props: Property[] = [];

  // 选中的组件ID
  selDomID: string;

  showAnimate = false;

  treeNodes = [];

  lastSelDs: any;

  //面版id集合
  panelIds = [];
  selPanelId = '';
  showPanelSet = false;

  constructor(
    private svgPipe: SvgElementPipe,
    private eventTransService: EventTransService,
    private message: NzMessageService,
    private propertyApi: PropertyApi,
    private commonFunc: CommonFunc,
    private componentTool: ComponentTool
  ) {
    this.initData();
  }

  ngOnInit() {
    this.initSubscribe();
  }

  ngOnDestroy() {
    this.selectedObjSub.unsubscribe();
    this.canvasDataChangeSub.unsubscribe();
    this.animeSetChangeSub.unsubscribe();
    this.canvasElementChangeSub.unsubscribe();
    this.propSetChangeSub.unsubscribe();
  }

  /**
   * 初始化数据订阅事件
   */
  initSubscribe() {
    /**
     * 选中的对象发生变更
     */
    this.selectedObjSub = this.eventTransService.selectedObjChangeEvent.subscribe((data: SelectedObj) => {
      this.handleSelectedObj(data);
    });
    this.canvasDataChangeSub = this.eventTransService.canvasDataChange.subscribe((data: boolean) => {
      if (!data) {
        return;
      }
      this.initAnime();
    });
    this.animeSetChangeSub = this.eventTransService.animeSetChange.subscribe((data: any) => {
      this.configAnime(data);
    });
    this.canvasElementChangeSub = this.eventTransService.canvasElementChange.subscribe((data: any) => {
      if (data) {
        this.handlePanelComponent();
      }
    });
    this.propSetChangeSub = this.eventTransService.propSetChange.subscribe((data: PropChangeData) => {
      if (data) {
        this.handlePropSetChange(data);
      }
    });
  }

  handleSelectedObj(data) {
    this.selectedObj = data;
    this.showPanelSet = false;
    if (PropCache.getCommon()) {
      this.props = PropCache.getCommon();
    }

    this.showAnimate = true;
    if (!this.selectedObj) {
      this.showAnimate = false;
      this.selectedComponent = null;
      this.initTree();
      return;
    }

    if (PropCache.isEmpty()) {
      return;
    }

    if (this.selectedObj.Svg) {
      this.handleSvg();
    } else if (this.selectedObj.Component) {
      this.handleComponent();
    } else if (this.selectedObj.Element) {
      this.handleElement();
    }
    if (DataCache.template) {
      this.props = this.props.filter(d => PropCache.getTemplateExProp().indexOf(d.name) < 0);
    }
  }

  handleSvg() {
    this.showAnimate = false;
    this.selectedComponent = null;
    this.initTree();
    if (PropCache.getSvg()) {
      this.props = this.props.concat(PropCache.getSvg());
    }
    // svg画布去除id、变量、可见性属性
    this.props = this.props.filter(d => PropCache.getSvgExProp().indexOf(d.name) < 0);
  }

  handleComponent() {
    this.selectedComponent = this.selectedObj.Component;
    if (this.panelIds.length > 0 &&
      (!this.selectedComponent.select('foreignObject') ||
        this.selectedComponent.select('foreignObject').attr(DataAttrName.componentName) !== ComponentType.panel
      )) {
      this.showPanelSet = true;
    }
    this.initTree();
    this.selectedComponentName = this.selectedObj.Component.attr(DataAttrName.text);
    if (!this.selectedComponentName) {
      this.selectedComponentName = 'g标签';
    }
    const id = this.selectedObj.Component.attr('id');
    if (id) {
      this.selectedComponentName += ` <@${id}> `;
    }
    const key = this.selectedObj.Component.type.toLowerCase();
    if (PropCache.hasKey(key)) {
      this.props = this.props.concat(PropCache.get(key));
    }
    // 组件去除变量属性
    this.props = this.props.filter(d => PropCache.getComExProp().indexOf(d.name) < 0);
  }

  handleElement() {
    let key = this.selectedObj.Element.type.toLowerCase();
    // 图层去除权限级别属性
    this.props = this.props.filter(d => PropCache.getElementExProp().indexOf(d.name) < 0);

    if (key === 'div' || key === 'input' || key === 'video') {
      this.message.warning('组件解析异常，请使用新版本组件！');
      return;
    }
    const name = this.selectedObj.Element.attr(DataAttrName.componentName);
    if (name) {
      // 自定义组件
      key = name;
    }
    if (PropCache.hasKey(key) && key !== 'line') {
      this.props = this.props.concat(PropCache.get(key));
    }
    // 直线Ex、竖线Ex特殊处理
    if (key === 'line' && this.selectedObj.Element.parent() &&
      this.selectedObj.Element.parent().attr(DataAttrName.type)) {
      key = this.selectedObj.Element.parent().attr(DataAttrName.type).toLowerCase();
      if (PropCache.hasKey(key)) {
        this.props = this.props.concat(PropCache.get(key));
      }
    }
    this.props = this.props.filter(d => d.name !== 'id');
  }

  initTree() {
    this.treeNodes = [];
    // 选中多个组件，不展示图层信息
    if (!this.selectedComponent || (CanvasCache.obs && CanvasCache.obs.observers &&
      CanvasCache.obs.observers.onapply && CanvasCache.obs.observers.onapply.length > 1)) {
      return;
    }

    // 将图层按y轴排序
    let children = this.svgPipe.transform(this.selectedComponent.children());
    children = children.sort((d1, d2) => d1.getBBox().y - d2.getBBox().y);

    children.forEach((item, index) => {
      const title = '图层' + (index + 1);
      let mt = '';
      if (item.attr(DataAttrName.meterType)) {
        mt = `<a>[${item.attr(DataAttrName.meterType)}]</a>`;
      }

      const rootNode = {
        key: title,
        title: title + '-' + SvgNodeUtil.getNodeOtherName(item) + mt,
        expanded: true,
        children: [],
        dom: item,
        selected: false
      };
      if (!item.attr(DataAttrName.componentName)) {
        this.initSubTree(item, rootNode, title);
      }

      this.treeNodes.push(rootNode);
    });

    this.selectedComponent.selectAll('*').forEach(item => {
      item.attr('opacity', 1);
    });
  }

  initSubTree(dom, rootNode, rootText) {
    // 将图层按y轴排序
    let children = this.svgPipe.transform(dom.children());
    children = children.sort((d1, d2) => d1.getBBox().y - d2.getBBox().y);

    children.forEach((item, index) => {
      const title = rootText + '-' + (index + 1);
      let mt = '';
      if (item.attr(DataAttrName.meterType)) {
        mt = `<a>[${item.attr(DataAttrName.meterType)}]</a>`;
      }
      const subNode = {
        key: title,
        title: title + '-' + SvgNodeUtil.getNodeOtherName(item) + mt,
        expanded: true,
        children: [],
        dom: item
      };
      item.attr('opacity', 1);
      if (!item.attr(DataAttrName.componentName)) {
        // 系统组件，不继续加载
        this.initSubTree(item, subNode, title);
      }
      rootNode.children.push(subNode);
    });
  }

  /**
   * 初始化数据
   */
  async initData() {
    const data = await this.propertyApi.getAll();
    if (data) {
      PropCache.init(data);
    }
  }

  /**
   * 元件点击事件
   * @param event 点击事件
   */
  domClick(event: any) {
    if (event && event.selectedKeys && event.selectedKeys.length > 0) {
      const origin = event.selectedKeys[0].origin;
      if (origin && origin.dom) {
        this.eventTransService.eleTreeNodeChange.next(event.selectedKeys[0]);
        this.selectedComponent.selectAll('*').forEach(item => {
          if (item.type !== 'foreignObject') {
            item.attr('opacity', .1);
          }
        });
        origin.dom.attr('opacity', 1);
        origin.dom.selectAll('*').forEach(item => {
          item.attr('opacity', 1);
        });

        let parent = event.selectedKeys[0];
        let level = event.selectedKeys[0].level;
        while (level > 0) {
          parent = parent.parentNode;
          level = parent.level;
          if (parent && parent.origin && parent.origin.dom) {
            parent.origin.dom.attr('opacity', '');
          }
        }

        this.eventTransService.selectedObjChangeEvent.next({ Element: origin.dom });
      }
    }
  }

  /**
   * 元件鼠标右键事件
   * @param event 右键事件
   */
  domRightClick(event: any) {
    if (event && event.node) {
      const origin = event.node.origin;
      if (origin && origin.dom) {
        this.selectedComponent.selectAll('*').forEach(item => {
          item.attr('opacity', 1);
        });
      }
    }
  }

  propertyClick(event) {
    CanvasCache.isFoucus = false;
  }

  /**
   * 初始化页面动画
   */
  initAnime() {
    const animeObjs = CanvasCache.svg.selectAll(`[${DataAttrName.anime}]`);
    anime.remove();
    animeObjs.forEach(item => {
      this.configAnime(item);
    });
  }

  /**
   * 水流动画处理
   * @param prop 
   * @param targets 
   */
  handleAnimeFlow(prop: AnimeProp, targets: string) {
    if (!prop.flow || !prop.flow.enable) {
      return;
    }

    anime({
      targets,
      loop: true,
      duration: prop.flow.duration,
      direction: prop.flow.direction,
      strokeDashoffset: {
        value: [100, 0]
      },
      easing: 'linear'
    });
  }

  /**
   * 动画设置
   * @param item 
   */
  configAnime(item) {
    if (!item) {
      return;
    }
    const animeStr = item.attr(DataAttrName.anime);
    if (!animeStr) {
      return;
    }
    const prop: AnimeProp = JSON.parse(animeStr);
    if (!prop) {
      return;
    }
    let animeId = item.attr(DataAttrName.animeId);
    if (animeId) {
      anime.remove(`[${DataAttrName.animeId}='${animeId}']`);
    }
    animeId = MathUtil.getUUid();
    item.attr(DataAttrName.animeId, animeId);
    const targets = `[${DataAttrName.animeId}='${animeId}']`;
    this.handleAnimeFlow(prop, targets);
  }

  /**
   * 获取画布中列表面版组件信息
   */
  handlePanelComponent() {
    this.panelIds = [];
    this.showPanelSet = false;
    const panels = CanvasCache.svg.selectAll(`[${DataAttrName.componentName}='${ComponentType.panel}']`);
    panels.forEach((item: any) => {
      this.panelIds.push(item.parent().attr('id'));
    });
    if (this.panelIds.length > 0) {
      // this.panelIds.splice(0, 0, '');
    }
  }

  /**
   * 选择所属列表面版
   */
  selectPanel() {
    if (!this.selPanelId) {
      return;
    }
    const panel = CanvasCache.svg.select(`svg>g[id='${this.selPanelId}']`);
    if (!panel || !panel.select('svg') || !this.selectedComponent || !panel.select('foreignObject') || panel.select('foreignObject').attr(DataAttrName.componentName) !== ComponentType.panel) {
      return;
    }
    this.selectedComponent.attr('id', '');
    this.componentTool.moveToCorner([this.selectedComponent]);

    panel.select('svg').add(this.selectedComponent);
    this.selPanelId = null;
  }

  /**
   * 属性设置变更处理
   * @param propData
   */
  handlePropSetChange(propData) {
    if (!propData.name || !propData.value) {
      return;
    }
    const prop = this.props.find(d => d.name === propData.name);
    if (!prop || !this.selectedObj) {
      return;
    }
    prop.setValue = propData.value.replace('px', '');
  }
}
