export class Property {
    name: string;
    desc?: string;
    type?: string;
    values?: Property[] = [];
    defaultValue?: any;
    setValue?: any;
    children?: Property[] = [];
    min?: number;
    max?: number;
    tooltip?: string;
}
