import { Property } from '../_models';

export class Table {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const table = selectedObj.select('table');
        switch (prop.name) {
            case 'border-color': {
                prop.setValue = table.node.style.borderColor;
                break;
            }
            case 'border-width': {
                prop.setValue = table.node.style.borderWidth.replace('px', '');
                break;
            }
            case 'border-style': {
                prop.setValue = table.node.style.borderStyle;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const table = selectedObj.select('table');
        switch (prop.name) {
            case 'border-color':
            case 'border-style': {
                table.node.style.setProperty(prop.name, prop.setValue);
                break;
            }
            case 'border-width': {
                table.node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
