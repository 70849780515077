import { PositionEnum } from './positionEnum';

/**
 * 标题配置
 */
export class TitleConfig {
    /**
     * 显示标题
     */
    show = true;
    /**
     * 位置
     */
    position = PositionEnum.TOP_LEFT;
    /**
     * 显示时间区域
     */
    showTimeRange = true;
    /**
     * 特定内容
     */
    text: string;
}
