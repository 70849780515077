export class SvgNodeType {
    static svg = 'svg';
    static use = 'use';
    static foreignObject = 'foreignObject';
    static text = 'text';
    static path = 'path';
    static line = 'line';
    static lineHor = 'line-hor';
    static lineVer = 'line-ver';
    static circle = 'circle';
    static rect = 'rect';
    static polygon = 'polygon';
    static ellipse = 'ellipse';
    static g = 'g';
    static image = 'image';
    static div = 'div';
    static video = 'video'
}