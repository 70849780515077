<nz-tabset>
  <nz-tab nzTitle="基本设置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <!-- <nz-link nzHref="#type" nzTitle="类型"></nz-link> -->
        <nz-link nzHref="#datasource" nzTitle="数据源"></nz-link>
        <nz-link nzHref="#time" nzTitle="时间配置"></nz-link>
        <nz-link nzHref="#statistic-time-frame" nzTitle="统计时段"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <!-- <div id='type'>
        <span>类型:</span>
        <div class="left">
          <nz-radio-group [(ngModel)]="prop.type" class="typeRadio">
            <label nz-radio nzValue="base">基础饼图</label>
            <label nz-radio nzValue="rose">南丁格尔玫瑰图</label>
          </nz-radio-group>
          <div>
            <label nz-checkbox [(ngModel)]="prop.circle">环形图</label>
            <label nz-checkbox [(ngModel)]="prop.round">显示圆角</label>
          </div>
        </div>
      </div> -->
      <div id='dataSource'>
        <span>数据源:</span>
        <div class="left">
          <div>
            <div class="dataSource">
              <label nz-checkbox nz-tooltip nzTooltipTitle="动态数据源，需要在全局数据脚本中定义对应变量" [(ngModel)]="dataSource.dynamic"
                style="width: 20%;">动态</label>
            </div>
            <div class="dataSource">
              <nz-select [(ngModel)]="dataSource.type" style="width: 150px;">
                <nz-option nzValue="build" nzLabel="建筑"></nz-option>
                <nz-option nzValue="dept" nzLabel="分户"></nz-option>
                <nz-option nzValue="account" nzLabel="账套"></nz-option>
                <nz-option nzValue="device" nzLabel="设备"></nz-option>
                <nz-option nzValue="param" nzLabel="设备参数"></nz-option>
                <nz-option nzValue="formula" nzLabel="能耗关系"></nz-option>
              </nz-select>
              <ng-container [(ngSwitch)]="dataSource.type">
                <div *ngSwitchCase="'build'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="建筑id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'dept'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="分户id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'account'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="账套id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="能耗编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'device'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="设备编码">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                  <nz-input-group nzAddOnBefore="参数类型">
                    <input nz-input class="input" [(ngModel)]="dataSource.param" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'param'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="参数id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                </div>
                <div *ngSwitchCase="'formula'" style="display: flex;">
                  <nz-input-group nzAddOnBefore="关系id">
                    <input nz-input class="input" [(ngModel)]="dataSource.code" />
                  </nz-input-group>
                </div>
              </ng-container>
              <button nz-button nzType="default" (click)="addDataSource()">添加</button>
            </div>

          </div>
          <div class="dataSource-list">
            <nz-select [(ngModel)]="selDataSourceId" style="width: 86%;" (ngModelChange)="selDataSource()">
              <nz-option [(nzValue)]="item.id" [nzLabel]="getDataSourceDesc(item)"
                *ngFor="let item of prop.dataSources">
              </nz-option>
            </nz-select>
            <button nz-button nzType="default" nzDanger (click)="removeDataSource()">删除</button>
          </div>
        </div>
      </div>

      <div id='time'>
        <span>时间配置:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.timeConfig.enable" style="width: 100%;">启用</label>
          <nz-radio-group [(ngModel)]="prop.timeConfig.timeType" style="width: 100%;">
            <label nz-radio nzValue="day">本日</label>
            <label nz-radio nzValue="week">本周</label>
            <label nz-radio nzValue="month">本月</label>
            <label nz-radio nzValue="season">本季度</label>
            <label nz-radio nzValue="year">本年</label>
          </nz-radio-group>
          <div>
            <label nz-checkbox [(ngModel)]="prop.timeConfig.showPreNext">显示上下周期</label>
            <nz-input-group nzAddOnBefore="默认周期" style="width: 200px;">
              <nz-input-number [(ngModel)]="prop.timeConfig.index" [nzMin]="-100" [nzMax]="0" [nzStep]="1">
              </nz-input-number>
            </nz-input-group>
          </div>
        </div>
      </div>
      <div id='statistic-time-frame'>
        <span>统计时段:</span>
        <div class="left">
          <div>
            <nz-time-picker [(ngModel)]="sumTime.start" nzFormat="HH:00">
            </nz-time-picker>
            <span>~</span>
            <nz-time-picker [(ngModel)]="sumTime.end" nzFormat="HH:00" style="margin-right: 10px;">
            </nz-time-picker>
            <button (click)="addSumTime()" nz-radio-button nzType="primary">添加</button>
          </div>

          <div>
            <nz-select [(ngModel)]="selTimeId" style="width: 86%;">
              <nz-option [(nzValue)]="item.id" [nzLabel]="getSumTimeDesc(item)" *ngFor="let item of prop.timeRanges">
              </nz-option>
            </nz-select>
            <button nz-button nzType="default" nzDanger (click)="removeSumTime()">删除</button>
          </div>

          <div>
            <nz-tag nzColor="warning">注：不配置默认为全时段</nz-tag>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
  <nz-tab nzTitle="图表显示配置">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="contentCss" [nzOffsetTop]="20">
        <nz-link nzHref="#title" nzTitle="图表标题"></nz-link>
        <nz-link nzHref="#legend" nzTitle="图例"></nz-link>
        <nz-link nzHref="#toolbox" nzTitle="工具箱"></nz-link>
      </nz-anchor>
    </div>
    <div class="content" #contentCss>
      <div id='title'>
        <span>图表标题:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.titleConfig.show">显示</label><br>
          <label>显示位置：</label>
          <nz-radio-group [(ngModel)]="prop.titleConfig.position">
            <label nz-radio nzValue="top_left">左侧</label>
            <label nz-radio nzValue="top">居中</label>
          </nz-radio-group>
          <br>
          <label>标题内容：</label>
          <label nz-checkbox [(ngModel)]="prop.titleConfig.showTimeRange">时间区域</label>
          <nz-input-group nzAddOnBefore="特定内容">
            <input nz-input [(ngModel)]="prop.titleConfig.text" />
          </nz-input-group>
        </div>
      </div>
      <div id='legend'>
        <span>图例:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.legendConfig.show">显示</label><br>
          <div>
            <label>显示位置：</label>
            <nz-radio-group [(ngModel)]="prop.legendConfig.position">
              <label nz-radio nzValue="top">上</label>
              <label nz-radio nzValue="top_left">左上</label>
              <label nz-radio nzValue="top_right">右上</label>
              <label nz-radio nzValue="bottom">下</label>
              <label nz-radio nzValue="bottom_left">左下</label>
              <label nz-radio nzValue="bottom_right">右下</label>
            </nz-radio-group>
          </div>
          <div>
            <label nz-checkbox [(ngModel)]="prop.legendConfig.ver">垂直显示</label><br>
          </div>
        </div>
      </div>
      <div id='toolbox'>
        <span>工具箱:</span>
        <div class="left">
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.saveAsImg">保存为图片</label><br>
          <label nz-checkbox [(ngModel)]="prop.toolBoxConfig.showDataView">数据视图</label><br>

          <div *ngIf="prop.toolBoxConfig.showDataView">
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showAvg">显示平均值</label>
            <label nz-checkbox [(ngModel)]="prop.dataViewConfig.showSum">显示合计值</label>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
