<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">设置状态</span>:</span>
        <div class="code">
            <div class="remark">设置为选中状态，可以设置的值：大于0的数字、true、'true'：</div>
            <div>
                <@p2.*>.value(1);
            </div>
            <div>
                <@p2.*>.value('1');
            </div>
            <div>
                <@p2.*>.value(true);
            </div>
            <div>
                <@p2.*>.value('true');
            </div>
            <div class="remark">设置其余值时，为未选中状态：</div>
            <div>
                <@p2.*>.value(0);
            </div>
            <div>
                <@p2.*>.value(false);
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title">获取<span class="mark">控件状态</span>:</span>
        <div class="code">
            <div class="remark">选中状态时，result等于1，未选中状态时result等于0</div>
            <div>
                var result = <@p2.*>.value();
            </div>
        </div>
    </div>
</div>