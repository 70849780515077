import { DataAttrName } from '../const';
import { Property } from '../_models';
import { PxUtil } from '../utils';

export class Switch {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                prop.setValue = selectedObj.select('span').node.style.getPropertyValue(prop.name)
                    .replace('px', '');
                break;
            }
            case DataAttrName.onKey:
            case DataAttrName.offKey: {
                prop.setValue = selectedObj.select(`span[${prop.name}]`).attr(prop.name);
                break;
            }
            case DataAttrName.onText: {
                prop.setValue = selectedObj.select(`span[data-attr-on-key]`).node.innerText;
                break;
            }
            case DataAttrName.offText: {
                prop.setValue = selectedObj.select(`span[data-attr-off-key]`).node.textContent;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'font-size': {
                selectedObj.selectAll('span').forEach(item => {
                    item.node.style.setProperty(prop.name, prop.setValue + 'px');
                });
                break;
            }
            case DataAttrName.onKey:
            case DataAttrName.offKey: {
                selectedObj.select(`span[${prop.name}]`).attr(prop.name, prop.setValue);
                break;
            }
            case DataAttrName.onText: {
                selectedObj.select(`span[data-attr-on-key]`).node.innerText = prop.setValue;
                break;
            }
            case DataAttrName.offText: {
                selectedObj.select(`span[data-attr-off-key]`).node.innerText = prop.setValue;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
