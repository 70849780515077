import { Component } from '@angular/core';

@Component({
  selector: 'app-left-component',
  templateUrl: './leftSideNav.component.html',
  styleUrls: ['./leftSideNav.component.css']
})

export class LeftSideNavComponent {
  constructor(
  ) {
  }
}
