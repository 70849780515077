export class AnimeEasing {
    /**
     * 线性动画
     */
    static linear = 'linear';
    /**
     * 台阶式
     */
    static steps = 'steps';
    /**
     * 由快至慢
     */
    static easeInOutSine = 'easeInOutSine';
    /**
     * 突然减速
     */
    static easeInOutCirc = 'easeInOutCirc';
}
