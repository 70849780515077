/**
 * 设备信息
 */
export class DevInfo {
    id: number;
    name: string;
    code: string;
    buildId: number;
    deptId: number;
}
