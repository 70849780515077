import { Component } from '@angular/core';

@Component({
    selector: 'app-help-video-ys',
    templateUrl: './videoYs.component.html',
    styleUrls: ['./videoYs.component.css']
})
export class VideoYsHelpComponent {
    constructor(
    ) {
    }
}

