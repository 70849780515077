import { Component, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProjectApi } from '../api';


/*选择组件分类 */
@Component({
  selector: 'app-project-upload',
  template: `
      <nz-spin [nzSpinning]="isLoading">
        <input nz-input placeholder="请输入工程名称" [(ngModel)]="name" autofocus />
        <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="false"
            [nzLimit]="1"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="fileList">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
        </nz-upload>
        </cdk-virtual-scroll-viewport>
      </nz-spin>
    `,
  styles: [
    `.infinite-container{
            height: 200px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        input{
            margin-bottom:10px;
        }
        `
  ]
})
export class ProjectUpLoadComponent implements OnDestroy {
  name = '';

  fileList: NzUploadFile[] = [];

  isLoading = false;

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private projectApi: ProjectApi
  ) { }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.fileList && this.fileList.length === 1) {
      return false;
    }
    this.fileList = this.fileList.concat(file);
    return false;
  }

  save(): any {
    if (!this.name) {
      this.message.warning('请输入工程名称！');
      return false;
    }
    if (!this.fileList || this.fileList.length === 0) {
      this.message.warning('请先添加需要导入的工程！');
      return false;
    }
    this.isLoading = true;
    this.fileList.forEach(async (file) => {
      const ret = await this.projectApi.upload(file, this.name);
      if (ret) {
        this.isLoading = false;
        this.message.success(`工程：${this.name}导入成功！`);
        this.fileList = [];
      }
    });
    return false;
  }

  ngOnDestroy() {
    this.modal.destroy();
  }
}
