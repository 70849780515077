import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BarProperty, DataSourceConfig, DataSourceType, DataViewConfig, TimeRangeConfig } from 'src/app/chartConfig';
import { MathUtil } from 'src/app/utils';

interface DataSourceEdit {
    dynamic: boolean;
    type: string;
    sign?: string;
    code?: string;
    param?: string;
}

interface SumTimeEdit {
    start: Date;
    end: Date;
    name: string;
}

/**
 * 折线图配置
 */
@Component({
    selector: 'app-chart-bar-config',
    templateUrl: './chartBarConfig.component.html',
    styleUrls: ['./chartBarConfig.component.css']
})
export class ChartBarConfigComponent implements OnInit, OnDestroy {
    @Input() prop: BarProperty = new BarProperty();

    dataSource: DataSourceEdit = {
        dynamic: false,
        type: 'build',
    };

    sumTime: SumTimeEdit = {
        start: new Date(),
        end: new Date(),
        name: ''
    };

    selDataSourceId: string;
    selSumTimeId: string;

    constructor(private modal: NzModalRef, private message: NzMessageService) {
    }

    ngOnInit(): void {
        if (!this.prop.dataViewConfig) {
            this.prop.dataViewConfig = new DataViewConfig();
        }
    }

    /**
     * 添加数据源
     */
    addDataSource(): void {
        if (!this.dataSource.code) {
            this.message.warning('参数错误，请检查！');
            return;
        }
        let param = '';
        if (this.dataSource.type !== DataSourceType.param && this.dataSource.type !== DataSourceType.formula) {
            if (!this.dataSource.param) {
                this.message.warning('参数错误，请检查！');
                return;
            }
            param = '.' + this.dataSource.param;
        }
        if (this.prop.dataSources.findIndex(d => d.dynamic === this.dataSource.dynamic &&
            d.type === this.dataSource.type && d.code === this.dataSource.code + param) >= 0) {
            this.message.warning('该数据源已存在！');
            return;
        }
        const id = MathUtil.getUUid();
        this.prop.dataSources.push({
            id,
            dynamic: this.dataSource.dynamic,
            sign: this.dataSource.sign,
            type: this.dataSource.type,
            code: this.dataSource.code + param
        });
        this.message.success('添加成功！');
        this.selDataSourceId = id;
    }

    /**
     * 获取数据源描述信息
     */
    getDataSourceDesc(item: DataSourceConfig) {
        let name;
        switch (item.type) {
            case 'build':
                name = '建筑';
                break;
            case 'dept':
                name = '分户';
                break;
            case 'account':
                name = '账套';
                break;
            case 'device':
                name = '设备';
                break;
            case 'formula':
                name = '能耗关系';
                break;
            case 'device':
                name = '设备参数';
                break;
        }
        let desc = `[${name}] ${item.code}`;

        if (item.dynamic) {
            desc = `动态 - [${name}] ${item.code}`;
        }
        if (item.sign) {
            desc += ` (${item.sign})`;
        }
        return desc;
    }

    /**
     * 移除数据源
     */
    removeDataSource() {
        if (!this.selDataSourceId) {
            return;
        }
        this.prop.dataSources = this.prop.dataSources.filter(d => d.id !== this.selDataSourceId);
        this.message.success('操作成功！');
        this.selDataSourceId = '';
    }

    /**
     * 添加统计时段
     */
    addSumTime() {
        const id = MathUtil.getUUid();
        this.prop.sumTimeConfig.timeRanges.push({
            id,
            start: this.sumTime.start.getHours() + ':00',
            end: this.sumTime.end.getHours() + ':00',
            name: this.sumTime.name
        });
        this.selSumTimeId = id;
    }

    getSumTimeDesc(item: TimeRangeConfig) {
        let desc = `${item.start} ~ ${item.end}`;
        if (item.name) {
            desc += ` (${item.name})`;
        }
        return desc;
    }

    /**
     * 删除统计时段
     */
    removeSumTime() {
        this.prop.sumTimeConfig.timeRanges = this.prop.sumTimeConfig.timeRanges.filter(d => d.id !== this.selSumTimeId);
        this.selSumTimeId = '';
    }

    setKgce() {
        if (this.prop.toKgce) {
            this.prop.toCO2 = false;
        }
    }

    setCO2() {
        if (this.prop.toCO2) {
            this.prop.toKgce = false;
        }
    }

    /**
     * 数据源选择
     */
    selDataSource() {
        if (!this.selDataSourceId) {
            return;
        }
        const data = this.prop.dataSources.find(d => d.id === this.selDataSourceId);
        this.dataSource.dynamic = data.dynamic;
        this.dataSource.sign = data.sign;
        this.dataSource.type = data.type;
        const arr = data.code.split('.');
        this.dataSource.code = arr[0];
        if (arr.length > 1) {
            this.dataSource.param = arr[1];
        }
    }

    save(): any {
        return new Promise(resolve => {
            resolve(this.prop);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
