import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CommonApi, ThemeApi } from '../api';
import { DataCache } from '../cache';
import { Platform, Project, ProjectConfig, ProjectRoute, Theme } from '../_models';
import { HttpService } from '../_services';

import { PageEncodeUtil } from '../utils';

/**
 * 保存页面
 */
@Component({
  selector: 'app-project-set',
  templateUrl: './projectSet.component.html',
  styleUrls: ['./projectSet.component.css']
})
export class ProjectSetComponent implements OnInit, OnDestroy {

  isLoading = true;
  project: Project;

  themes: Theme[] = [];
  selSite: string;
  setRoute: ProjectRoute = new ProjectRoute();

  noLoginUrl = '';
  noLoginUser = '';
  noLoginPwd = '';
  passwordVisible = false;

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private http: HttpService,
    private themeApi: ThemeApi,
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.project = DataCache.project;
    if (!this.project.routes) {
      this.project.routes = [];
    }
    if (!this.project.config) {
      this.project.config = new ProjectConfig();
    }
    if (!this.project.platform) {
      this.project.platform = new Platform();
    }
    if (this.project.routes.length > 0) {
      this.setRoute = { ...this.project.routes[0] };
      this.selSite = this.setRoute.site;
    } else {
      var api = this.http.api();
      if (api) {
        api = api.replace('http://', '').replace('https://', '').replace('/v1', '');
      }
      this.setRoute.apiPath = api;
    }
    this.themes = await this.themeApi.getAll();
    this.isLoading = false;
  }

  /**
   * 更新地址路由
   */
  updateRoute() {
    if (!this.setRoute.site || !this.setRoute.apiPath) {
      this.message.warning('请填写完整的路由信息！');
      return;
    }
    var route = this.project.routes.find(d => d.site === this.selSite);
    if (!route) {
      this.message.warning('发布站点地址不存在！');
      return;
    }
    if (this.setRoute.isDefault) {
      const oldRoute = this.project.routes.find(d => d.isDefault);
      if (oldRoute) {
        oldRoute.isDefault = false;
      }
    }
    route.site = this.setRoute.site;
    route.tag = this.setRoute.tag;
    route.apiPath = this.setRoute.apiPath;
    route.isDefault = this.setRoute.isDefault;
    route.protocol = this.setRoute.protocol;
    this.selSite = this.setRoute.site;

    this.message.success('更新成功！');
  }

  /**
   * 新增地址路由
   */
  addRoute() {
    if (!this.setRoute.site || !this.setRoute.apiPath) {
      this.message.warning('请填写完整的路由信息！');
      return;
    }
    var route = this.project.routes.find(d => d.site === this.setRoute.site);
    if (route) {
      this.message.warning('发布站点地址配置重复！');
      return;
    }
    if (this.setRoute.isDefault) {
      route = this.project.routes.find(d => d.isDefault);
      if (route) {
        route.isDefault = false;
      }
    }
    this.project.routes.push({
      ...this.setRoute
    })
    this.selSite = this.setRoute.site;
  }

  /**
   * 删除地址路由
   */
  deleteRoute() {
    if (!this.selSite) {
      return;
    }
    this.project.routes = this.project.routes.filter(d => d.site !== this.selSite);
    if (this.project.routes.length > 0) {
      const route = this.project.routes[0];
      this.selSite = route.site;
    } else {
      this.selSite = '';
    }
  }

  selRouteChange() {
    var route = this.project.routes.find(d => d.site === this.selSite);
    this.setRoute = { ...route };
  }

  save(): any {
    return new Promise(resolve => {
      resolve(this.project);
    });
  }

  async generateNoLoginUrl() {
    if (!this.noLoginUser || !this.noLoginPwd) {
      this.message.warning('请先输入符合要求的用户名和密码！');
      return;
    }
    if (!this.project.routes || this.project.routes.length == 0) {
      this.message.warning('请先配置地址路由！');
      return;
    }
    let route = this.project.routes.find(d => d.isDefault);
    if (!route) {
      route = this.project.routes[0];
    }

    var json = {
      user: this.noLoginUser,
      pwd: this.noLoginPwd
    }
    var str = PageEncodeUtil.sysParamEncode(JSON.stringify(json));
    let url = `${route.protocol}://${route.site}`;
    if (route.tag) {
      url += '/' + route.tag;
    }
    this.noLoginUrl = `${url}/index.html?tw-nologin=${str}`;
  }



  ngOnDestroy() {
    this.modal.destroy();
  }
}
