import { DataAttrName, SelectDataType } from '../const';
import { Property } from '../_models';

/**
 * 平铺面版
 */
export class SelectPanel {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.model: {
                const type = setNode.attr('multiple');
                prop.setValue = type ? type : '';
                break;
            }
            case DataAttrName.items: {
                let name = '';
                const dataStr = setNode.attr(DataAttrName.items);
                if (dataStr) {
                    const data = JSON.parse(dataStr);
                    if (data) {
                        name = SelectDataType.getName(data.type);
                    }
                }
                prop.setValue = `{${name}}`;
                break;
            }
            case DataAttrName.showBorder:
            case DataAttrName.index:
            case DataAttrName.iconKey: {
                prop.setValue = setNode.attr(prop.name);
                break;
            }
            case 'border-width':
            case 'border-radius':
            case 'border-color':
            case 'border-style': {
                prop.setValue = setNode.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.model: {
                const tag = 'multiple';
                if (prop.setValue) {
                    setNode.attr(tag, tag);
                } else {
                    setNode.node.removeAttribute(tag);
                }
                break;
            }
            case DataAttrName.index:
            case DataAttrName.showBorder:
            case DataAttrName.iconKey: {
                setNode.attr(prop.name, prop.setValue);
                break;
            }
            case 'border-width':
            case 'border-radius': {
                setNode.node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            case 'border-color':
            case 'border-style': {
                setNode.node.style.setProperty(prop.name, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
