import { DataAttrName } from '../const';
import { Property } from '../_models';

export class IFrame {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.src:
            case DataAttrName.showBorder: {
                prop.setValue = setNode.attr(prop.name);
                break;
            }
            case 'border-width':
            case 'border-radius':
            case 'border-color':
            case 'border-style': {
                prop.setValue = setNode.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        const setNode = selectedObj.select('div');
        switch (prop.name) {
            case DataAttrName.src:
            case DataAttrName.showBorder: {
                setNode.attr(prop.name, prop.setValue);
                break;
            }
            case 'border-width':
            case 'border-radius': {
                setNode.node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            case 'border-color':
            case 'border-style': {
                setNode.node.style.setProperty(prop.name, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
