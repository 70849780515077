import { DataAttrName } from '../const';
import { Property } from '../_models';

export class Time {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.accuracy: {
                prop.setValue = selectedObj.select('input').attr(prop.name);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.accuracy: {
                selectedObj.select('input').attr(prop.name, prop.setValue);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
