import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataAttrName } from 'src/app/const';
import { EventTransService } from 'src/app/_services';
import { AnimeProp, SelectedObj } from '../../_models';

@Component({
    selector: 'app-animate',
    templateUrl: 'animate.component.html',
    styles: [`
    .ant-collapse > .ant-collapse-item{
        border:0 !important;
    }
    .anime {
        width: 100%;
    }
    .anime::ng-deep.ant-collapse-item>.ant-collapse-header {
        color: #177ddc;
    }
    .anime div {
        margin-top:10px;
        display:flex;
        flex-direction:row;
        align-items:center;
        flex-wrap:nowrap;
    }
    .anime div nz-select{
        width:100%;
    }
    `]
})
export class AnimateComponent implements OnInit, OnDestroy {
    // 对象选中事件
    selectedObjSubscribe: Subscription;
    // 选中的对象
    selectedObj;

    prop = new AnimeProp();

    // 旋转动画
    showRotate = true;
    // 水流动画
    showFlow = false;
    // 位移动画
    showTranslate = false;


    constructor(
        private eventTransService: EventTransService
    ) {
    }

    ngOnInit() {
        this.initSubscribe();
    }

    ngOnDestroy() {
        this.selectedObjSubscribe.unsubscribe();
    }

    /**
     * 数据订阅
     */
    initSubscribe() {
        this.selectedObjSubscribe = this.eventTransService.selectedObjChangeEvent.subscribe((data: SelectedObj) => {
            this.handleSelectChange(data);
        });
    }

    /**
     * 选中对象发生变更
     */
    handleSelectChange(data: SelectedObj) {
        this.selectedObj = null;
        if (!data || data.Svg) {
            return;
        }
        this.showRotate = false;
        this.showFlow = false;
        this.showTranslate = true;

        if (data.Component) {
            this.selectedObj = data.Component;
        } else if (data.Element) {
            this.showRotate = true;
            this.showTranslate = true;

            this.selectedObj = data.Element;
            const nodeType = data.Element.type;

            if (nodeType === 'text' || nodeType === 'line' || nodeType === 'circle' || nodeType === 'rect'
                || nodeType === 'polygon' || nodeType === 'ellipse' || nodeType === 'path') {
                this.showFlow = true;
            }
        }
        if (!this.selectedObj) {
            return;
        }
        this.prop = new AnimeProp();
        const animeStr = this.selectedObj.attr(DataAttrName.anime);
        if (animeStr) {
            this.prop = JSON.parse(animeStr);
        }
    }

    setAnime() {
        if (!this.selectedObj) {
            return;
        }
        this.selectedObj.attr(DataAttrName.anime, JSON.stringify(this.prop));
        this.eventTransService.animeSetChange.next(this.selectedObj);
    }
}
