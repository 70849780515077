<div class="main">
    <div class="desc">
        <span class="title"><span class="mark">绑定数据</span>:</span>
        <div class="code">
            <div>
                <@p2.*>.value('111');
            </div>
        </div>
    </div>
    <div class="desc">
        <span class="title"><span class="mark">获取控件数据</span>:</span>
        <div class="code">
            <div>
                var txt = <@p2.*>.value();
            </div>
        </div>
    </div>
</div>