/**
 * 右键菜单操作/工具栏操作 类型
 */
export enum OperateType {
    /**
     * 复制
     */
    Copy = 1,
    /**
     * 粘贴
     */
    Paste = 2,
    /**
     * 删除
     */
    Delete = 3,
    /**
     * 全选
     */
    SelectAll = 4,
    /**
     * 查找
     */
    Find = 5,
    /**
     * 替换
     */
    Replace = 6,
    /**
     * 保存为模板
     */
    SaveToTemplate = 7,
    /**
     * 保存至组件库
     */
    SaveToBaseModel = 8,
    /**
     * 样式同步(当前页面)
     */
    SyncCss = 9,
    /**
     * 事件脚本
     */
    JsEvent = 10,

    /**
     * 顶端对齐
     */
    AlignToTop = 11,
    /**
     * 底端对齐
     */
    AlignToBottom = 12,
    /**
     * 左对齐
     */
    AlignToLeft = 13,
    /**
     * 右对齐
     */
    AlignToRight = 14,
    /**
     * 垂直对齐
     */
    AlignToVertical = 15,
    /**
     * 水平对齐
     */
    AlignToMiddle = 16,
    /**
     * 水平间距对齐
     */
    AlignMiddleSpace = 17,
    /**
     * 垂直间距对齐
     */
    AlignVerticalSpace = 18,
    /**
     * 水平居中
     */
    CenterToMiddle = 19,
    /**
     * 垂直居中
     */
    CenterToVertical = 20,
    /**
     * 置于顶层
     */
    SetToTop = 21,
    /**
     * 置于底层
     */
    SetToBottom = 22,
    /**
     * 组件合并
     */
    MergeCom = 23,
    /**
     * 组件拆分
     */
    SplitCom = 24,
    /**
     * 绘制折线图
     */
    DrawLine = 25,
    /**
     * 创建表格
     */
    CreateTable = 26,
    /**
     * 创建曲线图
     */
    CreateLine = 27,
    /**
     * 创建柱图
     */
    CreateBar = 28,
    /**
     * 创建饼图
     */
    CreatePie = 29,

    /**
     * 本地导出(与服务器连接中断时使用)
     */
    ExportPage = 30,

    /**
     * 旋转90度
     */
    Rotate90 = 31,
    /**
     * 旋转180度
     */
    Rotate180 = 32,
    /**
     * 还原(0度)
     */
    Rotate0 = 33,
    /**
     * 旋转自定义脚本
     */
    RotateCustom = 34,

    /**
     * 还原引用模板
     */
    RestoreTemplate = 35,
    /**
     * 创建仪表盘
     */
    CreateGauge = 36,
    /**
     * 去除页面留白
     */
    RemoveBlank = 37,
    /**
     * 组件大小相同
     */
    SameSize = 38,
    /**
     * 中心点对齐
     */
    Center = 39,
    /**
     * 宽度相同
     */
    SameWidth = 40,
    /**
     * 高度相同
     */
    SameHeight = 41,

    /**
     * 保存页面
     */
    Save = 100
}
