
        <nz-input-group nzAddOnBefore="ID" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.name" placeholder="请输入页面ID"/>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="名称" style="width:50%;">
            <input type="text" nz-input [(ngModel)]="page.text" placeholder="请输入页面名称"/>
        </nz-input-group>
        <div style="margin-top:5px;"></div>
        <label nz-checkbox [(ngModel)]="page.config.menu" style="width:25%;color:#ffa41af5">菜单页面</label>
        <label nz-checkbox [(ngModel)]="page.config.scale" style="width:25%;">允许缩放</label>
        <div style="margin-top:10px;"></div>
        <nz-input-group nzAddOnBefore="初始缩放" style="width:50%;">
              <nz-select [(ngModel)]="page.config.initScaleType" style="width:100%">
                <nz-option nzValue="0" nzLabel="自动"></nz-option>
                <nz-option nzValue="1" nzLabel="缩放"></nz-option>
                <nz-option nzValue="2" nzLabel="不缩放"></nz-option>
              </nz-select>
        </nz-input-group>
        <nz-input-group nzAddOnBefore="权限级别" style="width:50%;">
            <nz-input-number style="width:100%" [(ngModel)]="page.config.level" [nzMin]="0" [nzMax]="999" [nzStep]="1"
                nzTooltipTitle="权限等级低于该值的用户无法访问该页面！" nzTooltipPlacement="bottom" nz-tooltip></nz-input-number>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
        <nz-upload
            nzType="drag"
            [nzMultiple]="false"
            [nzLimit]="1"
            nzListType="text"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="files">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p class="ant-upload-hint">支持的文件类型：zip</p>
        </nz-upload>
        </cdk-virtual-scroll-viewport>
    