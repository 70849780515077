import { Component } from '@angular/core';

@Component({
    selector: 'app-help-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.css']
})
export class TextHelpComponent {
    constructor(
    ) {
    }
}

