import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService, } from '../_services';
import { NameInputComponent, FileUpLoadComponent } from '../_modals';
import { ComClassify } from '../_models';
import { ComClassifyApi } from '../api';

/**
 * 组件分类标题右键菜单
 */
@Component({
    selector: 'app-classify-title-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}" (contextmenu)='menuRightClick($event)'>
            <li nz-menu-item (click)='reNameClassify()' *ngIf="!classify.base">
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='addComponent($event)' *ngIf="!classify.base">
                <i nz-icon nzType="file-add" nzTheme="outline"></i>添加组件
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='createClassify()'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>添加组件分类
            </li>
            <li nz-menu-item (click)='deleteClassify()' *ngIf="!classify.base" [nzDisabled]=!enableDeleteMenu>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除组件分类
            </li>
            <nz-divider *ngIf="!classify.base"></nz-divider>
            <li nz-menu-item (click)='packDownload()' *ngIf="!classify.base">
                <i nz-icon nzType="download" nzTheme="outline"></i>打包下载
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})
export class ClassifyTitleContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() classify: ComClassify;
    @Input() enableDeleteMenu: boolean;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private classifyApi: ComClassifyApi
    ) { }

    /**
     * 重命名
     */
    reNameClassify() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (this.classify.base) {
            return;
        }
        this.modalService.create({
            nzTitle: '修改分类名称',
            nzComponentParams: {
                tipMsg: '请输入新的分类名称...',
                inputName: this.classify.text
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    if (name === this.classify.text) {
                        this.message.warning('新名称不能与原名称相同！');
                        return false;
                    }
                    const ret = await this.classifyApi.rename(this.classify.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.classifysChangeEvent.next(true);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 添加组件
     */
    addComponent(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        if (this.classify.base) {
            return;
        }
        this.modalService.create({
            nzTitle: '组件批量上传',
            nzComponentParams: {
                classify: this.classify
            },
            nzContent: FileUpLoadComponent,
            nzMaskClosable: false,
            nzOkText: '开始上传',
            nzOnOk: (ins) => ins.save()
        });
    }

    /**
     * 添加组件分类
     */
    createClassify() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '创建组件分类',
            nzComponentParams: {
                tipMsg: '请输入组件分类...'
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    const ret = await this.classifyApi.createWithSelectId(name, this.classify.id);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.classifysChangeEvent.next(true);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 删除组件分类
     */
    deleteClassify() {
        this.eventTransService.menuStateChangeEvent.next({});
        if (!this.enableDeleteMenu) {
            return;
        }
        this.modalService.confirm({
            nzTitle: `<i>确认删除组件分类：${this.classify.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.classifyApi.delete(this.classify.id);
                if (ret) {
                    this.message.info('操作成功');
                    this.eventTransService.classifysChangeEvent.next(true);
                }
            }
        });
    }

    /**
     * 打包下载
     */
    async packDownload() {
        const data: Blob = await this.classifyApi.packDownload(this.classify.id);
        if (!data) {
            return;
        }
        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, `${this.classify.text}.zip`);
    }

    /**
     * 鼠标右键-阻止默认菜单
     */
    menuRightClick(event: Event) {
        event.preventDefault();
    }
}

