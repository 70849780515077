import { DataAttrName } from '../const';
import { Property } from '../_models';

export class Panel {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.height: {
                prop.setValue = selectedObj.select('svg').node.style.height.replace('px', '');
                break;
            }
            case 'border-color': {
                prop.setValue = selectedObj.node.style.borderColor;
                break;
            }
            case 'border-width': {
                prop.setValue = selectedObj.node.style.borderWidth.replace('px', '');
                break;
            }
            case 'border-radius': {
                prop.setValue = selectedObj.node.style.borderRadius.replace('px', '');
                break;
            }
            case 'border-style': {
                prop.setValue = selectedObj.node.style.borderStyle;
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case DataAttrName.height: {
                selectedObj.select('svg').node.style.setProperty('height', prop.setValue + 'px');
                break;
            }
            case 'border-color':
            case 'border-style': {
                selectedObj.node.style.setProperty(prop.name, prop.setValue);
                break;
            }
            case 'border-width':
            case 'border-radius': {
                selectedObj.node.style.setProperty(prop.name, prop.setValue + 'px');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
