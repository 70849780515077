/**
 * 工具箱配置
 */
export class ToolBoxConfig {
    /**
     * 区域缩放
     */
    showArea = false;
    /**
     * 数据视图
     */
    showDataView = false;
    /**
     * 保存为图片
     */
    saveAsImg = true;
}
