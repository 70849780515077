
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='reName($event)'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='import($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createClassify($event)'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>创建模板分类
            </li>
            <li nz-menu-item (click)='deleteClassify($event)' [nzDisabled]=!enableDelete>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模板分类
            </li>
        </ul>
    