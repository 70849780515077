import { Component } from '@angular/core';

@Component({
    selector: 'app-help-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css']
})
export class SelectHelpComponent {
    constructor(
    ) {
    }
}

