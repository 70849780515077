import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

import {
  ContextMenuState, SelectedObj,
  OperateType, DragedData, CanvasData, DataSource, DataEvent,
} from '../../_models';
import { CommondAction } from '../../_helpers/commondAction';
import { CommonApi, PageApi, TemplateApi } from 'src/app/api';
import { CanvasConstData, ComponentType, DataAttrName, DefaultJs, ImgType, LoadingMsg, MenuData, SvgNodeType } from 'src/app/const';
import { CanvasCache, DataCache } from 'src/app/cache';
import { EventTransService } from 'src/app/_services';
import { CommonFunc } from './commonFunc';
import { CanvasTool } from './canvasTool';
import { EventHandle } from './eventHandle';
import { MathUtil } from 'src/app/utils';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DOCUMENT } from '@angular/common';
import { JsDataType } from 'src/app/enums';


declare var subjx: any;
declare var dragscroll: any;
declare var Snap: any;
declare var $: any;

@Component({
  selector: 'app-canvas',
  templateUrl: './svgCanvas.component.html',
  styleUrls: ['./svgCanvas.component.css']
})
/*svg 画布 */
export class SvgCanvasComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('canvasX', { static: true }) canvasX: ElementRef;
  @ViewChild('canvasY', { static: true }) canvasY: ElementRef;
  @ViewChild('svgCanvas', { static: true }) svgCanvas: ElementRef;
  @ViewChild('divCanvas', { static: true }) divCanvas: ElementRef;
  @ViewChild('rulerX', { static: true }) rulerX: ElementRef;
  @ViewChild('rulerY', { static: true }) rulerY: ElementRef;


  lockCanvas = true;
  /**
   * 是否显示标尺
   */
  showRuler = true;
  /**
   * 是否显示网格
   */
  showGridLine = true;
  /**
   * 拖拽的svg数据
   */
  dragedData: DragedData;

  /**
   * 当前选中的对象
   */
  selectedObj: SelectedObj;

  // 数据订阅
  dragDataEventSub: Subscription;
  menuStateChangeEventSub: Subscription;
  loadCanvasDataEventSub: Subscription;
  loadCanvasCacheDataEventSub: Subscription;
  keyDownEventSub: Subscription;
  keyUpEventSub: Subscription;
  saveCanvasEventSub: Subscription;
  selectedObjChangeEventSub: Subscription;
  toolBarFuncSub: Subscription;
  svgScaleChangeSub: Subscription;
  setCanvasCacheSub: Subscription;
  clearCanvasCacheSub: Subscription;
  recordActionEventSub: Subscription;
  projectThemeChangeSub: Subscription;

  /**
   * snap.svg对象
   */
  svg;

  /**
   * 右键菜单X轴位置
   */
  contextmenuX = 0;
  /**
   * 右键菜单Y轴位置
   */
  contextmenuY = 0;

  /**
   * 右键菜单状态
   */
  menuState: ContextMenuState = new ContextMenuState();
  /**
   * 是否开始折线绘制
   */
  startDrawLine = false;

  /**
   * 曲线绘制-转折点分组
   */
  draw_Group = null;
  /**
   * 曲线绘制-曲线路径
   */
  draw_path = null;
  /**
   * 曲线绘制-曲线路径值
   */
  draw_path_value: string;

  /**
   * 是否开启鼠标区域选择
   */
  isStartSelect = false;
  /**
   * 鼠标区域框对象
   */
  drawReginDom = null;
  /**
   * 鼠标区域选择开始位置X
   */
  drawReginStartX: number;
  /**
   * 鼠标区域选择开始位置Y
   */
  drawReginStartY: number;

  /**
   * 是否为模板页面
   */
  isTemplatePage = false;

  svgMouseDownDoneTime: Date = new Date();

  /**
   * 页面状态色，未保存：red；已保存:green
   */
  pageStateColor: string;
  pageStateStr: string;

  showRestoreBtn = false;

  showRoate = false;

  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private eventTransService: EventTransService,
    private commonApi: CommonApi,
    private commonFunc: CommonFunc,
    private canvasTool: CanvasTool,
    private eventHandle: EventHandle,
    private pageApi: PageApi,
    private templateApi: TemplateApi,
    @Inject(DOCUMENT) private document
  ) {
    console.log('svgCanvas page init....');
    this.eventTransService.reset();

    // 画布数据实时缓存缓存
    setTimeout(() => {
      this.pageCacheSaver();
    }, 5000);
  }

  /**
   * 页面初始化
   */
  ngOnInit() {
    // this.initStyleFile();
    CanvasCache.svg = Snap('#svgCanvas');
    this.svg = Snap('#svgCanvas');
    CanvasCache.obs = subjx.createObservable();
    // 初始化事件订阅
    this.initSubscrite();
    // svg画布缩放事件
    this.zoomSvg();
  }

  ngAfterViewInit() {
    this.drawRuler();
  }

  /**
   * 页面销毁
   */
  ngOnDestroy() {
    // 取消事件订阅
    this.dragDataEventSub.unsubscribe();
    this.menuStateChangeEventSub.unsubscribe();
    this.loadCanvasDataEventSub.unsubscribe();
    this.loadCanvasCacheDataEventSub.unsubscribe();
    this.keyDownEventSub.unsubscribe();
    this.keyUpEventSub.unsubscribe();
    this.saveCanvasEventSub.unsubscribe();
    this.selectedObjChangeEventSub.unsubscribe();
    this.toolBarFuncSub.unsubscribe();
    this.svgScaleChangeSub.unsubscribe();
    this.setCanvasCacheSub.unsubscribe();
    this.clearCanvasCacheSub.unsubscribe();
    this.recordActionEventSub.unsubscribe();
    this.projectThemeChangeSub.unsubscribe();
  }

  /**
   * 初始化数据监听事件
   */
  initSubscrite() {
    // 拖拽的svg组件变更事件
    this.dragDataEventSub = this.eventTransService.dragDataEvent.subscribe((data: DragedData) => {
      this.dragedData = data;
    });
    // 右键菜单状态变更事件
    this.menuStateChangeEventSub = this.eventTransService.menuStateChangeEvent.subscribe((data: ContextMenuState) => {
      this.menuState = data;
    });
    // 当前页面数据重新加载事件
    this.loadCanvasDataEventSub = this.eventTransService.loadCanvasDataEvent.subscribe(async (data: CanvasData) => {
      if (data) {
        this.loadCanvasData(data);
      } else {
        this.clearCanvas();
        CommondAction.doStack(this.commonFunc.getEmptyCanvas());
      }
    });
    this.loadCanvasCacheDataEventSub = this.eventTransService.loadCanvasCacheDataEvent.subscribe(async (data: CanvasData) => {
      if (!data) {
        return;
      }
      this.loadCanvasCacheData(data);
    });
    // 键盘事件处理
    this.keyDownEventSub = this.eventTransService.keyDownEvent.subscribe((event: KeyboardEvent) => {
      if (event) {
        this.eventHandle.keyDown(event);
      }
    });
    this.keyUpEventSub = this.eventTransService.keyUpEvent.subscribe((event: KeyboardEvent) => {
      if (event && event.code && (event.code.startsWith('Control') || event.code.startsWith('Meta'))) {
        CanvasCache.isCtrlDown = false;
      }
    });
    // 页面保存事件
    this.saveCanvasEventSub = this.eventTransService.saveCanvasEvent.subscribe((event: boolean) => {
      if (event) {
        this.canvasTool.saveCanvas();
      }
    });
    // 页面选中元素事件
    this.selectedObjChangeEventSub = this.eventTransService.selectedObjChangeEvent.subscribe((event: SelectedObj) => {
      this.selectedObj = event;
      if (event) {
        if (event.Component) {
          CanvasCache.selectedComponent = event.Component;
        } else if (event.Svg) {
          CanvasCache.selectedComponent = null;
        }
      } else {
        CanvasCache.selectedComponent = null;
      }
    });
    /**
     * 工具栏点击操作
     */
    this.toolBarFuncSub = this.eventTransService.toolBarFunc.subscribe((event: OperateType) => {
      if (event) {
        this.eventHandle.operate(event);
      }
    });
    this.svgScaleChangeSub = this.eventTransService.svgScaleChange.subscribe((data: number) => {
      if (data > 0) {
        CanvasCache.svgScaleNum = data;
        const elemnt = document.getElementById('svgCanvas');
        elemnt.style.transform = 'scale(' + data + ')';
      }
    });
    this.setCanvasCacheSub = this.eventTransService.setCanvasCache.subscribe((data: boolean) => {
      if (data) {
        this.setCanvasCache(false);
      }
    });
    this.clearCanvasCacheSub = this.eventTransService.clearCanvasCache.subscribe((id: string) => {
      if (id) {
        this.clearCanvasCache(id);
      }
    });
    this.recordActionEventSub = this.eventTransService.recordActionEvent.subscribe((data: boolean) => {
      if (data) {
        this.recordAction();
      }
    });
    this.projectThemeChangeSub = this.eventTransService.projectThemeChange.subscribe((data: boolean) => {
      if (data) {
        this.initStyleFile();
      }
    });
  }

  /**
   * 动态加载组件样式文件
   */
  initStyleFile() {
    this.document.getElementById('componentHref').setAttribute('href',
      `${DataCache.sysConfig.domin}/library/bootstrapSwitch/bootstrap-switch.css`);

    this.document.getElementById('comHref').setAttribute('href', `${DataCache.sysConfig.domin}/css/component.css`);
    this.document.getElementById('comColorHref').setAttribute('href', `${DataCache.sysConfig.domin}/css/component-color.css`);

    let themeId = 'default';
    if (DataCache.project && DataCache.project.themeId) {
      themeId = DataCache.project.themeId;
    }
    this.document.getElementById('skinHref').setAttribute('href', `${DataCache.sysConfig.domin}/themes/${themeId}/css/skin.css`);
  }

  /**
   * 绘制标尺
   */
  drawRuler() {
    const contextX = (this.canvasX.nativeElement as HTMLCanvasElement).getContext('2d');
    const contextY = (this.canvasY.nativeElement as HTMLCanvasElement).getContext('2d');

    contextX.clearRect(0, 0, 50000, 20);
    contextY.clearRect(0, 0, 20, 50000);
    contextX.beginPath();
    contextX.fillStyle = '#a1a1a1';
    contextY.fillStyle = '#a1a1a1';
    for (let i = 0; i < 50000; i += 10) {
      const y = (i % 100 === 0) ? 0 : 10;
      contextX.moveTo(i, y);
      contextX.lineTo(i, 15);
      if (y === 0) {
        contextX.fillText(i.toString(), i + 2, 8);
      }

      contextY.save();
      contextY.beginPath();
      const x = (i % 100 === 0) ? 0 : 10;
      contextY.moveTo(x, i);
      contextY.lineTo(15, i);

      contextY.strokeStyle = '#a1a1a1';
      contextY.stroke();
      contextY.closePath();

      if (x === 0 && i > 0) {
        contextY.beginPath();

        contextY.translate(8, i - 2);
        contextY.rotate(270 * Math.PI / 180);
        contextY.fillText(i.toString(), 0, 0);

        contextY.closePath();
        contextY.restore();
      }
    }
    contextX.strokeStyle = '#a1a1a1';
    contextX.stroke();
    contextY.stroke();
  }

  /**
   * 设置允许拖拽
   * @param event 事件
   */
  allowDrop(event: Event): void {
    event.preventDefault();
  }

  /**
   * 拖拽完成事件
   * @param event 事件
   */
  drop(event: any): void {
    event.preventDefault();
    CanvasCache.isFoucus = true;

    if (!this.dragedData) {
      this.message.warning('组件未加载完成，请尝试重新拖拽！');
      return;
    }

    const g = this.svg.paper.g().attr({
      'data-attr-id': this.dragedData.id,
      'data-attr-text': this.dragedData.text,
      transform: 'translate(' + event.offsetX + ' ' + event.offsetY + ')'
    });

    if (this.dragedData.isTemplate) {
      this.handleTemplate(g);
    } else {
      this.handleComponent(g);
    }

    // 绑定操作事件
    this.canvasTool.bindEvent(g);

    // 控制元素初始化大小
    let bbox = g.getBBox();

    if (this.dragedData.type === ImgType.Svg && bbox.width > 200 && !g.attr(DataAttrName.type)
      && !this.dragedData.isTemplate && (!g.select('foreignObject') || !g.select('foreignObject').attr(DataAttrName.componentName))) {
      const scale = 200 / bbox.width;
      g.attr({
        transform: g.transform().local + (g.transform().local ? 'S' : 's') + `${scale}`
      });
      bbox = g.getBBox();
      g.attr({
        transform: g.transform().local + (g.transform().local ? 'T' : 't') + `${event.offsetX - bbox.x} ${event.offsetY - bbox.y}`
      });
    }
    g.mousedown();
    // 列表面版组件
    if (g.select('foreignObject') && g.select('foreignObject').attr(DataAttrName.componentName) === ComponentType.panel) {
      this.eventTransService.canvasElementChange.next(true);
    }
    this.recordAction();
  }

  /**
   * 拖拽模板处理
   */
  handleTemplate(g: any) {
    // 模板组件
    let defs = this.svg.select(`[id='${this.dragedData.id}']`);
    if (!defs) {
      // 添加模板引用
      defs = this.svg.paper.g().attr({
        id: this.dragedData.id,
        'data-attr-id': this.dragedData.id,
        'data-attr-text': this.dragedData.text,
      }).toDefs();
      if (defs.parent().parent().type !== 'svg') {
        this.svg.add(defs.parent());
      }
      this.handleComponent(defs);
    }
    g.append(defs.use());
    g.attr(DataAttrName.template, this.dragedData.id);

    if (!DataCache.page) {
      this.message.warning('未打开页面，不添加初始化数据源、事件！');
      return;
    }

    // 绑定初始数据源和组件事件
    if (this.dragedData.source) {
      const source: DataSource = JSON.parse(JSON.stringify(this.dragedData.source));
      source.id = MathUtil.getUUid();
      g.attr(DataAttrName.source, source.id);

      DataCache.pageData.sources.push(source);
    }

    if (this.dragedData.event) {
      const event: DataEvent = JSON.parse(JSON.stringify(this.dragedData.event));
      event.id = MathUtil.getUUid();
      g.attr(DataAttrName.event, event.id);

      DataCache.pageData.events.push(event);
    }
  }

  /**
   * 拖拽组件处理
   */
  handleComponent(dropObj) {
    let svgContent = this.dragedData.content;

    if (this.dragedData.type === ImgType.Svg) {
      // 去除xml父级元素，只保留svg内的子元素
      const startIndex = svgContent.indexOf('<svg');
      if (startIndex > 0) {
        svgContent = svgContent.replace(svgContent.substring(0, startIndex), '');
      }
      const stopIndex = svgContent.indexOf('>');
      const startStr = svgContent.substring(0, stopIndex + 1);
      svgContent = svgContent.replace(startStr, '').replace('</svg>', '');

      const snap = Snap.fragment(svgContent);
      // foreignObject 特殊组件处理
      if (snap.selectAll('g').length === 0 && snap.select('foreignObject')) {
        if (snap.select('foreignObject').attr(DataAttrName.type)) {
          dropObj.attr(DataAttrName.type, snap.select('foreignObject').attr(DataAttrName.type));
        }
        const comType = snap.select('foreignObject').attr(DataAttrName.componentName);
        if (comType === ComponentType.button || comType === ComponentType.buttonIcon) {
          if (DataCache.page) {
            const eid = MathUtil.getUUid();
            const dataEvent: DataEvent = {
              id: eid,
              action: DefaultJs.BUTTON
            };
            DataCache.pageData.events.push(dataEvent);
            dropObj.attr(DataAttrName.event, eid);
          }
        }
      }
      // 直线Ex、竖线Ex特殊处理
      if (snap.selectAll('g').length === 0 && snap.select('line') && snap.select('line').attr(DataAttrName.type)) {
        dropObj.attr(DataAttrName.type, snap.select('line').attr(DataAttrName.type));
      }
      dropObj.add(snap);
    } else {
      // 普通图片、BASE64编码方式传输
      svgContent = `data:${this.dragedData.type};base64,${svgContent}`;

      let w = 150;
      let h = 150;
      if (this.dragedData.width && this.dragedData.width > 0) {
        w = this.dragedData.width;
      }
      if (this.dragedData.height && this.dragedData.height > 0) {
        h = this.dragedData.height;
      }
      dropObj.image(svgContent, 0, 0, w, h);
      dropObj.attr(DataAttrName.type, SvgNodeType.image);
    }
  }

  /**
   * svg画布鼠标右击事件
   * @param event 事件
   */
  svgRightClick(event: any) {
    CanvasCache.isFoucus = true;
    event.stopPropagation();
    event.preventDefault();

    if (CanvasCache.isDrawMode) {
      this.setDrawMode();
      return;
    }
    // let x = event.offsetX * CanvasCache.svgScaleNum - this.divCanvas.nativeElement.scrollLeft;
    // let y = event.offsetY * CanvasCache.svgScaleNum - this.divCanvas.nativeElement.scrollTop;

    let x = event.pageX - MenuData.canvasShiftX;
    let y = event.pageY - MenuData.canvasShiftY;

    if (x + MenuData.canvasMenuWidth > this.divCanvas.nativeElement.clientWidth) {
      x = x - MenuData.canvasMenuWidth;
    }
    if (y + MenuData.canvasMenuHeight > this.divCanvas.nativeElement.clientHeight) {
      y = y - MenuData.canvasMenuHeight;
    }
    if (y < -120) y = -120;

    this.contextmenuX = x;
    this.contextmenuY = y;

    this.eventTransService.menuStateChangeEvent.next({ SvgCanvas: true });
  }

  /**
   * 画布滚动事件
   */
  canvasScroll(event: any) {
    this.rulerX.nativeElement.scrollLeft = this.divCanvas.nativeElement.scrollLeft;
    this.rulerY.nativeElement.scrollTop = this.divCanvas.nativeElement.scrollTop;
  }

  /**
   * 启用/禁用画布拖拽滚动
   */
  lockChange() {
    if (this.lockCanvas) {
      this.divCanvas.nativeElement.classList.add('dragscroll');
      this.divCanvas.nativeElement.style.cursor = 'grab';
      this.message.info('已启用画布拖拽功能！');
    } else {
      this.divCanvas.nativeElement.classList.remove('dragscroll');
      this.divCanvas.nativeElement.style.cursor = 'default';
      this.message.error('已禁用画布拖拽功能！');
    }
    this.lockCanvas = !this.lockCanvas;
    dragscroll.reset();
  }

  /**
   * svg画布点击事件
   * @param event 事件
   */
  svgClick(event: any) {
    CanvasCache.isFoucus = true;
    if (CanvasCache.isDrawMode) {
      if (!this.draw_Group) {
        this.draw_Group = this.svg.paper.g();
        const pathGroup = this.svg.paper.g().attr({ 'data-attr-text': '自定义路径' });

        this.draw_path = this.svg.paper.path(`M${event.offsetX} ${event.offsetY}`).attr({
          stroke: '#ffffff',
          fill: 'rgba(177,196,239,0.21)',
          strokeWidth: 4
        });
        pathGroup.add(this.draw_path);
        this.draw_path_value = `M${event.offsetX} ${event.offsetY}`;

        this.draw_Group.add(this.svg.paper.circle(event.offsetX, event.offsetY, 4).attr({
          fill: '#0FF',
          stroke: '#00F'
        }));
        this.startDrawLine = true;
      } else {
        this.draw_path_value = this.draw_path.attr('d');
        this.draw_Group.add(this.svg.paper.circle(event.offsetX, event.offsetY, 4).attr({
          fill: '#0FF',
          stroke: '#00F'
        }));
      }
    }
  }

  clearCanvas() {
    this.commonFunc.clearSelected();
    this.eventTransService.selectedObjChangeEvent.next(null);
    this.commonFunc.clearCanvas();
    CommondAction.clear();
    DataCache.clearCanvasData();
  }

  /**
   * 加载页面数据
   */
  async loadCanvasData(data: CanvasData) {
    this.clearCanvas();
    this.eventTransService.globalLoadingEvent.next(LoadingMsg.query);

    let content = '';
    if (data.isPage) {
      if (data.isBackUp) {
        const map = await this.pageApi.getBackUp(data.id);
        DataCache.page = map.Page;
        DataCache.pageData = map.Data;
      } else {
        DataCache.page = data.data;
        DataCache.pageData = await this.pageApi.getData(data.id);
      }

      content = DataCache.pageData.content;
    } else {
      DataCache.template = await this.templateApi.get(data.id);
      if (DataCache.template.data) {
        content = DataCache.template.data.content;
      }
    }

    // 加载svg画布
    this.canvasTool.loadCanvas(content);
    CommondAction.doStack(content);

    this.eventTransService.canvasDataChange.next(true);
    this.eventTransService.globalLoadingEvent.next('');
    this.eventTransService.canvasElementChange.next(true);

    // 新打开页面不触发自动保存
    this.pageStateColor = 'green';
  }

  /**
   * 加载缓存数据
   * @param data CanvasData
   */
  async loadCanvasCacheData(data: CanvasData) {

    if (!localStorage.getItem(data.id + '_content')) {
      this.message.warning(`缓存中无该${data.isPage ? '页面' : '模板'}数据！`);
      return;
    }

    this.clearCanvas();

    const content = localStorage.getItem(data.id + '_content');
    const source = localStorage.getItem(data.id + '_source');
    const event = localStorage.getItem(data.id + '_event');
    const globalEvent = localStorage.getItem(data.id + '_globalEvent');

    if (data.isPage) {
      DataCache.page = data.data;
      DataCache.pageData = {
        id: data.id,
        projectId: DataCache.page.projectId,
        content,
        sources: JSON.parse(source),
        events: JSON.parse(event),
        globalEvent
      };
    } else {
      DataCache.template = data.data;
      DataCache.template.data = {
        content,
        source: JSON.parse(source),
        event: JSON.parse(event)
      };
    }

    // 加载svg画布
    this.canvasTool.loadCanvas(content);
    // 初始loadpage时压栈
    CommondAction.doStack(content);

    this.eventTransService.canvasDataChange.next(true);
    // 新打开页面不触发自动保存
    this.pageStateColor = 'green';
  }

  /**
   * 绘图模式设置
   */
  setDrawMode() {
    if (CanvasCache.isDrawMode) {
      if (this.draw_Group) {
        this.draw_Group.remove();
        this.draw_Group = null;
        this.startDrawLine = false;
        this.canvasTool.bindEvent(this.draw_path.parent());
      }
      CanvasCache.isDrawMode = false;
    } else {
      CanvasCache.isDrawMode = true;
      this.message.info('开启折线绘制模式，鼠标右键完成绘制！');
    }
    this.eventTransService.isDrawMode.next(CanvasCache.isDrawMode);
  }

  /**
   * svg画布鼠标按下事件
   */
  svgMouseDown(event: MouseEvent) {
    this.eventTransService.openJsWindow.next(null);
    // const x = event.offsetX;
    // const y = event.offsetY;

    const x = (event.pageX - MenuData.canvasShiftX + this.divCanvas.nativeElement.scrollLeft) / CanvasCache.svgScaleNum;
    const y = (event.pageY - MenuData.canvasShiftY + this.divCanvas.nativeElement.scrollTop) / CanvasCache.svgScaleNum;

    // 鼠标左键
    if (event.button === 0) {
      this.isStartSelect = true;
      if (this.drawReginDom) {
        this.drawReginDom.remove();
        this.drawReginDom = null;
      }
      this.drawReginDom = this.svg.paper.path(`M${x} ${y}`).attr({
        stroke: '#a1a1a1',
        fill: 'rgba(177,196,239,0.21)',
        'stroke-dasharray': '3px',
        strokeWidth: 1
      });
      this.drawReginStartX = x;
      this.drawReginStartY = y;
    }
    this.svgMouseDownDoneTime = new Date();
  }

  /**
   * svg画布鼠标松开事件
   * @param event 事件
   */
  svgMouseUp(event) {
    this.isStartSelect = false;
    // event.which 鼠标右键释放，不触发
    if (!this.drawReginDom || event.which == 3) {
      return;
    }
    this.drawReginDom.remove();
    this.drawReginDom = null;

    if (new Date().getTime() - this.svgMouseDownDoneTime.getTime() < 200) {
      if (!CanvasCache.isCtrlDown) {
        this.commonFunc.clearSelected();
        this.eventTransService.selectedObjChangeEvent.next({ Svg: this.svg });
      }
      return;
    }

    if (!CanvasCache.isCtrlDown) {
      this.commonFunc.clearSelected();
    }

    let sx = 0;
    let sy = 0;
    let ex = 0;
    let ey = 0;
    if (this.drawReginStartX < CanvasCache.mouseOffsetX) {
      sx = this.drawReginStartX;
      ex = CanvasCache.mouseOffsetX;
    } else {
      sx = CanvasCache.mouseOffsetX;
      ex = this.drawReginStartX;
    }
    if (this.drawReginStartY < CanvasCache.mouseOffsetY) {
      sy = this.drawReginStartY;
      ey = CanvasCache.mouseOffsetY;
    } else {
      sy = CanvasCache.mouseOffsetY;
      ey = this.drawReginStartY;
    }
    const selDoms = [];

    this.svg.children().forEach((item: any) => {
      // 排除网格标签
      if (this.commonFunc.isDataNode(item) && !item.hasClass(CanvasConstData.subjxClass)) {
        const bbox = item.getBBox();
        const x = bbox.x;
        const y = bbox.y;
        const width = bbox.width;
        const height = bbox.height;

        if (width === 0 && height === 0) {
          item.remove();
          return true;
        }

        const dataId = item.attr(DataAttrName.id);
        if (this.commonFunc.isFilterDom(dataId) || item.attr(DataAttrName.disableSelect)) {
          return true;
        }

        if ((x >= sx && x <= ex && ((y >= sy && y <= ey) || (y + height >= sy && y + height <= ey) || (y <= sy && y + height >= sy))) ||
          ((x + width >= sx && x + width <= ex) && ((y >= sy && y <= ey) || (y + height >= sy && y + height <= ey) ||
            (y <= sy && y + height >= sy))) ||
          ((y >= sy && y <= ey) && (x <= sx && x + width >= sx)) ||
          ((y + height >= sy && y + height <= ey) && (x <= sx && x + width >= sx))
        ) {
          selDoms.push(item);
        }
      }
    });
    selDoms.forEach(item => {
      this.canvasTool.svgDomMouseDown(item, true);
    });
  }

  /**
   * svg画布鼠标移动事件
   * @param event 事件
   */
  svgMouseMove(event) {
    event.preventDefault();
    CanvasCache.isCtrlDown = event.ctrlKey || event.metaKey;
    // // 使用 foreignObject 节点时，offset位置会重置
    // CanvasCache.mouseOffsetX = event.layerX / CanvasCache.svgScaleNum;
    // CanvasCache.mouseOffsetY = event.layerY / CanvasCache.svgScaleNum;

    CanvasCache.mouseOffsetX = (event.pageX - MenuData.canvasShiftX + this.divCanvas.nativeElement.scrollLeft) / CanvasCache.svgScaleNum;
    CanvasCache.mouseOffsetY = (event.pageY - MenuData.canvasShiftY + this.divCanvas.nativeElement.scrollTop) / CanvasCache.svgScaleNum;

    if (this.startDrawLine) {
      this.draw_path.attr('d', `${this.draw_path_value}L${CanvasCache.mouseOffsetX} ${CanvasCache.mouseOffsetY}`);
      return;
    }
    if (this.isStartSelect) {
      this.drawReginDom.attr('d', `M${this.drawReginStartX} ${this.drawReginStartY}
                                   L${CanvasCache.mouseOffsetX} ${this.drawReginStartY}
                                   L${CanvasCache.mouseOffsetX} ${CanvasCache.mouseOffsetY}
                                   L${this.drawReginStartX} ${CanvasCache.mouseOffsetY}Z`);
      return;
    }
  }

  /**
   * 记录执行动作
   */
  recordAction() {
    if (CanvasCache.isBatchAction) {
      return;
    }
    CommondAction.doStack(this.commonFunc.getCanvasData());
    this.setCanvasCache(false);
  }

  /**
   * svg画布缩放事件
   */
  zoomSvg() {
    const elemnt = document.getElementById('svgCanvas');
    if (elemnt.addEventListener) {
      // IE9, Chrome, Safari, Opera
      elemnt.addEventListener('mousewheel', MouseWheelHandler, false);
      // Firefox
      elemnt.addEventListener('DOMMouseScroll', MouseWheelHandler, false);
    }

    let i = 1;
    const global = this;
    function MouseWheelHandler(e) {
      if (e.ctrlKey == true) {
        // cross-browser wheel delta
        const event = window.event || e; // old IE support
        event.preventDefault();
        const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        if (delta == 1) {
          if (i > 3) {
            i = 3;
          } else {
            i += 0.1;
          }
          global.eventTransService.svgScaleChange.next(i);
          elemnt.style.transform = 'scale(' + i + ')';
        } else if (delta == -1) {
          if (i < 0.3) {
            i = 0.2;
          } else {
            i -= 0.1;
          }
          global.eventTransService.svgScaleChange.next(i);
          elemnt.style.transform = 'scale(' + i + ')';
        }
        return false;
      }
    }
  }

  /**
   * 定时缓存页面数据
   */
  pageCacheSaver() {
    try {
      if ((!DataCache.page && !DataCache.template) || this.pageStateColor === 'green') {
        return;
      }
      this.setCanvasCache(true);
    } catch (e) {
      console.log(`%cpage cache saver error:${e.message}`, 'color:red');
    } finally {
      setTimeout(() => {
        this.pageCacheSaver();
      }, 5000);
    }
  }

  /**
   * 设置画布缓存
   * @param isNeedSave 是否缓存
   */
  setCanvasCache(isNeedSave: boolean) {
    try {
      if (isNeedSave) {
        if (this.pageStateColor === 'green' || (!DataCache.page && !DataCache.template)) {
          return;
        }
        if (DataCache.page) {
          const id = DataCache.page.id;
          localStorage.setItem(id + '_content', CanvasCache.canvasContent);
          localStorage.setItem(id + '_source', JSON.stringify(DataCache.pageData.sources));
          localStorage.setItem(id + '_event', JSON.stringify(DataCache.pageData.events));
          localStorage.setItem(id + '_globalEvent', DataCache.pageData.globalEvent);
        } else {
          const id = DataCache.template.id;
          localStorage.setItem(id + '_content', CanvasCache.canvasContent);
          localStorage.setItem(id + '_source', JSON.stringify(DataCache.template.data.source));
          localStorage.setItem(id + '_event', JSON.stringify(DataCache.template.data.event));
        }

        this.pageStateColor = 'green';
        const time = new Date();
        this.pageStateStr = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()} 页面已缓存`;
      } else {
        this.pageStateColor = 'red';
        this.pageStateStr = '**页面未缓存**';
      }
    } catch (e) {
      console.log('setCanvasCache:' + e.message);
    }
  }

  /**
   * 清除页面缓存数据
   * @param id 页面/模板 id
   */
  clearCanvasCache(id: string) {
    this.pageStateColor = 'green';
    this.pageStateStr = '';
    localStorage.removeItem(id + '_content');
    localStorage.removeItem(id + '_source');
    localStorage.removeItem(id + '_event');
    localStorage.removeItem(id + '_globalEvent');
  }

  showRoateDeg(that, x, y, transform) {
    const aa = Math.round(180 * Math.asin(transform.a) / Math.PI);
    const bb = Math.round(180 * Math.acos(transform.b) / Math.PI);
    const cc = Math.round(180 * Math.asin(transform.c) / Math.PI);
    const dd = Math.round(180 * Math.acos(transform.d) / Math.PI);
    let deg = 0;
    if (aa === bb || -aa === bb) {
      deg = dd;
    } else if (-aa + bb === 180) {
      deg = 180 + cc;
    } else if (aa + bb === 180) {
      deg = 360 - cc || 360 - dd;
    }
    deg = deg >= 360 ? 0 : deg;

    $('#showRoate text').attr('x', x);
    $('#showRoate text').attr('y', y);
    $('#showRoate text').text(deg);
    that.showRoate = true;
  }

  menuClick(event: number) {
    this.eventHandle.operate(event);
  }

  /**
   * 清除画布背景
   */
  clearBackGround() {
    this.svg.node.style.setProperty('background-image', '');
    CommondAction.isChange = true;
    this.message.success('操作成功！');
  }

  /**
   * 清除组件id
   */
  clearId() {
    if (this.commonFunc.getSelectedSize() == 0) {
      this.message.warning('请先选择需要清除id的组件!');
      return;
    }
    let count = 0;
    for (const item of this.commonFunc.getSelectedDoms()) {
      const dom = $(item.el).data('dom');
      dom.attr('id', '');
      count++;
    }
    if (count > 0) {
      this.message.success(`操作成功, 清除组件id:${count} 个 !`);
      CommondAction.isChange = true;
    }
  }

  /**
   * 配置异常修复
   */
  repairConfig() {
    if (!DataCache.page) {
      this.message.warning('请先打开页面！');
      return;
    }
    //组件id修复
    const idNodes = CanvasCache.svg.selectAll(`[id]`);
    idNodes.forEach(node => {
      const id = node.attr('id');
      const nodes = CanvasCache.svg.selectAll(`[id='${id}']`);
      if (nodes.length > 1) {
        node.attr('id', '');
      }
    });
    //数据源id重复修复
    const sourceNodes = CanvasCache.svg.selectAll(`[${DataAttrName.source}]`);
    sourceNodes.forEach(node => {
      let sourceId = node.attr(DataAttrName.source);
      const nodes = CanvasCache.svg.selectAll(`[${DataAttrName.source}='${sourceId}']`);
      if (nodes.length > 1) {
        node.attr(DataAttrName.source, '');
        const source = DataCache.pageData.sources.find(d => d.id === sourceId);
        if (source) {
          sourceId = MathUtil.getUUid();
          const newSource = JSON.parse(JSON.stringify(source));
          newSource.id = sourceId;
          DataCache.pageData.sources.push(newSource);
          node.attr(DataAttrName.source, sourceId);
        }
      }
    });
    //事件id重复修复
    const eventNodes = CanvasCache.svg.selectAll(`[${DataAttrName.event}]`);
    eventNodes.forEach(node => {
      let eventId = node.attr(DataAttrName.event);
      const nodes = CanvasCache.svg.selectAll(`[${DataAttrName.event}='${eventId}']`);
      if (nodes.length > 1) {
        node.attr(DataAttrName.event, '');
        const event = DataCache.pageData.events.find(d => d.id === eventId);
        if (event) {
          eventId = MathUtil.getUUid();
          const newEvent = JSON.parse(JSON.stringify(event));
          newEvent.id = eventId;
          DataCache.pageData.events.push(newEvent);
          node.attr(DataAttrName.event, eventId);
        }
      }
    });

    this.message.success('页面修复完成！');
  }

  cuotomPageJs() {
    if (!DataCache.page) {
      this.message.warning('请先打开页面！');
      return;
    }
    this.eventTransService.openJsWindow.next({ type: JsDataType.CustomPage, content: DataCache.pageData.customJs });
  }

  cuotomProjectJs() {
    if (!DataCache.project || !DataCache.project.id) {
      this.message.warning('请先打开工程！');
      return;
    }
    this.eventTransService.openJsWindow.next({ type: JsDataType.CustomProject, content: DataCache.project.js });
  }
}
