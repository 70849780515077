import { Img } from "./img";

/**
 * 登录页面配置
 */
export class LoginPageConfig {
    /**
     * 背景图片
     */
    bg: Img = new Img();
    /**
     * 左侧图片
     */
    left: Img = new Img();
    /**
     * logo
     */
    logo: Img = new Img();
    /**
     * 显示验证码
     */
    hideValidCode: boolean;
    /**
     * 显示底部图片
     */
    hideBottomImg: boolean;
    /**
     * 底部图片
     */
    bottom: Img = new Img();
    /**
     * 自定义css样式
     */
    css: string;
}