import { Pipe, PipeTransform } from '@angular/core';
import { Page } from '../_models';

@Pipe({
    name: "pageTypePipe"
})

export class PageTypePipe implements PipeTransform {
    transform(page: Page, pageID: string): string {
        if (page.id === pageID) {
            return 'edit';
        }
        if (page.config && page.config.menu) {
            return 'menu';
        }
        return '';
    }
}
