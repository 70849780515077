import { Property } from '../_models';
import { DataAttrName } from '../const';
import { PxUtil } from '../utils';

export class Checkbox {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'checked': {
                prop.setValue = selectedObj.node.classList.contains(prop.name);
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'checked': {
                if (prop.setValue) {
                    selectedObj.node.classList.add(prop.name);
                } else {
                    selectedObj.node.classList.remove(prop.name);
                }
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
