import { ComponentType } from '../const';
import { Property } from '../_models';
import { Button } from './button';
import { ButtonIcon } from './buttonIcon';
import { InputText } from './inputText';
import { InputNumber } from './inputNumber';
import { Checkbox } from './checkbox';
import { InputRadio } from './inputRadio';
import { Time } from './time';
import { ButtonGroup } from './buttonGroup';
import { Switch } from './switch';
import { Select } from './select';
import { Date } from './date';
import { Tree } from './tree';
import { IFrame } from './iframe';
import { ProgressCircle } from './progressCircle';
import { ProgressLine } from './progressLine';
import { Panel } from './panel';
import { VideoYs } from './videoYs';
import { SelectPanel } from './selectPanel';
import { Table } from './table';
import { Chart } from './chart';
import { ChartLiquid } from './chartLiquid';
import { Slider } from './slider';
import { TextRow } from './textRow';
import { PxUtil } from '../utils';

export class SysComponents {
    static get(comType: string, selectedObj: any, prop: Property) {
        let ret = false;
        switch (comType) {
            case ComponentType.button: {
                ret = Button.get(selectedObj, prop);
                break;
            }
            case ComponentType.buttonIcon: {
                ret = ButtonIcon.get(selectedObj, prop);
                break;
            }
            case ComponentType.buttonGroup: {
                ret = ButtonGroup.get(selectedObj, prop);
                break;
            }
            case ComponentType.switch: {
                ret = Switch.get(selectedObj, prop);
                break;
            }
            case ComponentType.inputText: {
                ret = InputText.get(selectedObj, prop);
                break;
            }
            case ComponentType.inputNumber: {
                ret = InputNumber.get(selectedObj, prop);
                break;
            }
            case ComponentType.checkbox: {
                ret = Checkbox.get(selectedObj, prop);
                break;
            }
            case ComponentType.inputRadio: {
                ret = InputRadio.get(selectedObj, prop);
                break;
            }
            case ComponentType.select: {
                ret = Select.get(selectedObj, prop);
                break;
            }
            case ComponentType.selectPanel: {
                ret = SelectPanel.get(selectedObj, prop);
                break;
            }
            case ComponentType.time: {
                ret = Time.get(selectedObj, prop);
                break;
            }
            case ComponentType.date: {
                ret = Date.get(selectedObj, prop);
                break;
            }
            case ComponentType.tree: {
                ret = Tree.get(selectedObj, prop);
                break;
            }
            case ComponentType.iframe: {
                ret = IFrame.get(selectedObj, prop);
                break;
            }
            case ComponentType.progressCircle: {
                ret = ProgressCircle.get(selectedObj, prop);
                break;
            }
            case ComponentType.progressLine: {
                ret = ProgressLine.get(selectedObj, prop);
                break;
            }
            case ComponentType.slider: {
                ret = Slider.get(selectedObj, prop);
                break;
            }
            case ComponentType.panel: {
                ret = Panel.get(selectedObj, prop);
                break;
            }
            case ComponentType.videoYs: {
                ret = VideoYs.get(selectedObj, prop);
                break;
            }
            case ComponentType.table: {
                ret = Table.get(selectedObj, prop);
                break;
            }
            case ComponentType.chartLine:
            case ComponentType.chartBar:
            case ComponentType.chartPie:
            case ComponentType.chartGauge: {
                ret = Chart.get(selectedObj, prop);
                break;
            }
            case ComponentType.chartLiquid: {
                ret = ChartLiquid.get(selectedObj, prop);
                break;
            }
            case ComponentType.textRow: {
                ret = TextRow.get(selectedObj, prop);
                break;
            }
        }
        if (!ret) {
            if (selectedObj.node.style.hasOwnProperty(prop.name)) {
                prop.setValue = selectedObj.node.style.getPropertyValue(prop.name);
            } else {
                prop.setValue = selectedObj.attr(prop.name);
            }
            if (prop.setValue) {
                prop.setValue = prop.setValue.replace('px', '');
            }
        }
    }

    static set(comType: string, selectedObj: any, prop: Property) {
        let ret = false;
        switch (comType) {
            case ComponentType.button: {
                ret = Button.set(selectedObj, prop);
                break;
            }
            case ComponentType.buttonIcon: {
                ret = ButtonIcon.set(selectedObj, prop);
                break;
            }
            case ComponentType.buttonGroup: {
                ret = ButtonGroup.set(selectedObj, prop);
                break;
            }
            case ComponentType.switch: {
                ret = Switch.set(selectedObj, prop);
                break;
            }
            case ComponentType.inputText: {
                ret = InputText.set(selectedObj, prop);
                break;
            }
            case ComponentType.inputNumber: {
                ret = InputNumber.set(selectedObj, prop);
                break;
            }
            case ComponentType.checkbox: {
                ret = Checkbox.set(selectedObj, prop);
                break;
            }
            case ComponentType.inputRadio: {
                ret = InputRadio.set(selectedObj, prop);
                break;
            }
            case ComponentType.select: {
                ret = Select.set(selectedObj, prop);
                break;
            }
            case ComponentType.selectPanel: {
                ret = SelectPanel.set(selectedObj, prop);
                break;
            }
            case ComponentType.time: {
                ret = Time.set(selectedObj, prop);
                break;
            }
            case ComponentType.date: {
                ret = Date.set(selectedObj, prop);
                break;
            }
            case ComponentType.tree: {
                ret = Tree.set(selectedObj, prop);
                break;
            }
            case ComponentType.iframe: {
                ret = IFrame.set(selectedObj, prop);
                break;
            }
            case ComponentType.progressCircle: {
                ret = ProgressCircle.set(selectedObj, prop);
                break;
            }
            case ComponentType.progressLine: {
                ret = ProgressLine.set(selectedObj, prop);
                break;
            }
            case ComponentType.slider: {
                ret = Slider.set(selectedObj, prop);
                break;
            }
            case ComponentType.panel: {
                ret = Panel.set(selectedObj, prop);
                break;
            }
            case ComponentType.videoYs: {
                ret = VideoYs.set(selectedObj, prop);
                break;
            }
            case ComponentType.table: {
                ret = Table.set(selectedObj, prop);
                break;
            }
            case ComponentType.chartLine:
            case ComponentType.chartBar:
            case ComponentType.chartPie:
            case ComponentType.chartGauge: {
                ret = Chart.set(selectedObj, prop);
                break;
            }
            case ComponentType.chartLiquid: {
                ret = ChartLiquid.set(selectedObj, prop);
                break;
            }
            case ComponentType.textRow: {
                ret = TextRow.set(selectedObj, prop);
                break;
            }
        }
        if (!ret) {
            if (selectedObj.node.style.hasOwnProperty(prop.name)) {
                selectedObj.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name));
            } else {
                selectedObj.attr(prop.name, prop.setValue + PxUtil.get(prop.name));
            }
        }
    }
}
