/**
 * 下拉列表数据类型
 */
export class SelectDataType {
    /**
     * 自定义
     */
    static custom = 0;
    /**
     * 固定数据源
     */
    static data = 1;
    /**
     * 注册数据源
     */
    static reg = 2;

    static getName(type: number) {
        let name = '';
        switch (type) {
            case this.custom: {
                name = '自定义数据源';
                break;
            }
            case this.data: {
                name = '固定数据源';
                break;
            }
            case this.reg: {
                name = '注册数据源';
                break;
            }
        }
        return name;
    }
}
