<nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
  <div class="main">
    <div class="anchor">
      <nz-anchor [nzShowInkInFixed]="true" [nzAffix]="false" [nzContainer]="content" [nzOffsetTop]="20">
        <nz-link nzHref="#routeSet" nzTitle="地址路由设置"></nz-link>
        <nz-link nzHref="#theme" nzTitle="主题设置"></nz-link>
        <nz-link nzHref="#safe" nzTitle="安全"></nz-link>
        <nz-link nzHref="#platform" nzTitle="平台接入">
          <nz-link nzHref="#platform-ys" nzTitle="萤石云平台接入"></nz-link>
        </nz-link>
      </nz-anchor>
    </div>

    <div class="content" #content>
      <div id='routeSet'>
        <nz-divider nzText="地址路由设置"></nz-divider>
        <nz-input-group nzAddOnBefore="发布站点路径">
          <input type="text" nz-input [(ngModel)]="project.sitePath" />
        </nz-input-group>
        <div class="data">
          <nz-input-group nzAddOnBefore="地址路由">
            <nz-select [(ngModel)]="selSite" (ngModelChange)="selRouteChange()">
              <nz-option [nzLabel]="item.site" [nzValue]="item.site" *ngFor="let item of project.routes"></nz-option>
            </nz-select>
          </nz-input-group>
          <button nz-button nzType="primary" nzDanger (click)="deleteRoute()">删除</button>
        </div>
        <div class="config">
          <div class="left">
            <nz-input-group nzAddOnBefore="协议">
              <nz-select [(ngModel)]="setRoute.protocol">
                <nz-option nzValue="http" nzLabel="http / ws"></nz-option>
                <nz-option nzValue="https" nzLabel="https / wss"></nz-option>
              </nz-select>
            </nz-input-group>
            <nz-input-group nzAddOnBefore="发布地址 *">
              <input type="text" nz-input [(ngModel)]="setRoute.site" />
            </nz-input-group>
            <nz-input-group nzAddOnBefore="访问标志">
              <input type="text" nz-input [(ngModel)]="setRoute.tag" />
            </nz-input-group>
            <nz-input-group nzAddOnBefore="Api地址 *">
              <input type="text" nz-input [(ngModel)]="setRoute.apiPath" />
            </nz-input-group>
            <label nz-checkbox [(ngModel)]="setRoute.isDefault">设为默认</label>
          </div>
          <div class="right">
            <button nz-button nzType="primary" (click)="updateRoute()">更新</button>
            <button nz-button nzType="primary" (click)="addRoute()">添加</button>
          </div>
        </div>
        <span>说明：支持配置多个路由，根据访问的站点地址进行切换。</span>
      </div>

      <div id='theme'>
        <nz-divider nzText="主题设置"></nz-divider>
        <div>
          <nz-select [(ngModel)]="project.themeId" style="width:100%">
            <nz-option [nzLabel]="item.name" [nzValue]="item.id" *ngFor="let item of themes"></nz-option>
          </nz-select>
        </div>
      </div>

      <div id='safe'>
        <nz-divider nzText="安全"></nz-divider>
        <div>
          <label nz-checkbox [(ngModel)]="project.config.expressJs" style="width:100%;">启用脚本压缩</label>
          <span>说明：脚本发生错误时可关闭该项，便于定位问题。</span>

          <nz-input-group nzAddOnBefore="页面锁定时间" nzAddOnAfter="(分钟)" style="width:100%;">
            <nz-input-number [(ngModel)]="project.config.lockIdleMinute" [nzMin]="0" [nzMax]="1440" [nzStep]="1"
              style="width:100%"></nz-input-number>
          </nz-input-group>
          <span>说明：值为0表示不启用该功能。</span>
        </div>
      </div>

      <div id='platform-ys'>
        <nz-divider nzText="萤石云平台接入"></nz-divider>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-input-group nzAddOnBefore="AppKey">
              <input type="text" nz-input [(ngModel)]="project.platform.ys.appKey" />
            </nz-input-group>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-input-group nzAddOnBefore="AppSecret">
              <input type="text" nz-input [(ngModel)]="project.platform.ys.appSecret" />
            </nz-input-group>
          </div>
        </div>
      </div>

    </div>
  </div>
</nz-spin>