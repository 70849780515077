/**
 * 组件类型
 */
export class ComponentType {
    static textRow = 'text-row';
    static button = 'button';
    static buttonIcon = 'button-icon';
    static buttonGroup = 'button-group';
    static inputText = 'input-text';
    static inputNumber = 'input-number';
    static inputRadio = 'input-radio';
    static checkbox = 'checkbox';
    static time = 'time';
    static date = 'date';
    static switch = 'switch';
    static select = 'select';
    static selectPanel = 'select-panel';
    static tree = 'tree';
    static chartLine = 'chart-line';
    static chartBar = 'chart-bar';
    static chartPie = 'chart-pie';
    static chartGauge = 'chart-gauge';
    static chartLiquid = 'chart-liquid';
    static iframe = 'iframe';
    static progressCircle = 'progress-circle';
    static progressLine = 'progress-line';
    static slider = 'slider';
    static panel = 'panel';
    static videoYs = 'video-ys';
    static table = 'table';

    static getName(type: string): string {
        let name = '';
        switch (type) {
            case this.textRow: {
                name = '文本-换行';
                break;
            }
            case this.button: {
                name = '按钮';
                break;
            }
            case this.buttonIcon: {
                name = '图标按钮';
                break;
            }
            case this.buttonGroup: {
                name = '按钮组';
                break;
            }
            case this.switch: {
                name = '开关';
                break;
            }
            case this.inputText: {
                name = '文本输入框';
                break;
            }
            case this.inputNumber: {
                name = '数字输入框';
                break;
            }
            case this.checkbox: {
                name = '复选框';
                break;
            }
            case this.inputRadio: {
                name = '单选框';
                break;
            }
            case this.select: {
                name = '下拉列表';
                break;
            }
            case this.selectPanel: {
                name = '平铺列表';
                break;
            }
            case this.time: {
                name = '时间选择';
                break;
            }
            case this.date: {
                name = '日期选择';
                break;
            }
            case this.tree: {
                name = '树';
                break;
            }
            case this.chartLine: {
                name = '折线图';
                break;
            }
            case this.chartBar: {
                name = '柱图';
                break;
            }
            case this.chartPie: {
                name = '饼图';
                break;
            }
            case this.chartGauge: {
                name = '仪表盘';
                break;
            }
            case this.chartLiquid: {
                name = '水球占比图';
                break;
            }
            case this.iframe: {
                name = '页面容器';
                break;
            }
            case this.progressCircle: {
                name = '环形进度条';
                break;
            }
            case this.progressLine: {
                name = '线形进度条';
                break;
            }
            case this.slider: {
                name = '滑块';
                break;
            }
            case this.panel: {
                name = '列表面版';
                break;
            }
            case this.videoYs: {
                name = '萤石视频';
                break;
            }
            case this.table: {
                name = '表格';
                break;
            }
            default: {
                break;
            }
        }
        return name;
    }
}
