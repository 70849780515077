import { Injectable } from '@angular/core';
import { DateUtil } from '../utils';
import { User } from '../_models';
import { HttpService } from '../_services';
import { NzMessageService } from 'ng-zorro-antd/message';

/**
 * 通用Api
 */
@Injectable({ providedIn: 'root' })
export class CommonApi {
    private prefix = '/common';
    private timer;
    constructor(private http: HttpService, private message: NzMessageService) {
        this.init();
    }

    /**
     * token 校验
     */
    init() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            const user: User = JSON.parse(sessionStorage.getItem('user'));
            if (!user || !user.expiratime) {
                return;
            }
            const exTime = DateUtil.addSeconds(user.expiratime, -30);
            if (new Date() >= exTime) {
                this.refreshToken();
            }
        }, 10000);
    }

    /**
     * 登录
     * @param param 登录参数
     * @returns promise
     */
    async login(param): Promise<boolean> {
        return await this.http.post(`${this.prefix}/login`, param);
    }

    /**
     * 注销
     * @returns  boolean
     */
    async logout(): Promise<boolean> {
        const data = await this.http.post(`${this.prefix}/loginout`);
        if (!data) {
            return false;
        }
        clearInterval(this.timer);
        sessionStorage.removeItem('user');
        location.reload();
    }

    /**
     * 刷新token
     * @returns boolean
     */
    async refreshToken(): Promise<boolean> {
        const data = await this.http.post(`${this.prefix}/refreshToken`);
        if (!data) {
            return false;
        }
        const user: User = JSON.parse(sessionStorage.getItem('user'));
        user.token = data.token;
        user.expiratime = DateUtil.addSeconds(new Date(), data.expiration);

        sessionStorage.setItem('user', JSON.stringify(user));
        return true;
    }
}


