import { Property } from "../_models";
import { DataAttrName } from "../const";
import { BoolCheckUtil, PxUtil } from "../utils";

export class Svg {
    static get(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'width':
            case 'height': {
                prop.setValue = selectedObj.node.style.getPropertyValue(prop.name).replace('px', '');
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }

    static set(selectedObj: any, prop: Property) {
        let ret = true;
        switch (prop.name) {
            case 'width':
            case 'height': {
                const keepRatio = selectedObj.attr(DataAttrName.keepRatio);
                if (BoolCheckUtil.check(keepRatio)) {
                    const w = parseFloat(selectedObj.node.style.getPropertyValue('width').replace('px', ''));
                    const h = parseFloat(selectedObj.node.style.getPropertyValue('height').replace('px', ''));
                    const ratio = w / h;
                    if (prop.name === 'width') {
                        selectedObj.node.style.setProperty('height', parseFloat(prop.setValue) / ratio + PxUtil.get(prop.name));
                    } else {
                        selectedObj.node.style.setProperty('width', parseFloat(prop.setValue) * ratio + PxUtil.get(prop.name));
                    }
                }
                selectedObj.node.style.setProperty(prop.name, prop.setValue + PxUtil.get(prop.name));
                break;
            }
            default: {
                ret = false;
                break;
            }
        }
        return ret;
    }
}
