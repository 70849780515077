import { AlarmAlert } from "./alarmAlert";
import { AlarmCount } from "./alarmCount";

/**
 * 报警配置
 */
export class AlarmConfig {
    count = new AlarmCount();

    alert = new AlarmAlert();

    projectId: string;
}