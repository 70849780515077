import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DataCache } from '../cache';
import { AlarmAlert, AlarmCount, Img, RealDataConfig, ThemeConfig } from '../_models';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ThemeConfigApi } from '../api';
import { DomSanitizer } from '@angular/platform-browser';
import { MathUtil } from '../utils';

declare const city: [];
/**
 * 保存页面
 */
@Component({
  selector: 'app-theme-set',
  templateUrl: './themeSet.component.html',
  styleUrls: ['./themeSet.component.css']
})
export class ThemeSetComponent implements OnInit, OnDestroy {
  isLoading = true;
  codeBorderColor = 'transparent';
  config: ThemeConfig = new ThemeConfig();

  realData: RealDataConfig = new RealDataConfig();
  froms = ['天气网'];
  file: NzUploadFile;

  selIndexAlarmTopic = '';

  citys = [];

  constructor(
    private modal: NzModalRef,
    private sanitizer: DomSanitizer,
    private message: NzMessageService,
    private modalService: NzModalService,
    private themeConfigApi: ThemeConfigApi
  ) {
  }

  ngOnInit() {
    this.citys = city;
    this.init();
  }

  async init() {
    this.config = await this.themeConfigApi.getConfig(DataCache.project.id);
    if (!this.config) {
      this.config = new ThemeConfig();
      this.config.id = DataCache.project.id;
    }
    if (!this.config.index.alarm.count) {
      this.config.index.alarm.count = new AlarmCount();
    }
    if (!this.config.index.alarm.alert) {
      this.config.index.alarm.alert = new AlarmAlert();
    }
    this.isLoading = false;
  }

  /**
   * 登录页左侧图片
   * @param file 
   * @returns 
   */
  beforeLoginLeftImgUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0 / 1024.0;
    if (size > 5) {
      this.message.warning('文件过大，不能超过 5MB!');
      return false;
    }

    this.themeConfigApi.uploadLoginLeftImg(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.login.left = data;
      }
    });

    return false;
  }

  deleteLoginLeftImg() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteLoginLeftImg(this.config.id).then((data: boolean) => {
          if (data) {
            this.message.success(`文件上传成功！`);
            this.config.login.left = new Img();
          }
        });
      }
    });
  }

  /**
  * 登录页左侧图片
  * @param file 
  * @returns 
  */
  beforeLoginLogoUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0;
    if (size > 500) {
      this.message.warning('文件过大，不能超过 500KB!');
      return false;
    }

    this.themeConfigApi.uploadLoginLogoImg(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.login.logo = data;
      }
    });

    return false;
  }

  deleteLoginLogoImg() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteLoginLogoImg(this.config.id).then((data: boolean) => {
          if (data) {
            this.message.success(`文件上传成功！`);
            this.config.login.logo = new Img();
          }
        });
      }
    });
  }

  setLoginValidHide() {
    this.themeConfigApi.setLoginValidHide(this.config.id, this.config.login.hideValidCode);
  }

  /**
   * 登录页-背景图片
   * @param file 
   * @returns 
   */
  beforeLoginBgUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0 / 1024.0;
    if (size > 5) {
      this.message.warning('文件过大，不能超过 5MB!');
      return false;
    }

    this.themeConfigApi.uploadLoginBgImg(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.login.bg = data;
      }
    });

    return false;
  }

  deleteLoginBgImg() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteLoginBgImg(this.config.id).then((data: boolean) => {
          if (data) {
            this.message.success(`文件上传成功！`);
            this.config.login.bg = new Img();
          }
        });
      }
    });
  }

  saveLoginCss() {
    this.themeConfigApi.saveLoginCss(this.config.id, this.config.login.css).then((data: boolean) => {
      if (data) {
        this.message.success(`保存成功！`);
      }
    });
  }

  setDefaultFull() {
    this.themeConfigApi.setDefaultFull(this.config.id, this.config.index.defaultFull);
  }

  setShowFullBtn() {
    this.themeConfigApi.setShowFullBtn(this.config.id, this.config.index.showFullBtn);
  }

  setIndexMenuExpand() {
    this.themeConfigApi.setIndexMenuExpand(this.config.id, this.config.index.expandMenuBar);
  }

  /**
  * 首页左侧图片
  * @param file 
  * @returns 
  */
  beforeIndexLogoUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0;
    if (size > 500) {
      this.message.warning('文件过大，不能超过 500KB!');
      return false;
    }

    this.themeConfigApi.uploadIndexLogoImg(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.index.logo = data;
      }
    });

    return false;
  }

  deleteIndexLogoImg() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteIndexLogoImg(this.config.id).then((data: boolean) => {
          if (data) {
            this.config.index.logo = new Img();
          }
        });
      }
    });
  }

  saveIndexCss() {
    this.themeConfigApi.saveIndexCss(this.config.id, this.config.index.css).then((data: boolean) => {
      if (data) {
        this.message.success(`保存成功！`);
      }
    });
  }

  saveIndexTitle() {
    this.themeConfigApi.saveIndexTitle(this.config.id, this.config.index.title.text, this.config.index.title.subText).then((data: boolean) => {
      if (data) {
        this.message.success(`保存成功！`);
      }
    });
  }

  setIndexTimeHide() {
    this.themeConfigApi.setIndexTimeHide(this.config.id, this.config.index.time.hide);
  }

  setIndexTimeWeekHide() {
    this.themeConfigApi.setIndexTimeWeekHide(this.config.id, this.config.index.time.hideWeek);
  }

  setIndexTimeIconHide() {
    this.themeConfigApi.setIndexTimeIconHide(this.config.id, this.config.index.time.hideIcon);
  }

  saveIndexTimeFormat() {
    this.themeConfigApi.saveIndexTimeFormat(this.config.id, this.config.index.time.format).then((data: boolean) => {
      if (data) {
        this.message.success(`保存成功！`);
      }
    });
  }

  beforeIndexTimeIconUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0;
    if (size > 50) {
      this.message.warning('文件过大，不能超过 50KB!');
      return false;
    }

    this.themeConfigApi.uploadIndexTimeIcon(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.index.time.icon = data;
      }
    });

    return false;
  }

  deleteIndexTimeIcon() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteIndexTimeIcon(this.config.id).then((data: boolean) => {
          if (data) {
            this.config.index.time.icon = new Img();
          }
        });
      }
    });
  }

  setIndexNameIconHide() {
    this.themeConfigApi.setIndexNameIconHide(this.config.id, this.config.index.hideNameIcon);
  }

  beforeIndexNameIconUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0;
    if (size > 50) {
      this.message.warning('文件过大，不能超过 50KB!');
      return false;
    }

    this.themeConfigApi.uploadIndexNameIcon(file, this.config.id).then((data: Img) => {
      if (data) {
        this.message.success(`文件上传成功！`);
        this.config.index.nameIcon = data;
      }
    });

    return false;
  }

  deleteIndexNameIcon() {
    this.modalService.confirm({
      nzTitle: `确认删除图片？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteIndexNameIcon(this.config.id).then((data: boolean) => {
          if (data) {
            this.config.index.nameIcon = new Img();
          }
        });
      }
    });
  }

  checkIcon(file: NzUploadFile) {
    const isIcon = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      || file.type === 'image/svg+xml' || file.type === 'image/x-icon' || file.type === 'image/gif';
    if (!isIcon) {
      this.message.warning('文件格式错误，只支持：jqg、png、bmp、svg、ico、gif 格式!');
      console.log(`upload file type:${file.type}`);
      return false;
    }
    return true;
  }

  getParam(from: number) {
    switch (from) {
      case 1: {
        return '主题：';
      }
      case 2: {
        return '关系ID：'
      }
      default: {
        return '设备参数ID：'
      }
    }
  }

  getRealName(data: RealDataConfig) {
    let name = '';
    switch (data.from) {
      case 0: {
        name = '天气网';
        break;
      }
    }
    return name + '-' + data.param;
  }

  selRealChange() {
    const rd = this.config.index.reals.find(d => d.id === this.realData.id);
    if (!rd) return;
    this.realData = JSON.parse(JSON.stringify(rd));
  }

  beforeIndexRealIconUpload = (file: NzUploadFile): boolean => {
    if (!this.checkIcon(file)) {
      return false;
    }
    const size = file.size! / 1024.0;
    if (size > 50) {
      this.message.warning('文件过大，不能超过 50KB!');
      return false;
    }

    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file as any);
    reader.onload = () => {
      this.realData.icon.base64 = reader.result as string;
      this.realData.iconChanged = true;
    };

    return false;
  }

  checkRealData() {
    if (!this.realData.param) {
      this.message.warning('参数输入错误！');
      return false;
    }
    if (this.realData.from === 0) {
      if (!this.realData.city) {
        this.message.warning('城市编码不能为空！');
        return false;
      }
    }
    return true;
  }

  addRealData() {
    if (!this.checkRealData()) {
      return;
    }
    const rd = this.config.index.reals.find(d => d.from === this.realData.from && d.param === this.realData.param);
    if (rd) {
      this.message.warning('数据重复添加！');
      return;
    }
    this.realData.id = MathUtil.getUUid();
    this.realData.projectId = this.config.id;
    this.themeConfigApi.addRealData(this.file, this.realData).then((data: any) => {
      if (data) {
        this.config.index.reals.push(JSON.parse(JSON.stringify(this.realData)));
        this.realData.id = '';
        this.realData.showIcon = false;
        this.realData.icon = new Img();
        this.message.success('新增成功！');
      }
    });
  }

  deleteRealData() {
    if (!this.realData.id) {
      this.message.warning('请先选择要删除的数据源！');
      return;
    }

    this.modalService.confirm({
      nzTitle: `确认删除数据？`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.themeConfigApi.deleteRealData(this.config.id, this.realData.id).then((data: Boolean) => {
          if (data) {
            this.config.index.reals = this.config.index.reals.filter(d => d.id != this.realData.id);
            this.realData.id = '';
            this.realData.showIcon = false;
            this.realData.icon = new Img();
            this.message.success('操作成功！');
          }
        })
      }
    });
  }

  getSafeSrc(data: Img) {
    if (!data) return;
    const type = data.type === 'svg' ? 'image/svg+xml' : data.type;
    if (type && data.base64.indexOf('data:') < 0) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${type};base64,${data.base64}`);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(data.base64);
  }

  ngOnDestroy() {
    this.modal.destroy();
  }
}
