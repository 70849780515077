import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-help-home',
    templateUrl: './homeHelp.component.html',
    styles: [`
 
    `]
})
export class HomeHelpComponent {
    @Input() com = '';
    constructor(
    ) {
    }
}

