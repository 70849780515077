import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SelectedObj, DataEvent, JsData } from '../../_models';
import { CommondAction } from '../../_helpers/commondAction';
import { CanvasCache, DataCache, EventCache } from 'src/app/cache';
import { JsDataType } from 'src/app/enums';
import { EventTransService } from 'src/app/_services';
import { DataAttrName, JsColor } from 'src/app/const';
import { JsUtil, MathUtil } from 'src/app/utils';

@Component({
  selector: 'app-data-event',
  templateUrl: './dataEvent.component.html',
  styleUrls: ['./dataEvent.component.css']
})

export class DataEventComponent implements OnInit, OnDestroy {
  // 通用事件
  commonEvents = EventCache.common;
  // 组件事件
  componentEvents = [];

  // 选择事件名称
  selFuncName = 'click';

  // 变量列表
  meterTypes: string[] = ['*'];
  // 选择的变量名称
  selMeterType: string;

  // 选择的组件对象
  selectedCom: any;
  // 组件选择变更事件
  selectedObjSubscribe: Subscription;
  jsDataChangeSub: Subscription;
  paramTypeCodeChangeSub: Subscription;

  // 事件内容
  action = '';
  dataEvent: DataEvent;

  // 脚本编辑器边框颜色
  codeBorderColor = JsColor.right;

  constructor(
    private message: NzMessageService,
    private eventTransService: EventTransService,
  ) {
  }

  ngOnInit() {

    this.initSubscribe();
  }

  ngOnDestroy() {
    this.selectedObjSubscribe.unsubscribe();
    this.jsDataChangeSub.unsubscribe();
    this.paramTypeCodeChangeSub.unsubscribe();
  }

  initSubscribe() {
    this.selectedObjSubscribe = this.eventTransService.selectedObjChangeEvent.subscribe((data: SelectedObj) => {
      this.handleObjSelect(data);
    });
    this.jsDataChangeSub = this.eventTransService.jsDataChange.subscribe((data: JsData) => {
      if (data && data.type === JsDataType.Event) {
        this.action = data.content;
        this.onEventChange(null);
      }
    });
    this.paramTypeCodeChangeSub = this.eventTransService.paramTypeCodeChange.subscribe((data: boolean) => {
      if (!data) {
        return;
      }
      this.setMeterTypes();
    });
  }

  handleObjSelect(data: SelectedObj) {
    if (data && data.Element) {
      return;
    }
    this.setEmpty();
    if (!DataCache.page && !DataCache.template) {
      return;
    }
    if (DataCache.template) {
      this.initTemplateEvent();
      this.setMeterTypes();
      return;
    }

    if (!data || !data.Component) {
      this.selectedCom = null;
      this.selMeterType = null;
      this.setMeterTypes();
      return;
    }
    this.componentEvents = [];
    this.selectedCom = data.Component;
    const com = this.selectedCom.select(`[${DataAttrName.componentName}]`);
    if (com) {
      const componentType = com.attr(DataAttrName.componentName);
      if (componentType && EventCache.component[componentType]) {
        this.componentEvents = EventCache.component[componentType];
      }
    }
    this.setMeterTypes();
    this.initComEvent();
  }

  setMeterTypes() {
    this.meterTypes = ['*'];
    let types: any;
    if (DataCache.template) {
      // 模板页面，只允许配置一个事件源
      types = CanvasCache.svg.selectAll(`[${DataAttrName.meterType}]`);
    } else if (this.selectedCom) {
      types = this.selectedCom.selectAll(`[${DataAttrName.meterType}]`);
    } else {
      return;
    }

    if (types && types.length > 0) {
      types.forEach(item => {
        if (item.attr(DataAttrName.meterType)) {
          this.meterTypes.push(item.attr(DataAttrName.meterType));
        }
      });
    }
  }

  setEmpty() {
    this.dataEvent = null;
    this.action = '';
  }

  initTemplateEvent() {
    if (!DataCache.template.data || !DataCache.template.data.event) {
      return;
    }
    this.dataEvent = DataCache.template.data.event;
    this.action = this.dataEvent.action;
  }

  initComEvent() {
    const eid = this.selectedCom.attr(DataAttrName.event);
    if (!eid) {
      return;
    }
    const event = DataCache.pageData.events.find(d => d.id === eid);
    if (!event) {
      return;
    }
    this.dataEvent = event;
    this.action = event.action;
  }

  /**
   * 创建事件
   * @param event 事件
   */
  createEvent(event: any) {
    if (!this.selMeterType) {
      this.message.warning('请先输入变量名称！');
      return;
    }
    if (!this.selFuncName) {
      this.message.warning('请先输入事件名称！');
      return;
    }
    let str = ``;
    if (this.selFuncName === 'keypress') {
      str = `<@${this.selMeterType}>.${this.selFuncName}(function(e) {\r    if(e.keyCode=='13'){\r    \r  }\r});\r`;
    } else {
      str = `<@${this.selMeterType}>.${this.selFuncName}(function(e) {\r    \r});\r`;
    }

    this.action += str;
    this.selMeterType = null;
    this.onEventChange(null);
  }

  /**
   * 事件内容变更
   * @param event 事件
   */
  onEventChange(event: any) {
    if (!DataCache.page && !DataCache.template) {
      this.action = '';
      this.message.warning('请先打开页面！');
      return;
    }
    if (!this.selectedCom && !DataCache.template) {
      this.action = '';
      this.message.warning('请先选择组件！');
      return;
    }
    this.saveEvent();
    CommondAction.isChange = true;
    this.eventTransService.setCanvasCache.next(true);
    if (JsUtil.check(this.action)) {
      this.codeBorderColor = JsColor.right;
    } else {
      this.codeBorderColor = JsColor.error;
    }
  }


  /**
   * 保存事件
   * @param event 事件
   */
  saveEvent() {
    // 新增事件
    if (!this.dataEvent) {
      if (!this.action || !this.action.trim()) {
        return;
      }
      const eventId = MathUtil.getUUid();
      this.dataEvent = {
        id: eventId,
        action: this.action
      };
      if (DataCache.page) {
        this.selectedCom.attr(DataAttrName.event, eventId);
        DataCache.pageData.events.push(this.dataEvent);
      } else {
        DataCache.template.data.event = this.dataEvent;
      }
    }

    this.dataEvent.action = this.action;

    // 删除事件
    if (!this.action || !this.action.trim()) {
      if (DataCache.page) {
        DataCache.pageData.events = DataCache.pageData.events.filter(d => d.id !== this.dataEvent.id);
        this.selectedCom.attr(DataAttrName.event, '');
      } else {
        DataCache.template.data.event = null;
      }
      this.dataEvent = null;
    }
  }

  setToFull(event) {
    if (!DataCache.page && !DataCache.template) {
      this.action = '';
      this.message.warning('请先打开页面！');
      return;
    }
    if (!this.selectedCom && !DataCache.template) {
      this.action = '';
      this.message.warning('请先选择组件！');
      return;
    }
    let content = '';
    if (this.dataEvent) {
      content = this.dataEvent.action;
    }
    this.eventTransService.openJsWindow.next({ type: JsDataType.Event, content });
  }

  /**
   * 脚本格式化
   */
  formatCode() {
    this.action = JsUtil.format(this.action);
    this.onEventChange(null);
  }
}
