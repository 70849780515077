import { Component, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService, } from '../_services';
import { ComClassify } from '../_models';
import { FileUpLoadComponent } from '../_modals';

/**
 * 组件内容空白区域右键菜单
 */
@Component({
    selector: 'app-classify-content-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='addComponent($event)'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>添加组件
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
    `]
})
export class ClassifyContentContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() classify: ComClassify;

    constructor(
        private modalService: NzModalService,
        private eventTransService: EventTransService,

    ) { }

    /**
     * 添加组件
     */
    addComponent(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '组件批量上传',
            nzComponentParams: {
                classify: this.classify
            },
            nzContent: FileUpLoadComponent,
            nzMaskClosable: false,
            nzOkText: '开始上传',
            nzOnOk: (ins) => ins.save()
        });
    }
}

