import { TimeTypeEnum } from './timeTypeEnum';

/**
 * 时间配置
 */
export class TimeConfig {
    /**
     * 是否启用
     */
    enable = true;
    /**
     * 时间类型
     */
    timeType = TimeTypeEnum.DAY;
    /**
     * 其他类型：跨度
     */
    otherTimeNum = 1;
    /**
     * 其他时间类型
     */
    otherTimeType = TimeTypeEnum.MINUTE;
    /**
     * 是否显示上下周期
     */
    showPreNext = true;
    /**
     * 默认周期序号
     */
    index = 0;
    /**
     * 对比时段
     */
    enableCompare = false;
    /**
     * 对比周期
     */
    compareIndex = -1;
}
