import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'suffixPipe'
})

export class SuffixPipe implements PipeTransform {
    transform(name: string): any {
        const idx = name.lastIndexOf('.');
        if (idx <= 0) {
            return name;
        }
        return name.substring(0, idx);
    }
}
