/**
 * 发布主题
 */
export class Theme {
    /**
     * 主题ID
     */
    id: string;
    /**
     * 主题名称
     */
    name: string;
    /**
     * 主题路径
     */
    path: string;
    /**
     * 主题预览图标
     */
    preview: string;
}
