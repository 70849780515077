import { Img } from "./img";

/**
 * 实时数据配置
 */
export class RealDataConfig {
    id: string;
    projectId: string;
    /**
     * 来源。0：天气网；1：topic；2：关系；3：设备参数
     */
    from: number = 0;
    /**
     * 来源为0时使用
     */
    city: string;

    showIcon: boolean;
    /**
     * 数据图标
     */
    icon: Img = new Img();
    iconChanged = false;
    /**
     * 参数，根据来源配置不同的参数
     */
    param: string;
    /**
     * 数据名称
     */
    name: string;
    /**
     * 单位
     */
    unit: string;

    /**
     * 保留小数位
     */
    fix: number;
}