import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'svgElementPipe'
})

export class SvgElementPipe implements PipeTransform {
    transform(items: any[]): any {
        return items.filter(d => d.type !== 'defs' && d.type !== '#text'
            && d.type !== 'animate' && d.type !== 'animateTransform' && d.type !== 'animateMotion');
    }
}
