import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Project } from '../_models';
import { HttpService } from '../_services';


/**
 * web组态API-工程
 */
@Injectable({ providedIn: 'root' })
export class ProjectApi {
    private prefix = '/design/project';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 项目初始化
     */
    async init() {
        return await this.http.post(`${this.prefix}/init`);
    }

    /**
     * 获取工程信息
     * @param id 工程id，为空则获取主工程
     */
    async get(id?: string) {
        const param = {
            id: ''
        };
        if (id) {
            param.id = id;
        }
        return await this.http.get(`${this.prefix}/get`, param);
    }

    /**
     * 获取所有工程
     */
    async getAll() {
        return await this.http.get(`${this.prefix}/getAll`);
    }

    /**
     * 创建工程
     * @param name 工程名
     * @param themeId 主题id
     */
    async create(name: string, themeId: string) {
        if (!name) {
            this.message.warning('参数错误[工程名称]');
            return false;
        }
        if (!themeId) {
            themeId = '';
        }
        return await this.http.post_str(`${this.prefix}/create`, { name, themeId });
    }

    /**
     * 获取工程文件树结构
     * @param id 工程id
     */
    async getTree(id: string) {
        if (!id) {
            this.message.warning('参数错误[工程id]');
            return null;
        }
        return await this.http.get(`${this.prefix}/getTree`, { id });
    }

    /**
     * 工程树排序
     * @param params 排序参数
     */
    async setTreeSort(params) {
        if (!params || params.length < 1) {
            this.message.warning('参数错误');
            return null;
        }
        return await this.http.post(`${this.prefix}/setTreeSort`, params);
    }

    /**
     * 获取某模块下树结构
     * @param moduleId 模块id
     */
    async getNodesByModuleId(moduleId: string) {
        if (!moduleId) {
            this.message.warning('参数错误[模块id]');
            return null;
        }
        return await this.http.get(`${this.prefix}/getNodesByModuleId`, { moduleId });
    }

    /**
     * 设置工程首页
     * @param pageId 页面id
     */
    async setHome(pageId: string) {
        if (!pageId) {
            this.message.warning('参数错误[页面id]');
            return null;
        }
        return await this.http.post_str(`${this.prefix}/setHome`, { pageId });
    }

    /**
     * 更新工程信息
     * @param project 工程对象
     */
    async update(project: Project) {
        return await this.http.post(`${this.prefix}/update`, project);
    }

    /**
     * 删除工程
     * @param id 工程id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[工程id]');
            return null;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 下载工程
     * @param id 工程id
     */
    async download(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.download(`${this.prefix}/download`, { id });
    }

    async upload(file: any, name: string) {
        if (!file || !name) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.uploadFile(`${this.prefix}/upload`, file, { name });
    }

    /**
     * 工程发布
     * @param id 工程id
     */
    async publish(id: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.post_str(`${this.prefix}/publish`, { id });
    }

    /**
     * 获取cms菜单信息
     */
    async getCmsMenus() {
        return await this.http.get(`/config/cmsMenu/getAll`);
    }

    async saveJs(id: string, js: string) {
        if (!id) {
            this.message.warning('参数错误 ! ');
            return;
        }
        return await this.http.post_str(`${this.prefix}/saveJs`, { id, js });
    }
}
