export class PageConfig {
    menu = false;
    /**
     * 访问权限级别
     */
    level = 0;
    /**
     * 允许缩放
     */
    scale = false;
    /**
     * 初始化缩放类型（0：自动；1：缩放；2：不缩放）
     */
    initScaleType = '0';
    /**
     * 页面菜单图标
     */
    icon = '';
    /**
     * 页面菜单图标(激活状态)
     */
    iconActive = '';
}
