import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';
import { ProjectIcon, ProjectVideo } from '../_models';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-video-manage',
  template: `
    <div class="clearfix">
      <div style="display:flex;flex-wrap:wrap;">
        <div style="width:200px;">
          <nz-upload
            [(nzFileList)]="upList"
            [nzBeforeUpload]="beforeUpload"
            [nzRemove]="removeFile"
          >
            <button nz-button><i nz-icon nzType="upload"></i>上传</button>
          </nz-upload>
        </div>
        <nz-list nzBordered>
          <nz-list-item *ngFor="let item of fileList">
            {{ item.name }}
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="removeFile(item.name)">
                  <i nz-icon nzType="delete" nzTheme="outline" style="color:red;"></i>
                </a>
              </nz-list-item-action>
            </ul>
            <span nz-typography>{{item.lastModified}}</span>
          </nz-list-item>
        </nz-list>
    </div>
  </div>
  `,
  styles: [
    `
      nz-list {
        width:100%;
        margin-top:5px;
      }
    `
  ]
})
export class VideoManageComponent implements OnInit, OnDestroy {
  /**
   * 工程ID
   */
  @Input() projectId: string;

  fileList: NzUploadFile[] = [];
  upList: NzUploadFile[] = [];

  constructor(
    private modal: NzModalRef,
    private http: HttpService,
    private message: NzMessageService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit(): void {
    this.init();
    this.http.get('/TopNav/GetVideos', { ProjectId: this.projectId }).then((datas: ProjectVideo[]) => {
      let idx = 0;
      for (const data of datas) {
        this.fileList.push({
          uid: (++idx).toString(),
          name: data.Name,
          status: 'done',
          lastModified: data.Time
        });
      }
    });
  }

  async init() {

  }

  /**
   * 图标上传
   * @param file 待上传的文件对象
   */
  beforeUpload = (file: NzUploadFile): boolean => {
    const isIcon = file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/webm';
    if (!isIcon) {
      this.message.warning('文件格式错误，只支持：mp4、ogg、webm 格式!');
      console.log(`upload file type:${file.type}`);
      return false;
    }
    const size = file.size / 1024 / 1024;
    if (size > 500) {
      this.message.warning('文件过大，不能超过 500M !');
      return false;
    }

    this.http.uploadFile('/TopNav/UploadVideo', file, { ProjectId: this.projectId }).then((res: ProjectVideo) => {
      if (res) {
        this.message.success(`文件上传成功！`);
        const newFile: NzUploadFile = {
          uid: res.Name,
          name: res.Name,
          status: 'done',
          lastModified: res.Time
        };
        this.fileList = [newFile, ...this.fileList];
      }
    });

    return false;
  }

  /**
   * 移除文件
   * @param fileName 文件
   */
  removeFile(fileName: string) {
    this.modalService.confirm({
      nzTitle: `<i>确认删除视频文件？</i>`,
      nzOkDanger: true,
      nzOnOk: () => {
        this.http.post('/TopNav/DeleteVideo', { ProjectId: this.projectId, Name: fileName }).then(res => {
          if (res) {
            this.message.success(`视频已删除！`);
            this.fileList = this.fileList.filter(d => d.name !== fileName);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.modal.destroy();
  }
}
