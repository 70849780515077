import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { CanvasCache, DataCache, PropCache } from 'src/app/cache';
import { BarProperty, GaugeProperty, LineProperty, PieProperty } from 'src/app/chartConfig';
import { ComponentType, DataAttrName } from 'src/app/const';
import { SysComponents } from 'src/app/sysComponents';
import { BoolCheckUtil, MathUtil } from 'src/app/utils';
import { CommondAction } from 'src/app/_helpers/commondAction';
import {
    ItemsConfigComponent, ChartLineConfigComponent,
    ChartPieConfigComponent,
    ChartGaugeConfigComponent
} from 'src/app/_modals';
import { ChartBarConfigComponent } from 'src/app/_modals/plugins/chartBarConfig.component';
import { SelectConfigComponent } from 'src/app/_modals/plugins/selectConfig.component';
import { EventTransService } from 'src/app/_services';
import { ItemData, Property, SelectData, SelectedObj, TreeParam } from '../../_models';
import { SvgNodesProp } from 'src/app/svgNodesProp';

declare var Snap: any;
declare var $: any;

@Component({
    selector: 'app-property',
    templateUrl: 'property.component.html',
    styles: [``]
})
export class PropertyComponent implements OnInit, OnDestroy {
    @Input() property: Property;
    // 对象选中事件
    selectedObjSub: Subscription;

    // 选中的对象
    selectedObj;
    // 是否选择的svg画布
    isSvg = false;

    constructor(
        private modalService: NzModalService,
        private eventTransService: EventTransService
    ) {
    }

    ngOnInit() {
        this.initSubscribe();
    }

    ngOnDestroy() {
        this.selectedObjSub.unsubscribe();
    }

    /**
     * 数据订阅
     */
    initSubscribe() {
        this.selectedObjSub = this.eventTransService.selectedObjChangeEvent.subscribe((data: SelectedObj) => {
            this.isSvg = false;
            if (data) {
                if (data.Component) {
                    this.selectedObj = data.Component;
                } else if (data.Element) {
                    this.selectedObj = data.Element;
                } else {
                    this.selectedObj = data.Svg;
                    this.isSvg = true;
                }
            } else {
                this.selectedObj = null;
            }
            this.initProperty();
        });
    }

    /**
     * 初始化dom属性值
     * @param data
     */
    initProperty() {
        if (!this.selectedObj || !this.property.name) {
            return;
        }
        if (PropCache.getComponentCommonProp().indexOf(this.property.name) >= 0) {
            this.property.setValue = this.selectedObj.attr(this.property.name);
            return;
        }
        // 自定义组件
        if (this.selectedObj.attr(DataAttrName.componentName)) {
            const comType = this.selectedObj.attr(DataAttrName.componentName);
            SysComponents.get(comType, this.selectedObj, this.property);
            return;
        }
        SvgNodesProp.get(this.selectedObj, this.property);
    }

    /**
     * 属性设置
     * @param prop 属性对象
     */
    setProperty(prop: Property) {
        CanvasCache.isFoucus = false;
        // 动画属性
        if (!prop.name || !this.selectedObj || prop.name.startsWith('$')) {
            return;
        }
        CommondAction.isChange = true;
        if (PropCache.getComponentCommonProp().indexOf(prop.name) >= 0) {
            this.selectedObj.attr(prop.name, prop.setValue);
            return;
        }
        // 自定义组件
        if (this.selectedObj.attr(DataAttrName.componentName)) {
            const comType = this.selectedObj.attr(DataAttrName.componentName);
            SysComponents.set(comType, this.selectedObj, prop);
            return;
        }
        SvgNodesProp.set(this.selectedObj, prop);
        const keepRatio = this.selectedObj.attr(DataAttrName.keepRatio);
        if (BoolCheckUtil.check(keepRatio) && (prop.name === 'width' || prop.name === 'height')) {
            if (prop.name === 'width') {
                this.keepRatio('height');
            } else {
                this.keepRatio('width');
            }
        }
    }

    keepRatio(propName) {
        let hProp = { name: propName, setValue: '' };
        SvgNodesProp.get(this.selectedObj, hProp);
        this.eventTransService.propSetChange.next({
            name: propName,
            value: hProp.setValue
        });
    }

    setBoolProperty(event, prop: Property) {
        prop.setValue = event;
        this.setProperty(prop);
    }

    setItems(prop: Property) {
        const type = this.selectedObj.attr(DataAttrName.componentName);
        switch (type) {
            case ComponentType.inputRadio: {
                this.radioConfig();
                break;
            }
            case ComponentType.buttonGroup: {
                this.buttonGroupConfig();
                break;
            }
            case ComponentType.select:
            case ComponentType.selectPanel: {
                this.selectConfig(type, prop);
                break;
            }
            case ComponentType.chartLine: {
                this.chartLineConfig(prop);
                break;
            }
            case ComponentType.chartBar: {
                this.chartBarConfig(prop);
                break;
            }
            case ComponentType.chartPie: {
                this.chartPieConfig(prop);
                break;
            }
            case ComponentType.chartGauge: {
                this.chartGaugeConfig(prop);
                break;
            }
        }
    }

    /**
     * 单选框集合配置
     */
    radioConfig() {
        const labels = this.selectedObj.selectAll('label');
        const datas: ItemData[] = [];
        labels.forEach(item => {
            datas.push({
                id: MathUtil.getUUid(),
                key: item.select('input').attr('value'),
                title: item.select('span').node.innerText,
            });
        });
        let margin = 'margin-left';
        if (this.selectedObj.node.style.getPropertyValue('display') === 'flex') {
            margin = 'margin-top';
        }
        if (labels.length > 1) {
            margin += `:${labels[1].node.style.getPropertyValue(margin)}`;
        }

        const fontSize = this.selectedObj.select('label').node.style.getPropertyValue('font-size');

        this.modalService.create({
            nzTitle: '集合编辑器',
            nzWidth: '50%',
            nzContent: ItemsConfigComponent,
            nzComponentParams: {
                items: datas
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((items: ItemData[]) => {
                let idx = 1;
                const group = MathUtil.getUUid();
                this.selectedObj.selectAll('*').remove();

                let html = '';
                for (const item of items) {
                    let css = '';
                    if (idx++ > 1) {
                        css = margin;
                    }

                    html += `
                    <label xmlns="http://www.w3.org/1999/xhtml" class="tw-radio" style="font-size: ${fontSize};${css}">
                        <input type="radio" name="${group}" value="${item.key}" disabled="disabled">
                        <i></i>
                        <span>${item.title}</span>
                    </label>
                    `;
                }
                const id = MathUtil.getUUid();
                this.selectedObj.attr('id', id);
                $(`#${id}`).html(html);
                $(`#${id}`).removeAttr('id');

                return true;
            })
        });
    }

    /**
     * 按钮组集合配置
     */
    buttonGroupConfig() {
        const btns = this.selectedObj.selectAll('button');
        const datas: ItemData[] = [];
        btns.forEach(item => {
            datas.push({
                id: MathUtil.getUUid(),
                key: item.attr(DataAttrName.value),
                title: item.node.innerText,
            });
        });
        const fontSize = this.selectedObj.select('button').node.style.getPropertyValue('font-size');
        this.modalService.create({
            nzTitle: '集合编辑器',
            nzWidth: '50%',
            nzContent: ItemsConfigComponent,
            nzComponentParams: {
                items: datas
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((items: ItemData[]) => {
                this.selectedObj.selectAll('button').remove();
                let html = '';
                for (const item of items) {
                    html += `
                    <button type="button" class="btn tw-button" style="height: 100%; font-size: ${fontSize};"
                        data-attr-value="${item.key}">${item.title}</button>
                    `;
                }
                const id = MathUtil.getUUid();
                this.selectedObj.select('div').attr('id', id);
                $(`#${id}`).html(html);
                $(`#${id}`).removeAttr('id');

                return true;
            })
        });
    }

    /**
     * 列表集合配置
     */
    selectConfig(type: string, prop: Property) {
        let data = new SelectData();
        const tagName = type === ComponentType.select ? 'select' : 'div';
        const dataStr = this.selectedObj.select(tagName).attr(DataAttrName.items);
        if (dataStr) {
            data = JSON.parse(dataStr);
        }
        this.modalService.create({
            nzTitle: '列表集合配置',
            nzWidth: '50%',
            nzContent: SelectConfigComponent,
            nzComponentParams: {
                data
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((setData: SelectData) => {
                if (!setData) {
                    return false;
                }
                this.selectedObj.select(tagName).attr(DataAttrName.items, JSON.stringify(setData));
                SysComponents.get(type, this.selectedObj, prop);
                return true;
            })
        });
    }

    /**
     * ECharts图表-曲线配置
     * @param prop 属性
     */
    chartLineConfig(prop: Property) {
        let lineProp = new LineProperty();
        const configStr = this.selectedObj.select('div').attr(DataAttrName.config);
        if (configStr) {
            lineProp = JSON.parse(configStr);
        }

        this.modalService.create({
            nzTitle: '图表-曲线配置',
            nzWidth: '60%',
            nzContent: ChartLineConfigComponent,
            nzComponentParams: {
                prop: lineProp
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((param: LineProperty) => {
                this.selectedObj.select('div').attr(DataAttrName.config, JSON.stringify(param));
                return true;
            })
        });
    }

    /**
     * ECharts图表-柱图配置
     */
    chartBarConfig(prop: Property) {
        let barProp = new BarProperty();
        const configStr = this.selectedObj.select('div').attr(DataAttrName.config);
        if (configStr) {
            barProp = JSON.parse(configStr);
        }
        this.modalService.create({
            nzTitle: '图表-柱图配置',
            nzWidth: '50%',
            nzContent: ChartBarConfigComponent,
            nzComponentParams: {
                prop: barProp
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((param: BarProperty) => {
                this.selectedObj.select('div').attr(DataAttrName.config, JSON.stringify(param));
                return true;
            })
        });
    }
    /**
     * 饼图配置
     */
    chartPieConfig(prop: Property) {
        let pieProp = new PieProperty();
        const configStr = this.selectedObj.select('div').attr(DataAttrName.config);
        if (configStr) {
            pieProp = JSON.parse(configStr);
        }
        this.modalService.create({
            nzTitle: '饼图配置',
            nzWidth: '50%',
            nzContent: ChartPieConfigComponent,
            nzComponentParams: {
                prop: pieProp
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((param: PieProperty) => {
                this.selectedObj.select('div').attr(DataAttrName.config, JSON.stringify(param));
                return true;
            })
        });
    }
    /**
     * 仪表盘配置
     */
    chartGaugeConfig(prop: Property) {
        let gaugeProp = new GaugeProperty();
        const configStr = this.selectedObj.select('div').attr(DataAttrName.config);
        if (configStr) {
            gaugeProp = JSON.parse(configStr);
        } else {
            gaugeProp.toolBoxConfig.saveAsImg = false;
            gaugeProp.titleConfig.show = false;
        }
        this.modalService.create({
            nzTitle: '仪表盘配置',
            nzWidth: '50%',
            nzContent: ChartGaugeConfigComponent,
            nzComponentParams: {
                prop: gaugeProp
            },
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then((param: GaugeProperty) => {
                this.selectedObj.select('div').attr(DataAttrName.config, JSON.stringify(param));
                return true;
            })
        });
    }
}
