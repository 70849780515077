import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->组件分类
 */
@Injectable({ providedIn: 'root' })
export class ComClassifyApi {
    private prefix = '/design/classify';

    constructor(private http: HttpService, private message: NzMessageService) {
    }

    /**
     * 获取组件分类信息
     */
    async getAll() {
        return await this.http.get(`${this.prefix}/getAll`);
    }

    /**
     * 创建组件分类
     * @param name 分类名称
     */
    async create(name: string) {
        if (!name) {
            this.message.warning('参数错误[分类名称]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/create`, { name });
    }

    /**
     * 创建组件分类
     * @param name 分类名称
     * @param id 选择的分类id
     */
    async createWithSelectId(name: string, id: string) {
        if (!name || !id) {
            this.message.warning('参数错误 ！');
            return;
        }
        return await this.http.post_str(`${this.prefix}/createWithSelectId`, { name, id });
    }

    /**
     * 重命名
     * @param id 分类id
     * @param name 分类名称
     */
    async rename(id: string, name: string) {
        if (!id) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        if (!name) {
            this.message.warning('参数错误[分类名称]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/rename`, { id, name });
    }

    /**
     * 删除分类
     * @param id 分类id
     */
    async delete(id: string) {
        if (!id) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        return await this.http.post_str(`${this.prefix}/delete`, { id });
    }

    /**
     * 打包下载
     * @param id 分类id
     */
    async packDownload(id: string) {
        if (!id) {
            this.message.warning('参数错误[分类id]');
            return;
        }
        return await this.http.download(`${this.prefix}/packDownload`, { id });
    }
}
