import { Component } from '@angular/core';

@Component({
    selector: 'app-help-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.css']
})
export class InputHelpComponent {
    constructor(
    ) {
    }
}

