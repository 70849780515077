import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService, } from '../_services';
import { NameInputComponent, SaveSvgPageComponent, PageUpLoadComponent } from '../_modals';
import { ModuleApi, PageApi } from '../api';
import { Page } from '../_models';
import { DataCache } from '../cache';

/**
 * 组件分类标题右键菜单
 */
@Component({
    selector: 'app-page-title-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='createModule($event)'>
                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>创建模块
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='importPage($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入页面
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='createPage($event)'>
                <i nz-icon nzType="file-add" nzTheme="outline"></i>创建页面
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})
export class PageTitleContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private moduleApi: ModuleApi,
        private pageApi: PageApi
    ) { }

    /**
     * 创建模块
     */
    createModule(event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '创建模块',
            nzComponentParams: {
                tipMsg: '请输入模块名称...'
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    const ret = await this.moduleApi.createWithName(DataCache.project.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadProjectEvent.next(DataCache.project.id);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 创建页面
     */
    createPage(event) {
        this.modalService.create({
            nzTitle: '创建页面',
            nzContent: SaveSvgPageComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async (data: Page) => {
                if (data) {
                    const ret = await this.pageApi.createEmpty(data);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.loadModuleEvent.next(data.moduleId);
                    }
                }
                return data;
            })
        });
    }

    /**
     * 导入页面
     * @param event 事件
     */
    importPage(event: Event) {
        this.modalService.create({
            nzTitle: '页面导入',
            nzContent: PageUpLoadComponent,
            nzOkText: '开始导入',
            nzOnOk: (ins) => ins.save()
        });
    }
}

