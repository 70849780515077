/**
 * 工程图标
 */
export class ProjectIcon {
    /**
     * 图标名称
     */
    Name: string;
    /**
     * 主题预览图标
     */
    Url: string;
}
