
        <input nz-input placeholder="请输入工程名称..." [(ngModel)]="name" autofocus />
        <div style='margin-top:10px;'></div>
        <label style='width:100%;'>请选择主题：</label>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <div nz-row [nzGutter]="4">
                <div nz-col [nzSpan]="4" *ngFor="let item of themes"  (click)="setTheme(item)">
                    <nz-card nzHoverable [nzCover]="coverTemplate" [nzTitle]="item.name" [nzExtra]="extraTemplate">
                        <nz-card-meta></nz-card-meta>
                    </nz-card>
                    <ng-template #coverTemplate>
                        <img alt="主题" [src]="item.preview" *ngIf="item.preview!=''" (dblclick)="preview(item)"/>
                    </ng-template>
                    <ng-template #extraTemplate>
                        <label nz-radio [(ngModel)]="item.id==themeId" (click)="setTheme(item)"></label>
                    </ng-template>
                </div>
            </div>
        </nz-spin>
        <nz-modal
            [(nzVisible)]="isVisible"
            [nzTitle]="previewTheme.name"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
            [nzFooter]="null"
            nzWidth="70%"
            nzHeight="70%"
        >
            <img *ngIf="isVisible" [src]="previewTheme.preview" style="width:100%;height:100%;"/>
        </nz-modal>
    