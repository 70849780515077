<nz-layout class="login">
  <div class="bg"></div>
  <div class="set" (click)="setService()"></div>

  <div class="login-box">
    <div class="panel">
      <img src="../assets/img/login-panel.png" />
    </div>
    <div class="info">
      <div class="logo">
        <span>TW低代码开发平台-V1.0</span>
      </div>
      <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()" autocomplete="off">
        <nz-form-item>
          <nz-form-control nzErrorTip="请输入用户名！">
            <nz-input-group nzPrefixIcon="user" nzSize="large">
              <input class="ant-input ant-input-lg" type="text" nz-input formControlName="userName" placeholder="用户名" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="请输入密码！">
            <nz-input-group nzPrefixIcon="lock" nzSize="large">
              <input class="ant-input ant-input-lg" type="password" nz-input formControlName="password"
                placeholder="密码" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzSize="large" class="login-form-button" [nzType]="'primary'" [nzLoading]="isLoading">登
              录</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>

</nz-layout>
