import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService } from '../_services';
import { NameInputComponent, FileUpLoadComponent, TemplateSaveAsComponent } from '../_modals';
import { Template, User } from '../_models';
import { TemplateApi } from '../api';
import { CommondAction } from '../_helpers/commondAction';

@Component({
    selector: 'app-template-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}">
            <li nz-menu-item (click)='edit($event)' style='font-weight:bold'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>编辑模板
            </li>
            <li nz-menu-item (click)='loadCache()'>
             <i nz-icon nzType="reload" nzTheme="outline"></i>加载缓存数据
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='reName()'>
                <i nz-icon nzType="edit" nzTheme="outline"></i>重命名
            </li>
            <li nz-menu-item (click)='delete($event)'>
                <i nz-icon nzType="delete" nzTheme="outline"></i>删除模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='saveAs($event)'>
                <i nz-icon nzType="save" nzTheme="outline"></i>另存为
            </li>
            <li nz-menu-item (click)='import($event)'>
                <i nz-icon nzType="import" nzTheme="outline"></i>导入模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='downLoad($event)'>
                <i nz-icon nzType="download" nzTheme="outline"></i>下载模板
            </li>
            <nz-divider></nz-divider>
            <li nz-menu-item (click)='setProperty($event)'>
                <span class="iconfont iconshuxing-"></span>属性
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
        nz-divider{
            margin: 2px 0;
        }
    `]
})

/**
 * 工程模板右键菜单
 */
export class TemplateContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;
    @Input() template: Template;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private templateApi: TemplateApi
    ) { }

    /**
     * 重命名
     */
    reName() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '修改模板名称',
            nzComponentParams: {
                tipMsg: '请输入新的模板名称...',
                inputName: this.template.text
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    if (name === this.template.text) {
                        return true;
                    }
                    const ret = await this.templateApi.rename(this.template.id, name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateChange.next(this.template.classifyId);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 编辑模板
     * @param event 事件
     */
    edit(event: Event) {
        if (CommondAction.isChange) {
            this.showConfirm().then((res: boolean) => {
                if (res) {
                    this.eventTransService.saveCanvasEvent.next(true);
                    return;
                } else {
                    this.editTemplate();
                }
            });
        } else {
            this.editTemplate();
        }
    }

    editTemplate() {
        this.eventTransService.loadCanvasDataEvent.next({
            id: this.template.id,
            isPage: false
        });
    }

    loadCache() {
        if (CommondAction.isChange) {
            this.showConfirm().then((res: boolean) => {
                if (res) {
                    this.eventTransService.saveCanvasEvent.next(true);
                } else {
                    this.loadTemplateCache();
                }
            });
        } else {
            this.loadTemplateCache();
        }
    }

    loadTemplateCache() {
        this.eventTransService.loadCanvasCacheDataEvent.next({
            id: this.template.id,
            data: JSON.parse(JSON.stringify(this.template)),
            isPage: false
        });
    }

    showConfirm() {
        return new Promise((resolve) => {
            this.modalService.confirm({
                nzTitle: '当前页面尚未保存，是否保存?',
                nzOkText: '是',
                nzCancelText: '否',
                nzOnOk: () => {
                    resolve(true);
                }, nzOnCancel: () => {
                    resolve(false);
                }
            });
        });
    }

    /**
     * 导入模板
     */
    import(event: Event) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '工程模板批量导入',
            nzComponentParams: {
                projectId: this.template.projectId,
                fileType: 'application/x-zip-compressed',
                fileTypeName: 'zip'
            },
            nzContent: FileUpLoadComponent,
            nzMaskClosable: false,
            nzOkText: '开始导入',
            nzOnOk: (ins) => ins.saveTemplate()
        });
    }

    /**
     * 删除组件
     */
    delete(event: any) {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.confirm({
            nzTitle: `<i>确认删除模板：${this.template.text}?</i>`,
            nzOkDanger: true,
            nzOnOk: async () => {
                const ret = await this.templateApi.delete(this.template.id);
                if (ret) {
                    this.message.info('操作成功');
                    this.eventTransService.templateChange.next(this.template.classifyId);
                }
            }
        });
    }

    /**
     * 另存为
     * @param event 事件
     */
    saveAs(event) {
        this.modalService.create({
            nzTitle: '工程模板另存为',
            nzComponentParams: {
                text: this.template.text,
                classifyId: this.template.classifyId
            },
            nzContent: TemplateSaveAsComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async data => {
                if (data) {
                    const ret = await this.templateApi.saveAs(this.template.id, data.text, data.classifyId);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateChange.next(data.classifyId);
                    }
                }
                return data;
            })
        });
    }

    /**
     * 属性设置
     * @param event 事件
     */
    setProperty(event) {
        this.modalService.create({
            nzTitle: '工程模板属性设置',
            nzComponentParams: {
                text: this.template.text,
                classifyId: this.template.classifyId
            },
            nzContent: TemplateSaveAsComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async data => {
                if (data) {
                    if (data.name === this.template.text && data.classifyId === this.template.classifyId) {
                        return true;
                    }
                    const ret = await this.templateApi.saveConfig(this.template.id, data.text, data.classifyId);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.templateChange.next(this.template.classifyId);
                        if (data.classifyId !== this.template.classifyId) {
                            this.eventTransService.templateChange.next(data.classifyId);
                        }
                    }
                }
                return data;
            })
        });
    }

    /**
     * 下载至本地
     */
    downLoad(event: any) {
        const user: User = JSON.parse(sessionStorage.getItem('user'));
        const a = document.createElement('a');
        // a.href = localStorage.getItem('Domain') + '/LeftNav/DownloadTemplate?ProjectID=' + this.projectId
        //     + '&TemplateID=' + this.template.ID + '&AccountSetID=' + user.actId;
        a.target = '_blank';
        a.click();
    }
}

