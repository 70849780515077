import { Component } from '@angular/core';

@Component({
    selector: 'app-help-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css']
})
export class DateHelpComponent {
    constructor(
    ) {
    }
}

