import { Pipe, PipeTransform } from '@angular/core';
import { ComClassify } from '../_models';

@Pipe({
    name: 'classifyTitlePipe'
})

export class ClassifyTitlePipe implements PipeTransform {
    transform(item: ComClassify): string {
        if (item.count) {
            return `${item.text} (${item.count})`;
        }
        return item.text;
    }
}
