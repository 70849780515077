/**
 * 同环比配置
 */
export class YearMonthConfig {
    /**
     * 显示环比数据
     */
    showMonth = false;
    /**
     * 显示同比数据
     */
    showYear = false;
}
