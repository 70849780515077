
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <label>组件名称：</label>
            <input #input nz-input placeholder="组件名称" [(ngModel)]="cname" autofocus/>
            <label>组件分类：</label>
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group class="classify" [(ngModel)]="classifyId" [nzButtonStyle]="'solid'" *ngFor="let item of classifies">
                    <label nz-radio-button nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    