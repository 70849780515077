/**
 * 图表类型
 */
export class ChartType {
    /**
     * 曲线图
     */
    public static Line = 'line';
    /**
     * 柱图
     */
    public static Bar = 'bar';
    /**
     * 饼图
     */
    public static Pie = 'pie';
    /**
     * 仪表盘
     */
    public static Gauge = 'gauge';
}
