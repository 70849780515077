import { Component } from '@angular/core';

@Component({
    selector: 'app-help-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.css']
})
export class LineHelpComponent {
    constructor(
    ) {
    }
}

