import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataCache } from '../cache';
import { RegexUtil } from '../utils';

/*文件-新建-模块 */
@Component({
    selector: 'app-service-set',
    template: `
        <div class="space">
            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzAddOnAfter=":" style="width:70%;">
                <input type="text" nz-input [(ngModel)]="ip" />
            </nz-input-group>
            <nz-input-number style="width:30%;" [(ngModel)]="port" [nzMin]="1" [nzMax]="65535" [nzStep]="1"></nz-input-number>
        </div>

        <nz-input-group nzAddOnBefore="API 版本">
            <nz-select [(ngModel)]="version">
                <nz-option nzLabel="v1" nzValue="v1"></nz-option>
            </nz-select>
        </nz-input-group>

        <ng-template #addOnBeforeTemplate>
            <nz-select [(ngModel)]="protocol">
                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
            </nz-select>
        </ng-template>
    `,
    styles: [
        `
            .space {
                margin :20px 0 20px 0;
            }
        `
    ]
})
/**
 * 服务器设置
 */
export class ServiceSetComponent implements OnInit, OnDestroy {
    @Input() protocol = 'http://';
    @Input() address: string;
    @Input() version = 'v1';

    ip: string;
    port: string;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
    ) {
    }

    ngOnInit() {
        if (this.address && this.address.indexOf(':') >= 0) {
            this.ip = this.address.split(':')[0];
            this.port = this.address.split(':')[1];
        }
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.ip || !this.port) {
                this.message.warning('服务器地址输入错误！');
                resolve(false);
                return;
            }
            if (!this.version) {
                this.message.warning('请选择API版本！');
                resolve(false);
                return;
            }

            const data = {
                domin: `${this.protocol}${this.ip}:${this.port}`,
                version: this.version,
            };
            localStorage.setItem('sysConfig', JSON.stringify(data));
            DataCache.sysConfig = data;
            resolve(true);
        });
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
