import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EventTransService, } from '../_services';
import { NameInputComponent } from '../_modals';
import { ComClassifyApi } from '../api';

/**
 * 组件标题右键菜单
 */
@Component({
    selector: 'app-com-title-contextmenu',
    template: `
        <ul [ngStyle]="{'left.px':x, 'top.px':y}" (contextmenu)='menuRightClick($event)'>
            <li nz-menu-item (click)='createClassify()'>
                <i nz-icon nzType="folder-add" nzTheme="outline"></i>添加组件分类
            </li>
        </ul>
    `,
    styles: [`
        ul{
            width: 150px;
            position: fixed;
            box-shadow: 0 0 5px #fcfcfc8a !important;
            border-radius: 5px;
            z-index: 99999;
            background:#1f1f1f;
        }
        ul li{
            margin:0 !important;
            line-height:35px;
            height:35px;
        }
    `]
})
export class ComTitleContextMenuComponent {
    @Input() x = 200;
    @Input() y = 200;

    constructor(
        private modalService: NzModalService,
        private message: NzMessageService,
        private eventTransService: EventTransService,
        private classifyApi: ComClassifyApi
    ) { }

    /**
     * 添加组件分类
     */
    createClassify() {
        this.eventTransService.menuStateChangeEvent.next({});
        this.modalService.create({
            nzTitle: '创建组件分类',
            nzComponentParams: {
                tipMsg: '请输入组件分类...'
            },
            nzContent: NameInputComponent,
            nzMaskClosable: false,
            nzOnOk: (ins) => ins.save().then(async name => {
                if (name) {
                    const ret = await this.classifyApi.create(name);
                    if (ret) {
                        this.message.success('操作成功！');
                        this.eventTransService.classifysChangeEvent.next(true);
                    }
                }
                return name;
            })
        });
    }

    /**
     * 鼠标右键-阻止默认菜单
     */
    menuRightClick(event: Event) {
        event.preventDefault();
    }
}

