import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Module } from '../_models';
import { ModuleApi } from '../api';
import { DataCache } from '../cache';

/**
 * 系统页面
 */
@Component({
    selector: 'app-module-prop',
    template: `
        <nz-input-group nzAddOnBefore="模块名称" style="width:100%;">
            <input type="text" nz-input [(ngModel)]="module.text" placeholder="请输入模块名称"/>
        </nz-input-group>
        <div style="margin-top:5px;"></div>
        <label>上级模块：</label>
        <button nz-button nzType="link" (click)="clearParent()">清除选择(一级模块)</button>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="module.parentId" [nzButtonStyle]="'solid'">
                    <label nz-radio-button *ngFor="let item of modules" nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    `,
    styles: [
        `.infinite-container{
            height: 200px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }
        input{
            margin-bottom:10px;
        }
        nz-radio-group,label{
            width:100%;
        }
        nz-input-group{
            margin-top:5px;
        }
        nz-input-group,input{
            width:80%;
        }
        `
    ]
})
export class ModulePropComponent implements OnInit, OnDestroy {

    @Input() module: Module = new Module();

    modules: Module[] = [];
    allModules: Module[] = [];
    isLoading = true;

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private moduleApi: ModuleApi,
    ) { }

    ngOnInit() {
        this.init();
    }

    async init() {
        this.allModules = await this.moduleApi.getAll(DataCache.project.id);
        this.modules = this.allModules.filter(d => !d.parentId && d.id !== this.module.id);
        this.isLoading = false;
    }

    save(): any {
        return new Promise(resolve => {
            if (!this.module.text) {
                this.message.warning(`请输入模块名称！`);
                resolve(false);
                return;
            }
            if (this.module.parentId && this.allModules.find(d => d.parentId === this.module.id)) {
                this.message.warning('嵌套层数过多，暂不支持！');
                resolve(false);
                return;
            }
            resolve(this.module);
        });
    }

    clearParent() {
        this.module.parentId = '';
    }

    ngOnDestroy() {
        this.modal.destroy();
    }
}
