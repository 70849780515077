
/*组件数据 */
export class ComponentData {
    /**
     * 组件id
     */
    id: string;
    /**
     * 组件名称
     */
    text: string;
    /**
     * 所属分类id
     */
    classifyId: string;
    /**
     * 组件数据
     */
    base64: string;
    /**
     * 组件类型
     */
    type: string;
    /**
     * 是否系统组件
     */
    sys: string;
    /**
     * 排序编号
     */
    sort: number;

    width?: number;
    height?: number;
}

