/**
 * 查找类型
 */
export enum FindType {
    /**
     * 数据源
     */
    source = 1,
    /**
     * 组件id
     */
    id = 2,
    /**
     * 数据源脚本
     */
    sourceJs = 3,
    /**
     * 事件脚本
     */
    eventJs = 4
}