export class EventCache {
    /**
     * 通用事件
     */
    static common = [
        { name: 'click', desc: '鼠标单击' },
        { name: 'dblclick', desc: '鼠标双击' },
        { name: 'mousedown', desc: '鼠标按下' },
        { name: 'mouseup', desc: '鼠标松开' },
        { name: 'mouseover', desc: '鼠标悬浮' },
        { name: 'mouseleave', desc: '鼠标离开' },
        { name: 'change', desc: '内容变更' },
        { name: 'keypress', desc: '键盘回车' }
    ];

    /**
     * 组件事件
     */
    static component = {
        tree: [
            { name: 'loadSuccess', desc: '加载完成' },
            { name: 'nodeSelect', desc: '节点选中' }
        ],
        'chart-line': [
            { name: 'dataLoadSuccess', desc: '数据加载完成' },
            { name: 'loadSuccess', desc: '图表加载完成' }
        ],
        'chart-bar': [
            { name: 'dataLoadSuccess', desc: '数据加载完成' },
            { name: 'loadSuccess', desc: '图表加载完成' }
        ],
        'chart-pie': [
            { name: 'dataLoadSuccess', desc: '数据加载完成' },
            { name: 'loadSuccess', desc: '图表加载完成' }
        ],
        'chart-gauge': [
            { name: 'dataLoadSuccess', desc: '数据加载完成' },
            { name: 'loadSuccess', desc: '图表加载完成' }
        ],
        'slider': [
            { name: 'finish', desc: '拖动结束' },
            { name: 'format', desc: '格式化' }
        ],
        'select': [
            { name: 'loadSuccess', desc: '加载完成' }
        ]
    };
}
