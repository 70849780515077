
        <nz-input-group nzAddOnBefore="名称" style="width:100%;">
            <input type="text" nz-input [(ngModel)]="text" placeholder="请输入模板名称"/>
        </nz-input-group>
        <div style="margin-top:10px;"></div>
        <label>所属模板分类：</label>
        <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
            <cdk-virtual-scroll-viewport itemSize="10" class="infinite-container">
                <nz-radio-group [(ngModel)]="classifyId" [nzButtonStyle]="'solid'" *ngFor="let item of classifies">
                    <label nz-radio-button nzValue="{{item.id}}">{{item.text}}</label>
                </nz-radio-group>
            </cdk-virtual-scroll-viewport>
        </nz-spin>
    