import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '../_services';


/**
 * web组态API-工程->数据
 */
@Injectable({ providedIn: 'root' })
export class ThemeApi {
    private prefix = '/design/theme';

    constructor(private http: HttpService) {
    }

    /**
     * 获取主题列表
     */
    async getAll() {
        return await this.http.get(`${this.prefix}/getAll`);
    }

}
