/**
 * 时段展示模式
 */
export enum TimeRangeModalEnum {
    /**
     * 堆叠
     */
    STACK = 'stack',
    /**
     * 汇总
     */
    SUM = 'sum'
}
